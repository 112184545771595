/**
 * Created by r4zi4l on 21.06.2021
 */

var BoostTimeMissionWindow = CleverappsWindow.extend({
    onWindowLoaded: function (mission) {
        this.mission = mission;
        this.pack = BoostTimeMissionWindow.PACK;

        this._super({
            name: "BoostTimeMissionWindow",
            content: this.createContent(),
            title: "BoostTimeMissionWindow.title",
            noBackground: true,
            contentPadding: cleverapps.styles.BoostTimeMissionWindow.contentPadding,
            notCloseByTouchInShadow: true,
            closeButtonDelay: true
        });

        cleverapps.meta.showControlsWhileFocused(["MenuBarGoldItem", "MenuBarCoinsItem"]);
    },

    getPerson: function () {
        return cleverapps.styles.BoostTimeMissionWindow.person;
    },

    createContent: function () {
        var styles = cleverapps.styles.BoostTimeMissionWindow;

        var container = new cc.Node();
        container.setContentSize2(styles);
        container.setAnchorPoint(0.5, 0.5);

        if (bundles.boosttime.jsons.boost_time_json) {
            var animation = new cleverapps.Spine(bundles.boosttime.jsons.boost_time_json);
            animation.setAnimation(0, "animation", true);
            container.addChild(animation);
            animation.setPositionRound(styles.animation);
        } else {
            var animation2 = new cc.Sprite(bundles.boosttime.frames.coins_animation);
            animation2.setPositionRound(styles.animation);
            container.addChild(animation2);
        }

        var description = cleverapps.UI.generateOnlyText("BoostTimeMissionWindow.description", cleverapps.styles.FONTS.BOOSTTIMEMISSIONWINDOW_DESCRIPTION_TEXT);
        description.setPositionRound(styles.description);
        description.setDimensions(styles.description.width, 0);
        description.fitTo(undefined, styles.description.height);
        description.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        container.addChild(description);

        if (bundles.boosttime.frames.coins_pack) {
            var coinsPack = new cc.Sprite(bundles.boosttime.frames.coins_pack);
            container.addChild(coinsPack);
            coinsPack.setPositionRound(styles.coinsPack);

            var coinsReward = new TextWithIcon("@@x" + this.pack.prizes.soft, {
                font: cleverapps.styles.FONTS.BOOSTTIMEMISSIONWINDOW_REWARD_TEXT
            });

            coinsReward.setPositionRound(styles.coinsPack.reward);
            coinsPack.addChild(coinsReward);

            var badge = new cc.Sprite(bundles.badge.frames.badge_png);
            badge.setScale(0.7);
            var offerBadge = cleverapps.UI.generateOnlyText("BoostTimeMissionWindow.offerBadge", cleverapps.styles.FONTS.BOOSTTIMEMISSIONWINDOW_BADGE_TEXT);
            badge.addChild(offerBadge);
            offerBadge.setPositionRound(badge.width / 2, badge.height / 2);

            coinsPack.addChild(badge);
            badge.setPositionRound(styles.coinsPack.badge);
        }

        var packTitle = this.createPackTitle();
        container.addChild(packTitle);

        var buyPackButton = new UseGoldButton({
            price: this.pack.price,
            eventName: cleverapps.EVENTS.SPENT.OFFER + "_" + this.mission.getName(),
            onClicked: this.buyPackProduct.bind(this),
            width: styles.buyPackButton.width,
            height: styles.buyPackButton.height
        });
        buyPackButton.setPositionRound(styles.buyPackButton);
        container.addChild(buyPackButton);

        return container;
    },

    createPackTitle: function () {
        var styles = cleverapps.styles.BoostTimeMissionWindow;

        var title = cleverapps.UI.generateOnlyText("BoostTimeMissionWindow.packTitle", cleverapps.styles.FONTS.BOOSTTIMEMISSIONWINDOW_TITLE_TEXT);
        title.fitTo(styles.ribbon.title.width);
        title.setPositionRound(styles.ribbon.title);

        if (!bundles.boosttime.frames.purple_ribbon) {
            console.log(styles.ribbon);
            title.setPositionRound(styles.ribbon);

            return title;
        }

        var ribbon = new cc.Sprite(bundles.boosttime.frames.purple_ribbon);
        ribbon.addChild(title);
        ribbon.setPositionRound(styles.ribbon);

        return ribbon;
    },

    buyPackProduct: function () {
        new RewardWindow(this.pack.prizes, { event: cleverapps.EVENTS.EARN.PURCHASE });
        this.close();
    },

    listBundles: function () {
        return ["boosttime"];
    }
});

BoostTimeMissionWindow.PACK = {
    price: 50,
    prizes: {
        soft: 250
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BOOSTTIMEMISSIONWINDOW_TITLE_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    BOOSTTIMEMISSIONWINDOW_DESCRIPTION_TEXT: {
        size: 42,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    BOOSTTIMEMISSIONWINDOW_REWARD_TEXT: {
        size: 40,
        name: "default"
    },

    BOOSTTIMEMISSIONWINDOW_BADGE_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.BoostTimeMissionWindow = {
    width: 1200,
    height: 1000,

    person: "worker",

    contentPadding: {
        left: 200,
        right: 200
    },

    animation: {
        x: { align: "center", dx: -20 },
        y: { align: "center", dy: 0 }
    },

    description: {
        x: { align: "center" },
        y: { align: "center", dy: -400 },
        width: 1200,
        height: 180
    },

    ribbon: {
        x: { align: "right", dx: 260 },
        y: { align: "center", dy: 220 },
        title: {
            x: { align: "center" },
            y: { align: "center", dy: 23 },
            width: 280
        }
    },

    buyPackButton: {
        x: { align: "right", dx: 140 },
        y: { align: "center", dy: -200 },
        width: 230,
        height: 100
    },

    coinsPack: {
        x: { align: "right", dx: 200 },
        y: { align: "center", dy: 30 },

        reward: {
            x: { align: "center", dx: -10 },
            y: { align: "center", dy: -124 }
        },

        badge: {
            x: { align: "center", dx: 145 },
            y: { align: "center", dy: -60 }
        }
    }
};
