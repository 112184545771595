/**
 * Created by spepa on 11.01.2024
 */

Landmarks.WORLDS.china = {
    fog: "fog2",
    force: Forces.LANDMARK_DONOR_SLOT1,
    landmarks: [{
        code: "landmark",
        stage: 13,
        donors: ["chdragonrainbow"]
    }]
};

Mines.chsource = Mines.easource;
Mines.chsourcegenerator = function () {
    return { openedType: "chsource" };
};

PassLevelsConfig[Mission.CompoundType(Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_CHINA_EXPEDITION)] = [
    {
        energy: 20,
        task: {
            goal: 15
        },
        reward: {
            unit: { code: "worker", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "worker", stage: 2, amount: 1 }
        }
    }, // 1
    {
        energy: 20,
        task: {
            goal: 45
        },
        reward: {
            unit: { code: "sacks", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "sacks", stage: 1, amount: 1 }
        }
    }, // 2
    {
        energy: 20,
        task: {
            goal: 100
        },
        reward: {
            unit: { code: "wands", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "wands", stage: 2, amount: 1 }
        }
    }, // 3
    {
        energy: 20,
        task: {
            goal: 210
        },
        reward: {
            unit: { code: "coins", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "rubies", stage: 3, amount: 1 }
        }
    }, // 4
    {
        energy: 20,
        task: {
            goal: 430
        },
        reward: {
            unit: { code: "crystal", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 1, amount: 3 }
        }
    }, // 5
    {
        energy: 20,
        task: {
            goal: 900
        },
        reward: {
            unit: { code: "treasure", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "treasure", stage: 2, amount: 1 }
        }
    }, // 6
    {
        energy: 20,
        task: {
            goal: 1100
        },
        reward: {
            unit: { code: "sacks", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "sacks", stage: 2, amount: 2 }
        }
    }, // 7
    {
        energy: 20,
        task: {
            goal: 1300
        },
        reward: {
            unit: { code: "herochest", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "herochest", stage: 2, amount: 3 }
        }
    }, // 8
    {
        energy: 20,
        task: {
            goal: 1500
        },
        reward: {
            unit: { code: "crystal", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 3, amount: 2 }
        }
    }, // 9
    {
        energy: 20,
        task: {
            goal: 2100
        },
        reward: {
            unit: { code: "herochest", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "herochest", stage: 2, amount: 2 }
        }
    }, // 10
    {
        energy: 20,
        task: {
            goal: 2600
        },
        reward: {
            unit: { code: "coins", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "treasure", stage: 2, amount: 2 }
        }
    }, // 11
    {
        energy: 20,
        task: {
            goal: 3200
        },
        reward: {
            unit: { code: "worker", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "worker", stage: 3, amount: 3 }
        }
    }, // 12
    {
        energy: 20,
        task: {
            goal: 3800
        },
        reward: {
            unit: { code: "magicplant", stage: 3, amount: 1 }
        },
        premiumReward: {
            unit: { code: "magicplant", stage: 5, amount: 1 }
        }
    }, // 13
    {
        energy: 20,
        task: {
            goal: 4400
        },
        reward: {
            unit: { code: "treasure", stage: 1, amount: 2 }
        },
        premiumReward: {
            unit: { code: "treasure", stage: 2, amount: 3 }
        }
    }, // 14
    {
        energy: 20,
        task: {
            goal: 5000
        },
        reward: {
            unit: { code: "wands", stage: 1, amount: 2 }
        },
        premiumReward: {
            unit: { code: "wands", stage: 3, amount: 3 }
        }
    }, // 15
    {
        energy: 20,
        task: {
            goal: 5700
        },
        reward: {
            unit: { code: "crystal", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 3, amount: 3 }
        }
    }, // 16
    {
        energy: 20,
        task: {
            goal: 6500
        },
        reward: {
            unit: { code: "coins", stage: 2, amount: 3 }
        },
        premiumReward: {
            unit: { code: "herochest", stage: 2, amount: 2 }
        }
    }, // 17
    {
        energy: 20,
        task: {
            goal: 7400
        },
        reward: {
            unit: { code: "worker", stage: 3, amount: 2 }
        },
        premiumReward: {
            unit: { code: "worker", stage: 4, amount: 2 }
        }
    }, // 18
    {
        energy: 20,
        task: {
            goal: 8300
        },
        reward: {
            unit: { code: "worker", stage: 4, amount: 1 }
        },
        premiumReward: {
            unit: { code: "magicplant", stage: 7, amount: 1 }
        }
    }, // 19
    {
        energy: 20,
        task: {
            goal: 10000
        },
        reward: {
            unit: { code: "coinstree", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "energytree", stage: 3, amount: 1 }
        }
    } // 20
];

CustomerRecipes.china = {
    chnarrator_0: [
        {
            ingredients: [
                {
                    type: "chresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "chorder",
                    stage: 0,
                    amount: 3
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "chresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "chorder",
                    stage: 0,
                    amount: 5
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "chresource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "chresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "chorder",
                    stage: 0,
                    amount: 3
                },
                {
                    type: "chorder",
                    stage: 1,
                    amount: 3
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "chresource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "chresource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "chresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "chorder",
                    stage: 0,
                    amount: 3
                },
                {
                    type: "chorder",
                    stage: 0,
                    amount: 3
                },
                {
                    type: "chorder",
                    stage: 1,
                    amount: 4
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "chresource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "chresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "chorder",
                    stage: 0,
                    amount: 5
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "chresource",
                    stage: 3,
                    amount: 2
                },
                {
                    type: "chresource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "chorder",
                    stage: 0,
                    amount: 3
                },
                {
                    type: "chorder",
                    stage: 1,
                    amount: 3
                }
            ]
        }

    ],
    chthrone_0: [
        {
            ingredients: [
                {
                    type: "chorder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "chhero",
                    stage: 0,
                    amount: 3
                },
                {
                    type: "chhero",
                    stage: 1,
                    amount: 2
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "chorder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "chhero",
                    stage: 0,
                    amount: 1
                },
                {
                    type: "chhero",
                    stage: 1,
                    amount: 3
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "chorder",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "chorder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "chhero",
                    stage: 0,
                    amount: 1
                },
                {
                    type: "chhero",
                    stage: 1,
                    amount: 6
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "chorder",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "chorder",
                    stage: 3,
                    amount: 2
                }
            ],
            order: [
                {
                    type: "chhero",
                    stage: 0,
                    amount: 1
                },
                {
                    type: "chhero",
                    stage: 1,
                    amount: 6
                }
            ]
        }
    ]
};

cleverapps.styles.china = {
    miningAnimation: {
        x: { align: "center", dx: 0, leftDx: 0 },
        y: { align: "center", dy: 0 }
    },

    PixelView: {
        width: 80,
        height: 190,
        distanceDisappear: 1000,
        animation: {
            x: { align: "center" },
            y: { align: "center", dy: 0 }
        },
        collect: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 20 },
            delay: 10
        },
        timeScale: {
            base: 1,
            random: 0
        },
        speed: {
            x: 35,
            y: -18
        }

    }
};
