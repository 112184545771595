/**
 * Created by vladislav on 25/10/2022
 */

QuestsConfig.undersea3 = cleverapps.clone(QuestsConfig.universal, true);

delete QuestsConfig.undersea3.rpcustomermain0b_1;
delete QuestsConfig.undersea3.rpcustomermain1b_1;
delete QuestsConfig.undersea3.rpcustomermain1c_1;
delete QuestsConfig.undersea3.rpcustomermain2b_1;
delete QuestsConfig.undersea3.rpcustomermain2c_1;
delete QuestsConfig.undersea3.rpcustomermain2d_1;
delete QuestsConfig.undersea3.rpcustomermain3b_1;
delete QuestsConfig.undersea3.rpcustomermain3c_1;
delete QuestsConfig.undersea3.rpcustomermain3d_1;

QuestsConfig.undersea3.rpcustomerbridge1_1.trigger.unit = { code: "rpcustomermain0a", stage: 2 };
QuestsConfig.undersea3.rpcustomerbridge2_1.trigger.unit.code = "rpcustomermain1a";
QuestsConfig.undersea3.rpcustomerbridge3_1.trigger.unit.code = "rpcustomermain2a";
QuestsConfig.undersea3.rpproducer0.trigger.unit.stage = 1;

QuestsConfig.undersea3.rpcustomermain1a_0 = { 
    trigger: { unit: { code: "rpcustomermain0a", stage: 2 } },
    quest: {
        type: Map2d.OPEN_UNIT,
        unit: { code: "rpcustomermain1a", stage: 0 }
    }   
};

QuestsConfig.undersea3.rpcustomermain2a_0 = { 
    trigger: { unit: { code: "rpcustomermain1a", stage: 1 } },
    quest: {
        type: Map2d.OPEN_UNIT,
        unit: { code: "rpcustomermain2a", stage: 0 }
    }   
};

QuestsConfig.undersea3.rpcustomermain3a_0 = { 
    trigger: { unit: { code: "rpcustomermain2a", stage: 1 } },
    quest: {
        type: Map2d.OPEN_UNIT,
        unit: { code: "rpcustomermain3a", stage: 0 }
    }   
};