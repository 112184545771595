/**
 * Created by razial on 23.05.2024.
 */

QuestsConfig.adventure2 = {
    advbush_7: {
        trigger: { fog: "fog0" },
        tutorial: "advbush_7",
        quest: {
            type: Map2d.MINE,
            unit: { code: "advbush", stage: 7 }
        }
    },

    adv2customerbridge0_1: {
        trigger: { unit: { code: "adv2customerbridge0", stage: 0 } },
        tutorial: "adv2customerbridge0_1",
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "adv2customerbridge0", stage: 1 }
        }
    }
};
