/**
 * Created by ivan on 11.04.18.
 */

var CustomersToolbarItem = function () {
    ToolbarItem.call(this, ToolbarItem.CUSTOMERS);

    this.enable();
};

CustomersToolbarItem.prototype = Object.create(ToolbarItem.prototype);
CustomersToolbarItem.prototype.constructor = CustomersToolbarItem;

CustomersToolbarItem.prototype.resetState = function () {
    if (Game.currentGame.customers.listActive().length) {
        this.enable();
    } else {
        this.disable();
    }

    if (Game.currentGame.customers.listReady().length) {
        this.mark();
    } else {
        this.unmark();
    }
};

CustomersToolbarItem.prototype.isAvailableOnScene = function () {
    return ["rapunzel"].indexOf(cleverapps.travelBook.getCurrentPage().id) !== -1;
};

CustomersToolbarItem.prototype.onClick = function () {
    var customer = Game.currentGame.customers.listReady()[0];
    if (!customer) {
        customer = Game.currentGame.customers.listActive()[0];
    }

    Game.currentGame.customers.showWindow(customer);
};
