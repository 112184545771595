/**
 * Created by iamso on 04.10.2021
 */

var SeasonWindow = CleverappsWindow.extend({
    onWindowLoaded: function (season) {
        this.season = season;

        this._super({
            name: "seasonwindow",
            title: "SeasonWindow.title.season" + Season.getCurrentSeason(),
            foreground: bundles.windows.frames.window_foreground_png,
            noPadding: cleverapps.styles.SeasonWindow.noPadding,
            help: function () {
                new GuideWindow(SeasonGuideOptions);
            },
            content: this.createContent()
        });
    },

    createContent: function () {
        this.content = new cc.Node();
        this.content.setAnchorPoint(0.5, 0.5);
        this.content.setContentSize2(cleverapps.styles.SeasonWindow);

        this.createTimerNode();
        this.createItems();
        this.createTop();

        return this.content;
    },

    createTop: function () {
        var styles = cleverapps.styles.SeasonWindow.top;

        var top = this.top = cleverapps.UI.createScale9Sprite(bundles.season_window.frames.top_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        this.content.addChild(top);
        top.setContentSize2(cleverapps.styles.SeasonWindow.top);
        top.setPositionRound(styles);

        var icon = new cc.Sprite(bundles.season_window.frames.season_icon);
        top.addChild(icon);
        icon.setPositionRound(styles.icon);

        this.createShopButton();
        this.createDescription();
    },

    createTimerNode: function () {
        var styles = cleverapps.styles.SeasonWindow.timer;
        var timerNode;

        var timeLeft = Season.getTimeLeft();
        if (timeLeft > 0) {
            var countDown = new cleverapps.CountDown(timeLeft, {
                onFinish: function () {
                    this.timerNode.removeFromParent();
                    this.createTimerNode();
                }.bind(this)
            });

            var background = new cc.Scale9Sprite(bundles.season_window.frames.timer_substrate);
            background.setContentSize2(styles);

            var countdownView = new cleverapps.CountDownView(countDown, {
                font: cleverapps.styles.FONTS.SEASONAL_TIMER_TEXT,
                background_content: background,
                icon: bundles.season_window.frames.timer_icon,
                timerPosition: styles.timerPosition
            });
            countdownView.fitTo(styles.countdown.width, 0);

            var title = cleverapps.UI.generateOnlyText("SeasonWindow.timer", cleverapps.styles.FONTS.WINDOW_TEXT);
            timerNode = new cleverapps.Layout([title, countdownView], {
                direction: cleverapps.UI.HORIZONTAL,
                margin: styles.margin
            });
        } else {
            timerNode = cleverapps.UI.generateOnlyText("SeasonWindow.finished", cleverapps.styles.FONTS.WINDOW_TEXT);
        }

        this.timerNode = timerNode;
        this.content.addChild(timerNode);
        timerNode.setPositionRound(styles);
    },

    createItems: function () {
        var styles = cleverapps.styles.SeasonWindow.scroll;

        var stages = this.stages = this.season.getSeasonItems().map(function (unitData, id) {
            var stage = new SeasonStage(this.season, unitData);

            if (this.season.isOpened(id) && this.season.isFresh(id)) {
                cleverapps.UI.onClick(stage, this.close.bind(this));
            }

            return stage;
        }, this);

        var itemsNodes = this.itemsNode = new cleverapps.Layout(stages, {
            direction: cleverapps.UI.VERTICAL,
            padding: styles.padding
        });

        var scroll = this.scroll = new cleverapps.UI.ScrollView(itemsNodes, {
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL,
            scrollBarEnabled: false
        });

        scroll.setContentSize2(styles);
        scroll.scrollTo(cleverapps.UI.ScrollView.SCROLLS.UPPER_LEFT);
        this.content.addChild(scroll);
        scroll.setPositionRound(styles);

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.5),
            new cc.CallFunc(this.scrollToLast.bind(this))
        ));
    },

    scrollToLast: function () {
        var lastStage = this.season.opened;
        if (this.season.hasFresh()) {
            lastStage--;
        }

        var callback = function () {
            var freshStages = this.season.getFreshStages();
            freshStages.forEach(function (stage) {
                this.stages[stage].freshStageAnimation();
            }.bind(this));
        }.bind(this);

        if (this.scroll.scrollHandler.isProcessing()) {
            callback();
        } else {
            this.scroll.scrollTo(this.stages[lastStage], 0.8, {
                speed: 0.1,
                easing: cc.easeBackInOut(),
                callback: callback
            });
        }
    },

    createShopButton: function () {
        var styles = cleverapps.styles.SeasonWindow.top;

        var seasonChests = this.season.getSeasonChests();
        var openShop = function () {
            new UnitsShopWindow({ tab: ShopWindow.TABS.UNITS_DISCOUNT, priorityUnit: seasonChests[2] });
        };

        var button = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.small_button_green,
            width: styles.button.width,
            height: styles.button.height,
            text: "SeasonWindow.taskHeader.button.season",
            onClicked: openShop
        });

        var isDiscountShopAvailable = cleverapps.unitsShop.getAvailableShops().filter(function (shop) {
            return shop.tab === ShopWindow.TABS.UNITS_DISCOUNT;
        }).length > 0;
        isDiscountShopAvailable ? button.enable() : button.disable();

        this.top.addChild(button);
        button.setPositionRound(styles.button);
    },

    createDescription: function () {
        var styles = cleverapps.styles.SeasonWindow.top.description;

        var text = cleverapps.UI.generateOnlyText("SeasonWindow.description.season", cleverapps.styles.FONTS.SEASONAL_DESCRIPTION_TEXT);
        text.fitTo(styles.width);
        this.top.addChild(text);
        text.setPositionRound(styles);
    },

    listBundles: function () {
        return ["season_window"];
    }
});

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    SEASONAL_DESCRIPTION_COLOR: new cc.Color(247, 232, 81)
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SEASONAL_TIMER_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.WHITE
    },

    SEASONAL_DESCRIPTION_TEXT: {
        size: 38,
        color: cleverapps.styles.COLORS.SEASONAL_DESCRIPTION_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.SeasonWindow = {
    height: 1050,
    width: 1080,
    noPadding: true,

    scroll: {
        height: 690,
        width: 1025,
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 20 },

        padding: {
            left: 0,
            right: 0,
            top: 25,
            bottom: 30
        }
    },

    timer: {
        width: 270,
        height: 58,
        margin: 90,
        x: { align: "center" },
        y: { align: "top", dy: -75 },

        countdown: {
            width: 200
        },

        timerPosition: {
            x: 0,
            y: -1
        }
    },

    top: {
        height: 210,
        width: 980,

        x: { align: "center", dx: 0 },
        y: { align: "top", dy: -150 },

        icon: {
            x: { align: "left", dx: 65 },
            y: { align: "center", dy: 3 }
        },

        button: {
            width: 240,
            height: 80,
            x: { align: "center", dx: 110 },
            y: { align: "center", dy: -30 }
        },

        description: {
            width: 650,
            margin: 20,
            x: { align: "center", dx: 105 },
            y: { align: "top", dy: -30 }
        }
    }
};
