/**
 * Created by andrey on 12.02.2021.
 */

var QuestIcon = cc.Node.extend({
    ctor: function (quest, options) {
        this._super();

        this.quest = quest;
        this.options = options = options || {};
        this.images = options.images || QuestIcon.Images.default;

        var styles = cleverapps.styles.QuestIcon;

        var background = this.background = new cc.Scale9Sprite(this.options.selected ? this.images.background_selected : this.images.background);
        if (this.images.backgroundScale) {
            background.setScale(this.images.backgroundScale);
        } else {
            background.setContentSize2(styles.background);
        }
        background.setPositionRound(styles.background);
        this.setContentSize2(background.width * background.scale, background.height * background.scale);
        this.setAnchorPoint(0.5, 0.5);
        this.addChild(background);

        var icon = this.icon = QuestIcon.getIconImage(quest);
        var iconBorderFactor = cleverapps.config.ui === "heroes" ? 0.6 : 0.7;
        cleverapps.UI.fitToBox(icon, {
            width: this.width * iconBorderFactor,
            height: this.height * iconBorderFactor,
            maxScale: styles.icon.maxScale
        });
        icon.baseScale = icon.getScale();
        icon.setPositionRound(styles.icon);
        this.addChild(icon);

        var progress = this.progress = this.createProgress();
        progress.setAnchorPoint(0.5, 0.5);
        progress.setPositionRound(styles.progress);
        this.addChild(progress);

        this.updateProgress(true);
    },

    createProgress: function () {
        var styles = cleverapps.styles.QuestIcon.progress;

        var background = cleverapps.UI.createScale9Sprite(this.options.selected ? this.images.title_selected : this.images.title);
        background.setContentSize2(styles);

        var progressText = background.text = cleverapps.UI.generateImageText("", cleverapps.styles.FONTS.QUEST_PROGRESS_TEXT);
        progressText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        progressText.setPositionRound(styles.text);
        background.addChild(progressText);

        return background;
    },

    update: function () {
        var isSelected = this.quest.selected && this.images.background_selected;
        var image = isSelected ? this.images.background_selected : this.images.background;
        this.background.setSpriteFrame(new cc.Sprite(image).getSpriteFrame());

        var scale = 1;
        var bg = this.images.title;

        if (isSelected) {
            scale = 1.1;
            bg = this.images.title_selected;
        }

        this.baseScale = scale;
        this.setScale(scale);

        this.progress.setSpriteFrame(new cc.Sprite(bg).getSpriteFrame());
    },

    updateProgress: function (silent) {
        if (silent === true) {
            this.progress.text.setString(this.quest.progress + "/" + this.quest.goal);
            this.progress.text.fitTo({ width: this.progress.width * 0.9, height: 0 });
            return;
        }

        if (this.quest.progress !== this.quest.goal) {
            this.highlight();
        }

        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.CallFunc(this.updateProgress.bind(this, true))
        ));
    },

    makeInteractive: function (destination) {
        this.update();
        this.quest.on("updateProgress", this.updateProgress.bind(this), this);
        this.quest.on("select", this.update.bind(this), this);
        this.quest.on("unselect", this.update.bind(this), this);

        var self = this;
        this.icon.scheduleUpdate();
        this.icon.update = cleverapps.extendFunc(this.icon.update, function () {
            this._super.apply(this, arguments);

            this.setScaleX(this.baseScale * self.getScaleX());
            this.setScaleY(this.baseScale * self.getScaleY());
        });

        this.progress.removeTemporarily();
        var node = new cc.Node();
        node.setContentSize2(this.getContentSize());
        node.setAnchorPoint(0.5, 0.5);
        node.addChild(this.progress);

        var progressNode = new cc.Node();
        progressNode.setContentSize2(this.getContentSize());
        progressNode.addChild(node);
        node.setPositionRound(progressNode.width / 2, progressNode.height / 2);
        this.addChild(progressNode);

        node.scheduleUpdate();
        node.update = cleverapps.extendFunc(node.update, function () {
            this._super.apply(this, arguments);

            var scaleX = progressNode.initialScaleX || 1;
            var scaleY = progressNode.initialScaleY || 1;
            this.setScaleX(scaleX * self.getScaleX());
            this.setScaleY(scaleY * self.getScaleY());
        });

        if (destination) {
            this.icon.replaceParentSamePlace(destination);

            var scale = progressNode.adjustScaleTo(destination);
            progressNode.initialScaleX = 1 / scale.x;
            progressNode.initialScaleY = 1 / scale.y;
            progressNode.replaceParentSamePlace(destination, { keepScale: true });
            progressNode.setLocalZOrder(1);
        }
    },

    highlight: function (duration) {
        var questsGroupIcon = cleverapps.sideBar.findQuestsGroupIcon();
        if (!questsGroupIcon || this.highlightInProgress) {
            return;
        }

        questsGroupIcon.onScrollToItemListener(this);

        var savedBaseScale = this.baseScale;
        this.baseScale *= 1.1;

        this.highlightInProgress = true;
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.2),
            new cc.ScaleTo(0.2, 0.9 * savedBaseScale),
            new cc.ScaleTo(0.2, 1.1 * savedBaseScale).easing(cc.easeOut(3)),
            new cc.CallFunc(function () {
                var iconLight = new cleverapps.Spine(bundles.questsgroupicon.jsons.sidebar_light_json);
                iconLight.setAnchorPoint(0.5, 0.5);
                iconLight.setPositionRound(this.width / 2, this.height / 2);
                iconLight.setAnimation(0, "animation", false);
                iconLight.setCompleteListenerRemove();
                this.background.addChild(iconLight);

                if (this.quest.progress !== this.quest.goal) {
                    questsGroupIcon.showInfo(this, {
                        compact: this.quest.progress !== 0
                    });
                }
            }.bind(this)),
            new cc.DelayTime(duration || 2.2),
            new cc.ScaleTo(0.2, 0.9 * savedBaseScale),
            new cc.ScaleTo(0.2, savedBaseScale).easing(cc.easeOut(3)),
            new cc.CallFunc(function () {
                this.highlightInProgress = false;
                this.baseScale = savedBaseScale;
            }.bind(this))
        ));
    },

    getPreferredBundles: function () {
        if (this.images.preferredBundles) {
            return this.images.preferredBundles;
        }
        return ["main"];
    }
});

QuestIcon.getIconImage = function (quest) {
    return quest.getIcon();
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    QUEST_PROGRESS_TEXT: {
        name: "default",
        size: 60,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR
    }
});

cleverapps.styles.QuestIcon = {
    background: {
        x: { align: "center" },
        y: { align: "center" },
        width: 300,
        height: 300
    },

    icon: {
        x: { align: "center" },
        y: { align: "center", dy: 10 },
        maxScale: 1.6
    },

    checkmark: {
        x: { align: "right", dx: 10 },
        y: { align: "top", dy: 5 }
    },

    progress: {
        x: { align: "center" },
        y: { align: "bottom", dy: -28 },

        width: 240,
        height: 90,

        text: {
            x: { align: "center" },
            y: { align: "center", dy: 3 }
        }
    }
};

QuestIcon.Images = cleverapps.overrideTemplates({}, {
    default: {
        backgroundScale: 1,
        background: bundles.questswindow.frames.quest_icon_bg,
        background_selected: bundles.questswindow.frames.quest_icon_bg_selected,
        title: bundles.questswindow.frames.icon_title,
        title_selected: bundles.questswindow.frames.icon_title_selected
    },

    small: {
        backgroundScale: 0.85,
        background: bundles.questsgroupicon.frames.quest_icon_bg || bundles.questswindow.frames.quest_icon_bg,
        background_selected: bundles.questswindow.frames.quest_icon_bg_selected,
        title: bundles.questswindow.frames.icon_title,
        title_selected: bundles.questswindow.frames.icon_title_selected
    },

    sidebar: {
        preferredBundles: ["game"],
        backgroundScale: 1.3,
        background: bundles.sidebar.frames.icon_bg,
        background_selected: bundles.sidebar.frames.icon_bg,
        title: bundles.sidebar.frames.icon_text_bg,
        title_selected: bundles.sidebar.frames.icon_text_bg
    },

    questsgroup: {
        preferredBundles: ["units"],
        backgroundScale: 1,
        background: bundles.questsgroupicon.frames.quest_icon_bg,
        title: bundles.questsgroupicon.frames.icon_title
    }
});
