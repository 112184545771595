/**
 * Created by andrey on 24.02.2021.
 */

var QuestsConfig = {};

QuestsConfig.main = {
    wheat_3: {
        trigger: { unit: { code: "dwarf", stage: 1 } },
        tutorial: "wheat_3",
        dynamic: {
            filter: function () {
                return cleverapps.gameLevel.getLevel() < 4;
            },
            finished: function () {
                return cleverapps.unitsLibrary.isOpened({ code: "wheat", stage: 3 });
            }
        },
        quest: {
            silent: true,
            type: Map2d.SPAWN,
            unit: { code: "wheat", stage: 3 }
        }
    },

    dishes_0: {
        trigger: { unit: { code: "wheat", stage: 3 } },
        tutorial: "dishes_0",
        finishTutorial: "openfog2",
        dynamic: {
            filter: function () {
                return cleverapps.gameLevel.getLevel() < 5;
            }
        },
        quest: {
            controls: [],
            type: Map2d.SPAWN,
            unit: { code: "wands", stage: 0 }
        }
    },

    woodsource_1_0: { // deprecated
        quest: {
            type: Map2d.OPENFOG
        }
    },

    openfog_3: {
        trigger: { unit: { code: "woodchest", stage: 0 } },
        quest: {
            type: Map2d.OPENFOG
        }
    },

    woodsource_1_1: { // deprecated
        quest: {
            type: Map2d.MINE,
            unit: { code: "woodsource", stage: 1 }
        }
    },

    wood_1: {
        trigger: { unit: { code: "wood", stage: 0 } },
        tutorial: "wood_1",
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "wood", stage: 1 }
        }
    },

    wood_2: {
        trigger: { unit: { code: "wood", stage: 1 } },
        tutorial: "wood_2",
        quest: {
            type: Map2d.BUILD,
            unit: { code: "wood", stage: 2 }
        }
    },

    candies_4: {
        trigger: { unit: { code: "candies", stage: 4 } },

        quest: {
            type: Map2d.HARVEST,
            unit: { code: "candies", stage: 4 }
        }
    },

    wheat_4: {
        trigger: { unit: { code: "wood", stage: 8 } },

        quest: {
            type: Map2d.HARVEST,
            unit: { code: "wheat", stage: 4 }
        }
    },

    dragonisland_1: {
        trigger: { unit: { code: "king", stage: 3 } },
        dynamic: {
            filter: function () {
                return Families.dragonmine && !cleverapps.unitsLibrary.isOpened({ code: "dragonmine", stage: 0 });
            },
            getIcon: function () {
                return UnitView.getUnitImage({ code: "dragonmine", stage: 0 }, { alignAnchorX: true });
            }
        },
        quest: {
            type: Map2d.OPENDRAGON
        }
    },

    kraken_0: { // deprecated
        dynamic: {
            filter: function () {
                var mission = cleverapps.missionManager.findByType(Mission.TYPE_KRAKENFEAST);
                return mission && mission.isRunning();
            },
            getTitle: function () {
                return Messages.get("Quest.kraken_0.title");
            },
            getIcon: function () {
                return UnitView.getUnitImage({ code: "kraken", stage: 0 }, { alignAnchorX: true, preferStatic: true });
            }
        },
        quest: {
            type: Map2d.MINE,
            unit: { code: "kraken" },
            monster: "kraken"
        }
    },

    woodgenerator_1: {
        trigger: { unit: { code: "woodgenerator", stage: 0 } },
        tutorial: "woodgenerator_1",
        dynamic: {
            getTitle: function () {
                return Messages.get("Quest.woodgenerator_1.title", {
                    name: cleverapps.unitsLibrary.getUnitName({
                        code: "woodgenerator",
                        stage: 1
                    })
                });
            }
        },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "woodgenerator", stage: 1 }
        }
    },

    woodgenerator_2: {
        trigger: { unit: { code: "woodgenerator", stage: 1 } },
        dynamic: {
            getTitle: function () {
                return Messages.get("Quest.woodgenerator_1.title", {
                    name: cleverapps.unitsLibrary.getUnitName({
                        code: "woodgenerator",
                        stage: 2
                    })
                });
            }
        },
        quest: {
            type: Map2d.SPAWN,
            unit: { code: "woodgenerator", stage: 2 }
        }
    },

    fruitshop_0: { // deprecated
        quest: {
            type: Map2d.BUY_FREE_UNIT,
            unit: { code: "fruitshop", stage: 0 },
            tab: ShopWindow.TABS.UNITS_FRUIT
        }
    },

    resourceshop_0: { // deprecated
        quest: {
            type: Map2d.BUY_FREE_UNIT,
            unit: { code: "resourceshop", stage: 0 },
            tab: ShopWindow.TABS.UNITS_RESOURCE
        }
    },

    discountshop_0: { // deprecated
        quest: {
            type: Map2d.BUY_FREE_UNIT,
            unit: { code: "discountshop", stage: 0 },
            tab: ShopWindow.TABS.UNITS_DISCOUNT
        }
    },

    crystal_use: {
        trigger: { fog: "fog5" },
        dialogueTargets: { code: "crystal", stage: 2 },
        tutorial: "crystal_use",
        dynamic: {
            getTitle: function () {
                return Messages.get("Quest.crystal_use.title");
            },
            getIcon: function () {
                return new cc.Sprite(bundles.quests.frames.use_crystal);
            }
        },
        quest: {
            type: Map2d.MERGE,
            unit: { code: "crystal", stage: 3 }
        }
    }
};

var RepeatedQuests = {
    r_collectfruit: {
        dynamic: {
            getTitle: function (unit) {
                return Messages.get("Quest.r_collectfruit.title", { name: cleverapps.unitsLibrary.getFamilyName(unit.code) });
            },
            getUnit: function () {
                var fruits = cleverapps.unitsLibrary.listAvailableByType("fruit");
                var chosen = cleverapps.Random.choose(fruits);
                if (chosen) {
                    return { code: chosen, stage: 4 };
                }
            }
        },
        quest: {
            type: Map2d.HARVEST
        }
    },
    r_createfruit: {
        dynamic: {
            getTitle: function (unit) {
                return Messages.get("Quest.r_createfruit.title", { name: cleverapps.unitsLibrary.getFamilyName(unit.code) });
            },
            getUnit: function () {
                var fruits = cleverapps.unitsLibrary.listAvailableByType("fruit");
                var chosen = cleverapps.Random.choose(fruits);
                if (chosen) {
                    return { code: chosen, stage: 3 };
                }
            }
        },
        quest: {
            type: Map2d.SPAWN
        }
    },
    r_bonusmerge: {
        dynamic: {
            getTitle: function () {
                return Messages.get("Quest.r_bonusmerge.title");
            },
            getIcon: function () {
                return new cc.Sprite(bundles.quests.frames.bonus_merge);
            }
        },
        quest: {
            type: Map2d.MERGE,
            minUnits: 5
        }
    },
    r_open_freechest: {
        dynamic: {
            getTitle: function (unit) {
                return Messages.get("Quest.r_open_chest.title", { name: cleverapps.unitsLibrary.getFamilyName(unit.code) });
            },
            getIcon: function (unit) {
                return UnitView.getUnitImage(UnitsLibrary.ListByCode(unit.code).pop(), { alignAnchorX: true });
            },
            getFindTargets: function () {
                return ["freechest", "discountshop"];
            }
        },

        quest: {
            type: Map2d.PRIZE_HARVESTED,
            unit: { code: "freechest" }
        }
    },
    r_open_herochest: {
        dynamic: {
            getTitle: function (unit) {
                return Messages.get("Quest.r_open_chest.title", { name: cleverapps.unitsLibrary.getFamilyName(unit.code) });
            },
            getIcon: function (unit) {
                return UnitView.getUnitImage(UnitsLibrary.ListByCode(unit.code).pop(), { alignAnchorX: true });
            },
            filter: function () {
                return cleverapps.unitsLibrary.getCurrentHero() !== undefined;
            },
            getFindTargets: function () {
                return ["herochest", "discountshop"];
            }
        },

        quest: {
            type: Map2d.PRIZE_HARVESTED,
            unit: { code: "herochest" }
        }
    },
    r_open_randomchest: {
        dynamic: {
            getTitle: function (unit) {
                return Messages.get("Quest.r_open_randomchest.title", { name: cleverapps.unitsLibrary.getFamilyName(unit.code) });
            },
            getIcon: function (unit) {
                return UnitView.getUnitImage(UnitsLibrary.ListByCode(unit.code).pop(), { alignAnchorX: true });
            },
            getUnit: function () {
                var chests = cleverapps.unitsLibrary.listAvailableByType("chest");
                var eligible = ["sacks", "woodchest", "stonechest", "wagonchest", "bamboochest", "sapphirechest", "snowchest", "lavachest", "sandchest", "asiachest"];
                chests = cleverapps.intersect(chests, eligible);
                var chosen = cleverapps.Random.choose(chests);
                if (chosen) {
                    return { code: chosen };
                }
            },
            getFindTargets: function (unit) {
                switch (unit.code) {
                    case "sacks":
                        return ["fruitshop"];
                    case "woodchest": case "stonechest": case "wagonchest": case "bamboochest": case "sapphirechest": case "snowchest": case "lavachest": case "sandchest": case "asiachest":
                        var resource = unit.code.replace("chest", "");
                        return [resource + "chest", resource + "source", resource + "generator"];
                }
            }
        },
        quest: {
            type: Map2d.PRIZE_HARVESTED
        }
    },
    r_use_randomcastle: {
        dynamic: {
            getTitle: function (unit) {
                var name = cleverapps.unitsLibrary.getUnitName(unit);
                return Messages.get("Quest.r_use_randomcastle.title", { name: name });
            },
            getIcon: function (unit) {
                return UnitView.getUnitImage(UnitsLibrary.ListByCode(unit.code).pop(), { alignAnchorX: true });
            },
            getUnit: function () {
                var resources = cleverapps.unitsLibrary.listAvailableByType("resource");
                var castles = resources.map(function (code) {
                    return UnitsLibrary.ListByCode(code).pop();
                });
                castles = Map2d.currentMap.listAvailableUnits(castles);
                var chosen = cleverapps.Random.choose(castles);
                if (chosen) {
                    return { code: chosen.code, stage: chosen.stage };
                }
            }
        },
        quest: {
            type: Map2d.PRIZE_HARVESTED
        }
    },
    r_use_randommine: {
        dynamic: {
            getTitle: function (unit) {
                return Messages.get("Quest.r_use_randommine.title", { name: cleverapps.unitsLibrary.getFamilyName(unit.code) });
            },
            getIcon: function (unit) {
                var units = UnitsLibrary.ListByCode(unit.code).filter(function (unit) {
                    return cleverapps.unitsLibrary.isOpened(unit);
                });
                return UnitView.getUnitImage(units[units.length - 1] || { code: unit.code, stage: 0 }, { alignAnchorX: true });
            },
            getUnit: function () {
                var generators = Map2d.currentMap.listAvailableUnits({ type: "generator" });
                var chosen = cleverapps.Random.choose(generators);
                if (chosen) {
                    return { code: chosen.code };
                }
            }
        },
        quest: {
            type: Map2d.COMPLETE_MINING
        }
    },
    r_builditems: {
        dynamic: {
            getTitle: function () {
                return Messages.get("Quest.r_builditems.title");
            },
            getIcon: function () {
                return new cc.Sprite(bundles.quests.frames.build_object);
            }
        },
        quest: {
            type: Map2d.BUILD,
            unit: { type: "resource" }
        }
    }
};

Object.assign(QuestsConfig.main, RepeatedQuests);
