/**
 * Created by andrey on 24.12.2020.
 */

var EditorMap2dBaseView = Map2dView.extend({
    ctor: function (map2d) {
        this._super(map2d);

        this.isVisiblePoint = function () {
            return true;
        };

        this.highlight = new HighlightView(new Highlight(0, 0));
        this.animations.addChild(this.highlight);
    },

    hasTouch: function (touch) {
        var point = this.convertTouchToNodeSpace(touch);
        return this.isVisiblePoint(point) && this._super(touch);
    },

    getVisibleCellByCoordinates: function (p) {
        var cell = this.getCellByCoordinates(p);
        if (this.map2d.containsCell(cell) && this.isVisiblePoint(p)) {
            return cell;
        }
    },

    createView: function (object) {
        var map2d = this.map2d;

        var isBorder = function () {
            var border = 2;
            return map2d.pointer.x < border || map2d.pointer.y < border
                || map2d.pointer.x >= map2d.getWidth() - border || map2d.pointer.y >= map2d.getHeight() - border;
        };

        if (object instanceof EditorFogTile) {
            return new EditorFogTileView(object);
        }
        if (object instanceof Unit && !object.isMultiCellBody()) {
            if (Map2d.unitsVisible || object.code === "decorator" && Map2d.decoratorUnitsVisible) {
                return new EditorUnitView(object);
            }
        }
        if (object === Map2d.TILE_WATER_UNIT) {
            var water = new cc.Sprite(cleverapps.skins.getSlot("water") || bundles.dev_resources.frames.water);
            water.setColor(new cc.Color(100, 100, 100, 255));
            return water;
        }
        if (object === Map2d.TILE_WATER && !cleverapps.skins.getSlot("water") && isBorder()) {
            return new cc.Sprite(bundles.dev_resources.frames.water);
        }

        return this._super(object);
    }
});
