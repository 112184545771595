/**
 * Created by decipaliz on 15.08.2022
 */

var PackOfferComponent = cc.Node.extend({
    ctor: function (options) {
        options = this.options = options || {};
        this._super();
        this.onClicked = options.onClicked;
        this.bundleName = options.bundle;
        this.styles = cleverapps.styles.PackOfferComponent[options.offerType] || cleverapps.styles.PackOfferComponent;
        this.createPack();
        this.setupChildren();
    },

    clickHandle: function () {
        cleverapps.audio.playSound(bundles.main.urls.click_effect);
        this.onClicked();
    },

    createPack: function () {
        var styles = this.styles;
        var packBundle = bundles[this.bundleName].jsons.pack;
        if (!packBundle) {
            return;
        }
        var container = this.container = new cc.Node();
        var pack = this.pack = new cleverapps.Spine(packBundle);
        pack.setAnimation(0, "animation", true);
        var tapContainer = new cc.Node();
        tapContainer.setPositionRound(styles.tap);
        tapContainer.setRotation(styles.tap.rotation);
        var tap = new cleverapps.Spine(bundles[this.bundleName].jsons.pack_tap);
        tap.scaleX = styles.tap.scaleX || -Math.abs(tap.scaleX);
        tap.setPositionRound(styles.tap.animation);
        tap.setAnimation(0, "animation", true);
        tapContainer.addChild(tap);

        if (styles.pack.title) {
            var title = this.title = cleverapps.UI.generateOnlyText("FeastMissionWindow.tap", cleverapps.styles.FONTS.FEAST_MISSION_PACK_BUTTON);
            title.setPositionRound(styles.pack.title);
            title.fitTo(styles.pack.title.width);
            tapContainer.addChild(title);
        }
        if (styles.pack.badge) {
            var badge = new TileBadge({
                text: this.options.badgeText,
                rotation: styles.pack.badge.rotation || 0
            });
            badge.setPositionRound(styles.pack.badge);
            pack.addChild(badge);
        }

        container.addChild(tapContainer, styles.tap.zIndex);

        cleverapps.UI.applyHover(pack, styles.pack.hoverRays ? {
            onMouseOver: function () {
                pack.applyInteractiveScale();
                pack.rays = AnimationsLibrary.rays(pack);
                pack.rays.setAnimation(0, "idle");
                pack.rays.setPositionRound(styles.pack.hoverRays.position);
            },
            onMouseOut: function () {
                pack.applyInteractiveScale();
                pack.rays.setAnimation(0, "close");
                pack.rays.setCompleteListenerRemove();
                pack.rays = undefined;
            }
        } : {});
        cleverapps.UI.onClick(pack, this.clickHandle.bind(this));
        cleverapps.UI.onClick(tapContainer, this.clickHandle.bind(this));
        container.addChild(pack);
        container.setVisible(false);
        this.addChild(container);
    },

    positionPack: function () {
        if (this.container) {
            var styles = this.styles.pack;
            var window = cleverapps.windows.currentWindow();
            var sceneSize = cleverapps.resolution.getSceneSize();

            var dx = sceneSize.width / 2 + (window ? window.window.width / 2 : 0);
            this.container.setPositionRound({
                x: { align: "left", dx: dx + styles.offsetX },
                y: { align: "bottom", dy: styles.offsetY - cleverapps.resolution.getSafePadding().bottom }
            });

            var isVisible = (this.container.x - this.container.width * this.container.anchorX) + this.container.width * styles.packOverlap < sceneSize.width;
            this.container.setVisible(isVisible && styles.visibleResolutionModes.indexOf(cleverapps.resolution.mode) !== -1);
        }
    },

    setupChildren: function () {
        this.container && this.positionPack();
    },

    isPackVisible: function () {
        return this.container && this.container.isVisible();
    },

    getOverlapNode: function () {
        return this.styles.isOverlapNode && this.pack;
    },

    show: function () {
        if (this.container) {
            this.container.setScale(0);
            this.container.runAction(new cc.ScaleTo(0.3, 1).easing(cc.easeBackOut()));
        }
    }
});
cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    FEAST_MISSION_PACK_BUTTON: {
        size: 70,
        color: new cc.Color(182, 99, 52, 255),
        shadow: {
            color: new cc.Color(126, 70, 37, 255),
            direction: cc.size(0, 2),
            blur: 1
        }
    }
});

cleverapps.styles.PackOfferComponent = {
    pack: {
        offsetX: -50,
        offsetY: 100,

        title: {
            x: { align: "center", dx: -17 },
            y: { align: "center", dy: 24 },
            width: 200
        },
        packOverlap: 0.4,
        visibleResolutionModes: [
            cleverapps.WideMode.SQUARE,
            cleverapps.WideMode.HORIZONTAL
        ]
    },

    tap: {
        x: { align: "left", dx: -45 },
        y: { align: "center", dy: 260 },
        rotation: 0,
        animation: {
            x: { align: "left", dx: 0 },
            y: { align: "top", dy: 0 }
        }
    }
};

// eslint-disable-next-line max-len
cleverapps.styles.PackOfferComponent[Offers.TYPES.SNAILFEAST] = cleverapps.styles.PackOfferComponent[Offers.TYPES.SNAILFEAST_DRAGONIA3] = cleverapps.styles.PackOfferComponent[Offers.TYPES.SNAILFEAST_DRAGONIA2] = cleverapps.styles.PackOfferComponent[Offers.TYPES.SNAILFEAST_UNDERSEA3] = cleverapps.overrideStyles(cleverapps.styles.PackOfferComponent, {
    pack: {
        offsetY: 210,
        offsetX: 130,

        title: {
            x: { align: "center", dx: 225 },
            y: { align: "center", dy: -90 }
        }
    },

    tap: {
        x: { align: "left", dx: -240 },
        y: { align: "top", dy: 430 },
        animation: {
            x: { align: "left", dx: 0 },
            y: { align: "top", dy: 0 }
        }
    }
}, true);

cleverapps.styles.PackOfferComponent[Offers.TYPES.STICKERS_PACK] = cleverapps.overrideStyles(cleverapps.styles.PackOfferComponent, {
    pack: {
        offsetX: 80,
        offsetY: 440,
        badge: {
            x: { align: "right", dx: -90 },
            y: { align: "top", dy: 65 },
            rotation: 30
        },
        title: undefined,
        packOverlap: 0.6,
        hoverRays: {
            position: {
                x: { align: "center", dx: -40 },
                y: { align: "center" }
            }
        }
    },
    tap: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 },
        rotation: 0,
        animation: {
            x: { align: "center", dx: -10 },
            y: { align: "center", dy: -140 }
        }
    },
    isOverlapNode: true
}, true);

cleverapps.styles.PackOfferComponent[Offers.TYPES.PINBALL_PACK] = cleverapps.overrideStyles(cleverapps.styles.PackOfferComponent, {
    pack: {
        offsetX: 670,
        offsetY: 540,
        badge: {
            x: { align: "right", dx: 90 },
            y: { align: "top", dy: 175 }
        },
        title: undefined,
        packOverlap: 0.8,
        hoverRays: {
            position: {
                x: { align: "center", dx: -40 },
                y: { align: "center" }
            }
        }
    },
    tap: {
        x: { align: "center", dx: 20 },
        y: { align: "center", dy: -290 },
        rotation: 0,
        animation: {
            x: { align: "center", dx: -20 },
            y: { align: "center", dy: -50 }
        }
    },
    isOverlapNode: true
}, true);