/**
 * Created by evgenijsenkevic on 18.09.2024
 */

var OpenFog = function (unit, options, fog) {
    UnitComponent.call(this, unit);
    this.unit = unit;
    this.fog = fog;
};

OpenFog.prototype = Object.create(UnitComponent.prototype);
OpenFog.prototype.constructor = OpenFog;

OpenFog.prototype.open = function () {
    Map2d.currentMap.fogs.blocks[this.fog].open();
};
