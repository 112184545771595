/**
 * Created by vladislav on 18.07.2022
 */

var UnitLocator = {
    find: function (type, targetUnit, options) {
        options = options || {};

        var isMagicCrystal = targetUnit && targetUnit.code === "crystal" && targetUnit.stage === 3;

        var minUnits = options.minUnits || 3;

        var center = Map2d.currentMap.getScreenCenterCell();
        var units;
        if (options.monster) {
            units = cleverapps.toArray(this.getMonsters(options.monster));
        } else {
            units = cleverapps.toArray(this.getTargetUnits(type, targetUnit, options));
        }

        if (type === Map2d.MERGE && targetUnit && targetUnit.code === "wands" && units.length < minUnits) {
            type = Map2d.COMPLETE_ORDER;
            units = cleverapps.toArray(this.getTargetUnits(type, targetUnit, options));
        }

        var amounts = {};
        var maxAmount = 0;
        var maxAmountKey = 0;
        units.forEach(function (unit) {
            if (unit instanceof Unit && unit.isMergeable(unit)) {
                var key = Unit.GetKey(unit);
                amounts[key] = (amounts[key] || 0) + 1;

                if (amounts[key] > maxAmount) {
                    maxAmount = amounts[key];
                    maxAmountKey = key;
                }
            }
        });
        units = units.filter(function (unit) {
            if (type === Map2d.MERGE) {
                return isMagicCrystal && unit.isMagicCrystal()
                    || Unit.GetKey(unit) === maxAmountKey && amounts[Unit.GetKey(unit)] >= minUnits && unit.isMergeable(unit);
            }
            return !amounts[Unit.GetKey(unit)] || unit.getData().chest || amounts[Unit.GetKey(unit)] >= minUnits;
        }, this);

        units.sort(function (a, b) {
            var stageA = this.getWeight(type, targetUnit, a);
            var stageB = this.getWeight(type, targetUnit, b);
            if (stageA !== stageB) {
                return stageA - stageB;
            }
            return cc.getDistanceChebyshev(a, center) - cc.getDistanceChebyshev(b, center);
        }.bind(this));

        if (units.length === 0) {
            cleverapps.notification.create("Quest.find.empty");
            return false;
        }

        if (type === Map2d.MERGE) {
            Game.currentGame.advice.setLogic(Map2d.MERGE, {
                targets: units,
                minUnits: minUnits,
                highlight: true
            });
            Game.currentGame.advice.startHintOnce();
        }

        Map2d.currentMap.getView().scroll.runAction(new cc.CellScrollAction(units[0], {
            allowScrollWithFocus: options.allowScrollWithFocus,
            skipFocusReport: true
        }).easing(cc.easeInOut(2)));
        Map2d.currentMap.onAddTarget(units[0].x, units[0].y);

        return true;
    },

    getWeight: function (type, targetUnit, unit) {
        var targetCode = targetUnit && targetUnit.code;
        var targetStage = targetUnit && targetUnit.stage;
        var targetType = targetUnit && targetUnit.type;

        var typePriority = ["chest", "source", "generator"].map(cleverapps.unitsLibrary.getExpeditionUnitType);

        var res = 0;
        var stageSign = -1;

        if (type === Map2d.BUILD && targetStage === undefined && Unit.IsApplicable(unit, unit)) {
            var buildable = unit.findComponent && unit.findComponent(Buildable);
            if (buildable && !buildable.isCompleted()) {
                stageSign = 1;
                res -= 20;
            }
        }

        var resourceName = function (code) {
            return code.replace(/(chest|source|generator)$/, "");
        };

        res += stageSign * unit.stage;
        if (targetCode && targetCode === unit.code) {
            res -= 3000;
        } else if (targetCode && unit.code && resourceName(targetCode) === resourceName(unit.code)) {
            res -= 2000;
        } else if (targetType && unit.getType && targetType === unit.getType()) {
            res -= 1000;
        }
        res += (typePriority.indexOf(unit.getType()) + 1) * 100;
        return res;
    },

    getMonsters: function (monster) {
        var monsters = [];
        var region = Map2d.currentMap.regions.monsters;
        for (var i = 0; i < region.positions.length; i++) {
            var unit = Map2d.currentMap.getUnit(region.positions[i].x, region.positions[i].y);
            if (unit && unit.getData().monster === monster) {
                monsters.push(unit);
            }
        }
        return monsters;
    },

    getTargetUnits: function (type, unit, options) {
        var getFindTargets = options.getFindTargets;
        var map = Map2d.currentMap;

        switch (type) {
            case Map2d.SPAWN:
            case Map2d.OPENCHEST:
                var chests = map.listAvailableUnits(unit);
                if (chests.length > 0 && !options.useStageForCustomerSearch && !getFindTargets) {
                    return chests;
                }

                var units;
                var customer;
                if (options.useStageForCustomerSearch) {
                    units = map.fogs.listFakeUnits(unit);
                    units = units.filter(function (unit) {
                        return map.getFog(unit.x, unit.y);
                    });
                    if (units.length === 0) {
                        units = map.listAvailableUnits(unit);
                    }
                    customer = Game.currentGame.customers.findCustomerForHint(unit);
                    if (customer) {
                        units = [customer];
                    }

                    if (units.length === 0) {
                        units = map.listAvailableUnits({ type: "hlgenerator" }).filter(function (generator) {
                            return generator.prizes && generator.prizes.some(Unit.Equals.bind(Unit, unit));
                        });
                    }
                } else {
                    units = map.listAvailableUnits(this.getSpawnTargets(unit, 0, getFindTargets));
                    customer = Game.currentGame.customers.findCustomerForHint(unit);
                    if (customer) {
                        units = units.concat([customer]);
                    }
                    if (units.length === 0) {
                        units = map.fogs.listFakeUnits(this.getSpawnTargets(unit, 1, getFindTargets)).filter(function (unit) {
                            return map.getFog(unit.x, unit.y);
                        });
                    }
                }

                return units;

            case Map2d.HARVEST:
            case Map2d.PRIZE_HARVESTED:
                return map.listAvailableUnits(this.getSpawnTargets(unit, 1, getFindTargets));

            case Map2d.MINE:
            case Map2d.COMPLETE_MINING:
            case Map2d.BUY_FREE_UNIT:
                var mineUnits = map.listAvailableUnits(unit);
                if (mineUnits.length) {
                    return mineUnits;
                }

                var mineFakeUnits = map.fogs.listFakeUnits(unit).filter(function (fakeUnit) {
                    return fakeUnit.pointOfInterest;
                });
                if (mineFakeUnits.length) {
                    return mineFakeUnits;
                }

                return [];

            case Map2d.BUILD:
                return this.getBuildUnits(unit, getFindTargets);

            case Map2d.OPENFOG:
                var fog;
                if (options.target) {
                    fog = map.fogs.getBlockById(options.target);
                } else {
                    fog = map.fogs.findAvailableFogBlock();
                }

                return fog && fog.head || [];

            case Map2d.OPENDRAGON:
                return map.fogs.blocks.dragonIsland.head;

            case Map2d.MERGE:
                var isMagicCrystal = unit && unit.code === "crystal" && unit.stage === 3;
                if (isMagicCrystal) {
                    return map.listAvailableUnits();
                }

                return map.listAvailableUnits(unit);

            case Map2d.COMPLETE_ORDER:
                var ready = Game.currentGame.orders.findReady();

                if (ready) {
                    return ready.unit;
                }

                var availableMakers = Game.currentGame.orders.listAvailableMakers();
                if (availableMakers.length > 0) {
                    return availableMakers.map(function (makesOrder) {
                        return makesOrder.unit;
                    });
                }

                return Game.currentGame.orders.listMakers().map(function (makesOrder) {
                    return makesOrder.unit;
                });
            case Map2d.USE_UP_INSTANT_WORKER:
                var free = Map2d.currentMap.workers.listInstantFree();
                if (free.length > 0) {
                    return free.map(function (worker) {
                        return worker.unit;
                    });
                }

                return Map2d.currentMap.workers.listInstant().map(function (unit) {
                    return unit.assignment.unit;
                });
            case Map2d.OPEN_UNIT:
                var openFakeUnit = map.fogs.findFakeUnit(unit);
                if (openFakeUnit && openFakeUnit.pointOfInterest) {
                    return openFakeUnit;
                }

                return map.listAvailableUnits(this.getSpawnTargets(unit, 0, getFindTargets));
        }
        return [];
    },

    getBuildUnits: function (unit, getFindTargets) {
        var targets = unit && unit.code ? this.getSpawnTargets(unit, 1, getFindTargets) : {};

        var units = Map2d.currentMap.listAvailableUnits(targets).filter(function (unit) {
            var buildable = unit.findComponent(Buildable);
            return !buildable || !buildable.isCompleted();
        }, this);

        var customer = Game.currentGame.customers.findCustomerForHint(unit);
        if (customer) {
            units = units.concat([customer]);
        }

        return units;
    },

    getSpawnTargets: function (unit, stageDelta, getFindTargets) {
        stageDelta = stageDelta || 0;

        var targets = [];

        if (getFindTargets) {
            getFindTargets(unit).forEach(function (code) {
                targets = targets.concat(Unit.getAllStagesTargets(code));
            });
            return targets;
        }

        if (unit.stage !== undefined) {
            targets = Unit.getPreviousStagesTargets(unit.code, unit.stage + stageDelta);
        } else if (unit.code !== undefined) {
            targets = Unit.getAllStagesTargets(unit.code);
        }

        cleverapps.unitsLibrary.listCodesByType(["chest", "source", "generator", "rpchest", "seasource", "seachest"])
            .forEach(function (source) {
                targets = targets.concat(Unit.getAllStagesTargets(source));
            });

        if (Families[unit.code].type === "rpresource") {
            targets = targets.concat(Unit.getAllStagesTargets(unit.code + "source"));
        }

        return targets;
    },

    findPrize: function (lost) {
        var units = [];

        Map2d.currentMap.listAvailableUnits(function (unit) {
            if (unit.prizes && this.findInPrizes(unit.prizes, lost)) {
                units.push(unit);
                return;
            }

            if (this.findInMineablePrizes(unit, lost)) {
                units.push(unit);
            }
        }.bind(this));

        if (!units.length) {
            Map2d.currentMap.fogs.listFakeUnits(function (unit) {
                if (Map2d.currentMap.getFog(unit.x, unit.y) && this.findInMineablePrizes(unit, lost)) {
                    units.push(unit);
                }
            }.bind(this));
        }

        if (!units.length) {
            cleverapps.notification.create("Quest.find.empty");
            return;
        }

        var center = Map2d.currentMap.getScreenCenterCell();

        units.sort(function (a, b) {
            return cc.pDistanceSQ(center, a) - cc.pDistanceSQ(center, b);
        });

        Map2d.currentMap.getView().scroll.runAction(new cc.CellScrollAction(units[0], {
            allowScrollWithFocus: lost.allowScrollWithFocus,
            skipFocusReport: true
        }).easing(cc.easeInOut(2)));
        Map2d.currentMap.onAddTarget(units[0].x, units[0].y);
    },

    findInPrizes: function (prizes, lost) {
        return prizes && prizes.some(function (prize) {
            if (prize.units) {
                return this.findInPrizes(prize.units, lost);
            }

            return lost.resource && lost.resource === prize.resource
                || lost.ingredient && lost.ingredient === prize.ingredient
                || (lost.type !== undefined || lost.code !== undefined || lost.stage !== undefined) && Unit.IsApplicable(lost, prize);
        }.bind(this));
    },

    findInMineablePrizes: function (unit, lost) {
        var type = Families[unit.code].type;
        var data = Families[unit.code].units[unit.stage];

        for (var mined = 0; mined < (data.mineable && data.mineable.length); ++mined) {
            var prizes = Prizes.Generate(cleverapps.toArray(Mines[type](mined, unit)), { listOnly: true });

            if (this.findInPrizes(prizes, lost)) {
                return true;
            }
        }

        return false;
    }
};
