/**
 * Created by r4zi4l on 09.02.2021
 */

var MergeAdviceHarvestLogic = function (options) {
    MergeAdviceBaseLogic.call(this, options);
};

MergeAdviceHarvestLogic.prototype = Object.create(MergeAdviceBaseLogic.prototype);
MergeAdviceHarvestLogic.prototype.constructor = MergeAdviceHarvestLogic;

MergeAdviceHarvestLogic.prototype.findMove = function () {
    var units = this.listAvailableUnits().filter(function (unit) {
        return unit.getData().collectible || unit.getData().resourceCollectible || unit.getData().tank || unit.getData().feedable;
    }, this);

    if (units.length) {
        var tank = units[0].findComponent(Tank);
        if (tank && tank.amount === 0) {
            return undefined;
        }
        var cells = units.map(function (unit) {
            return cc.p(unit.x, unit.y);
        });
        return {
            cells: cells
        };
    }
};
