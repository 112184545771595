/**
 * Created by andrey on 01.02.2021.
 */

var UnitsTab = cc.Node.extend({
    ctor: function (codes, options) {
        this.codes = codes;
        var families = this.families = codes.map(function (code) {
            var units = cleverapps.unitsLibrary.listOpenedPlusOneWithUnknown(code);
            if (!units.length) {
                return;
            }

            var icons = units.map(function (unit) {
                var icon = new UnitLibraryIcon(unit);
                icon.skipSeparator = Families[unit.code].units[unit.stage].unmergeable;
                return icon;
            });

            return new FamilyLayout(code, icons, {
                width: options.width
            });
        }, this).filter(Boolean);
        var styles = cleverapps.styles.UnitsTab;
        var content = new cleverapps.Layout(families, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });

        var scroll = this.scroll = new cleverapps.UI.ScrollView(content, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_FULLCHECK,
            scrollBarEnabled: false,
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL
        });

        scroll.setContentSize(content.width, Math.min(content.height, options.height));
        scroll.scrollToDefault();
        scroll.setPositionRound({
            x: { align: "center" },
            y: { align: "top" }
        });

        this._super();
        this.setContentSize2(options);
        this.setAnchorPoint(0.5, 0.5);
        this.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });
        this.addChild(scroll);
        cleverapps.eventBus.on("unitStatusChanged", this.onUnitStatusChanged.bind(this), this);
    },

    onUnitStatusChanged: function (unit) {
        if (!this.codes.includes(unit.code)) {
            return;
        }

        this.stopAllActions();
        this.runAction(new cc.Sequence(
            new cc.DelayTime(1.0),
            new cc.CallFunc(this.scrollToFresh.bind(this))
        ));
    },

    onSelectTab: function (params) {
        this.scroll.scrollToDefault();
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.3),
            new cc.CallFunc(this.scrollToFresh.bind(this, params))
        ));
    },

    onClickTab: function () {
        this.scrollToFresh();
    },

    scrollToFresh: function (params) {
        this.stopAllActions();

        params = params || {};
        var icon = this.findFamilyIcon(function (unit) {
            return params.searchUnit && Unit.IsApplicable(params.searchUnit, unit);
        }) || this.findFamilyIcon(function (unit) {
            return cleverapps.unitsLibrary.isFresh(unit);
        });

        if (icon) {
            this.scroll.runAction(new cc.ScrollAction(icon, {
                duration: 0.8,
                visibleBox: {
                    top: 0.85,
                    bottom: 0.1
                }
            }).easing(cc.easeBackInOut()));
        }
    },

    onUnSelectTab: function () {
        this.scroll.stopAutoScroll();
    },

    findFamilyIcon: function (filter) {
        for (var i = 0; i < this.families.length; ++i) {
            var family = this.families[i];

            for (var j = 0; j < family.icons.length; ++j) {
                var icon = family.icons[j];

                if (filter(icon.unit)) {
                    return icon;
                }
            }
        }
    }
});

cleverapps.styles.UnitsTab = {
    margin: 60,
    padding: {
        x: 0,
        y: 55
    }
};