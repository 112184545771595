/**
 * Created by vladislav on 18/10/2022
 */

var SpecialEnergyOffer = function () {
    this.expedition = cleverapps.travelBook.getCurrentPage().id;

    this.load(cleverapps.dataLoader.load(DataLoader.TYPES.SPECIAL_LIVES_OFFER_LAST_TIME));

    this.onBought = function () {};
};

SpecialEnergyOffer.prototype.load = function (data) {
    data = data || {};

    if (typeof data[this.expedition] === "object") {
        data[this.expedition] = data[this.expedition].show;
    }

    data[this.expedition] = data[this.expedition] || 0;

    this.data = data;
};

SpecialEnergyOffer.prototype.init = function () {
    this.refreshIcon();
};

SpecialEnergyOffer.prototype.refreshIcon = function () {
    var icon = cleverapps.sideBar.getIconByClassName(SpecialEnergyOfferIcon);
    if (icon) {
        icon.resetState();
    } else if (this.getIconLeftTime() > 0) {
        cleverapps.sideBar.addTemporaryIcon(new SpecialEnergyOfferIcon());
    }
};

SpecialEnergyOffer.prototype.wantsToShow = function () {
    if (cleverapps.meta.isFocused()) {
        this.showPlacement = true;
    } else {
        this.displayWindow();
    }
};

SpecialEnergyOffer.prototype.save = function (time) {
    this.data[this.expedition] = time || Date.now();

    cleverapps.dataLoader.save(DataLoader.TYPES.SPECIAL_LIVES_OFFER_LAST_TIME, this.data);
};

SpecialEnergyOffer.prototype.getNextTime = function () {
    return this.getData() + cleverapps.parseInterval(SpecialEnergyOffer.TIMEOUT);
};

SpecialEnergyOffer.prototype.getIconLeftTime = function () {
    var availableUntil = this.getData() + cleverapps.parseInterval(SpecialEnergyOffer.ICON_DURATION);
    return Math.max(0, availableUntil - Date.now());
};

SpecialEnergyOffer.prototype.getData = function () {
    return this.data[this.expedition] || 0;
};

SpecialEnergyOffer.prototype.isReady = function () {
    if (!Game.currentGame) {
        return false;
    }

    return cleverapps.user.checkAvailable(SpecialEnergyOffer.AVAILABLE) && this.getNextTime() <= Date.now();
};

SpecialEnergyOffer.prototype.displayWindow = function () {
    if (cleverapps.lives.showForce()) {
        return;
    }

    cleverapps.meta.display({
        focus: "SpecialEnergyOfferWindow",
        action: function (f) {
            this.save();
            this.refreshIcon();
            new SpecialEnergyOfferWindow();
            cleverapps.meta.onceNoWindowsListener = f;
        }.bind(this)
    });
};

SpecialEnergyOffer.prototype.getProduct = function () {
    return Product.CreateByHardPrice(SpecialEnergyOffer.ENERGY_PRICE, {
        type: "special_offer",
        expedition: cleverapps.travelBook.getCurrentPage().id
    });
};

SpecialEnergyOffer.prototype.getEnergyAmount = function () {
    return SpecialEnergyOffer.ENERGY_AMOUNT;
};

SpecialEnergyOffer.prototype.getPriceTag = function () {
    return this.getProduct().getCurrentPrice();
};

SpecialEnergyOffer.prototype.buy = function () {
    var product = this.getProduct();
    if (product) {
        product.buy(this.processBought.bind(this), { noRewardWindow: true });
    }
};

SpecialEnergyOffer.prototype.refreshAfterBought = function () {
    this.save(Date.now() - cleverapps.parseInterval(SpecialEnergyOffer.ICON_DURATION));
    this.refreshIcon();
};

SpecialEnergyOffer.prototype.processBought = function (success) {
    if (!success) {
        return;
    }

    this.refreshAfterBought();

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SPENT.GENIE_ENERGY);

    new RewardWindow({
        energy: this.getEnergyAmount()
    }, {
        event: "third_element"
    });

    this.onBought();
};

SpecialEnergyOffer.RestoreExpeditionId = function (context) {
    return context.expedition || "main";
};

SpecialEnergyOffer.RestoreActions = function (context, consume) {
    if (!Game.currentGame || !Game.currentGame.specialEnergyOffer) {
        return;
    }

    return [
        function (f) {
            consume();
            Game.currentGame.specialEnergyOffer.processBought(true);
            cleverapps.meta.onceNoWindowsListener = f;
        }
    ];
};

SpecialEnergyOffer.TIMEOUT = cleverapps.config.debugMode ? "2 minute" : "24 hours";
SpecialEnergyOffer.ICON_DURATION = cleverapps.config.debugMode ? "1 minute" : "10 minutes";

SpecialEnergyOffer.ENERGY_AMOUNT = 350;
SpecialEnergyOffer.ENERGY_PRICE = 280;

SpecialEnergyOffer.AVAILABLE = {
    level: 7
};

Product.RESTORE_LOGIC["special_offer"] = SpecialEnergyOffer;