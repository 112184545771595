/**
 * Created by spepa on 03.03.2023
 */

Landmarks.WORLDS.easter = {
    fog: "fog2",
    force: Forces.LANDMARK_DONOR_SLOT1,
    landmarks: [
        {
            code: "landmark",
            stage: 8,
            donors: []
        },
        {
            code: "landmark",
            stage: 14,
            donors: "eaunicorn"
        }
    ]
};

Mines.easource = function (level, unit) {
    if (unit.stage === 1) {
        return [
            {
                amount: 5,
                units: [
                    { code: unit.getResource(), stage: 1 }
                ]
            },
            {
                amount: 1,
                probabilities: [0.1],
                units: [
                    { code: "coins", stage: 1 }
                ]
            }
        ];
    }

    return [
        {
            amount: 4,
            units: [
                { code: unit.getResource(), stage: 0 },
                { code: unit.getResource(), stage: 1 }
            ]
        }
    ];
};

Mines.easourcegenerator = function () {
    return { openedType: "easource" };
};

PassLevelsConfig[Mission.CompoundType(Mission.TYPE_EXPEDITION_PASS, Mission.TYPE_EASTER_EXPEDITION)] = [
    {
        energy: 20,
        task: {
            goal: 15
        },
        reward: {
            unit: { code: "worker", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "worker", stage: 2, amount: 1 }
        }
    }, // 1
    {
        energy: 20,
        task: {
            goal: 45
        },
        reward: {
            unit: { code: "sacks", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "sacks", stage: 1, amount: 1 }
        }
    }, // 2
    {
        energy: 20,
        task: {
            goal: 100
        },
        reward: {
            unit: { code: "wands", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "wands", stage: 2, amount: 1 }
        }
    }, // 3
    {
        energy: 20,
        task: {
            goal: 210
        },
        reward: {
            unit: { code: "coins", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "rubies", stage: 3, amount: 1 }
        }
    }, // 4
    {
        energy: 20,
        task: {
            goal: 430
        },
        reward: {
            unit: { code: "crystal", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 1, amount: 3 }
        }
    }, // 5
    {
        energy: 20,
        task: {
            goal: 900
        },
        reward: {
            unit: { code: "treasure", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "treasure", stage: 2, amount: 1 }
        }
    }, // 6
    {
        energy: 20,
        task: {
            goal: 1100
        },
        reward: {
            unit: { code: "sacks", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "sacks", stage: 2, amount: 2 }
        }
    }, // 7
    {
        energy: 20,
        task: {
            goal: 1300
        },
        reward: {
            unit: { code: "herochest", stage: 0, amount: 1 }
        },
        premiumReward: {
            unit: { code: "herochest", stage: 1, amount: 3 }
        }
    }, // 8
    {
        energy: 20,
        task: {
            goal: 1500
        },
        reward: {
            unit: { code: "crystal", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 3, amount: 2 }
        }
    }, // 9
    {
        energy: 20,
        task: {
            goal: 1700
        },
        reward: {
            unit: { code: "herochest", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "herochest", stage: 2, amount: 2 }
        }
    }, // 10
    {
        energy: 20,
        task: {
            goal: 2000
        },
        reward: {
            unit: { code: "coins", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "treasure", stage: 2, amount: 2 }
        }
    }, // 11
    {
        energy: 20,
        task: {
            goal: 2300
        },
        reward: {
            unit: { code: "worker", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "worker", stage: 3, amount: 3 }
        }
    }, // 12
    {
        energy: 20,
        task: {
            goal: 2700
        },
        reward: {
            unit: { code: "magicplant", stage: 3, amount: 1 }
        },
        premiumReward: {
            unit: { code: "magicplant", stage: 5, amount: 1 }
        }
    }, // 13
    {
        energy: 20,
        task: {
            goal: 3100
        },
        reward: {
            unit: { code: "treasure", stage: 1, amount: 2 }
        },
        premiumReward: {
            unit: { code: "treasure", stage: 2, amount: 3 }
        }
    }, // 14
    {
        energy: 20,
        task: {
            goal: 3600
        },
        reward: {
            unit: { code: "wands", stage: 1, amount: 2 }
        },
        premiumReward: {
            unit: { code: "wands", stage: 3, amount: 3 }
        }
    }, // 15
    {
        energy: 20,
        task: {
            goal: 4100
        },
        reward: {
            unit: { code: "crystal", stage: 2, amount: 1 }
        },
        premiumReward: {
            unit: { code: "crystal", stage: 3, amount: 3 }
        }
    }, // 16
    {
        energy: 20,
        task: {
            goal: 4700
        },
        reward: {
            unit: { code: "coins", stage: 2, amount: 3 }
        },
        premiumReward: {
            unit: { code: "herochest", stage: 2, amount: 2 }
        }
    }, // 17
    {
        energy: 20,
        task: {
            goal: 5300
        },
        reward: {
            unit: { code: "worker", stage: 3, amount: 2 }
        },
        premiumReward: {
            unit: { code: "worker", stage: 4, amount: 2 }
        }
    }, // 18
    {
        energy: 20,
        task: {
            goal: 5900
        },
        reward: {
            unit: { code: "worker", stage: 4, amount: 1 }
        },
        premiumReward: {
            unit: { code: "magicplant", stage: 7, amount: 1 }
        }
    }, // 19
    {
        energy: 20,
        task: {
            goal: 6500
        },
        reward: {
            unit: { code: "coinstree", stage: 1, amount: 1 }
        },
        premiumReward: {
            unit: { code: "energytree", stage: 3, amount: 1 }
        }
    } // 20
];

CustomerRecipes.easter = {
    eaprince_0: [
        {
            ingredients: [
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "eaorder",
                    stage: 0,
                    amount: 3
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "eaorder",
                    stage: 0,
                    amount: 5
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "eaorder",
                    stage: 0,
                    amount: 3
                },
                {
                    type: "eaorder",
                    stage: 1,
                    amount: 3
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "eaorder",
                    stage: 0,
                    amount: 3
                },
                {
                    type: "eaorder",
                    stage: 0,
                    amount: 3
                },
                {
                    type: "eaorder",
                    stage: 1,
                    amount: 4
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "eaorder",
                    stage: 0,
                    amount: 5
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "earesource",
                    stage: 3,
                    amount: 2
                },
                {
                    type: "earesource",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "eaorder",
                    stage: 0,
                    amount: 3
                },
                {
                    type: "eaorder",
                    stage: 1,
                    amount: 3
                }
            ]
        }

    ],
    eathrone_0: [
        {
            ingredients: [
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "eahero",
                    stage: 0,
                    amount: 3
                },
                {
                    type: "eahero",
                    stage: 1,
                    amount: 2
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "eahero",
                    stage: 0,
                    amount: 1
                },
                {
                    type: "eahero",
                    stage: 1,
                    amount: 3
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 1
                }
            ],
            order: [
                {
                    type: "eahero",
                    stage: 0,
                    amount: 1
                },
                {
                    type: "eahero",
                    stage: 1,
                    amount: 6
                }
            ]
        },
        {
            ingredients: [
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 2
                }
            ],
            order: [
                {
                    type: "eahero",
                    stage: 0,
                    amount: 1
                },
                {
                    type: "eahero",
                    stage: 1,
                    amount: 6
                }
            ]
        }
    ],
    eacustomerbridge0a_0: [
        {
            ingredients: [
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 2
                }
            ],
            order: [
                {
                    code: "energy",
                    stage: 0,
                    amount: 1
                },
                {
                    code: "crystal",
                    stage: 0,
                    amount: 1
                }
            ]
        }
    ],
    eacustomerbridge0b_0: [
        {
            ingredients: [
                {
                    code: "eaproduct0",
                    stage: 0,
                    amount: 3
                }
            ],
            order: [
                {
                    code: "energy",
                    stage: 1,
                    amount: 1
                },
                {
                    code: "crystal",
                    stage: 0,
                    amount: 1
                }
            ]
        }
    ],
    eacustomermain0a_0: [
        {
            ingredients: [
                {
                    code: "eaproduct0",
                    stage: 0,
                    amount: 3
                }
            ],
            order: [
                {
                    code: "energy",
                    stage: 1,
                    amount: 1
                },
                {
                    code: "coins",
                    stage: 3,
                    amount: 1
                }
            ]
        }
    ],
    eacustomermain0a_1: [
        {
            ingredients: [
                {
                    code: "eaproduct0",
                    stage: 0,
                    amount: 2
                }
            ],
            order: [
                {
                    code: "energy",
                    stage: 1,
                    amount: 1
                },
                {
                    code: "coins",
                    stage: 2,
                    amount: 1
                }
            ]
        },
        {
            ingredients: [
                {
                    code: "eaproduct0",
                    stage: 0,
                    amount: 3
                }
            ],
            order: [
                {
                    code: "energy",
                    stage: 1,
                    amount: 2
                },
                {
                    code: "coins",
                    stage: 3,
                    amount: 1
                }
            ]
        }
    ],
    eacustomermain0b_0: [
        {
            ingredients: [
                {
                    code: "eaproduct0",
                    stage: 0,
                    amount: 3
                }
            ],
            order: [
                {
                    code: "energy",
                    stage: 1,
                    amount: 1
                },
                {
                    code: "coins",
                    stage: 2,
                    amount: 1
                }
            ]
        }
    ],
    eacustomermain0b_1: [
        {
            ingredients: [
                {
                    code: "eaproduct0",
                    stage: 0,
                    amount: 2
                }
            ],
            order: [
                {
                    code: "energy",
                    stage: 1,
                    amount: 1
                },
                {
                    code: "coins",
                    stage: 2,
                    amount: 1
                }
            ]
        },
        {
            ingredients: [
                {
                    code: "eaproduct0",
                    stage: 0,
                    amount: 3
                }
            ],
            order: [
                {
                    code: "energy",
                    stage: 1,
                    amount: 2
                },
                {
                    code: "coins",
                    stage: 3,
                    amount: 1
                }
            ]
        }
    ],
    eaproducer0_0: [
        {
            ingredients: [
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 1
                },
                {
                    type: "eaorder",
                    stage: 3,
                    amount: 2
                }
            ],
            order: [
                {
                    code: "eaproduct0",
                    stage: 0,
                    amount: 1
                }
            ]
        }
    ]
};

cleverapps.styles.easter = {
    miningAnimation: {
        x: { align: "center", dx: 0, leftDx: 0 },
        y: { align: "center", dy: 0 }
    },

    PixelView: {
        width: 90,
        height: 140,
        distanceDisappear: 1000,
        animation: {
            x: { align: "center", dx: -30 },
            y: { align: "center", dy: 20 }
        },
        collect: {
            x: { align: "center", dx: 10 },
            y: { align: "center", dy: -140 },
            delay: 1350
        },
        timeScale: {
            base: 1,
            random: 0
        },
        speed: {
            x: 35,
            y: -18
        }
    },

    fogBlockerLock: {
        width: 100,
        height: 100,
        margin: 25,
        content: {
            width: 68,
            height: 68
        },
        position: {
            x: { align: "center" },
            y: { align: "center", dy: 50 }
        },
        text: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -28 }
        },
        icon: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -28 }
        },
        cloud: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -39 }
        },
        lock: {
            x: { align: "center" },
            y: { align: "center" }
        }
    }
};
