/**
 * Created by vtbelo on 01.04.2021
 */

UnitsShop.COIN_PRICE = 0;
UnitsShop.RUBY_PRICE = 1;
UnitsShop.ADS_PRICE = 2;

UnitsShop.PRODUCTS = {};

UnitsShop.PRODUCTS[ShopWindow.TABS.UNITS_FRUIT] = {
    refreshTimeout: "270 minutes",
    refreshPrice: 29,

    default: [{
        unit: {
            code: "sacks",
            stage: 0
        },
        videoBonus: true,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "sacks",
            stage: 2
        },
        price: 150,
        priceType: UnitsShop.COIN_PRICE,
        amount: 1,
        left: 3
    }],

    fruit_template: [{
        unit: {
            stage: 4
        },
        price: 75,
        priceType: UnitsShop.COIN_PRICE,
        amount: 5,
        left: 3
    }, {
        unit: {
            stage: 4
        },
        price: 25,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 10,
        left: 3
    }]
};

UnitsShop.PRODUCTS[ShopWindow.TABS.UNITS_RESOURCE] = {
    refreshTimeout: "5 hours",
    refreshPrice: 49,

    default: [],

    resource_chest_template: [{
        unit: {
            stage: 0
        },
        videoBonus: true,
        amount: 1,
        left: 1
    }, {
        unit: {
            stage: 1
        },
        price: 135,
        priceType: UnitsShop.COIN_PRICE,
        amount: 1,
        left: 2
    }, {
        unit: {
            stage: 2
        },
        price: 75,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 2
    }],

    resource_data: {
        default: {
            basicPrice: 50,
            stages: 6
        },
        wood: {
            basicPrice: 25,
            stages: 4
        },
        stone: {
            basicPrice: 30,
            stages: 5
        },
        wagon: {
            basicPrice: 35,
            stages: 5
        }
    },

    resource_template: {
        priceType: UnitsShop.COIN_PRICE,
        amount: 1,
        left: 5
    }
};

UnitsShop.PRODUCTS[ShopWindow.TABS.UNITS_DISCOUNT] = {
    refreshTimeout: "6 hours",
    refreshPrice: 99,

    default: [{
        unit: {
            code: "freechest",
            stage: 0
        },
        videoBonus: true,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "thanksgiving",
            stage: 0
        },
        landmarkDonor: true,
        price: 55,
        priceType: UnitsShop.COIN_PRICE,
        amount: 1,
        left: 50
    },
    {
        unit: {
            code: "thanksgiving",
            stage: 1
        },
        landmarkDonor: true,
        price: 49,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 50
    }, {
        unit: {
            code: "herochest",
            stage: 0
        },
        price: 55,
        priceType: UnitsShop.COIN_PRICE,
        amount: 1,
        left: 2
    }, {
        unit: {
            code: "herochest",
            stage: 1
        },
        price: 35,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 2
    }, {
        unit: {
            code: "herochest",
            stage: 2
        },
        price: 95,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 2
    }, {
        unit: {
            code: "crystal",
            stage: 0
        },
        price: 75,
        priceType: UnitsShop.COIN_PRICE,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "crystal",
            stage: 3
        },
        price: 195,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "worker",
            stage: 4
        },
        price: 150,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 2,
        left: 2
    }],

    hero_template: [{
        unit: {
            stage: 1
        },
        price: 25,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 3
    }, {
        unit: {
            stage: 0
        },
        price: 35,
        priceType: UnitsShop.COIN_PRICE,
        amount: 1,
        left: 3
    }],

    season_chest: [{
        unit: {
            code: "seasonchest",
            stage: 0
        },
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "seasonchest",
            stage: 1
        },
        price: 65,
        priceType: UnitsShop.COIN_PRICE,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "seasonchest",
            stage: 2
        },
        price: 75,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 1
    }]
};

UnitsShop.PRODUCTS[ShopWindow.TABS.UNITS_EXPEDITION_HALLOWEEN] = {
    refreshTimeout: "24 hours",
    refreshPrice: 299,

    default: [{
        unit: {
            code: "hlfreechest",
            stage: 0
        },
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "hlshopchest",
            stage: 0
        },
        price: 49,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 10
    }, {
        unit: {
            code: "hlshopchest",
            stage: 1
        },
        price: 129,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 15
    }, {
        unit: {
            code: "hlshopchest",
            stage: 2
        },
        price: 319,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 30
    }, {
        unit: {
            code: "crystal",
            stage: 3
        },
        price: 119,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 5
    },
    {
        unit: {
            code: "worker",
            stage: 4
        },
        price: 150,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 2,
        left: 2
    }]
};

UnitsShop.PRODUCTS[ShopWindow.TABS.UNITS_EXPEDITION_XMAS] = {
    refreshTimeout: "24 hours",
    refreshPrice: 199,

    default: [{
        unit: {
            code: "xmfreechest",
            stage: 0
        },
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "xmgrowing",
            stage: 3
        },
        price: 149,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 3
    }, {
        unit: {
            code: "xmshopchest",
            stage: 0
        },
        price: 49,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 10
    }, {
        unit: {
            code: "xmshopchest",
            stage: 1
        },
        price: 129,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 15
    }, {
        unit: {
            code: "xmshopchest",
            stage: 2
        },
        price: 319,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 30
    }, {
        unit: {
            code: "crystal",
            stage: 3
        },
        price: 119,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 5
    }, {
        unit: {
            code: "worker",
            stage: 4
        },
        price: 79,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 5
    }]
};

UnitsShop.PRODUCTS[ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA] = {
    refreshTimeout: "24 hours",
    refreshPrice: 199,

    default: [{
        unit: {
            code: "drrudolf",
            stage: 0
        },
        price: 1500,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "drgiftgenerator",
            stage: 0
        },
        guideWindowOptions: ImproverGuideOptions,
        price: 20000,
        priceType: UnitsShop.COIN_PRICE,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "drfreechest",
            stage: 0
        },
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "drshopchest",
            stage: 0
        },
        price: 49,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 10
    }, {
        unit: {
            code: "drshopchest",
            stage: 1
        },
        price: 129,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 15
    }, {
        unit: {
            code: "drshopchest",
            stage: 2
        },
        price: 319,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 30
    }, {
        unit: {
            code: "crystal",
            stage: 3
        },
        price: 119,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 5
    }, {
        unit: {
            code: "worker",
            stage: 4
        },
        price: 79,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 5
    }],

    hero_ruby: [{
        unit: {
            code: "dragonruby",
            stage: 0
        },
        price: 49,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 30
    }]
};

UnitsShop.PRODUCTS[ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA2] = {
    refreshTimeout: "24 hours",
    refreshPrice: 199,

    default: [{
        unit: {
            code: "drfreechest",
            stage: 0
        },
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "coins",
            stage: 1
        },
        price: 10,
        priceType: UnitsShop.COIN_PRICE,
        amount: 1,
        left: 50
    }, {
        unit: {
            code: "drsack",
            stage: 0
        },
        amount: 2,
        left: 1
    }, {
        unit: {
            code: "drsack",
            stage: 2
        },
        price: 149,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 3
    }, {
        unit: {
            code: "drshopchest",
            stage: 0
        },
        price: 49,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 10
    }, {
        unit: {
            code: "drshopchest",
            stage: 1
        },
        price: 129,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 15
    }, {
        unit: {
            code: "drshopchest",
            stage: 2
        },
        price: 319,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 30
    }, {
        unit: {
            code: "crystal",
            stage: 3
        },
        price: 119,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 5
    }, {
        unit: {
            code: "worker",
            stage: 4
        },
        price: 79,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 5
    }],

    hero_ruby: [{
        unit: {
            code: "dr2dragonruby",
            stage: 0
        },
        price: 49,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 30
    }],

    line_template: [{
        stage: 0,
        type: "field",
        amount: 1,
        left: 1,
        price: 199,
        priceType: UnitsShop.RUBY_PRICE
    }]
};

UnitsShop.PRODUCTS[ShopWindow.TABS.UNITS_EXPEDITION_DRAGONIA3] = {
    refreshTimeout: "24 hours",
    refreshPrice: 199,

    default: [{
        unit: {
            code: "drfreechest",
            stage: 0
        },
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "drsack",
            stage: 0
        },
        amount: 2,
        left: 1
    }, {
        unit: {
            code: "drsack",
            stage: 2
        },
        price: 149,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 3
    }, {
        unit: {
            code: "drshopchest",
            stage: 0
        },
        price: 49,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 10
    }, {
        unit: {
            code: "drshopchest",
            stage: 1
        },
        price: 129,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 15
    }, {
        unit: {
            code: "drshopchest",
            stage: 2
        },
        price: 319,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 30
    }, {
        unit: {
            code: "crystal",
            stage: 3
        },
        price: 119,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 5
    }, {
        unit: {
            code: "worker",
            stage: 4
        },
        price: 79,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 5
    }],

    line_template: [{
        stage: 0,
        type: "field",
        amount: 1,
        left: 1,
        price: 199,
        priceType: UnitsShop.RUBY_PRICE
    }]
};

UnitsShop.PRODUCTS[ShopWindow.TABS.UNITS_EXPEDITION_UNDERSEA] = {
    refreshTimeout: "6 hours",
    refreshPrice: 199,

    default: [{
        unit: {
            code: "searudolf",
            stage: 0
        },
        price: 1500,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "seagiftgenerator",
            stage: 0
        },
        guideWindowOptions: ImproverGuideOptions,
        price: 20000,
        priceType: UnitsShop.COIN_PRICE,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "seafreechest",
            stage: 0
        },
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "seasack",
            stage: 0
        },
        amount: 2,
        left: 1
    }, {
        unit: {
            code: "seasack",
            stage: 2
        },
        price: 149,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 3
    }, {
        unit: {
            code: "seashopchest",
            stage: 0
        },
        price: 49,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 10
    }, {
        unit: {
            code: "seashopchest",
            stage: 1
        },
        price: 129,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 15
    }, {
        unit: {
            code: "seashopchest",
            stage: 2
        },
        price: 319,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 30
    }, {
        unit: {
            code: "crystal",
            stage: 3
        },
        price: 119,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 5
    }, {
        unit: {
            code: "worker",
            stage: 4
        },
        price: 79,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 5
    }],

    hero_ruby: [{
        unit: {
            code: "sea2walrusruby",
            stage: 0
        },
        price: 49,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 30
    }, {
        unit: {
            code: "walrusruby",
            stage: 0
        },
        price: 49,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 30
    }]
};

UnitsShop.PRODUCTS[ShopWindow.TABS.UNITS_ADS_UNDECIDED] = {
    refreshTimeout: "24 hours",
    refreshPrice: 299,

    default: [{
        unit: {
            code: "wands",
            stage: 0
        },
        priceType: UnitsShop.ADS_PRICE,
        price: 1,
        groupId: 1,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "worker",
            stage: 1
        },
        groupId: 2,
        priceType: UnitsShop.ADS_PRICE,
        price: 1,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "crystal",
            stage: 0
        },
        groupId: 2,
        priceType: UnitsShop.ADS_PRICE,
        price: 1,
        amount: 1,
        left: 1
    }],

    fruit_template: [{
        unit: {
            stage: 4
        },
        priceType: UnitsShop.ADS_PRICE,
        price: 1,
        groupId: 1,
        currentType: true,
        amount: 2,
        left: 1
    }],

    resource_chest_template: [{
        unit: {
            stage: 0
        },
        groupId: 3,
        priceType: UnitsShop.ADS_PRICE,
        price: 1,
        amount: 1,
        left: 1
    }],

    season_chest: [{
        unit: {
            code: "seasonitem",
            stage: 0
        },
        groupId: 3,
        priceType: UnitsShop.ADS_PRICE,
        price: 1,
        amount: 1,
        left: 1
    }]
};

UnitsShop.PRODUCTS[ShopWindow.TABS.UNITS_ADS_VIP] = {
    refreshTimeout: "24 hours",
    refreshPrice: 299,

    default: [{
        unit: {
            code: "wands",
            stage: 0
        },
        groupId: 1,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "worker",
            stage: 1
        },
        groupId: 2,
        priceType: UnitsShop.ADS_PRICE,
        price: 1,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "crystal",
            stage: 0
        },
        groupId: 2,
        priceType: UnitsShop.ADS_PRICE,
        price: 1,
        amount: 1,
        left: 1
    }],

    fruit_template: [{
        unit: {
            stage: 4
        },
        groupId: 1,
        currentType: true,
        amount: 2,
        left: 1
    }],

    resource_chest_template: [{
        unit: {
            stage: 0
        },
        groupId: 3,
        priceType: UnitsShop.ADS_PRICE,
        price: 1,
        amount: 1,
        left: 1
    }],

    season_chest: [{
        unit: {
            code: "seasonitem",
            stage: 0
        },
        groupId: 3,
        priceType: UnitsShop.ADS_PRICE,
        price: 1,
        amount: 1,
        left: 1
    }]
};

UnitsShop.PRODUCTS[ShopWindow.TABS.UNITS_ADS] = {
    refreshTimeout: "12 hours",
    refreshPrice: 299,

    default: [{
        unit: {
            code: "wands",
            stage: 0
        },
        groupId: 1,
        priceType: UnitsShop.ADS_PRICE,
        price: 5,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "herochest",
            stage: 0
        },
        groupId: 2,
        priceType: UnitsShop.ADS_PRICE,
        price: 10,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "sacks",
            stage: 2
        },
        groupId: 3,
        priceType: UnitsShop.ADS_PRICE,
        price: 20,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "coinscup",
            stage: 2
        },
        groupId: 3,
        priceType: UnitsShop.ADS_PRICE,
        price: 20,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "worker",
            stage: 3
        },
        groupId: 4,
        priceType: UnitsShop.ADS_PRICE,
        price: 25,
        amount: 2,
        left: 1
    }, {
        unit: {
            code: "worker",
            stage: 4
        },
        groupId: 4,
        priceType: UnitsShop.ADS_PRICE,
        price: 35,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "energycup",
            stage: 1
        },
        groupId: 4,
        priceType: UnitsShop.ADS_PRICE,
        price: 35,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "herochest",
            stage: 2
        },
        groupId: 5,
        priceType: UnitsShop.ADS_PRICE,
        price: 40,
        amount: 1,
        left: 1
    }, {
        unit: {
            code: "crystal",
            stage: 2
        },
        groupId: 5,
        priceType: UnitsShop.ADS_PRICE,
        price: 50,
        amount: 2,
        left: 1
    }, {
        unit: {
            code: "crystal",
            stage: 3
        },
        groupId: 5,
        priceType: UnitsShop.ADS_PRICE,
        price: 60,
        amount: 1,
        left: 1
    }],

    fruit_template: [{
        unit: {
            stage: 4
        },
        groupId: 2,
        currentType: true,
        priceType: UnitsShop.ADS_PRICE,
        price: 10,
        amount: 5,
        left: 1
    }, {
        unit: {
            stage: 3
        },
        groupId: 2,
        currentType: true,
        priceType: UnitsShop.ADS_PRICE,
        price: 15,
        amount: 1,
        left: 1
    }],

    resource_chest_template: [{
        unit: {
            stage: 2
        },
        groupId: 3,
        priceType: UnitsShop.ADS_PRICE,
        price: 20,
        amount: 1,
        left: 1
    }],

    season_chest: [{
        unit: {
            code: "seasonchest",
            stage: 0
        },
        groupId: 1,
        priceType: UnitsShop.ADS_PRICE,
        price: 5,
        amount: 1,
        left: 1
    }]
};

UnitsShop.PRODUCTS[ShopWindow.TABS.UNITS_EXPEDITION_RAPUNZEL] = {
    refreshTimeout: "6 hours",
    refreshPrice: 199,

    default: [
        {
            unit: {
                code: "rpfreechest",
                stage: 0
            },
            amount: 1,
            left: 1
        },
        {
            unit: {
                code: "rpshopchest",
                stage: 0
            },
            amount: 1,
            left: 1,
            price: 99,
            priceType: UnitsShop.RUBY_PRICE
        },
        {
            unit: {
                code: "crystal",
                stage: 1
            },
            price: 390,
            priceType: UnitsShop.COIN_PRICE,
            amount: 2,
            left: 1
        },
        {
            unit: {
                code: "crystal",
                stage: 3
            },
            price: 550,
            priceType: UnitsShop.RUBY_PRICE,
            amount: 3,
            left: 1
        },
        {
            unit: {
                code: "rprapunzel",
                stage: 0
            },
            price: 199,
            priceType: UnitsShop.COIN_PRICE,
            amount: 5,
            left: 5,
            onlyIfOpen: true,
            onlyIfNotDone: true
        },
        {
            unit: {
                code: "rprapunzel",
                stage: 1
            },
            price: 99,
            priceType: UnitsShop.RUBY_PRICE,
            amount: 3,
            left: 3,
            onlyIfOpen: true,
            onlyIfNotDone: true
        },
        {
            unit: {
                code: "rpprincehero",
                stage: 0
            },
            price: 199,
            priceType: UnitsShop.COIN_PRICE,
            amount: 5,
            left: 5,
            onlyIfOpen: true,
            onlyIfNotDone: true
        },
        {
            unit: {
                code: "rpprincehero",
                stage: 1
            },
            price: 99,
            priceType: UnitsShop.RUBY_PRICE,
            amount: 3,
            left: 3,
            onlyIfOpen: true,
            onlyIfNotDone: true
        }
    ],

    line_template: [
        {
            stage: 3,
            type: "rpresource",
            amount: 1,
            left: 5,
            price: 199,
            priceType: UnitsShop.COIN_PRICE
        },
        {
            stage: 1,
            type: "rporder",
            amount: 2,
            left: 5,
            price: 249,
            priceType: UnitsShop.COIN_PRICE
        }
    ]
};

UnitsShop.PRODUCTS[ShopWindow.TABS.UNITS_EXPEDITION_EASTER] = {
    refreshTimeout: "6 hours",
    refreshPrice: 199,

    default: [
        {
            unit: {
                code: "eafreechest",
                stage: 0
            },
            amount: 1,
            left: 1
        },
        {
            unit: {
                code: "eashopchest",
                stage: 0
            },
            amount: 1,
            left: 1,
            price: 99,
            priceType: UnitsShop.RUBY_PRICE
        },
        {
            unit: {
                code: "crystal",
                stage: 1
            },
            price: 390,
            priceType: UnitsShop.COIN_PRICE,
            amount: 2,
            left: 1
        },
        {
            unit: {
                code: "crystal",
                stage: 3
            },
            price: 550,
            priceType: UnitsShop.RUBY_PRICE,
            amount: 3,
            left: 1
        },
        {
            unit: {
                code: "eaunicorn",
                stage: 0
            },
            price: 199,
            priceType: UnitsShop.COIN_PRICE,
            amount: 5,
            left: 5,
            onlyIfOpen: true,
            onlyIfNotDone: true
        },
        {
            unit: {
                code: "eaunicorn",
                stage: 1
            },
            price: 99,
            priceType: UnitsShop.RUBY_PRICE,
            amount: 3,
            left: 3,
            onlyIfOpen: true,
            onlyIfNotDone: true
        }
    ],

    line_template: [
        {
            stage: 3,
            type: "earesource",
            amount: 1,
            left: 5,
            price: 199,
            priceType: UnitsShop.COIN_PRICE
        },
        {
            stage: 1,
            type: "eaorder",
            amount: 2,
            left: 5,
            price: 249,
            priceType: UnitsShop.COIN_PRICE
        }
    ]
};

UnitsShop.PRODUCTS[ShopWindow.TABS.UNITS_EXPEDITION_COLLECTIONS] = {
    refreshTimeout: "8 hours",
    refreshPrice: 149,

    default: [{
        unit: {
            code: "clsack",
            stage: 0
        },
        videoBonus: true,
        amount: 2,
        left: 1
    },
    {
        unit: {
            code: "clsimplechest",
            stage: 0
        },
        videoBonus: true,
        amount: 1,
        left: 1
    },
    {
        unit: {
            code: "clpaychest",
            stage: 0
        },
        amount: 1,
        left: 2,
        price: 89,
        priceType: UnitsShop.RUBY_PRICE
    },
    {
        unit: {
            code: "clpaychest",
            stage: 1
        },
        amount: 1,
        left: 2,
        price: 189,
        priceType: UnitsShop.RUBY_PRICE
    },
    {
        unit: {
            code: "clpaychest",
            stage: 2
        },
        amount: 1,
        left: 3,
        price: 389,
        priceType: UnitsShop.RUBY_PRICE
    },
    {
        unit: {
            code: "crystal",
            stage: 3
        },
        price: 195,
        priceType: UnitsShop.RUBY_PRICE,
        amount: 1,
        left: 3
    },
    {
        unit: {
            code: "clsimplechest",
            stage: 2
        },
        amount: 1,
        left: 1,
        price: 649,
        priceType: UnitsShop.COIN_PRICE
    }
    ],

    line_template: [
        {
            stage: 2,
            type: "clpetrare",
            amount: 1,
            left: 3,
            price: 99,
            randomChoose: 1,
            preferUndone: true,
            priceType: UnitsShop.RUBY_PRICE
        },
        {
            stage: 2,
            type: "clpetlegend",
            amount: 1,
            left: 3,
            price: 249,
            randomChoose: 1,
            preferUndone: true,
            priceType: UnitsShop.RUBY_PRICE
        }
    ]
};

UnitsShop.PRODUCTS[ShopWindow.TABS.UNITS_EXPEDITION_CHINA] = {
    refreshTimeout: "6 hours",
    refreshPrice: 199,

    default: [
        {
            unit: {
                code: "chfreechest",
                stage: 0
            },
            amount: 1,
            left: 1
        },
        {
            unit: {
                code: "chshopchest",
                stage: 0
            },
            amount: 1,
            left: 1,
            price: 99,
            priceType: UnitsShop.RUBY_PRICE
        },
        {
            unit: {
                code: "crystal",
                stage: 1
            },
            price: 390,
            priceType: UnitsShop.COIN_PRICE,
            amount: 2,
            left: 1
        },
        {
            unit: {
                code: "crystal",
                stage: 3
            },
            price: 550,
            priceType: UnitsShop.RUBY_PRICE,
            amount: 3,
            left: 1
        },
        {
            unit: {
                code: "chdragonrainbow",
                stage: 0
            },
            price: 199,
            priceType: UnitsShop.COIN_PRICE,
            amount: 5,
            left: 5,
            onlyIfOpen: true,
            onlyIfNotDone: true
        },
        {
            unit: {
                code: "chdragonrainbow",
                stage: 1
            },
            price: 99,
            priceType: UnitsShop.RUBY_PRICE,
            amount: 3,
            left: 3,
            onlyIfOpen: true,
            onlyIfNotDone: true
        }
    ],

    line_template: [
        {
            stage: 3,
            type: "chresource",
            amount: 1,
            left: 5,
            price: 199,
            priceType: UnitsShop.COIN_PRICE
        },
        {
            stage: 1,
            type: "chorder",
            amount: 2,
            left: 5,
            price: 249,
            priceType: UnitsShop.COIN_PRICE
        }
    ]
};
