/**
 * Created by andrey on 27.01.2021.
 */

var UnitLibraryIcon = cleverapps.Layout.extend({
    ctor: function (unit) {
        this.unit = unit;

        if (cleverapps.config.debugMode && cleverapps.flags.keyboardAnchorsMode) {
            delete unit.unknown;
        }

        this.background = new UnitLibraryIconBg(unit);
        this.footer = new UnitLibraryIconFooter(unit, this.collectReward.bind(this));
        this.addProducer();

        this._super([this.background, this.footer], {
            direction: cleverapps.UI.VERTICAL
        });

        cleverapps.eventBus.on("unitStatusChanged", this.onUpdateStatus.bind(this), this);

        this.onUpdateStatus();
    },

    addProducer: function () {
        if (cleverapps.unitsLibrary.isOpened(this.unit) || this.unit.unknown) {
            return;
        }

        var styles = cleverapps.styles.UnitLibraryIcon.producer;
        var producerData = Families[this.unit.code].units[this.unit.stage].producer;

        if (producerData && this.unit.stage > 0 && Families[this.unit.code].units[this.unit.stage - 1].unmergeable) {
            var producer = this.producer = UnitView.getUnitImage(producerData, { alignAnchorX: true, preferStatic: true });
            producer.pData = producerData;
            if (producer.clearTrack) {
                producer.clearTrack(0);
            }
            cleverapps.UI.fitToBox(producer, styles);
            producer.setPositionRound(styles);
            this.background.addChild(producer);
        }
    },

    collectReward: function () {
        cleverapps.unitsLibrary.removeFresh(this.unit, this.background);
    },

    onUpdateStatus: function () {
        if (this.clickHandler) {
            this.clickHandler.remove();
            this.clickHandler = undefined;
        }

        this.background.updateStatus();
        this.footer.updateStatus();

        if (cleverapps.unitsLibrary.isFresh(this.unit)) {
            this.clickHandler = cleverapps.UI.onClick(this, function () {
                this.collectReward();
            }.bind(this), {
                interactiveScale: false
            });
        }

        if (this.producer) {
            if (cleverapps.unitsLibrary.isOpened(this.unit)) {
                this.producer.removeFromParent();
                delete this.producer;
            } else {
                this.clickHandler = cleverapps.UI.onClick(this, function () {
                    var GuideClass = this.producer.pData.guide;
                    new GuideClass(this.producer.pData.guideOptions, this.producer.pData);
                }.bind(this), {
                    interactiveScale: false
                });
            }
        }

        if (cleverapps.config.debugMode && !this.clickHandler) {
            this.clickHandler = cleverapps.UI.onClick(this, function () {}, {
                interactiveScale: false,
                onDoubleClick: function () {
                    if (cleverapps.unitsLibrary.isOpened(this.unit)) {
                        delete cleverapps.unitsLibrary.opened[this.unit.code][this.unit.stage];
                        delete cleverapps.unitsLibrary.fresh[this.unit.code][this.unit.stage];
                        cleverapps.unitsLibrary.load(cleverapps.unitsLibrary.getInfo());
                        cleverapps.unitsLibrary.save();
                        cleverapps.windows.currentWindow().close();
                        new UnitsLibraryWindow(this.unit);
                    } else if (!cleverapps.unitsLibrary.isOpened(this.unit)) {
                        cleverapps.unitsLibrary.openUnit(this.unit);
                        cleverapps.windows.currentWindow().close();
                        new UnitsLibraryWindow(this.unit);
                    }
                }.bind(this)
            });
        }
    }
});

cleverapps.overrideColors(cleverapps.styles.COLORS, {
    UNITICON_COLOR: new cc.Color(255, 255, 255, 255)
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    UNITICON_TITLE: {
        size: 30,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    },

    UNITICON_LEVEL: {
        size: 30,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    }
});

cleverapps.styles.UnitLibraryIcon = {
    background: {
        width: 260,
        height: 340
    },

    producer: {
        x: { align: "left", dx: 10 },
        y: { align: "bottom", dy: 15 },
        width: 80,
        height: 80
    }
};
