/**
 * Created by r4zi4l on 17.01.2022
 */

var OrdersTab = cc.Node.extend({
    ctor: function (maker) {
        this._super();
        this.setContentSize2(cleverapps.styles.OrdersWindow);

        var styles = cleverapps.styles.OrdersTab;

        if (bundles.orderswindow.frames.ow_table) {
            var table = cleverapps.UI.createScale9Sprite(bundles.orderswindow.frames.ow_table, cleverapps.UI.Scale9Rect.TwoPixelXY);
            table.setContentSize2(styles.table);
            table.setPositionRound(styles.table);
            this.addChild(table);

            var shelf = cleverapps.UI.createScale9Sprite(bundles.orderswindow.frames.ow_shelf, cleverapps.UI.Scale9Rect.TwoPixelXY);
            shelf.setContentSize2(cleverapps.styles.OrdersWindow.width + styles.shelve.padding, shelf.height);
            shelf.setPositionRound(styles.shelve);
            this.addChild(shelf);
        }

        this.icons = Game.currentGame.orders.listAvailableMakers().map(function (maker) {
            return new OrderIcon(maker);
        }, this);

        if (this.icons.length === 0) {
            var placeholder = cleverapps.UI.generateOnlyText("OrdersWindow.placeholder", cleverapps.styles.FONTS.ORDERS_PLACEHOLDER_TEXT || cleverapps.styles.FONTS.LIGHT_TEXT);
            placeholder.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            placeholder.setVerticalAlignment(cc.VERTICAL_TEXT_ALIGNMENT_CENTER);
            placeholder.setDimensions(styles.placeholder.width, 0);
            placeholder.setPositionRound(styles.placeholder);
            this.addChild(placeholder);
        } else {
            var grid = this.createGrid(maker);
            grid.setAnchorPoint(0.5, 0.5);
            grid.setPositionRound(styles.grid);
            this.addChild(grid);
        }

        var details = this.details = new OrderDetails(maker);
        details.setAnchorPoint(0.5, 0.5);
        details.setPositionRound(styles.details);
        this.addChild(details);

        this.icons.forEach(function (icon) {
            icon.updateSelection(maker);
        });

        Game.currentGame.orders.on("ingredientsConsumed", this.onIngredientsConsumed.bind(this), this);
    },

    createGrid: function (maker) {
        var styles = cleverapps.styles.OrdersTab.grid;

        var grid = new cleverapps.GridLayout(this.icons, {
            columns: styles.columns || this.icons.length,
            margin: styles.margin,
            padding: styles.padding
        });

        this.icons.forEach(function (icon) {
            icon.unitsLayer.replaceParentSamePlace(grid);
        });

        var tooWide = grid.width > styles.width;
        var tooTall = grid.height > styles.height;

        if (!tooWide && !tooTall) {
            return grid;
        }

        var scroll = this.scroll = new cleverapps.UI.ScrollView(grid, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_FULLCHECK,
            scrollBarEnabled: false,
            direction: tooWide ? cleverapps.UI.ScrollView.DIR_HORIZONTAL : cleverapps.UI.ScrollView.DIR_VERTICAL
        });
        scroll.setContentSize(tooWide ? styles.width : grid.width, tooTall ? styles.height : grid.height);
        scroll.setPositionRound(scroll.width / 2, scroll.height / 2);

        var selectedIcon;
        for (var i = 0; i < this.icons.length; ++i) {
            if (this.icons[i].maker === maker) {
                selectedIcon = this.icons[i];
                break;
            }
        }

        if (selectedIcon) {
            scroll.runAction(new cc.ScrollAction(selectedIcon, {
                silent: true,
                visibleBox: {
                    left: 0.9,
                    right: 0.9,
                    top: 0.9,
                    bottom: 0.9
                }
            }));
        } else {
            scroll.scrollToDefault();
        }

        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        container.setContentSize2(scroll.width, scroll.height);
        container.addChild(scroll);

        if (bundles.orderswindow.frames.ow_scroll_border) {
            var rightBorder = cleverapps.UI.createScale9Sprite(bundles.orderswindow.frames.ow_scroll_border);
            rightBorder.setContentSize(rightBorder.width, container.height);
            rightBorder.setPositionRound(styles.border.right, styles.border.y);
            container.addChild(rightBorder);

            var leftBorder = cleverapps.UI.createScale9Sprite(bundles.orderswindow.frames.ow_scroll_border);
            leftBorder.setContentSize(leftBorder.width, container.height);
            leftBorder.setPositionRound(styles.border.left, styles.border.y);
            leftBorder.setScaleX(-1);
            container.addChild(leftBorder);
        }

        return container;
    },

    onIngredientsConsumed: function (maker) {
        var flyToTarget = function (target) {
            var position;
            this.runAction(new cc.Sequence(
                new cc.CallFunc(function () {
                    var flyTarget = target;
                    if (cleverapps.config.name === "hustlemerge") {
                        flyTarget = this.details.rewards;

                        this.details.arrow.setAnimationAndIdleAfter("consume", "idle");
                        var dx = cleverapps.styles.OrdersTab.shelve.exchangeShift;

                        this.details.ingredients.children.concat(this.details.leftBasket).forEach(function (l) {
                            l.runAction(new cc.Sequence(
                                new cc.MoveBy(0.3, dx, 0).easing(cc.easeIn(2)),
                                new cc.MoveBy(0.3, -dx, 0).easing(cc.easeOut(2))
                            ));
                        });

                        this.details.rewards.children.concat(this.details.rightBasket).forEach(function (l) {
                            l.runAction(new cc.Sequence(
                                new cc.MoveBy(0.3, -dx, 0).easing(cc.easeIn(2)),
                                new cc.MoveBy(0.3, dx, 0).easing(cc.easeOut(2))
                            ));
                        });
                    }

                    position = this.convertToNodeSpace(flyTarget.parent.convertToWorldSpace(flyTarget.getPosition()));

                    this.details.ingredients.children.forEach(function (icon) {
                        icon.beforeRemoveAnimated();

                        var flyingIcon = new IngredientOrderIcon({
                            code: icon.code,
                            stage: icon.stage
                        });
                        flyingIcon.setPositionRound(this.convertToNodeSpace(icon.parent.convertToWorldSpace(icon.getPosition())));
                        flyingIcon.setLocalZOrder(10);
                        this.addChild(flyingIcon);

                        flyingIcon.runAction(new cc.Sequence(
                            new cc.Spawn(
                                new cc.RotateTo(0.2, -30).easing(cc.easeIn(1)),
                                new cc.JumpTo(0.8, position.x, position.y, cleverapps.styles.OrderIcon.fly.height, 1)
                            ),
                            new cc.RemoveSelf()
                        ));
                    }, this);
                }.bind(this)),
                new cc.DelayTime(0.8),
                new cc.PlaySound(bundles.merge.urls.unit_desturction_effect),
                new cc.CallFunc(function () {
                    var animation = new cleverapps.Spine(bundles.orderswindow.jsons.ow_rewards_highlight_json);
                    animation.setScale(1.7);
                    animation.setAnchorPoint(0.5, 0.5);
                    animation.setPositionRound(position.x, position.y);
                    animation.setAnimation(0, "animation", false);
                    animation.setCompleteListenerRemove();
                    this.addChild(animation);
                }.bind(this)),
                new cc.DelayTime(0.1),
                new cc.CallFunc(function () {
                    target.setFreeze(false);
                    target.updateState(target.maker);
                })
            ));
        }.bind(this);

        for (var i = 0; i < this.icons.length; ++i) {
            if (this.icons[i].maker === maker) {
                var target = this.icons[i];
                target.setFreeze(true);
                if (this.scroll) {
                    this.scroll.scrollTo(target, 0.5, {
                        callback: flyToTarget.bind(this, target)
                    });
                } else {
                    flyToTarget(target);
                }
                break;
            }
        }
    }
});

cleverapps.styles.OrdersTab = {
    table: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: -10 },
        width: 1350,
        height: 420
    },

    shelve: {
        x: { align: "center" },
        y: { align: "center", dy: -5 },
        padding: 130,
        exchangeShift: 30
    },

    placeholder: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -240 },
        width: 1240
    },

    grid: {
        x: { align: "left", dx: 18 },
        y: { align: "center", dy: -230 },
        width: 1240,

        margin: {
            x: 20,
            y: 0
        },
        padding: {
            x: 20,
            y: 20
        },

        border: {
            left: { align: "left", dx: 0 },
            right: { align: "right", dx: 2 },
            y: { align: "center", dy: 0 }
        }
    },

    details: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 40 }
    }
};
