/**
 * Created by mac on 2/25/20
 */

var Merge = function (level, options) {
    var episodeNo = level && level.episodeNo || 0;
    var levelNo = level && level.levelNo || 0;

    level = new Level(episodeNo, levelNo);
    level.load();

    cleverapps.ABTest.ChangeConfigsForAB(cleverapps.travelBook.getCurrentPage().id);

    GameBase.call(this, level, options);

    this.counter.turnOn();

    this.onChangeWandsListener = function () {};
    this.onSubstractEnergy = function () {};

    this.isScrollProcessing = function () {};
    this.onShowMergeBonus = function () {};

    var stored = this.savedGame;

    console.log("Stored", stored);

    cleverapps.config.gameInitialized = false;

    this.unitSaver = cleverapps.unitSavers.getInstance(this.slot);
    var saverUnits = this.unitSaver.loadUnits(level.families, cleverapps.travelBook.getCurrentPage().id);

    options = {};

    if (!cleverapps.config.editorMode) {
        var toDelete = [];

        if (this.isMainGame()) {
            if (cleverapps.user.level < 1) {
                stored = this.getInitialInfo();
                toDelete = saverUnits;

                cleverapps.unitsLibrary.reset();
                options.isNewGame = true;
            }
        } else if (stored.expedition !== cleverapps.travelBook.getCurrentPage().id) {
            toDelete = saverUnits;
            delete stored.wands;
            options.isNewGame = true;
        }

        toDelete.forEach(function (unit) {
            this.unitSaver.remove(unit.x, unit.y);
        }, this);

        saverUnits = cleverapps.substract(saverUnits, toDelete, Unit.GetPositionKey);
    }

    this.map = new Map2d(level.content.map, {
        tiles: level.meta.tiles,
        skins: level.meta.skins,
        visibleBox: level.content.visibleBox,
        tilesVisibleRectFrame: level.content.tilesVisibleRectFrame,
        regions: level.content.regions,
        decorators: level.content.decorators,
        terrains: level.content.terrains,
        field: level.content.field,
        isNewGame: options.isNewGame
    });

    this.map.onUnitAvailableListener = this.onUnitAvailable.bind(this);
    this.map.onUnitFreshListener = this.onUnitFresh.bind(this);
    this.map.onUnitRemovedListener = this.onUnitRemoved.bind(this);

    this.pocket = new Pocket(this.slot, options);
    this.pocket.validate(level);

    cleverapps.Lives.Switch(cleverapps.travelBook.getCurrentPage().slot, options.isNewGame);

    cleverapps.gameLevel.load(stored.ownLevel);

    Exp.Switch(cleverapps.travelBook.getCurrentPage().slot);
    if (cleverapps.gameLevel.withOwnLevel) {
        cleverapps.exp.load(stored.exp, options.isNewGame);
    }

    if (stored.gameLevel) {
        cleverapps.user.level = stored.gameLevel.value;
        cleverapps.exp.setExp(stored.gameLevel.exp);
        cleverapps.user.save();
    }

    this.wands = stored.wands || 0;
    if (this.wands < 0) {
        this.wands = 0;
    }

    this.orders = new Orders();
    this.harvested = new Harvested(this.slot, options);
    this.advice = new MergeAdvice();
    this.tutorial = new MergeTutorial();

    this.customers = new Customers();
    this.unitGreeter = new UnitGreeter();

    if (this.isMainGame()) {
        this.energyLottery = new EnergyLottery(stored.lottery);
    }

    if (this.isMainGame()) {
        this.season = new Season(stored.season);
    }

    if (cleverapps.travelBook.getCurrentPage().id === "collections") {
        this.pawBox = new PawBox(stored.paws);
    }

    if (!cleverapps.config.editorMode) {
        var comparator = Map2d.LOAD_UNITS_COMPARATOR();
        saverUnits.sort(function (unit1, unit2) {
            return comparator(unit1.data, unit2.data);
        }).forEach(function (info) {
            this.map.loadUnit(info.x, info.y, info.data);
        }, this);

        this.map.workers.load(options);

        this.loadStaticUnits(level);
        this.map.initFogs();
        this.map.fogs.setWands(this.wands);
        this.map.fogs.on("takeWands", this.takeWands.bind(this), this);
        this.map.fogs.on("open", function () {
            this.counter.setTimeout(function () {}, 0);
        }.bind(this));
    } else {
        Map2d.InsertDefaults(this.map, level.content.field);
    }

    if (!cleverapps.config.editorMode || cleverapps.config.wysiwygMode) {
        this.removeUnitsInFog();
    }

    if (!this.map.scrollCell || options.isNewGame) {
        this.map.scrollCell = undefined;
        this.map.zoom = this.getBasicZoom();
        var scrollCell = this.map.fogs.blocks.fog0 && this.map.fogs.blocks.fog0.head;
        if (scrollCell) {
            this.map.scrollCell = cc.p(scrollCell.x, scrollCell.y);
        }
        if (cleverapps.config.demoMode) {
            var units = this.map.listAvailableUnits();
            var geometricCenter = units.reduce(function (total, unit) {
                return cc.pAdd(total, cc.pMult(unit, 1 / units.length));
            }, cc.p(0, 0));
            this.map.scrollCell = cc.p(Math.floor(geometricCenter.x), Math.floor(geometricCenter.y));
        }
    }

    this.pushingComponent = new PushingComponent();

    this.pixelsPlanner = new PixelsPlanner(options);
    this.barrelPlanner = new BarrelPlanner();
    this.monstersPlanner = new MonstersPlanner();
    this.growingsPlanner = new GrowingsPlanner(options);
    this.bpPointsPlanner = new BattlePassPointsPlanner();
    this.shipsPlanner = new ShipsPlanner();
    this.thirdElementPlanner = new ThirdElementPlanner(options);

    this.unitStories = new UnitStories();
    this.landmarks = new Landmarks();
    this.pushes = new MergePushes();
    this.quests = new Quests(options);

    this.specialEnergyOffer = new SpecialEnergyOffer();
    this.specialEnergyOffer.init();

    this.thirdElements = [];

    if (cleverapps.config.debugMode && !cleverapps.config.editorMode) {
        this.orangery = new Orangery(MergeOrangery);
    }

    if (cleverapps.config.wysiwygMode) {
        this.counter.registerStage(0, this.initStage.bind(this));
    } else if (!cleverapps.config.adminMode && !cleverapps.config.editorMode) {
        this.counter.registerStage(0, this.initStage.bind(this));
        this.counter.registerStage(6, cleverapps.userStatus.reportUserAction.bind(cleverapps.userStatus));
        this.counter.registerStage(10, this.quests.finish.bind(this.quests));
        this.counter.registerStage(20, cleverapps.gameLevel.checkLevelUp.bind(cleverapps.gameLevel));
        this.counter.registerStage(21, this.map.blockedGrounds.processFresh.bind(this.map.blockedGrounds));
        this.counter.registerStage(24, this.monstersPlanner.processFresh.bind(this.monstersPlanner));
        this.counter.registerStage(25, this.unitStories.processFresh.bind(this.unitStories));
        this.counter.registerStage(30, this.tutorial.processFresh.bind(this.tutorial));
        this.counter.registerStage(40, this.quests.processFresh.bind(this.quests));
        this.counter.registerStage(41, this.map.fogs.calcFogStatesStage.bind(this.map.fogs));
        this.counter.registerStage(42, this.thirdElementPlanner.spawnPending.bind(this.thirdElementPlanner));
        this.counter.registerStage(43, this.customers.process.bind(this.customers));
        if (this.season) {
            this.counter.registerStage(45, this.season.processFresh.bind(this.season));
        }
        this.counter.registerStage(47, this.unitGreeter.processFresh.bind(this.unitGreeter));
        this.counter.registerStage(48, this.landmarks.process.bind(this.landmarks));
        this.counter.registerStage(50, this.tutorial.nextStep.bind(this.tutorial));
        if (this.pawBox) {
            this.counter.registerStage(55, this.pawBox.giveReward.bind(this.pawBox));
        }
        this.counter.registerStage(60, cleverapps.travelBookHint.check.bind(cleverapps.travelBookHint));
        this.counter.registerStage(61, this.pocket.showHint.bind(this.pocket));
        this.counter.registerStage(62, this.harvested.showHint.bind(this.harvested));

        cleverapps.config.gameInitialized = true;
        this.counter.setTimeout(this.updateAvailableUnits.bind(this), 0);
    }

    this.shipsPlanner.init();

    if (options.isNewGame || this.needSave) {
        delete this.needSave;

        this.storeSave();
    }

    if (options.isNewGame) {
        this.unitSaver.resetKickOuts();
    }

    cleverapps.offerManager.refreshAll();
};

var Game = Merge;

Merge.prototype = Object.create(GameBase.prototype);
Merge.constructor = Merge;

Merge.prototype.isMainGame = function () {
    return this.level.episodeNo === 0 && this.level.levelNo === 0 && cleverapps.environment.isMainScene();
};

Merge.prototype.initStats = function () {
    var collections = cleverapps.travelBook.getPageById("collections");
    if (collections && collections.isActive()) {
        cleverapps.playSession.set(cleverapps.EVENTS.COLLECTIONS_DAU, true);
    }

    var logFamily = function (type, progressEvent, completeEvent) {
        var current = cleverapps.unitsLibrary.getLastOpenCode(type);
        if (current) {
            var list = cleverapps.unitsLibrary.listCodesByType(type);
            var last = list[list.length - 1];

            var event = progressEvent + current;
            if (current === last && cleverapps.unitsLibrary.isOpened({ code: last, stage: Families[last].units.length - 1 })) {
                event = completeEvent;
            }
            cleverapps.eventLogger.logEvent(event);
        }
    };

    var logFamilies = function (resourceType, heroType, eventPref) {
        eventPref = eventPref || "";

        cleverapps.eventLogger.logEvent(eventPref + cleverapps.EVENTS.MERGE_START);
        logFamily(resourceType, eventPref + cleverapps.EVENTS.CURRENT_RESOURCE, eventPref + cleverapps.EVENTS.COMPLETE_RESOURCES);

        var currentHero = cleverapps.unitsLibrary.getCurrentHero(heroType);
        if (["rapunzel", "rapunzel2", "easter"].includes(cleverapps.travelBook.getCurrentPage().id)) {
            if (currentHero) {
                var stage = cleverapps.unitsLibrary.getLastOpenStage(currentHero);
                stage = stage === undefined ? "" : "_" + stage;
                cleverapps.eventLogger.logEvent(eventPref + cleverapps.EVENTS.CURRENT_HERO + currentHero + stage);
            } else {
                cleverapps.eventLogger.logEvent(eventPref + cleverapps.EVENTS.COMPLETE_HEROES);
            }
        } else {
            var heroEvent = currentHero ? (cleverapps.EVENTS.CURRENT_HERO + currentHero) : cleverapps.EVENTS.COMPLETE_HEROES;
            cleverapps.eventLogger.logEvent(eventPref + heroEvent);
        }
    };

    if (cleverapps.travelBook.isExpedition()) {
        var expedition = cleverapps.travelBook.getCurrentPage().id;
        var resourceType = cleverapps.unitsLibrary.getExpeditionUnitType("resource");
        var heroType = cleverapps.unitsLibrary.getExpeditionUnitType("hero");
        var prefix = cleverapps.travelBook.getCurrentPage().prefix;
        logFamilies(resourceType, heroType, prefix + "_");
        if (cleverapps.paymentsHistory.isPayer()) {
            logFamilies(resourceType, heroType, "payer_" + prefix + "_");
        }

        var logCampaign = function (type) {
            cleverapps.unitsLibrary.listCodesByType(type).forEach(function (code) {
                var openedStage = cleverapps.unitsLibrary.getLastOpenStage(code);
                if (openedStage !== undefined && Families[code].units[openedStage].climbable) {
                    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.CAMPAIGN + code + "_" + openedStage);
                }
            });
        };

        if (["rapunzel", "rapunzel2"].includes(expedition)) {
            var currentOrder = cleverapps.unitsLibrary.getLastOpenCode("rporder");
            if (currentOrder) {
                var ordersList = cleverapps.unitsLibrary.listCodesByType("rporder");
                var lastOrder = ordersList[ordersList.length - 1];

                var orderEvent = cleverapps.EVENTS.CURRENT_ORDER + currentOrder;
                if (currentOrder === lastOrder && cleverapps.unitsLibrary.isOpened({ code: lastOrder, stage: Families[lastOrder].units.length - 1 })) {
                    orderEvent = cleverapps.EVENTS.COMPLETE_ORDERS;
                }

                cleverapps.eventLogger.logEvent(prefix + "_" + orderEvent);
            }
            logCampaign("rpcustomer");
        } else if (expedition === "xmas") {
            logCampaign("xmcustomer");
        } else if (expedition === "halloween") {
            logCampaign("hlcustomer");
        } else if (expedition === "collections") {
            var logPets = function (payer) {
                var prefix = payer ? "payer_" : "";
                logFamily("clpet", prefix + cleverapps.EVENTS.CURRENT_PET, prefix + cleverapps.EVENTS.COMPLETE_PET);
                logFamily("clpetrare", prefix + cleverapps.EVENTS.CURRENT_PETRARE, prefix + cleverapps.EVENTS.COMPLETE_PETRARE);
                logFamily("clpetlegend", prefix + cleverapps.EVENTS.CURRENT_PETLEGEND, prefix + cleverapps.EVENTS.COMPLETE_PETLEGEND);
            };
            logPets();
            cleverapps.paymentsHistory.isPayer() && logPets(true);

            var petStats = {};
            Map2d.currentMap.listAvailableUnits([{ type: "clpet" }, { type: "clpetrare" }, { type: "clpetlegend" }]).forEach(function (pet) {
                var type = pet.getType();
                petStats[type] = petStats[type] ? petStats[type] + 1 : 1;
            });
            cleverapps.playSession.set(cleverapps.EVENTS.PET_RATE, petStats);
        }

        if (!cleverapps.travelBook.getCurrentExpedition().isCompleted()) {
            cleverapps.playSession.set(cleverapps.EVENTS.EXPEDITION_DAU, true, expedition);
        }

        var availableFog = this.map.fogs.findAvailableFogBlock();
        if (availableFog) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.EXPEDITION_FOG + prefix + "_" + availableFog.id);
        }
    }

    if (this.isMainGame()) {
        logFamilies("resource", "hero");
        if (cleverapps.paymentsHistory.isPayer()) {
            logFamilies("resource", "hero", "payer_");
        }

        if (this.season && Season.isRunning()) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SEASON_START);

            if (this.season.getSeasonItems().length === this.season.opened) {
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.COMPLETE_SEASON + this.season.runningSeason);
            } else {
                cleverapps.eventLogger.logEvent(cleverapps.EVENTS.CURRENT_SEASON + this.season.runningSeason + "_" + this.season.opened);
            }
        }

        if (cleverapps.eventManager.isActive("thanksgiving")) {
            logFamily("thanksgiving", cleverapps.EVENTS.CURRENT_EVENT, cleverapps.EVENTS.COMPLETE_EVENT + "thanksgiving");
        }

        var units = [{
            code: "magicplant",
            stage: 8
        }, {
            code: "crystal",
            stage: 3
        }, {
            code: "coinsplant"
        }, {
            code: "rubiesplant"
        }, {
            code: "energyplant"
        }, {
            code: "worker",
            stage: 4
        }];

        var unitsAmount = {};

        Map2d.currentMap.listAvailableUnits(units).forEach(function (unit) {
            var key = Unit.GetKey(unit);
            unitsAmount[key] = (unitsAmount[key] || 0) + 1;
        });

        cleverapps.playSession.set(cleverapps.EVENTS.UNITS_AMOUNT, {
            amount: unitsAmount,
            level: cleverapps.user.level
        });
    }
};

Merge.prototype.workersBusyHint = function (unit) {
    if (!this.map.workers.isBonusWorkerBuyed() && !cleverapps.meta.isFocused()) {
        if (this.map.workers.isFreeWorkerAvailable()) {
            cleverapps.meta.display({
                focus: "BonusWorkerWindow",
                control: "MenuBarWorkersItem",
                actions: [
                    function (f) {
                        new ReceiveWorkerWindow();
                        cleverapps.meta.onceNoWindowsListener = f;
                    },
                    function (f) {
                        var tutorial = cleverapps.clone(MergeTutorials.free_worker);

                        if (unit.findComponent(Buildable)) {
                            tutorial.steps[0].type = Map2d.START_BUILDING;
                        } else if (unit.findComponent(Mineable)) {
                            tutorial.steps[0].type = Map2d.START_MINING;
                        }

                        tutorial.steps[0].preferCell = { x: unit.x, y: unit.y };

                        this.tutorial.showTutorial(tutorial, f);
                    }.bind(this)
                ]
            });
            return;
        }

        if (!this.workersWindowShown || this.workersWindowShown < Date.now() - cleverapps.parseInterval(Merge.WORKERS_WINDOW_INTERVAL)) {
            cleverapps.meta.display({
                focus: "BonusWorkerWindow",
                control: ["MenuBarGoldItem", "MenuBarWorkersItem"],
                action: function (f) {
                    this.workersWindowShown = Date.now();
                    new BonusWorkerWindow();
                    cleverapps.meta.onceNoWindowsListener = f;
                }.bind(this)
            });
            return;
        }
    }

    cleverapps.centerHint.createTextHint("Workers.busy");

    var worker = this.map.workers.findLeastBusy();
    if (worker) {
        this.map.getView().scroll.runAction(
            new cc.CellScrollAction(worker.unit, {
                skipFocusReport: true
            }).easing(cc.easeInOut(2))
        );
        this.map.onAddTarget(worker.unit.x, worker.unit.y);
    }
};

Merge.prototype.removeUnitsInFog = function () {
    var removeUnits = [];
    for (var y = 0; y < this.map.getHeight(); y++) {
        for (var x = 0; x < this.map.getWidth(); x++) {
            var unit = this.map.getUnit(x, y);
            var fog = this.map.getFog(x, y);
            if (unit && fog) {
                if (unit.isMultiCellBody()) {
                    unit = unit.head;
                }

                if (unit.isMovable() && !unit.getData().important) {
                    removeUnits.push({
                        code: unit.code,
                        stage: unit.stage
                    });
                }
                if (unit.code !== "unknown" && !unit.isGrounded()) {
                    unit.remove(true);
                }
            }
        }
    }

    if (removeUnits.length) {
        this.pocket.addUnits(removeUnits);
    }
};

Merge.prototype.initStage = function () {
    if (this.initStageRunned) {
        return;
    }
    this.initStageRunned = true;

    if (!cleverapps.config.wysiwygMode) {
        this.initStats();
    }

    this.repairDeletedStages();
    this.map.initWorkers();
    this.restoreImportantUnits();
    this.restoreMultiCells();

    if (!cleverapps.config.wysiwygMode) {
        this.restoreKickOuts();

        if (!cleverapps.config.adminMode && !cleverapps.config.editorMode && !cleverapps.synchronizer._clientChecksumDifferent) {
            this.checkReset();
            this.checkReset2();
        }

        this.updateExpedition();
    }

    this.landmarks.restoreLandmarks();
    this.monstersPlanner.removeWrongMonsters();

    this.map.listAvailableUnits().forEach(function (unit) {
        if (unit.wantsSave) {
            unit.save();
            delete unit.wantsSave;
        }
    });
};

Merge.prototype.repairDeletedStages = function () {
    for (var y = 0; y < this.map.getHeight(); y++) {
        for (var x = 0; x < this.map.getWidth(); x++) {
            var unit = this.map.getUnit(x, y);
            if (unit && unit.getData().deleted) {
                var newStage = Unit.calcNewStageThenDeleted(unit.code, unit.stage);
                unit.remove();
                var newUnit = new Unit({
                    code: unit.code,
                    stage: newStage
                });
                newUnit.setPosition(unit.x, unit.y);
                Map2d.currentMap.add(Map2d.LAYER_UNITS, newUnit.x, newUnit.y, newUnit);
                Map2d.currentMap.onAddUnit(newUnit.x, newUnit.y, newUnit);
            }
        }
    }
};

Merge.prototype.restoreImportantUnits = function () {
    if (cleverapps.gameModes.multipleHeroes) {
        return;
    }

    var fakeImportantUnits = [], playerImportantUnits = [], x, y, unit;
    for (var row in this.map.fogs.fakeUnits) {
        for (var col in this.map.fogs.fakeUnits[row]) {
            x = parseInt(row);
            y = parseInt(col);
            if (this.map.fogs.fakeUnits[x][y].important && !this.map.getFog(x, y)) {
                fakeImportantUnits.push({ x: x, y: y, fakeUnit: this.map.fogs.fakeUnits[x][y] });
            }
        }
    }

    for (y = 0; y < this.map.getHeight(); y++) {
        for (x = 0; x < this.map.getWidth(); x++) {
            unit = this.map.getUnit(x, y);
            if (unit && unit.getData().important) {
                playerImportantUnits.push(unit);
            }
        }
    }

    var importantBubbles = !this.pocket ? [] : this.pocket.bubbles.filter(function (bubble) {
        return Families[bubble.code].units[bubble.stage].important;
    });

    fakeImportantUnits.forEach(function (fakeData) {
        var fakeUnit = fakeData.fakeUnit;
        x = fakeData.x;
        y = fakeData.y;

        var bestUnit = undefined, bestId = undefined;
        playerImportantUnits.forEach(function (playerUnit, id) {
            if (playerUnit.code === fakeUnit.code) {
                if (!bestUnit || bestUnit.stage < playerUnit.stage || bestUnit.stage === playerUnit.stage && cc.pDistanceSQ(cc.p(x, y), bestUnit) > cc.pDistanceSQ(cc.p(x, y), playerUnit)) {
                    bestUnit = playerUnit;
                    bestId = id;
                }
            }
        });

        if (bestUnit) {
            playerImportantUnits.splice(bestId, 1);

            if (!bestUnit.isMovable() && (bestUnit.x !== x || bestUnit.y !== y)) {
                bestUnit.move(x, y);
            }
            return;
        }

        importantBubbles.forEach(function (bubble, id) {
            if (bubble.code === fakeUnit.code) {
                if (!bestUnit || bestUnit.stage < bubble.stage) {
                    bestUnit = { code: bubble.code, stage: bubble.stage };
                    bestId = id;
                }
            }
        });

        if (bestUnit) {
            importantBubbles[bestId].remove();
            importantBubbles.splice(bestId, 1);
            fakeUnit = bestUnit;
        }

        if (Families[fakeUnit.code].units[fakeUnit.stage].climbable) {
            fakeUnit.stage = cleverapps.unitsLibrary.getLastOpenStage(fakeUnit.code) || 0;
        }

        unit = new Unit(fakeUnit);
        unit.move(x, y);
        this.map.onAddUnit(x, y, unit);
    }, this);

    importantBubbles.forEach(function (bubble) {
        bubble.remove();
    });

    playerImportantUnits.forEach(function (playerUnit) {
        playerUnit.remove();
    });
};

Merge.prototype.restoreMultiCells = function () {
    this.map.listAvailableUnits().forEach(function (unit) {
        var multiCell = unit.findComponent(MultiCell);
        if (multiCell) {
            multiCell.restore();
        }
    });
};

Merge.prototype.restoreKickOuts = function () {
    var units = this.unitSaver.loadKickOuts(this.level.families);

    var remains = [];

    units.forEach(function (info) {
        if (info.data.code === "unknown") {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.MERGE.RESTORE_KICKOUT + "unknown");
            return;
        }

        var empty = this.map.findEmptySlot(info.x, info.y);
        if (!empty) {
            remains.push(info.data);
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.MERGE.RESTORE_KICKOUT + "fail");
            return;
        }

        info.x = empty.x;
        info.y = empty.y;

        var unit = this.map.loadUnit(info.x, info.y, info.data);
        if (unit) {
            this.map.onUnitAvailable(unit);

            var code = unit.code;
            if (unit.getData().makesorder || unit.getData().important) {
                code += "_" + unit.stage;
            }

            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.MERGE.RESTORE_KICKOUT + code);
        }
    }, this);

    if (remains.length > 0) {
        this.pocket.addUnits(remains);
    }

    this.unitSaver.resetKickOuts();
};

Merge.prototype.loadStaticUnits = function (level) {
    var staticUnits = Fogs.ReadDefaults(level.content.field);
    for (var row in staticUnits) {
        for (var col in staticUnits[row]) {
            var x = parseInt(row), y = parseInt(col), data = staticUnits[x][y];
            if (data.code === "decorator" && !this.map.getFog(x, y)) {
                var unit = this.map.getUnit(x, y);
                if (unit) {
                    var empty = this.map.findEmptySlot(x, y);
                    if (empty) {
                        unit.move(empty.x, empty.y);
                    }
                }
                unit = new Unit(data);
                unit.setPosition(x, y);
                this.map.add(Map2d.LAYER_UNITS, x, y, unit);
                this.map.onAddUnit(x, y, unit);

                var multiCell = unit.findComponent(MultiCell);
                if (multiCell && !cleverapps.config.wysiwygMode) {
                    multiCell.restore();
                }
            }
        }
    }
};

Merge.prototype.checkReset = function () {
    var units = cleverapps.createSet(this.map.listAvailableUnits().map(Unit.GetKey));

    var types = [cleverapps.unitsLibrary.getExpeditionUnitType("hero")];
    if (this.isMainGame()) {
        types.push("resource");
    }

    var codes = cleverapps.unitsLibrary.listCodesByType(types);

    var resetedCodes = codes.filter(function (code) {
        var unit = {
            code: code,
            stage: Families[code].units.length - 1
        };
        return cleverapps.unitsLibrary.isOpened(unit) && !units[Unit.GetKey(unit)];
    });

    var reseted = resetedCodes.length > 0;

    var storeKey = DataLoader.TYPES.RESET_REPORT + this.slot;

    if (!reseted) {
        cleverapps.dataLoader.remove(storeKey);
    } else if (!cleverapps.dataLoader.load(storeKey)) {
        cleverapps.dataLoader.save(storeKey, Date.now());

        console.log("save reset event", resetedCodes);

        cleverapps.RestClient.post("/units/saveresetevent/" + encodeURIComponent(connector.platform.getUserID()), {
            source: connector.info.source,
            codes: resetedCodes
        });

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.MERGE.UNITS_RESET);
        resetedCodes.forEach(function (code) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.DEBUG.MERGE.UNITS_RESET + "_" + code);
        });
    }
};

Merge.prototype.checkReset2 = function () {
    var resetFogs = {};

    Object.keys(this.map.fogs.config).forEach(function (fogId) {
        var block = this.map.fogs.blocks[fogId];
        if (block) {
            return;
        }

        var region = this.map.regions[fogId];
        if (!region || !region.positions) {
            return;
        }

        region.positions.forEach(function (cell) {
            if (this.map.getFog(cell.x, cell.y)) {
                return;
            }

            var fakeUnit = this.map.fogs.getFakeUnit(cell.x, cell.y);
            if (fakeUnit && !fakeUnit.head && !cleverapps.unitsLibrary.isOpened(fakeUnit) && !cleverapps.unitsLibrary.isHidden(fakeUnit)) {
                resetFogs[fogId] = true;

                console.error("resets unit", fogId, fakeUnit.code, fakeUnit.stage);
            }
        }.bind(this));
    }.bind(this));

    resetFogs = Object.keys(resetFogs);

    if (resetFogs.length) {
        var expedition = cleverapps.travelBook.getCurrentPage().id;

        cleverapps.playSession.set(cleverapps.EVENTS.DEBUG.MERGE.UNITS_RESET2, true, expedition);

        resetFogs.forEach(function (fogId) {
            cleverapps.playSession.set(cleverapps.EVENTS.DEBUG.MERGE.UNITS_RESET2, true, expedition + "_" + fogId);
        });
    }
};

Merge.prototype.updateExpedition = function () {
    if (!cleverapps.travelBook.isExpedition()) {
        return;
    }

    var mission = cleverapps.missionManager.findLocalExpedition();
    if (mission) {
        mission.logic.resetPush();
        mission.logic.sendPeriodicPush();
    }

    cleverapps.travelBook.onUpdateExpedition(mission);

    Feedable.processFeedable();
};

Merge.prototype.displayTutorial = function (f) {
    var actions = [];

    var page = cleverapps.travelBook.getCurrentPage();

    if (page.isMain() && (this.map.fogs.blocks.fog0 || this.map.fogs.blocks.fog1)) {
        actions = [
            function (f) {
                if (this.map.fogs.blocks.fog0) {
                    this.map.fogs.blocks.fog0.open();
                    setTimeout(f, 500);
                } else {
                    f();
                }
            }.bind(this),

            function (f) {
                if (!cleverapps.unitsLibrary.isHeroAvailable("dwarf")) {
                    this.tutorial.showTutorial(MergeTutorials.dwarf, f);
                } else {
                    f();
                }
            }.bind(this),

            function (f) {
                if (this.map.fogs.blocks.fog1) {
                    this.map.fogs.blocks.fog1.open();
                    setTimeout(f, 500);
                } else {
                    f();
                }
            }.bind(this)
        ];
    }

    if (!page.isMain() && this.map.fogs.blocks.fog0) {
        actions = [
            function (f) {
                if (this.map.fogs.blocks.fog0) {
                    this.map.fogs.blocks.fog0.open();
                    setTimeout(f, 500);
                } else {
                    f();
                }
            }.bind(this),

            function (f) {
                var page = cleverapps.travelBook.getCurrentPage();
                if (MergeTutorials[page.id]) {
                    this.tutorial.showTutorial(MergeTutorials[page.id], f);
                    this.map.fogs.calcFogStates();
                } else {
                    f();
                }
            }.bind(this)
        ];
    }

    cleverapps.meta.compound(f, actions);
};

Merge.prototype.onUpdateScroll = function () {
    if (cleverapps.config.gameInitialized && this.showUpFinished) {
        this.map.onUpdateScroll();

        var questsGroupIcon = cleverapps.sideBar.findQuestsGroupIcon();
        if (questsGroupIcon) {
            questsGroupIcon.hideInfo();
        }
    }
};

Merge.prototype.storeSave = function () {
    if (!cleverapps.config.gameInitialized) {
        return;
    }

    GameBase.prototype.storeSave.call(this);
};

Merge.prototype.getInfo = function () {
    var saveData = {
        lottery: this.energyLottery && this.energyLottery.save(),
        wands: this.wands > 0 ? this.wands : undefined,
        season: this.season && this.season.save(),
        paws: this.pawBox && this.pawBox.getPoints()
    };

    if (!this.isMainGame()) {
        saveData.expedition = cleverapps.travelBook.getCurrentPage().id;

        if (cleverapps.gameLevel.withOwnLevel) {
            saveData.ownLevel = cleverapps.gameLevel.level;
            saveData.exp = cleverapps.exp.getExp();
        }
    }

    return saveData;
};

Merge.prototype.stop = function () {
    GameBase.prototype.stop.call(this);

    this.tutorial.destructor();
    this.advice.destructor();
    this.pixelsPlanner && this.pixelsPlanner.destructor();
    this.barrelPlanner.destructor();
    this.monstersPlanner.destructor();
    this.growingsPlanner.destructor();
    this.thirdElementPlanner.destructor();
    this.quests.destructor();
    this.unitStories.destructor();
    this.unitGreeter.destructor();

    this.orangery && this.orangery.destructor();

    this.pushingComponent.destructor();

    this.energyLottery && this.energyLottery.destructor();

    this.map.stop();

    InfoView.Clear();

    this.level.unload();
};

Merge.prototype.confirmEnoughEnergy = function (energy) {
    if (cleverapps.lives.amount < energy) {
        if (cleverapps.flags.monetization === cleverapps.Flags.MONETIZATION_DISABLED && !(Game.currentGame.energyLottery && Game.currentGame.energyLottery.isReady())) {
            cleverapps.meta.display({
                focus: "restoreLives",
                action: function (f) {
                    new RewardWindow({ lives: cleverapps.lives.getMaxLives() - cleverapps.lives.amount });
                    cleverapps.meta.onceNoWindowsListener = f;
                }
            });
        } else {
            cleverapps.meta.display({
                focus: "buy_energy",
                control: ["MenuBarGoldItem", "MenuBarGameLevelItem", "MenuBarLivesItem", "MenuBarCoinsItem"],
                action: function (f) {
                    new LivesShopWindow();
                    cleverapps.meta.onceNoWindowsListener = f;
                }
            });
        }
        return false;
    }
    return true;
};

Merge.prototype.useEnergy = function (energy, source) {
    if (!this.confirmEnoughEnergy(energy)) {
        return false;
    }

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.USE_ENERGY + cleverapps.lives.slot, { value: energy });
    cleverapps.lives.setAmount(cleverapps.lives.amount - energy);
    this.onSubstractEnergy(energy, source);

    return true;
};

Merge.prototype.setWands = function (wands) {
    this.wands = wands;
    this.map.fogs.setWands(wands);
    this.onChangeWandsListener();
    this.storeSave();
};

Merge.prototype.findLargestGroup = function (units) {
    var used = {};
    var largestArea = [];

    units.forEach(function (unit) {
        if (used[Unit.GetPositionKey(unit)]) {
            return;
        }

        var area = this.map.bfs(unit.x, unit.y, this.map.compareEqual.bind(this.map, unit));
        area.forEach(function (cell) {
            used[Unit.GetPositionKey(cell)] = true;
        });

        if (area.length > largestArea.length) {
            largestArea = area;
        }
    }, this);

    return largestArea.map(function (cell) {
        return this.map.getUnit(cell.x, cell.y);
    }, this);
};

Merge.prototype.showLargestGroup = function (params) {
    var units = this.map.listAvailableUnits({ code: params.itemCode, stage: params.itemStage });

    if (units.length === 0) {
        if (cleverapps.unitsLibrary.listTabCodes(cleverapps.travelBook.getCurrentPage().id).indexOf(params.itemCode) === -1) {
            cleverapps.centerHint.createTextHint(params.text);
            return;
        }

        cleverapps.meta.display({
            focus: "largestGroupUnitsLibrary",
            action: function (f) {
                var searchUnit = { code: params.itemCode, stage: params.itemStage || 0 };

                if ((["hero", "xmhero", "drhero", "seahero",
                    "resource", "xmresource", "drresource", "searesource"].indexOf(Families[searchUnit.code].type) !== -1)) {
                    searchUnit.stage = Families[params.itemCode].units.length - 1;
                }

                new UnitsLibraryWindow(searchUnit);
                cleverapps.meta.onceNoWindowsListener = f;

                if (params.text) {
                    cleverapps.centerHint.createTextHint(params.text);
                }
            }
        });
        return;
    }

    units.forEach(function (unit) {
        unit.highlight({ duration: 2 * Highlight.ONE_PULSE });
    });

    var target = this.findLargestGroup(units)[0];
    this.map.getView().scroll.runAction(new cc.CellScrollAction(target, {
        skipFocusReport: true,
        visibleBox: {
            left: 0.1,
            right: 0.1,
            top: 0.1,
            bottom: 0.1
        }
    }).easing(cc.easeInOut(2)));

    this.map.getView().scroll.runAction(
        new cc.ZoomAction(0.5, { zoomKoef: 1.25, maxZoom: 1.35, direction: cleverapps.UI.ZoomHandler.ZOOM_DIRECTION_IN })
    );

    if (params.text) {
        cleverapps.centerHint.createTextHint(params.text);
    }
};

Merge.prototype.takeWands = function (wands) {
    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SPEND_WANDS, { value: wands });
    this.setWands(this.wands - wands);
    this.storeSave();
};

Merge.prototype.addReward = function (type, amount, source, options) {
    options = options || {};
    options.event = options.event || cleverapps.EVENTS.EARN.OTHER;

    switch (type) {
        case "coins":
            type = "soft";
            break;
        case "gold":
            type = "hard";
            break;
    }

    var reward = new Reward(type, amount, options);
    reward.receiveReward();
    reward.collectRewardsAnimation(source, options);
};

Merge.prototype.copyUnit = function (unitToCopy) {
    if (["thirdelement"].includes(unitToCopy.code)) {
        return;
    }
    var position = this.map.findEmptySlot(unitToCopy.x, unitToCopy.y, unitToCopy);
    if (position) {
        var unit = new Unit(unitToCopy);
        unit.setPosition(position.x, position.y);
        this.map.add(Map2d.LAYER_UNITS, unit.x, unit.y, unit);
        this.map.onAddUnit(unit.x, unit.y, unit);
        this.map.onUnitAvailable(unit);
        unit.onAdd();
    }
};

Merge.prototype.spawn = function (units, parentUnit, options) {
    this.advice.boo();

    var spawned = [];
    var remains = [];

    units = cleverapps.toArray(units) || [];

    if (units.length === 0 || cleverapps.config.editorMode) {
        return remains;
    }

    var parentCell = parentUnit;

    if (options.fromNode) {
        var mapView = this.map.getView();
        var pos = parentUnit.convertToWorldSpaceAR();
        parentCell = mapView.getCellByCoordinates(mapView.convertToNodeSpace(pos));
    }

    units.sort(function (a, b) {
        if (a.code !== b.code) {
            if (a.code < b.code) {
                return -1;
            }
            return 1;
        }
        return a.stage - b.stage;
    });

    units = units.map(function (unit) {
        if (this.needToReplaceWithCoins(unit) && unit.stage < Families[unit.code].units.length - 1) {
            return this.getUnitReplacer(unit);
        }
        return unit;
    }.bind(this));

    var targetCell = options.targetCell || {
        x: parentCell.x,
        y: parentCell.y
    };

    units.forEach(function (unit) {
        if (remains.length) {
            remains.push(unit);
            return;
        }

        var slot = this.map.findEmptySlot(targetCell.x, targetCell.y, unit, options);
        if (!slot) {
            remains.push(unit);
            return;
        }

        unit = new Unit(unit);
        unit.setPosition(slot.x, slot.y);
        this.map.add(Map2d.LAYER_UNITS, slot.x, slot.y, unit);
        spawned.push(unit);
    }, this);

    this.spawnAction(spawned, remains, parentUnit, options);

    return remains;
};

Merge.prototype.showNoSpaceHint = function (remains) {
    if (remains.some(function (unit) {
        return Unit.GetShape(unit).length > 1;
    })) {
        cleverapps.centerHint.createTextHint("Spawn.nospace", { left: "2x2" });
    } else {
        cleverapps.centerHint.createTextHint("Spawn.nospace", { left: remains.length });
    }
};

Merge.prototype.spawnAction = function (spawned, remains, parentUnit, options) {
    if (remains.length) {
        cleverapps.audio.playSound(bundles.merge.urls.spawn_fail_effect);
        this.showNoSpaceHint(remains);
    } else if (!options.noSound) {
        cleverapps.audio.playSound(options.sound || bundles.merge.urls.spawn_start_effect);
    }

    var delay = options.delay || 100;

    this.counter.setTimeout(function () {
        if (!options.fromNode) {
            spawned.sort(function (a, b) {
                var d1 = Math.sqrt((a.x - parentUnit.x) * (a.x - parentUnit.x) + (a.y - parentUnit.y) * (a.y - parentUnit.y));
                var d2 = Math.sqrt((b.x - parentUnit.x) * (b.x - parentUnit.x) + (b.y - parentUnit.y) * (b.y - parentUnit.y));
                if (d1 === 0) {
                    d1 = 10000;
                }
                if (d2 === 0) {
                    d2 = 10000;
                }
                return d1 - d2;
            });
        }
        spawned.forEach(function (unit, id) {
            if (!unit.onGetView()) {
                this.map.onAddUnit(unit.x, unit.y, unit);
            }
            this.map.onUnitAvailable(unit);

            var pulsing = unit.findComponent(Pulsing);
            if (pulsing) {
                pulsing.beforeSpawn();
            }

            unit.onSpawned(parentUnit, id, options);

            Map2d.mapEvent(Map2d.SPAWN, {
                unit: unit,
                affected: options.fromNode ? undefined : parentUnit
            });
        }, this);
    }.bind(this), delay);

    this.counter.setTimeout(function () {
    }, 700);
};

Merge.prototype.mergeBonus = function (mergeInfoUnit, mergeAmount) {
    var prize = 0;
    var next = 5;
    var stages = Families[mergeInfoUnit.code].units;

    if (mergeAmount >= 3 && stages[mergeInfoUnit.stage].heroitem && mergeInfoUnit.stage === stages.length - 2) {
        prize = 1;
        mergeAmount -= 3;
    } else {
        while (mergeAmount >= 3) {
            var merging = 3;
            var p = 1;
            while (mergeAmount >= 2 * merging - 1) {
                p *= 2;
                merging = 2 * merging - 1;
            }
            if (next === 5) {
                next = 2 * merging - 1;
            }

            mergeAmount -= merging;
            prize += p;
        }
    }

    return {
        bonus: prize,
        keepLast: mergeAmount,
        next: next
    };
};

Merge.prototype.playMergeSound = function (options) {
    var total = options.stage * options.amount;
    var sound = 0;
    if (total >= 80) {
        sound = 4;
    } else if (total >= 40) {
        sound = 3;
    } else if (total >= 20) {
        sound = 2;
    } else if (total >= 10) {
        sound = 1;
    }

    cleverapps.audio.playSound(bundles.merge.urls["merge_effect_" + sound]);
};

Merge.prototype.merge = function (affected, unit, mergeInfoUnit, automerge) {
    var res;

    var affectedUnits = [unit];

    if (cleverapps.config.debugMode && automerge) {
        res = this.mergeBonus(mergeInfoUnit, affected.length);

        affectedUnits = [];

        affected.forEach(function (pos) {
            var merging = this.map.getUnit(pos.x, pos.y);

            merging.claimPoints();
            merging.onMerge(affected[0]);
            var worker = this.map.workers.findAssigned(merging);
            if (worker) {
                worker.clearAssignment();
            }
            merging.remove(true);
            affectedUnits.push(merging);
        }, this);
    } else {
        res = this.mergeBonus(mergeInfoUnit, affected.length + 1);

        affected.forEach(function (pos) {
            var merging = this.map.getUnit(pos.x, pos.y);
            var worker = this.map.workers.findAssigned(merging);
            if (worker) {
                worker.clearAssignment();
            }
            merging.claimPoints();
            merging.onMerge(affected[0]);
            merging.remove(true);
            affectedUnits.push(merging);
        }, this);

        var worker = this.map.workers.findAssigned(unit);
        if (worker) {
            worker.clearAssignment();
        }
        unit.onMerge(affected[0]);
        unit.remove(true);
    }

    var resultUnits = [];

    for (var i = 0; i < res.bonus + res.keepLast; i++) {
        var newUnit = {
            code: mergeInfoUnit.code,
            stage: mergeInfoUnit.stage
        };

        if (i < res.bonus) {
            newUnit.unbuilt = !cleverapps.gameModes.skipBuildingStage;
            Object.assign(newUnit, mergeInfoUnit.getMergeUnit());
        }

        resultUnits.push(newUnit);
    }

    var bonusUnit = this.landmarks.getMergeBonusUnit(mergeInfoUnit);
    if (bonusUnit) {
        for (i = 0; i < res.bonus; ++i) {
            resultUnits.push(bonusUnit);
        }
    }

    var newUnits = [];

    for (i = 0; i < resultUnits.length; i++) {
        var pos = affected[i] || affected[affected.length - 1];
        newUnit = resultUnits[i];

        var newPos = this.map.findEmptySlot(pos.x, pos.y, newUnit, {
            skipCheckScreen: true,
            skipCheckEqual: true
        });

        if (newPos) {
            newUnit = new Unit(newUnit);
            newUnit.setPosition(newPos.x, newPos.y);
            this.map.add(Map2d.LAYER_UNITS, newPos.x, newPos.y, newUnit);
            newUnits.push(newUnit);
        } else {
            Game.currentGame.pocket.addUnits(newUnit, pos);
        }
    }

    if (!newUnits.length) {
        return;
    }

    var offerType;
    if (ThirdElement.IsAvailable(ThirdElement.TYPE_ADS, newUnits[0]) && Math.random() <= 0.3) {
        offerType = ThirdElement.TYPE_ADS;
    } else if (ThirdElement.IsAvailable(ThirdElement.TYPE_RUBY, newUnits[0])) {
        offerType = ThirdElement.TYPE_RUBY;
    } else if (ThirdElement.IsAvailable(ThirdElement.TYPE_ANIMALS, newUnits[0])) {
        offerType = ThirdElement.TYPE_ANIMALS;
    }

    if (offerType) {
        Game.currentGame.thirdElementPlanner.planNext({
            type: offerType,
            delay: 2200,
            target: {
                code: newUnits[0].code,
                stage: newUnits[0].stage,
                x: newUnits[0].x,
                y: newUnits[0].y
            }
        });
    }

    newUnits.forEach(function (newUnit, index) {
        this.map.onAddUnit(newUnit.x, newUnit.y, newUnit);
        newUnit.didMerged(newUnits[0], index);
    }, this);

    newUnits[0].onStartDidMerged();

    this.counter.setTimeout(function () {
        this.playMergeSound({
            amount: affectedUnits.length,
            stage: unit.stage
        });
        this.map.onUnitFresh(newUnits[0]);
    }.bind(this), 300);

    var unitForInfo = !offerType && newUnits.find(function (newUnit) {
        return !newUnit.isBuilt();
    });

    if (unitForInfo) {
        this.counter.setTimeout(function () {
            if (!cleverapps.meta.isFocused()) {
                InfoView.DisplayInfo(unitForInfo);
            }
        }, 1300 + 100 * newUnits.length);
    }

    this.counter.setTimeout(function () {
        newUnits.forEach(function (newUnit, index) {
            this.map.onUnitAvailable(newUnit);
            if (index < res.bonus) {
                Map2d.mapEvent(Map2d.SPAWN, { unit: newUnit, affected: affectedUnits });
            }
        }, this);

        var sound = bundles.merge.urls["merge_effect_" + unit.code];
        if (sound) {
            cleverapps.audio.playSound(sound);
        }

        Map2d.mapEvent(Map2d.MERGE, { affected: affectedUnits });
    }.bind(this), 1000 + 100 * newUnits.length);

    if (res.next > 5 && res.next === 2 * affected.length + 1) {
        this.counter.setTimeout(function () {
            this.onShowMergeBonus(newUnits);
        }.bind(this), 1000 + 100 * newUnits.length);
    }

    if (cleverapps.gameModes.axemerge) {
        var targets = this.axeTargets(newUnits);

        this.counter.setTimeout(function () {
            targets.forEach(function (tuple) {
                tuple.target.onDestruction(false, tuple.origin);
            });
        }, 700);

        this.counter.setTimeout(function () {
            targets.forEach(function (tuple) {
                tuple.target.remove(true);
                Map2d.currentMap.blockedGrounds.updateBlockedGrounds();
            });
        }, 1400);
    }

    Game.currentGame.advice.boo();
};

Merge.prototype.axeTargets = function (units) {
    var directions = [{ x: 1, y: 0 }, { x: 0, y: 1 }, { x: -1, y: 0 }, { x: 0, y: -1 }];
    var cells = [];
    var used = {};
    var targets = [];
    var maxRadius = 6;
    var maxTargets = 3;

    units.forEach(function (unit) {
        var cell = {
            x: unit.x,
            y: unit.y,
            unit: unit
        };

        cells.push(cell);
        used[cell.x * 1000 + cell.y] = true;
    });

    for (var i = 0; i < cells.length && targets.length < maxTargets; ++i) {
        var origin = cells[i];

        for (var j = 0; j < directions.length && targets.length < maxTargets; ++j) {
            var delta = directions[j];
            var cell = {
                x: origin.x + delta.x,
                y: origin.y + delta.y,
                unit: origin.unit
            };

            if (used[cell.x * 1000 + cell.y]) {
                continue;
            }
            used[cell.x * 1000 + cell.y] = true;

            if (Math.abs(cell.x - cell.unit.x) > maxRadius || Math.abs(cell.y - cell.unit.y) > maxRadius) {
                continue;
            }

            cells.push(cell);

            var unit = this.map.getUnit(cell.x, cell.y);
            var isSource = unit && ["source", "hlsource", "xmsource", "drsource", "chsource", "easource", "rpsource", "seasource", "advsource"].indexOf(Families[unit.code].type) !== -1;

            if (isSource) {
                targets.push({
                    target: unit,
                    origin: cell.unit
                });
            }
        }
    }

    return targets;
};

Merge.prototype.replace = function (target, unit, silent) {
    if (target === this.map.dragging) {
        this.map.stopDragging();
    }

    if (!silent) {
        target.onDestruction();
        target.remove(true);

        this.spawn(unit, target, {
            radius: 0
        });
    } else {
        target.remove();

        unit = new Unit(unit);
        unit.setPosition(target.x, target.y);
        this.map.add(Map2d.LAYER_UNITS, unit.x, unit.y, unit);
        this.map.onAddUnit(unit.x, unit.y, unit);
        this.map.onUnitAvailable(unit);
        this.counter.setTimeout(Map2d.mapEvent.bind(this, Map2d.SPAWN, { unit: unit }), 0);
        return unit;
    }
};

Merge.prototype.getUnitReplacer = function (unit) {
    return this.landmarks.getDonorReplacer(unit) || { code: "coins", stage: Math.min(unit.stage, 3) };
};

Merge.prototype.needToReplaceWithCoins = function (unit) {
    if (cleverapps.gameModes.multipleHeroes) {
        return false;
    }

    if (Families[unit.code].units[unit.stage] && Families[unit.code].units[unit.stage].heroitem && cleverapps.unitsLibrary.isHeroAvailable(unit.code)) {
        return true;
    }

    if (unit.code === "cinema" && this.map.listAvailableUnits({ code: "cinema", stage: 3 }).length) {
        return true;
    }

    if (this.landmarks.needToReplaceDonor(unit)) {
        return true;
    }

    return false;
};

Merge.prototype.replaceUnit = function (unitToReplace, replacer) {
    this.counter.setTimeout(function () {
        var family = Families[unitToReplace.code];
        var units = this.map.listAvailableUnits();
        var lastStageUnits = [];

        for (var i = 0; i < units.length; ++i) {
            var unit = units[i];

            if (unit.code === family.code && unit.stage === family.units.length - 1 && (family.units[unit.stage].heroitem || unit.code === "cinema")) {
                lastStageUnits.push(unit);
            } else if (unit.code === "thirdelement" && unit.findComponent(ThirdElement).prize.code === family.code) {
                unit.remove();
            } else if (unit.code === family.code) {
                this.replace(unit, replacer(unit));
            } else if (unit.prizes && unit.prizes.length) {
                var prizes = unit.prizes.filter(function (prize) {
                    return prize.code !== family.code;
                });

                if (unit.prizes.length !== prizes.length) {
                    if (prizes.length === 0) {
                        prizes = [{ code: "coins", stage: 0 }];
                    }
                    unit.setPrizes(prizes, unit.prizesExp);
                }
            }
        }

        if (lastStageUnits.length > 1) {
            var index = lastStageUnits.indexOf(unitToReplace);
            if (index !== -1) {
                var tmp = lastStageUnits[0];
                lastStageUnits[0] = lastStageUnits[index];
                lastStageUnits[index] = tmp;
            }

            for (i = 0; i < lastStageUnits.length - 1; ++i) {
                this.replace(lastStageUnits[i], replacer(lastStageUnits[i]));
            }
        }
    }.bind(this), 100);
};

Merge.prototype.updateAvailableUnits = function () {
    this.counter.inc();

    this.map.listAvailableUnits().sort(function (a, b) {
        return b.stage - a.stage;
    }).forEach(this.map.onUnitAvailable.bind(this.map));

    var reportOnce = cleverapps.once(function (message) {
        cleverapps.throwAsync(message);
    });

    for (var col in this.map.fogs.fakeUnits) {
        for (var row in this.map.fogs.fakeUnits[col]) {
            var x = parseInt(col), y = parseInt(row);

            if (!cleverapps.isNumber(col) || !cleverapps.isNumber(row) || !this.map.fogs.fakeUnits[x] || !this.map.fogs.fakeUnits[x][y]) {
                reportOnce("fakeUnit coordinate is not number - " + JSON.stringify({
                    col: col,
                    row: row,
                    x: x,
                    y: y
                }));
                continue;
            }

            var unit = this.map.fogs.fakeUnits[x][y].head || this.map.fogs.fakeUnits[x][y];
            if (!this.map.getFog(x, y) && Families[unit.code] && Families[unit.code].type === "fruit") {
                cleverapps.unitsLibrary.openUnit(unit);
            }
        }
    }

    this.counter.dec();
};

Merge.prototype.onUnitAvailable = function (unit) {
    if (!Buildable.IsBuilt(unit)) {
        this.tutorial.triggerBuildTutorial({ unit: unit });
        return;
    }

    if (!Creatable.IsCreated(unit)) {
        return;
    }

    this.season && this.season.onUnitAvailable(unit);

    if (cleverapps.unitsLibrary.openUnit(unit)) {
        this.map.fogs.wantsCalcFogStates = true;
    }

    if (this.needToReplaceWithCoins(unit)) {
        this.replaceUnit(unit, this.getUnitReplacer.bind(this));
    }

    if (unit.findComponent(HeroItem) && cleverapps.unitsLibrary.isHeroAvailable(unit.code)) {
        this.quests.finishQuestsByUnit(unit, Map2d.SPAWN);
        this.quests.finishQuestsByUnit(unit, Map2d.BUILD);
    }

    if (unit.findComponent(Customer)) {
        this.quests.finishQuestsByUnit(unit, Map2d.SPAWN);
    }

    if (unit.isLast() && unit.isBuilt()) {
        this.quests.finishQuestsByUnit(unit, Map2d.BUILD);
    }

    if (unit.findComponent(ResourceCollectible) || unit.findComponent(Upgradable)) {
        this.updatePulsing();
    }

    if (unit.getData().feedableTarget || unit.findComponent(Feedable)) {
        Feedable.processFeedable();
    }

    this.customers.onUnitAvailable(unit);

    if (this.map.fogs.wantsCalcFogStates) {
        this.counter.setTimeout(function () {}, 0);
    }
};

Merge.prototype.onUnitFresh = function (unit) {
    if (cleverapps.aims.getTarget("unitsLibrary", { noDefault: true })
        && unit.isBuilt() && !cleverapps.gameModes.skipFlyToUnitsLibrary
        && !cleverapps.unitsLibrary.isOpened(unit) && !cleverapps.unitsLibrary.isHidden(unit)
        && !this.tutorial.isActive()) {
        unit.onShowFresh();
        this.counter.setTimeout(function () {}, 1700);
    }
};

Merge.prototype.onUnitRemoved = function (unit) {
    this.customers.onUnitRemoved(unit);
};

Merge.prototype.updatePulsing = cleverapps.accumulate(0, function () {
    if (this.stopped) {
        return;
    }

    var upgradables = {};
    var resources = [];

    this.map.listAvailableUnits(function (unit) {
        if (unit.isUpgradable() && unit.isBuilt()) {
            upgradables[unit.code] = true;
        }

        var resource = unit.findComponent(ResourceCollectible);
        if (resource) {
            resources.push(unit);
        }
    });

    resources.forEach(function (unit) {
        var pulsing = unit.findComponent(Pulsing);
        if (pulsing) {
            pulsing.setActive(unit.isBuilt() && upgradables[pulsing.unit.code]);
        }
    });
});

Merge.prototype.showScreen = function (f, silent) {
    cleverapps.meta.compound(f, [
        function (f) {
            this.introZoom(f, silent);
        }.bind(this),
        function (f) {
            this.map.fogs.showBalloons(silent);
            f();
        }.bind(this),
        Workers.ShowFinishedWorker,
        MiniGame.ProcessRewardsStage,
        function (f) {
            this.showUpFinished = true;
            this.onUpdateScroll();
            f();
        }.bind(this)
    ]);
};

Merge.prototype.introZoom = function (f, silent) {
    if (silent) {
        f();
        return;
    }

    var zoom = this.getBasicZoom();
    var scene = cleverapps.scenes.getRunningScene();
    scene.animateZoom(zoom, 1, f);
};

Merge.prototype.getBasicZoom = function () {
    var zoom = 1;

    if (this.map.zoom) {
        zoom = this.map.zoom;
    } else if (cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL) {
        zoom = connector.info.isMobile ? 1.85 : 1.2;
    }

    if (cleverapps.skins.getSlot("basicZoom", zoom)) {
        zoom = cleverapps.skins.getSlot("basicZoom", zoom);
    }

    if (cleverapps.config.editorMode) {
        zoom = 1;
    }

    if (cleverapps.config.demoMode) {
        zoom = 0.4;
    }

    return zoom;
};

Merge.prototype.collectAndRemoveAllUnits = function (f) {
    Object.keys(this.map.fogs.blocks).forEach(function (fogBlock) {
        this.map.fogs.blocks[fogBlock].trigger("updateState", FogBlock.NOTREADY, true);
    }, this);

    var units = [];

    for (var y = 0; y < this.map.getHeight(); y++) {
        for (var x = 0; x < this.map.getWidth(); x++) {
            var unit = this.map.getUnit(x, y);
            if (unit && !unit.getData().important && !unit.head) {
                units.push(unit);
                continue;
            }

            var fog = this.map.getFog(x, y);
            if (fog && (fog.state === FogBlock.CANTOPEN || fog.state === FogBlock.CANOPEN)) {
                var fakeUnit = fog.getFakeUnit();
                if (fakeUnit) {
                    fakeUnit = fakeUnit.head || fakeUnit;
                    if (!fakeUnit.important) {
                        units.push(fog);
                    }
                }
            }
        }
    }

    if (!units.length) {
        f();
        return;
    }

    units.sort(function (a, b) {
        return a.y - b.y || a.x - b.x;
    });

    var first = units[0];
    var last = units[units.length - 1];
    var lines = last.y - first.y;
    var delay = Math.max(1000, 2400 * this.map.getCenterDistance(first) / this.map.getWidth());
    var total = Math.min(Math.max(1500, 100 * lines), 6000);

    this.map.getView().scroll.runAction(
        new cc.Sequence(
            new cc.ZoomAction(0.7, { zoom: 0.5 }),
            new cc.DelayTime(1),
            new cc.CellScrollAction(first, { duration: delay / 1000, allowScrollWithFocus: true }).easing(cc.easeIn(1.4)),
            new cc.DelayTime(0.2),
            new cc.ZoomAction(0.4, { zoom: 0.9 }),
            new cc.CallFunc(function () {
                cleverapps.meta.showControlsWhileFocused(["MenuBarGoldItem", "MenuBarCoinsItem"]);
                this._removeAnimated(total, units);
            }.bind(this)),
            new cc.CellScrollAction(last, { duration: total / 1000, allowScrollWithFocus: true }).easing(cc.easeIn(1.4)),
            new cc.DelayTime(1.4),
            new cc.CallFunc(function () {
                cleverapps.meta.hideControlsWhileFocused(["MenuBarGoldItem", "MenuBarCoinsItem"]);
            }),
            new cc.CallFunc(f)
        )
    );
};

Merge.prototype._removeAnimated = function (duration, units) {
    var first = units[0];
    var last = units[units.length - 1];
    var lines = last.y - first.y;
    var easing = cc.easeQuadraticActionInOut();

    units.forEach(function (unit) {
        var timeout = duration * easing.easing((unit.y - first.y) / lines);

        if (unit.onDestructFog) {
            setTimeout(unit.onDestructFog.bind(unit), timeout);
            return;
        }

        var collectible = unit.findComponent(Collectible);
        if (collectible && ["drfruit", "seafruit", "clfruit"].indexOf(unit.getType()) === -1) {
            setTimeout(collectible.collect.bind(collectible), timeout);
            return;
        }

        setTimeout(function () {
            var worker = this.map.workers.findAssigned(unit);
            if (worker) {
                worker.clearAssignment();
            }

            unit.onDestruction();
            try {
                unit.remove(true);
            } catch (e) {
                var msg = e.message + " " + Unit.GetKey(unit) + " pos" + Unit.GetPositionKey(unit);
                msg += " time " + duration + "x" + timeout;
                msg += " units " + units.length + " y: " + unit.y + " " + first.y;
                throw new Error(msg);
            }
        }.bind(this), timeout);
    }, this);
};

Merge.WORKERS_WINDOW_INTERVAL = "1 minute";