/**
 * Created by Denis Kuzin on 15 december 2021
 */

cleverapps.SkinManager.SKINS.xmas_expedition = {
    name: "xmas",

    bundle: "expedition_xmas",

    localizations: {
        xmas: {

            "Fog.clear.notEnoughWands": "Xmas.Fog.clear.notEnoughWands",
            "Battle.notEnoughWands": "Xmas.Battle.notEnoughWands",
            "LocalPushes.PUMPKIN_RIPE": "Xmas.LocalPushes.PUMPKIN_RIPE",

            "ImproverGuideWindow.firstStage": "Xmas.ImproverGuideWindow.firstStage",
            "ImproverGuideWindow.secondStage": "Xmas.ImproverGuideWindow.secondStage",
            "ImproverGuideWindow.thirdStage": "Xmas.ImproverGuideWindow.thirdStage",

            "ExpeditionWandsGuideWindow.title": "Xmas.ExpeditionWandsGuideWindow.title",
            "ExpeditionWandsGuideWindow.firstStage": "Xmas.ExpeditionWandsGuideWindow.firstStage",
            "ExpeditionWandsGuideWindow.secondStage": "Xmas.ExpeditionWandsGuideWindow.secondStage",
            "ExpeditionWandsGuideWindow.thirdStage": "Xmas.ExpeditionWandsGuideWindow.thirdStage",

            "FeastMissionRulesWindow.ExpeditionFeast.title": "Xmas.FeastMissionRulesWindow.ExpeditionFeast.title",
            "FeastMissionRulesWindow.ExpeditionFeast.description": "Xmas.FeastMissionRulesWindow.ExpeditionFeast.description",
            "FeastMissionRulesWindow.ExpeditionFeast.firstStage": "Xmas.FeastMissionRulesWindow.ExpeditionFeast.firstStage",
            "FeastMissionRulesWindow.ExpeditionFeast.secondStage": "Xmas.FeastMissionRulesWindow.ExpeditionFeast.secondStage",
            "FeastMissionRulesWindow.ExpeditionFeast.thirdStage": "Xmas.FeastMissionRulesWindow.ExpeditionFeast.thirdStage",

            "ExpeditionFeastWindow.title": "Xmas.ExpeditionFeastWindow.title",
            "ExpeditionFeastWindow.tooltip.amount": "Xmas.ExpeditionFeastWindow.tooltip.amount",

            "ExpeditionPassRulesWindow.title": "Xmas.ExpeditionPassRulesWindow.title",
            "ExpeditionPassRulesWindow.firstStage": "Xmas.ExpeditionPassRulesWindow.firstStage",
            "ExpeditionPassRulesWindow.secondStage": "Xmas.ExpeditionPassRulesWindow.secondStage",
            "ExpeditionPassRulesWindow.thirdStage": "Xmas.ExpeditionPassRulesWindow.thirdStage",
            "PassWindow.moreItemsToolTip.star": "Xmas.PassWindow.moreItemsToolTip.star"
        }
    },

    slots: {
        skinName: function () {
            return "xmas";
        },

        localization: function (code) {
            var localizations = cleverapps.SkinManager.SKINS.xmas_expedition.localizations;
            var expedition = cleverapps.travelBook.getCurrentPage().id;
            return localizations && localizations[expedition] && localizations[expedition][code];
        },

        workerSkin: function () {
            return cleverapps.config.name === "mergecraft" ? "xm" : undefined;
        },

        water: function () {
            var tilesTexture = Map2d.currentMap && Map2d.currentMap.tilesTexture;
            return bundles[tilesTexture] && bundles[tilesTexture].frames.water_frozen;
        },

        windowDecors: function (options) {
            return cleverapps.SkinManager.SKINS.xmas.slots.windowDecors(options);
        },

        ruinsRewardsDelay: function () {
            return 0;
        },

        ruinsWindow: function () {
            return {
                bundle: "ruinswindow_xmas",
                dictionaryKey: "RuinsWindow.xmax.",
                person: {
                    role: "worker",
                    skin: cleverapps.config.name === "mergecraft" ? "xmas" : undefined
                }
            };
        },

        missionTableRowIcon: function (options) {
            if (Mission.GetChildType(options.type) === Mission.TYPE_EXPEDITION_FEAST) {
                return bundles.tablerow_icons.frames.feast_snowflake;
            }
        },

        gameAudio: function () {
            return bundles.expedition_xmas.urls.xmas_music;
        },

        unitIcon: function (unit) {
            return bundles.unit_icons.frames["small_icon_family_" + unit.code];
        },

        unitOnCell: function () {
            return cleverapps.styles.UnitOnCellExpedition;
        },

        gameSceneBg: function () {
            return {
                surfaceAnimation: bundles.expedition_xmas.jsons.xmas_bg_animation_top
            };
        },

        pixelView: function () {
            return cleverapps.styles.xmas.PixelView;
        },

        fogBlocker: function () {
            return cleverapps.styles.xmas.fogBlockerLock;
        },

        whirlwind: bundles.fog_animations.jsons.xmas_whirlwind,

        merge_wand_png: bundles.menubar.frames.xmas_wand_icon_png,

        expedition_buildpass_icon_json: bundles.sidebar.jsons.xmas_expedition_buildpass_icon_json,
        expedition_feast_icon_json: bundles.sidebar.jsons.xmas_expedition_feast_icon_json,
        toolbar_customers_icon_png: bundles.toolbar.frames.xmas_toolbar_customers_icon_png,

        expedition_feast_icon: bundles.reward_icons.frames.xmas_expedition_feast_icon,
        reward_wand_png: bundles.reward_icons.frames.xmas_reward_wand_png,
        reward_wand_small_png: bundles.reward_icons.frames.xmas_reward_wand_small_png,

        expeditionPassGuideBundle: function () {
            return bundles.xmas_expeditionguidewindow;
        },

        expeditionFeastBundle: function () {
            return bundles.expedition_feast_xmas;
        },

        landmarkGuideOptions: function () {
            return {
                name: "xmas_product_guidewindow",
                bundle: bundles.xmas_product_guidewindow,
                title: "LandmarkGuideWindow.title"
            };
        }
    }
};
