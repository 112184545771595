/**
 * Created by r4zi4l on 21.12.2021
 */

var MergeAdviceDragLogic = function (options) {
    MergeAdviceBaseLogic.call(this, options);
};

MergeAdviceDragLogic.prototype = Object.create(MergeAdviceBaseLogic.prototype);
MergeAdviceDragLogic.prototype.constructor = MergeAdviceDragLogic;

MergeAdviceDragLogic.prototype.findMove = function () {
    var units = Map2d.currentMap.listAvailableUnits();
    var draggables = [], acceptors = [];

    for (var i = 0; i < units.length; ++i) {
        var unit = units[i];

        if (unit.getData().draggable && this.targets.some(Unit.Equals.bind(null, unit))) {
            draggables.push(unit);
            continue;
        }

        var acceptor = unit.findAcceptorComponent(this.targets);
        if (acceptor) {
            acceptors.push(unit);
        }
    }

    acceptors = acceptors.filter(function (unit) {
        return Map2d.currentMap.isScreenCellPosition(unit.x, unit.y);
    });

    var bestDistance, bestDraggable, bestAcceptor;
    for (i = 0; i < acceptors.length; ++i) {
        acceptor = acceptors[i];

        for (var j = 0; j < draggables.length; ++j) {
            var draggable = draggables[j];
            var distance = cc.pDistanceSQ(acceptor, draggable);
            if (distance < 21 && (bestDistance === undefined || distance < bestDistance)) {
                bestDistance = distance;
                bestDraggable = draggable;
                bestAcceptor = acceptor;
            }
        }
    }

    if (bestDistance) {
        return {
            highlight: [bestDraggable, bestAcceptor],
            cells: [bestDraggable, bestAcceptor]
        };
    }
};
