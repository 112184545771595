/**
 * Created by mac on 12/22/20
 */

var Buildable = function (unit, options) {
    UnitComponent.call(this, unit);

    this.load(options);
};

Buildable.prototype = Object.create(UnitComponent.prototype);
Buildable.prototype.constructor = Buildable;

Buildable.prototype.onInfoOpened = function () {
    this.onUpdateState();
};

Buildable.prototype.onInfoClosed = function (withAction) {
    if (!withAction) {
        this.onUpdateState();
    }
};

Buildable.prototype.onCompletion = function () {
    if (this.view) {
        this.view.animateCompletion();
    }
};

Buildable.prototype.onUpdateState = function () {
    if (this.view) {
        this.view.restoreState(true);
    }
};

Buildable.prototype.startInstantWorker = function (worker) {
    if (this.view) {
        this.view.startMagic(worker);
    }
};

Buildable.prototype.finishInstantWorker = function (worker) {
    if (this.view) {
        this.view.finishMagic(worker);
    }
};

Buildable.prototype.isCompleted = function () {
    return this.state === Buildable.STATES.FINISHED;
};

Buildable.prototype.load = function (stored) {
    if (stored.time !== undefined) {
        this.time = stored.time;
        this.state = Buildable.STATES.WAIT;
    } else {
        this.time = cleverapps.parseInterval(this.unit.getData().buildable);
        this.state = (this.time === 0) ? Buildable.STATES.FINISHED : Buildable.STATES.WAIT;
    }
};

Buildable.prototype.save = function (data) {
    if (this.isCompleted()) {
        return;
    }

    data.time = this.time;
};

Buildable.prototype.getInfo = function () {
    if (!Map2d.currentMap.workers.findInstantAssigned(this) && this.state === Buildable.STATES.WAIT) {
        return {
            title: "Buildable.info.title",
            duration: this.time,
            buttons: {
                build: this.startBuilding.bind(this),
                instant: Map2d.currentMap.workers.hasInstant() && this.assignInstantWorker.bind(this)
            }
        };
    }
};

Buildable.prototype.getTask = function () {
    return {
        time: this.time
    };
};

Buildable.prototype.assignInstantWorker = function () {
    var instantWorker = Map2d.currentMap.workers.listInstantFree()[0];

    if (!instantWorker) {
        console.log("No instant");
        return;
    }

    instantWorker.buildInstantly(this);
};

Buildable.prototype.startBuilding = function () {
    if (Map2d.currentMap.workers.findAssigned(this.unit)) {
        return;
    }

    if (Map2d.currentMap.workers.findInstantAssigned(this)) {
        return;
    }

    var worker = Map2d.currentMap.workers.findFree(this.unit);
    if (!worker) {
        Game.currentGame.workersBusyHint(this.unit);
        this.onUpdateState();
        return;
    }

    if (!worker.assign(this.unit, this.time)) {
        this.onUpdateState();
        return;
    }

    Map2d.mapEvent(Map2d.START_BUILDING, { affected: this.unit });
};

Buildable.prototype.buildingSuspend = function (timeSpent) {
    this.time -= timeSpent;
    this.state = Buildable.STATES.WAIT;

    this.onUpdateState();
};

Buildable.prototype.buildingComplete = function () {
    this.time = 0;
    this.state = Buildable.STATES.FINISHED;

    this.onCompletion();
};

Buildable.prototype.canAcceptDraggable = function (unit) {
    return Families[unit.code].units[unit.stage].instantWorker && !this.isCompleted() && Map2d.currentMap.workers.findInstantAssigned(this) === undefined;
};

Buildable.prototype.acceptDraggable = function (unit) {
    var instantWorker = unit.findComponent(InstantWorker);
    instantWorker.buildInstantly(this);
};

Buildable.IsBuilt = function (unit) {
    var buildable = unit.findComponent(Buildable);
    return !buildable || buildable.isCompleted();
};

Buildable.STATES = {
    WAIT: 0,
    FINISHED: 1
};
