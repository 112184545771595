/**
 * Created by ivan on 11.04.18.
 */

var MagnetToolbarItem = function () {
    ToolbarItem.call(this, ToolbarItem.MAGNET);

    this.onUpdateDragState = function () {};

    this.dragHandler = new UnitMagnetHandler();
    this.dragHandler.on("updateDragView", this.onUpdateDragState.bind(this), this);
};

MagnetToolbarItem.prototype = Object.create(ToolbarItem.prototype);
MagnetToolbarItem.prototype.constructor = MagnetToolbarItem;

MagnetToolbarItem.prototype.getPrice = function () {
    return "@@" + UnitMagnetHandler.PRICE;
};

MagnetToolbarItem.prototype.onClick = function () {
    if (!this.hintShown) {
        this.hintShown = true;
        cleverapps.centerHint.createTextHint("UnitMagnet.hint");
    }
};

MagnetToolbarItem.prototype.onFollowPointer = function (touch) {
    this.dragHandler.searchTargets(touch);
};

MagnetToolbarItem.prototype.dragAction = function () {
    if (this.dragHandler.pullInUnits()) {
        return true;
    }
    return false;
};

MagnetToolbarItem.prototype.isVisible = function () {
    return levels.user.checkAvailable(cleverapps.Availables.UNITMAGNET) && cleverapps.environment.isMainScene()
        && cleverapps.travelBook.getCurrentPage().withToolbarMagnet();
};