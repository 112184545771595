/**
 * Created by vladislav on 27/10/2022
 */

var CustomerIngredientsTab = cc.Node.extend({
    ctor: function (customer, options) {
        this._super();

        this.customer = customer;
        this.options = options;
        this.recipe = this.customer.getCurrentRecipe();

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(options);

        var content = this.createContent();
        this.addChild(content);
        content.setPositionRound(this.width / 2, this.height / 2);

        customer.onAmountChanged = this.createListener(this.updateButton.bind(this));

        this.updateButton();
    },

    updateButton: function () {
        var newButton = this.createButton();
        this.button.parent.addChild(newButton);
        newButton.setPositionRound(this.button.getPosition());
        this.button.removeFromParent();
        this.button = newButton;
    },

    createContent: function () {
        var styles = cleverapps.styles.CustomerIngredientsTab;

        var text = cleverapps.UI.generateTTFText("CustomersWindow.Text." + Unit.GetSkinCode(this.customer.unit), cleverapps.styles.FONTS.WINDOW_SMALL_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setDimensions(styles.text.width, 0);

        this.button = this.createButton();
        var ingredients = this.createIngredients();
        var orders = this.createOrders();

        return new cleverapps.Layout([text, ingredients, orders, this.button], {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL
        });
    },

    createIngredients: function () {
        var styles = cleverapps.styles.CustomerIngredientsTab;

        var layout = new cleverapps.Layout(this.recipe.getIngredients().map(function (ingredient) {
            return new CustomerIngredientView(ingredient);
        }), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.list.margin
        });

        var bg = cleverapps.UI.createScale9Sprite(
            bundles.merge.frames.customer_ingredients_bg_png,
            cleverapps.UI.Scale9Rect.TwoPixelXY
        );
        bg.setContentSize2(styles.list.width, layout.height + styles.list.heightPadding);
        bg.addChild(layout);
        layout.setPositionRound(styles.list);

        var text = cleverapps.UI.generateTTFText("Customer.Ingredients", cleverapps.styles.FONTS.CUSTOMER_INGREDIENTS_TEXT);
        bg.addChild(text);
        text.setPositionRound(styles.list.text);

        var arrow = new cc.Sprite(bundles.merge.frames.customer_arrow_png);
        bg.addChild(arrow);
        arrow.setPositionRound(styles.list.arrow);

        return bg;
    },

    createOrders: function () {
        var styles = cleverapps.styles.CustomerIngredientsTab;

        var layout = new cleverapps.Layout(this.recipe.getOrder().map(function (order) {
            return new CustomerOrderView(order);
        }), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.list.margin
        });

        var bg = cleverapps.UI.createScale9Sprite(
            bundles.merge.frames.customer_orders_bg_png,
            cleverapps.UI.Scale9Rect.TwoPixelXY
        );
        bg.setContentSize2(styles.list.width, layout.height + styles.list.heightPadding);
        bg.addChild(layout);
        layout.setPositionRound(styles.list);

        var text = cleverapps.UI.generateTTFText("Customer.Orders", cleverapps.styles.FONTS.CUSTOMER_INGREDIENTS_TEXT);
        bg.addChild(text);
        text.setPositionRound(styles.list.text);

        return bg;
    },

    createButton: function () {
        var button = this.createBuyButton();
        if (!button) {
            var styles = cleverapps.styles.CustomerIngredientsTab;
            button = new cleverapps.UI.Button({
                text: "Customer.Exchange",
                width: styles.button.width,
                height: styles.button.height,
                onClicked: this.options.exchange.bind(this, this.customer)
            });
            button.adviceTarget = Map2d.SPAWN;

            if (this.customer.isRecipeReady()) {
                button.enable();
            } else {
                button.disable();
            }
        }

        return button;
    },

    createBuyButton: function () {
        var shoppingList = { hard: [], soft: [] };
        var totalAmount = 0, totalRuby = 0, totalCoins = 0;

        this.recipe.getIngredients().forEach(function (ingredient) {
            totalAmount += ingredient.getLackingAmount();
            if (ingredient.useCoins()) {
                shoppingList.soft.push(ingredient);
                totalCoins += ingredient.calcPrice();
            } else {
                shoppingList.hard.push(ingredient);
                totalRuby += ingredient.calcPrice();
            }
        });

        if (!totalRuby && !totalCoins) {
            return;
        }

        var styles = cleverapps.styles.CustomerIngredientsTab.button;
        var msg = Messages.get("Customer.BuyAll") + (totalCoins ? " @@" + totalCoins : "") + (totalRuby ? " $$" + totalRuby : "");

        return new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.button_blue,
            content: new TextWithIcon(msg),
            width: totalRuby && totalCoins ? styles.buyWidthPair : styles.buyWidth,
            height: styles.height,
            onClicked: function () {
                var cellsToFree = totalAmount - Map2d.currentMap.countEmptySlots();
                if (cellsToFree > 0) {
                    cleverapps.centerHint.createTextHint("Spawn.nospace", { left: cellsToFree });
                    return;
                }

                if (totalRuby) {
                    if (!cleverapps.user.spendHard(cleverapps.EVENTS.SPENT.RECIPE_INGREDIENT_ALL, totalRuby)) {
                        return;
                    }
                    shoppingList.hard.forEach(function (ingredient) {
                        ingredient.buyLacking();
                    });
                }

                if (totalCoins && cleverapps.user.spendSoft(cleverapps.EVENTS.SPENT.RECIPE_INGREDIENT_ALL, totalCoins)) {
                    shoppingList.soft.forEach(function (ingredient) {
                        ingredient.buyLacking();
                    });
                }
            }
        });
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CUSTOMER_INGREDIENTS_TEXT: {
        name: "default",
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.CustomerIngredientsTab = {
    margin: 30,

    button: {
        width: 250,
        height: 90,
        buyWidth: 290,
        buyWidthPair: 350
    },

    text: {
        width: 580
    },

    list: {
        margin: 30,
        width: 580,
        heightPadding: 100,

        x: { align: "center" },
        y: { align: "bottom", dy: 25 },

        arrow: {
            x: { align: "center" },
            y: { align: "bottom", dy: -33 }
        },

        text: {
            x: { align: "center" },
            y: { align: "top", dy: -2 }
        },

        order: {
            width: 120,
            height: 120
        }
    }
};
