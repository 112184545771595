/**
 * Created by r4zi4l on 19.01.2021
 */

var BuyIngredientsWindow = CleverappsWindow.extend({
    onWindowLoaded: function (ingredients) {
        this.ingredients = ingredients;
        this.price = this.calcPrice();

        this._super({
            title: "BuyIngredientsWindow.title",
            name: "BuyIngredientsWindow",
            content: this.createContent(),
            buttons: this.createButton(),
            closeButton: true
        });

        cleverapps.meta.showControlsWhileFocused("MenuBarGoldItem");
    },

    onClose: function () {
        cleverapps.meta.hideControlsWhileFocused("MenuBarGoldItem");
    },

    createContent: function () {
        var styles = cleverapps.styles.BuyIngredientsWindow;

        var icons = Object.keys(this.ingredients).map(function (code) {
            return this.createTile({
                code: code,
                amount: this.ingredients[code]
            });
        }, this);

        var iconsLayout = new cleverapps.Layout(icons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.tiles.margin
        });

        var question = cleverapps.UI.generateOnlyText("BuyIngredientsWindow.text", cleverapps.styles.FONTS.DARK_TEXT);
        question.setDimensions(styles.question.width, 0);
        question.fitTo(undefined, styles.question.height);
        question.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        return new cleverapps.Layout([question, iconsLayout], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createTile: function (ingredient) {
        var styles = cleverapps.styles.BuyIngredientsWindow.tile;

        var tile = new cc.Node();
        tile.setContentSize2(styles.background);

        var background = cleverapps.UI.createScale9Sprite(bundles.windows.frames.tile_bg_png);
        background.setContentSize2(styles.background);
        background.setPositionRound(styles.background);
        tile.addChild(background);

        var amountBackground = cleverapps.UI.createScale9Sprite(bundles.windows.frames.tile_amount_png);
        amountBackground.setContentSize2(styles.amountBackground);
        amountBackground.setPositionRound(styles.amountBackground);
        tile.addChild(amountBackground);

        var amountText = cleverapps.UI.generateImageText("x" + ingredient.amount, cleverapps.styles.FONTS.BUYINGREDIENTSWINDOW_AMOUNT);
        amountText.setPositionRound(styles.amountText);
        amountText.fitTo(styles.amountText.width);
        tile.addChild(amountText);

        var icon = Game.currentGame.harvested.getIngredientIcon(ingredient.code);
        icon.setAnchorPoint(0.5, 0.5);
        icon.setPositionRound(styles.icon);
        icon.setScale(styles.icon.scale);
        tile.addChild(icon);

        var title = cleverapps.UI.generateOnlyText(Game.currentGame.harvested.getIngredientName(ingredient.code), cleverapps.styles.FONTS.BUYINGREDIENTSWINDOW_TITLE);
        title.setAnchorPoint(0.5, 0.5);
        title.setPositionRound(styles.title);
        title.fitTo(styles.title.width);
        tile.addChild(title);

        if (cleverapps.travelBook.isExpedition()) {
            var help = new cleverapps.UI.HelpButton(this.findIngredient.bind(this, ingredient));
            tile.addChild(help);
            help.setPositionRound(styles.help);
        }

        return tile;
    },

    createButton: function () {
        var styles = cleverapps.styles.BuyIngredientsWindow.button;

        return new UseGoldButton({
            price: this.price,
            onClicked: this.buyIngredients.bind(this),
            eventName: cleverapps.EVENTS.SPENT.INGREDIENT,
            width: styles.width,
            height: styles.height
        });
    },

    calcPrice: function () {
        var total = 0;

        for (var code in this.ingredients) {
            total += 3 * this.ingredients[code];
        }

        return total;
    },

    buyIngredients: function () {
        Game.currentGame.harvested.addList(this.ingredients);
        this.close();
    },

    findIngredient: function (ingredient) {
        var target = BuyIngredientsWindow.findIngredientSource(ingredient.code);
        if (!target) {
            cleverapps.notification.create("Quest.find.empty");
            return;
        }

        this.hideSelf();
        Map2d.currentMap.onAddTarget(target.x, target.y);
        Map2d.currentMap.getView().scroll.runAction(new cc.Sequence(
            new cc.CellScrollAction(target, { allowScrollWithFocus: true }).easing(cc.easeInOut(2)),
            new cc.DelayTime(1),
            new cc.CallFunc(this.displaySelf.bind(this))
        ));
    }
});

BuyIngredientsWindow.findIngredientSource = function (code) {
    var resources = [];
    var mineables = [];
    var chests = [];
    var shops = [];

    var units = Map2d.currentMap.listAvailableUnits();
    units.forEach(function (unit) {
        if (unit.code === code) {
            resources.push(unit);
            return;
        }

        var mineable = unit.findComponent(Mineable);
        if (mineable) {
            mineables.push(mineable);
            return;
        }

        var chest = unit.findComponent(Chest);
        if (chest) {
            chests.push(chest);
            return;
        }

        var shop = unit.findComponent(UnitsShopComponent);
        if (shop) {
            shops.push(shop);
        }
    });

    var center = Map2d.currentMap.getScreenCenterCell();
    var comparator = function (a, b) {
        return cc.getDistanceChebyshev(a.unit || a, center) - cc.getDistanceChebyshev(b.unit || b, center);
    };

    resources.sort(comparator);
    if (resources.length) {
        return resources[0];
    }

    mineables.sort(comparator);
    for (var i = 0; i < mineables.length; ++i) {
        if (mineables[i].listAvailablePrizes().some(function (prize) {
            return prize && (prize.code === code || prize.ingredient === code);
        })) {
            return mineables[i].unit;
        }
    }

    chests.sort(comparator);
    for (i = 0; i < chests.length; ++i) {
        if (Chest.ListPrizeProb(chests[i].unit).types.some(function (prize) {
            return prize && (prize.code === code || prize.ingredient === code);
        })) {
            return chests[i].unit;
        }
    }

    shops.sort(comparator);
    for (i = 0; i < shops.length; ++i) {
        if (cleverapps.unitsShop.tabs[shops[i].tab].generateProducts().some(function (prize) {
            return prize && prize.unit && prize.unit.code === code;
        })) {
            return shops[i].unit;
        }
    }
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    BUYINGREDIENTSWINDOW_AMOUNT: {
        size: 60,
        color: cleverapps.styles.COLORS.LIGHT_TEXT_COLOR,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE,
        shadow: cleverapps.styles.DECORATORS.LIGHT_TEXT_SHADOW
    },

    BUYINGREDIENTSWINDOW_TITLE: {
        name: "nostroke",
        size: 45,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.BuyIngredientsWindow = {
    margin: 40,

    icons: {
        margin: 20
    },

    button: {
        width: 300,
        height: 110
    },

    question: {
        width: 800,
        height: 100
    },

    tiles: {
        margin: 60
    },

    tile: {
        background: {
            width: 280,
            height: 450,
            x: { align: "center" },
            y: { align: "center" }
        },

        amountBackground: {
            width: 330,
            height: 115,
            x: { align: "center", dx: 13 },
            y: { align: "center", dy: -80 }
        },

        amountText: {
            x: { align: "center" },
            y: { align: "center", dy: -68 },
            width: 320
        },

        icon: {
            x: { align: "center" },
            y: { align: "center", dy: 75 },
            scale: 1.6
        },

        title: {
            width: 260,
            x: { align: "center" },
            y: { align: "center", dy: -170 }
        },

        help: {
            x: { align: "top", dx: -10 },
            y: { align: "right", dy: -10 }
        }
    }
};
