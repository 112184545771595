/**
 * Created by mac on 1/12/21
 */

var GameLevel = function () {
    cleverapps.EventEmitter.call(this);

    CustomSyncers.SLOTS.forEach(function (slot) {
        cleverapps.expBySlots[slot].on("changeExp", this.onChangeExp.bind(this, slot));
    }, this);
};

GameLevel.prototype = Object.create(cleverapps.EventEmitter.prototype);
GameLevel.prototype.constructor = GameLevel;

GameLevel.prototype.onChangeExp = function (slot) {
    if (cleverapps.travelBook.getCurrentPage().slot === slot) {
        this.checkLevelUp();
    }

    if (cleverapps.travelBook.getCurrentPage().isMain()) {
        if (cleverapps.instantTournament.isAvailable()) {
            cleverapps.instantTournament.reportScore();
        } else {
            cleverapps.scoreTracker.report();
        }
    }
};

GameLevel.prototype.load = function (level) {
    var expedition = cleverapps.travelBook.getCurrentExpedition();

    this.withOwnLevel = Boolean(expedition && expedition.gameLevel);

    if (this.withOwnLevel) {
        this.level = level || 0;
    } else {
        delete this.level;
    }
};

GameLevel.prototype._getLevel = function () {
    if (this.withOwnLevel) {
        return this.level;
    }

    return cleverapps.user.level;
};

GameLevel.prototype.getLevel = function () {
    return this._getLevel() + 1;
};

GameLevel.prototype.getNextLevel = function () {
    return this._getLevel() + 2;
};

GameLevel.prototype.nextLevelThreshold = function (isMain) {
    var config = isMain ? GameLevelConfig.main : this.getConfig();
    var threshold = config.threshold;

    var level = isMain ? cleverapps.user.level : this._getLevel();

    if (level <= threshold.length - 1) {
        return threshold[level];
    }

    var d = level - (threshold.length - 1);
    var lastStep = threshold[threshold.length - 1] - threshold[threshold.length - 2];
    return threshold[threshold.length - 1] + lastStep * d;
};

GameLevel.prototype.checkLevelUp = function () {
    var page = cleverapps.travelBook.getCurrentPage();
    if (cleverapps.exp.getExp() >= this.nextLevelThreshold() && (page.isMain() || this.withOwnLevel)) {
        this.trigger("levelUp");
    }
};

GameLevel.prototype.incLevel = function () {
    if (this.withOwnLevel) {
        this.level++;
        Game.currentGame.storeSave();
    } else {
        cleverapps.user.level++;
        cleverapps.user.save();
    }
};

GameLevel.prototype.moveToNextLevel = function () {
    cleverapps.exp.takeExp(this.nextLevelThreshold(), true);
    this.incLevel();

    this.trigger("changeLevel");
    cleverapps.exp.onChange();
    Game.currentGame.energyLottery && Game.currentGame.energyLottery.onChangeGameLevel();

    cleverapps.lives.processRegenerateState(true);
    cleverapps.lives.runListeners();
    cleverapps.user.trigger("incLevel");
    cleverapps.eventBus.trigger("taskEvent", DailyTasks.LEVEL_UP);

    cleverapps.missionManager.refreshTeaser();
    cleverapps.sideBar.refreshAllIcons();
    cleverapps.restoreProgress.update();

    if (!this.withOwnLevel) {
        this.logLevelUp();
    }
};

GameLevel.prototype.logLevelUp = function () {
    if (cleverapps.user.level === 1 && cleverapps.user.getDaysSinceRegistration() === 0) {
        cleverapps.user.reportRetention();
    }

    var hash = Game.currentGame.level.hash;

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.LEVEL_FINISH, {
        hash: hash
    });

    cleverapps.eventLogger.logEvent(cleverapps.EVENTS.STATS.UNIQUE_FINISH, {
        hash: hash
    });

    if (cleverapps.config.type === "merge") {
        cleverapps.conversionManager.processConversion(cleverapps.EVENTS.CONVERSIONS.LEVEL, levels.user.level);

        if (levels.user.level === 3) {
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.GENERAL.TUTORIAL_COMPLETE);
        }

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.GENERAL.LEVEL_UP, {
            level: levels.user.level
        });
    }
};

GameLevel.prototype.getConfig = function () {
    var id = cleverapps.travelBook.getCurrentPage().id;
    if (!this.withOwnLevel) {
        id = "main";
    }

    return GameLevelConfig[id];
};

GameLevel.prototype.getReward = function () {
    return this.getConfig().calcReward(this._getLevel());
};