/**
 * Created by vladislav on 26/10/2022
 */

var CustomerIngredientView = cc.Node.extend({
    ctor: function (ingredient, mini) {
        this._super();
        this.mini = mini;
        this.ingredient = ingredient;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(mini ? cleverapps.styles.CustomerIngredientView.mini : cleverapps.styles.CustomerIngredientView);
        
        this.createIngredient();
        this.createCheckMark();

        if (!mini) {
            this.createBuyButton();
            this.createHelp();

            if (cleverapps.config.debugMode) {
                cleverapps.UI.onClick(this, function () {
                    var amountNeeded = this.ingredient.requiredAmount - this.ingredient.getCurrentAmount();

                    if (this.ingredient.ingredient) {
                        Game.currentGame.harvested.add(this.ingredient.ingredient, amountNeeded);
                    } else {
                        for (var i = 0; i < amountNeeded; i++) {
                            setTimeout(function () {
                                Unit.CreateDebug(this.ingredient.unit);
                            }.bind(this), 300 * i);
                        }
                    }
                }.bind(this));
            }
        }

        this.onAmountChanged();
        this.ingredient.on("amountChanged", this.createListener(this.onAmountChanged.bind(this)), this);
    },

    createIngredient: function () {
        var styles = cleverapps.styles.CustomerIngredientView;

        var bg = new cc.Scale9Sprite(bundles.merge.frames.customer_item_bg_png);
        bg.setContentSize2(this.width, this.height - (this.mini ? 0 : styles.bgPaddingY));
        bg.setAnchorPoint(0.5, 1);
        bg.setPositionRound(this.width / 2, this.height);
        this.addChild(bg);

        var icon;

        if (this.ingredient.ingredient) {
            icon = Game.currentGame.harvested.getIngredientSmallIcon(this.ingredient.ingredient);
        } else {
            icon = UnitView.getUnitImage(this.ingredient.unit, { preferStatic: true, useLibraryAnchors: true });
        }

        cleverapps.UI.fitToBox(icon, cc.size(bg.width - styles.iconPadding, bg.height - styles.iconPadding));
        icon.setPositionRound(bg.width / 2, bg.height / 2);
        bg.addChild(icon);
    },

    createBuyButton: function () {
        var price = this.ingredient.calcPrice();
        if (!price) {
            return;
        }
        var buyAmount = this.ingredient.getLackingAmount();
        var useCoins = this.ingredient.useCoins();

        var styles = cleverapps.styles.CustomerIngredientView.buyButton;
        var button = this.buyButton = new UseGoldButton({
            price: price,
            useCoins: useCoins,
            eventName: cleverapps.EVENTS.SPENT.RECIPE_INGREDIENT,
            type: cleverapps.styles.UI.Button.Images.small_button_blue,
            width: styles.width,
            height: styles.height,
            onClicked: this.ingredient.buyLacking.bind(this.ingredient),
            filter: function () {
                var cellsToFree = buyAmount - Map2d.currentMap.countEmptySlots();
                if (cellsToFree > 0) {
                    cleverapps.centerHint.createTextHint("Spawn.nospace", { left: cellsToFree });
                    return false;
                }
                return true;
            }
        });
        this.addChild(button);
        button.setPositionRound(styles);
    },

    createCheckMark: function () {
        var styles = cleverapps.styles.CustomerIngredientView.checkMark;
        var img = this.mini ? bundles.merge.frames.customer_check_mark : bundles.merge.frames.big_checkmark_png;
        this.checkMark = new cc.Sprite(img);
        this.addChild(this.checkMark);
        this.checkMark.setPositionRound(this.mini ? styles.mini : styles);
    },

    createHelp: function () {
        var styles = cleverapps.styles.CustomerIngredientView.help;

        var helpParams = this.ingredient.getHelp();
        if (helpParams) {
            helpParams.allowScrollWithFocus = true;
            this.help = cleverapps.UI.createScale9Sprite(bundles.merge.frames.lupa_mark);
            cleverapps.UI.applyHover(this.help);
            cleverapps.UI.onClick(this.help, function () {
                cleverapps.windows.currentWindow().close();

                if (helpParams.ingredient) {
                    UnitLocator.findPrize(helpParams);
                } else {
                    UnitLocator.find(Map2d.SPAWN, helpParams.unit, helpParams);
                }
            });
            this.addChild(this.help);
            this.help.setPositionRound(this.mini ? styles.mini : styles);
        }
    },

    onAmountChanged: function () {
        var styles = cleverapps.styles.CustomerIngredientView.progress;

        if (this.progress) {
            this.progress.removeFromParent();
        }

        this.progress = this.createProgress();
        this.addChild(this.progress);

        this.progress.setPositionRound(this.mini ? styles.mini : styles);
        cleverapps.UI.fitToBox(this.progress, {
            width: this.width * 0.9
        });

        var isReady = this.ingredient.isReady();
        this.checkMark.setVisible(isReady);
        if (this.help) {
            this.help.setVisible(!isReady);
        }
        if (this.buyButton) {
            this.buyButton.setVisible(!isReady);
        }
    },

    createProgress: function () {
        var styles = cleverapps.styles.CustomerIngredientView.progress;

        var font = this.mini ? cleverapps.styles.FONTS.CUSTOMER_INGREDIENT_NOT_READY_TEXT_MINI : cleverapps.styles.FONTS.CUSTOMER_INGREDIENT_NOT_READY_TEXT;
        if (this.ingredient.isReady()) {
            font = this.mini ? cleverapps.styles.FONTS.CUSTOMER_INGREDIENT_READY_TEXT_MINI : cleverapps.styles.FONTS.CUSTOMER_INGREDIENT_READY_TEXT;
        }
        var currentAmount = cleverapps.UI.generateImageText(this.ingredient.getCurrentAmount(), font);

        font = this.mini ? cleverapps.styles.FONTS.CUSTOMER_INGREDIENT_TEXT_MINI : cleverapps.styles.FONTS.CUSTOMER_INGREDIENT_TEXT;
        var slash = cleverapps.UI.generateImageText("/", font);
        var requiredAmount = cleverapps.UI.generateImageText(this.ingredient.requiredAmount, font);

        return new cleverapps.Layout([currentAmount, slash, requiredAmount], {
            margin: styles.margin,
            direction: cleverapps.UI.HORIZONTAL
        });
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CUSTOMER_INGREDIENT_TEXT: {
        size: 35,
        name: "default"
    },

    CUSTOMER_INGREDIENT_NOT_READY_TEXT: {
        size: 35,
        name: "default",
        color: new cc.Color(250, 79, 75, 255)
    },

    CUSTOMER_INGREDIENT_READY_TEXT: {
        size: 35,
        name: "default",
        color: new cc.Color(60, 212, 33, 255)
    },

    CUSTOMER_INGREDIENT_TEXT_MINI: {
        size: 30,
        name: "default"
    },

    CUSTOMER_INGREDIENT_NOT_READY_TEXT_MINI: {
        size: 30,
        name: "default",
        color: new cc.Color(250, 79, 75, 255)
    },

    CUSTOMER_INGREDIENT_READY_TEXT_MINI: {
        size: 30,
        name: "default",
        color: new cc.Color(60, 212, 33, 255)
    }
});

cleverapps.styles.CustomerIngredientView = {
    width: 130,
    height: 170,
    iconPadding: 5,
    bgPaddingY: 40,

    mini: {
        width: 80,
        height: 80
    },

    progress: {
        margin: -5,
        x: { align: "left", dx: 3 },
        y: { align: "top", dy: 0 },

        mini: {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 0 }
        }
    },

    help: {
        x: { align: "right", dx: 15 },
        y: { align: "top", dy: 10 }
    },

    checkMark: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 15 },

        mini: {
            x: { align: "right", dx: 15 },
            y: { align: "top", dy: -2 }
        }
    },

    buyButton: {
        width: 130,
        height: 70,
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 0 }
    }
};