/**
 * Created by vladislav on 26/10/2022
 */

var CustomerOrderView = cc.Node.extend({
    ctor: function (order) {
        this._super();

        this.order = order;

        var styles = cleverapps.styles.CustomerOrderView;

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles);

        var bg = new cc.Scale9Sprite(bundles.merge.frames.customer_item_bg_png);
        bg.setContentSize2(styles);
        this.addChild(bg);
        bg.setPositionRound(this.width / 2, this.height / 2);

        var icon;
        if (this.order.unit) {
            icon = UnitView.getUnitImage(this.order.unit, { preferStatic: true });
        }

        if (icon) {
            this.addChild(icon);
            icon.setPositionRound(this.width / 2, this.height / 2);
            cleverapps.UI.fitToBox(icon, this);
        }

        if (this.order.amount) {
            var amount = cleverapps.UI.generateImageText(this.order.amount, cleverapps.styles.FONTS.CUSTOMER_INGREDIENT_TEXT);
            this.addChild(amount);
            amount.setPositionRound(styles.amount);
        }
    }
});

cleverapps.styles.CustomerOrderView = {
    width: 130,
    height: 130,

    amount: {
        x: { align: "right", dx: -10 },
        y: { align: "bottom", dy: 10 }
    }
};