/**
 * Created by spepa on 26.09.2022
 */

Mines.hlsource = function (level, unit) {
    if (level === unit.getData().mineable.length - 1) {
        return [{
            units: [{ code: unit.getResource() + "chest", stage: 0 }]
        }];
    }

    return [{
        units: [{ code: unit.getResource(), stage: 0 }]
    }];
};

Ruins.TIERS.halloween = {
    cheap: {
        soft: 59,
        rewards: [
            { code: "crystal", stage: 0, amount: 1 },
            { code: "worker", stage: 0, amount: 1 },
            { code: "hlfreechest", stage: 0, amount: 1 }
        ]
    },
    costly: {
        hard: 249,
        rewards: [
            { code: "crystal", stage: 3, amount: 1 },
            { code: "worker", stage: 4, amount: 1 },
            { code: "hlshopchest", stage: 1, amount: 1 },
            { code: "bppointsstar", stage: 0, amount: 3 }
        ]
    }
};

Mines.hlgrowing = WandsGrowing.prize;

Mines.hlgenerator = function (level, unit) {
    return [{
        amount: 1,
        units: [
            { code: unit.getResource() + "source", stage: 0 }
        ]
    }];
};

Landmarks.WORLDS.halloween = {
    fog: "fog12",
    force: Forces.LANDMARK_DONOR_SLOT1,
    landmarks: [{
        code: "landmark",
        stage: 9,
        donors: "hlgnome"
    }]
};

cleverapps.styles.halloween = {
    PixelView: {
        width: 80,
        height: 80,
        animation: {
            x: { align: "center" },
            y: { align: "center", dy: -40 }
        },
        collect: {
            x: 0,
            y: 0,
            delay: 150
        },
        timeScale: {
            base: 1,
            random: 0
        },
        speed: {
            x: 35,
            y: -18
        },
        distanceDisappear: 1000
    }
};

CustomerRecipes.halloween = cleverapps.clone(CustomerRecipes.universal, true);
CustomerRecipes.halloween.rpproducer0_0[0].cookingDuration = "10 minutes";
CustomerRecipes.halloween.rpproducer0_0[0].ingredients[0] = {
    code: "seastrawberry",
    stage: 4,
    amount: 1
};
CustomerRecipes.halloween.rpcustomerbridge1_0[0].requiredUnits[0].code = "rpcustomermain0a";
CustomerRecipes.halloween.rpcustomerbridge1_0[0].requiredUnits[0].stage = 2;
CustomerRecipes.halloween.rpcustomerbridge2_0[0].requiredUnits[0].code = "rpcustomermain1a";
CustomerRecipes.halloween.rpcustomerbridge3_0[0].requiredUnits[0].code = "rpcustomermain2a";

CustomerRecipes.halloween.rpproducer1_0[0].requiredUnits[0].code = "rpcustomermain0a";
CustomerRecipes.halloween.rpproducer2_0[0].requiredUnits[0].code = "rpcustomermain1a";
CustomerRecipes.halloween.rpproducer3_0[0].requiredUnits[0].code = "rpcustomermain2a";

CustomerRecipes.halloween.rpcustomerbridge0_0[0].ingredients[0].type = "hlresource";
CustomerRecipes.halloween.rpcustomerbridge0_0[0].ingredients[0].amount = 1;
CustomerRecipes.halloween.rpcustomermain0a_1 = CustomerRecipes.halloween.rpcustomermain0a_0;
delete CustomerRecipes.halloween.rpcustomermain0a_0;

Climbable.TRIGGERS.halloween = {
    rpcustomermain0a_0: { code: "rpcustomerbridge0", stage: 1 }
};
