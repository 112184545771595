/**
 * Created by Aleksandr on 21.09.2022.
 */

var MergeAdviceRefillLogic = function (options) {
    MergeAdviceBaseLogic.call(this, options);
};

MergeAdviceRefillLogic.prototype = Object.create(MergeAdviceBaseLogic.prototype);
MergeAdviceRefillLogic.prototype.constructor = MergeAdviceRefillLogic;

MergeAdviceRefillLogic.prototype.findMove = function () {
    var unit = this.listAvailableUnits()[0];
    var move = {};

    if (unit && unit.findComponent(ToyFactory)) {
        return {
            cells: [cc.p(unit.x, unit.y)],
            unitView: ["speedup"]
        };
    }

    if (unit && unit.findComponent(Tank)) {
        move.cells = [cc.p(unit.x, unit.y)];
    }

    var currentWindow = cleverapps.windows.currentWindow();
    if (currentWindow instanceof RefillTankWindow && unit.findComponent(Tank)) {
        move.window = Map2d.REFILL;
    }

    if (move.cells || move.window) {
        return move;
    }
};
