/**
 * Created by andrey on 13.01.2021.
 */

cleverapps.override(cleverapps.styles.UnitAnimations, {
    "hlfranky": {
        "stage3": {
            animation: 200,
            idle: 800
        }
    },
    "hlwitch": {
        "stage5": {
            animation: 200,
            idle: 800
        }
    },
    "hlskeleton": {
        "stage5": {
            animation: 200,
            idle: 800
        },
        "stage7": {
            animation: 200,
            idle: 800
        }
    },
    "hlscarecrow": {
        "stage9": {
            animation: 200,
            idle: 800
        },
        "stage10": {
            animation: 200,
            idle: 800
        }
    }
});
