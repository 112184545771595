/**
 * Created by vtbelo on 04.04.2021
 */

var UnitsShopCart = cc.Node.extend({
    ctor: function (unitsShopTab) {
        this._super();
        this.unitsShopTab = unitsShopTab;

        var styles = cleverapps.styles.UnitsShopCart;

        this.setContentSize2(styles);

        this.icon = new cc.Node();
        this.icon.setAnchorPoint(0.5, 0.5);
        this.icon.setContentSize2(styles);
        this.icon.setCascadeOpacityEnabled(true);
        this.icon.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(this.icon);

        var bottomIcon = new cc.Sprite(bundles.tile_shop.frames.cart_bottom_png);
        bottomIcon.setPositionRound(styles.bottomIcon);
        this.icon.addChild(bottomIcon);
        var topIcon = new cc.Sprite(bundles.tile_shop.frames.cart_top_png);
        topIcon.setPositionRound(styles.topIcon);
        topIcon.setLocalZOrder(2);
        this.icon.addChild(topIcon);

        this.amountBlock = new cc.Sprite(bundles.tile_shop.frames.cart_amount_bg);
        this.amountBlock.setPositionRound(styles.amountBlock);
        this.amountText = cleverapps.UI.generateImageText("", cleverapps.styles.FONTS.UNITSSHOP_CART_AMOUNT);
        this.amountText.setPositionRound(styles.amountBlock.amountText);
        this.amountBlock.addChild(this.amountText);
        this.addChild(this.amountBlock);

        this.firstItem = this.target = new cc.Node();
        this.firstItem.setPositionRound(styles.item.firstItem.oneItem);
        this.firstItem.setLocalZOrder(1);
        this.icon.addChild(this.firstItem);

        this.secondItem = new cc.Node();
        this.secondItem.setPositionRound(styles.item.secondItem);
        this.icon.addChild(this.secondItem);

        this.amount = unitsShopTab.getCartAmount();
        this.updateCartAmount();

        this.createUnitViews();
    },

    createUnitViews: function () {
        if (!UnitsShop.cartRewards) {
            return;
        }

        UnitsShop.cartRewards.forEach(function (reward) {
            this.addUnitView(reward.getIcon());
        }, this);
    },

    addUnitView: function (unitView) {
        var styles = cleverapps.styles.UnitsShopCart.item;

        if (this.firstItem.children.length) {
            this.secondItem.removeAllChildren();
            this.firstItem.children[0].replaceParent(this.secondItem);
            this.firstItem.removeAllChildren();
            this.firstItem.setPositionRound(styles.firstItem.twoItems);
        }

        unitView.setScale(styles.scale);
        cleverapps.UI.fitToBox(unitView, {
            width: styles.width
        });
        unitView.replaceParent(this.firstItem);
    },

    incAmount: function (unitView) {
        this.amount++;
        this.updateCartAmount();

        this.addUnitView(unitView);
    },

    updateCartAmount: function () {
        if (this.amountBlock) {
            this.amountText.setString(this.amount);
            this.amountText.fitTo(cleverapps.styles.UnitsShopCart.amountBlock.amountText.maxWidth);
        }
    },

    processItems: function (callback) {
        if (!UnitsShop.cartRewards || !UnitsShop.cartRewards.length) {
            callback();
            return;
        }

        this.amountBlock.removeFromParent();
        this.amountBlock = undefined;

        this.replaceParentSamePlace(Map2d.currentMap.getView().animations, {
            keepScale: true
        });
        this.runAction(new cc.Sequence(
            new cc.MoveBy(0.5, {
                x: cleverapps.styles.UnitsShopCart.moveDelta.x / Map2d.currentMap.getScrollZoom(),
                y: cleverapps.styles.UnitsShopCart.moveDelta.y / Map2d.currentMap.getScrollZoom()
            }),
            new cc.CallFunc(function () {
                this.setLocalZOrder(-1);
                this.unitsShopTab.processCartUnits(this);
                path.stop();
            }.bind(this)),
            new cc.DelayTime(0.15),
            new cc.CallFunc(function () {
                this.firstItem.setVisible(false);
                this.secondItem.setVisible(false);
            }.bind(this))
        ));

        this.icon.runAction(new cc.Sequence(
            new cc.ScaleTo(0.5, 1.5),
            new cc.DelayTime(0.7),
            new cc.FadeOut(0.3),
            new cc.CallFunc(function () {
                this.removeFromParent();
            }.bind(this))
        ));

        cleverapps.timeouts.setTimeout(callback, 1500);

        var path = new cleverapps.Particles(bundles.particle.jsons.particles_collect_json, bundles.particle.urls.particle_texture);
        path.setScale(1.5);
        path.setLocalZOrder(-1);
        path.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(path);
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    UNITSSHOP_CART_AMOUNT: {
        name: "default",
        size: 40
    }
});

cleverapps.styles.UnitsShopCart = {
    width: 189,
    height: 139,

    bottomIcon: {
        x: { align: "center" },
        y: { align: "center" }
    },

    topIcon: {
        x: { align: "center", dx: -18 },
        y: { align: "center", dy: -18 }
    },

    amountBlock: {
        x: { align: "right", dx: -10 },
        y: { align: "bottom", dy: 4 },

        amountText: {
            x: { align: "center", dx: 1 },
            y: { align: "center", dy: 2 },
            maxWidth: 40
        }
    },

    item: {
        scale: 0.8,
        width: 130,
        firstItem: {
            oneItem: {
                x: { align: "center", dx: -15 },
                y: { align: "bottom", dy: 85 }
            },
            twoItems: {
                x: { align: "center", dx: -30 },
                y: { align: "bottom", dy: 85 }
            }
        },

        secondItem: {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: 100 }
        }
    },

    moveDelta: {
        x: 500,
        y: 400
    }
};
