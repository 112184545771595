/**
 * Created by Andrey Popov on 2/24/22.
 */

Telly.PRIZES.undersea3 = Telly.PRIZES.undersea;

MissionTree.PRIZES.undersea3 = [{
    amount: 5,
    units: [
        { code: "seasnail", stage: 1 },
        { code: "seasnail", stage: 1 },
        { code: "seasnail", stage: 1 },
        { code: "seasnail", stage: 2 }
    ]
}];

Ruins.TIERS.undersea3 = {
    cheap: {
        soft: 59,
        rewards: [
            { code: "energy", stage: 0, amount: 1 },
            { code: "worker", stage: 0, amount: 1 },
            { code: "seastrawberry", stage: 0, amount: 2 }
        ]
    },
    costly: {
        hard: 249,
        rewards: [
            { code: "energy", stage: 2, amount: 2 },
            { code: "crystal", stage: 2, amount: 3 },
            { code: "seasack", stage: 2, amount: 1 },
            { code: "worker", stage: 4, amount: 1 }
        ]
    }
};

Landmarks.WORLDS.undersea3 = {
    target: { code: "rpcustomermain0a", stage: 0 },
    force: Forces.LANDMARK_DONOR_SLOT2,
    landmarks: [{
        code: "landmark",
        stage: 17,
        donors: ["rpcustomermain0a", "rpcustomermain1a", "rpcustomermain2a", "rpcustomermain3a"]
    }]
};

CustomerRecipes.undersea3 = cleverapps.clone(CustomerRecipes.universal, true);
CustomerRecipes.undersea3.rpproducer0_0[0].cookingDuration = "10 minutes";
CustomerRecipes.undersea3.rpproducer0_0[0].ingredients[0] = {
    code: "seastrawberry",
    stage: 4,
    amount: 1
};
CustomerRecipes.undersea3.rpcustomerbridge1_0[0].requiredUnits[0].code = "rpcustomermain0a";
CustomerRecipes.undersea3.rpcustomerbridge1_0[0].requiredUnits[0].stage = 2;
CustomerRecipes.undersea3.rpcustomerbridge2_0[0].requiredUnits[0].code = "rpcustomermain1a";
CustomerRecipes.undersea3.rpcustomerbridge3_0[0].requiredUnits[0].code = "rpcustomermain2a";

CustomerRecipes.undersea3.rpproducer1_0[0].requiredUnits[0].code = "rpcustomermain0a";
CustomerRecipes.undersea3.rpproducer2_0[0].requiredUnits[0].code = "rpcustomermain1a";
CustomerRecipes.undersea3.rpproducer3_0[0].requiredUnits[0].code = "rpcustomermain2a";

CustomerRecipes.undersea3.rpcustomerbridge0_0[0].ingredients[0].type = "searesource";
CustomerRecipes.undersea3.rpcustomerbridge0_0[0].ingredients[0].amount = 1;
CustomerRecipes.undersea3.rpcustomermain0a_1 = CustomerRecipes.undersea3.rpcustomermain0a_0;
delete CustomerRecipes.undersea3.rpcustomermain0a_0;

Climbable.TRIGGERS.undersea3 = {
    rpcustomermain0a_0: { code: "rpcustomerbridge0", stage: 1 }
};
