/**
 * Created by vladislav on 25/10/2022
 */

QuestsConfig.rapunzel3 = cleverapps.clone(QuestsConfig.universal, true);
QuestsConfig.rapunzel3.rpplate_3 = {
    trigger: { unit: { code: "rpplate", stage: 1 } },
    tutorial: "rapunzel3_plate",
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpplate", stage: 3 }
    }
};

QuestsConfig.rapunzel3.rppans_3 = {
    trigger: { fog: "fog7" },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rppans", stage: 3 }
    }
};

QuestsConfig.rapunzel3.rpmugs_3 = {
    trigger: { fog: "fog10" },
    quest: {
        type: Map2d.SPAWN,
        unit: { code: "rpmugs", stage: 3 }
    },
    dynamic: {
        getIcon: function () {
            return new cc.Sprite(bundles.quests.frames.rpmugs3);
        }
    }
};
