/**
 * Created by andrey on 16.12.2020.
 */

var EditorMap2dView = EditorMap2dBaseView.extend({
    ctor: function (map2d) {
        this._super.apply(this, arguments);

        map2d.on("removeValue", this.onRemoveValue.bind(this), this);
        map2d.on("setValue", this.onSetValue.bind(this), this);
    },

    getLayersOrder: function () {
        return [
            [Map2d.LAYER_WATER],
            [Map2d.LAYER_BORDERS],
            [Map2d.LAYER_GROUND],
            [Map2d.ABOVE_GROUND],
            [Map2d.LAYER_UNITS],
            [Map2d.LAYER_FOG]
        ];
    },

    addControls: function () {
        this.addHighlightListener();
        this.addDragAndProduceListener();

        // new PointerHandler(this, {
        //     interactiveScale: false,
        //     onClick: function (touch) {
        //         console.log(this.getCellByTouch(touch));
        //     }.bind(this)
        // });
    },

    addHighlightListener: function () {
        cc.eventManager.addListener({
            event: cc.EventListener.MOUSE,
            onMouseMove: function (touch) {
                var p = this.convertTouchToNodeSpace(touch);
                var cell = this.getVisibleCellByCoordinates(p);
                var x = cell && cell.x || 0;
                var y = cell && cell.y || 0;

                if (Editor.currentEditor.selected && !this.draggable) {
                    this.highlight.removeFromParent(false);
                    this.highlight.setPositionRound(0, 0);
                    this.highlight.setVisible(true);
                    this.addTile(Map2d.LAYER_UNITS, x, y, this.highlight);
                } else {
                    this.highlight.replaceParent(this.animations);
                }
            }.bind(this)
        }, this);
    },

    findDraggable: function (cell) {
        return this.map2d.getDraggable(cell.x, cell.y);
    },

    onRemoveValue: function (layer, x, y) {
        this.removeLayerTile(layer, x, y);
    },

    onSetValue: function (layer, x, y) {
        this.showLayerTile(layer, x, y);
    },

    addDragAndProduceListener: function () {
        var produced = {}, dragUnit, draggable, highlight, startCell;

        var produce = function (touch) {
            var cell = this.getVisibleCellByCoordinates(this.convertTouchToNodeSpace(touch));
            if (!cell) {
                return;
            }
            var key = cell.x + "_" + cell.y;
            if (!produced[key]) {
                Editor.currentEditor.produce(cell.x, cell.y);
                produced[key] = true;
            }
        }.bind(this);

        cleverapps.UI.onDrag(this, {
            onPressed: function (touch) {
                var cell = this.getCellByTouch(touch);
                
                console.log(cell);

                var unit = this.map2d.getUnit(cell.x, cell.y);
                if (unit) {
                    console.log(unit);
                }

                dragUnit = this.findTouchUnit(touch);

                return false;
            }.bind(this),

            onClick: function (touch) {
                if (this.hasTouch(touch)) {
                    produced = {};
                    produce(touch);
                }
            }.bind(this),

            onDragStart: function (touch) {
                var brushMode = cleverapps.keyboardController.isPressed(cc.KEY.b);
                if (brushMode && this.hasTouch(touch)) {
                    dragUnit = undefined;
                    produced = {};
                    produce(touch);
                    return true;
                }

                var cell = this.getCellByTouch(touch);
                if (!cell) {
                    return;
                }

                draggable = this.findDraggable(cell);
                if (draggable) {
                    dragUnit = undefined;
                    startCell = cell;
                    highlight = new Highlight(cell.x, cell.y, { unit: draggable });
                    Map2d.currentMap.onAnimationsAdd(highlight);
                    highlight.move(cell.x, cell.y, true);
                    return true;
                }

                if (dragUnit) {
                    dragUnit.onDragStart();
                    return true;
                }
            }.bind(this),

            followPointer: function (touch) {
                if (dragUnit) {
                    dragUnit.onFollowPointer(touch);
                }
            },

            onDragMove: function (touch) {
                if (draggable) {
                    draggable.onDragMove(touch);
                    var cell = this.getCellByTouch(touch);
                    if (cell) {
                        highlight.move(cell.x, cell.y);
                    }
                    return;
                }

                if (dragUnit) {
                    console.log("!!! onDragMove");
                    dragUnit.onDragMove();
                    return;
                }

                if (this.hasTouch(touch)) {
                    produce(touch);
                }
            }.bind(this),

            onDragEnd: function (touch) {
                if (draggable) {
                    highlight.remove();

                    var cell = this.getCellByTouch(touch);
                    if (!draggable.canMoveTo(cell.x, cell.y)) {
                        cell = startCell;
                    }

                    draggable.setPosition(cell.x, cell.y);
                    draggable = undefined;
                    startCell = undefined;
                    highlight = undefined;
                    return;
                }

                if (dragUnit) {
                    dragUnit.onDragEnd();
                    this.map2d.saveLevel();

                    dragUnit = undefined;
                    return;
                }

                if (this.hasTouch(touch)) {
                    produce(touch);
                }
                produced = {};
            }.bind(this)
        });
    }
});