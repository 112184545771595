/**
 * Created by Denis Kuzin on 16 november 2022
 */

Landmarks.WORLDS.xmas = {
    target: { code: "xmcustomermain0", stage: 0 },
    force: Forces.LANDMARK_DONOR_SLOT2,
    landmarks: [{
        code: "landmark",
        stage: 3,
        donors: []
    }, {
        code: "landmark",
        stage: 4,
        donors: []
    }, {
        code: "landmark",
        stage: 12,
        donors: ["xmcustomermain0", "xmcustomermain1", "xmcustomermain2", "xmcustomermain3", "xmcustomermain4", "xmcustomermain5"]
    }]
};

Telly.PRIZES.xmas = [
    [{
        units: [
            { currentType: "xmresource", stage: 0 },
            { currentType: "xmhero", stage: 0 },
            { code: "worker", stage: 0 }
        ]
    }],

    [{
        units: [
            { currentType: "xmresource", stage: 1 },
            { currentType: "xmhero", stage: 1 },
            { code: "worker", stage: 1 },
            { code: "xmmagicplant", stage: 1 }
        ]
    }],

    [{
        units: [
            { currentType: "xmresource", stage: 2 },
            { currentType: "xmhero", stage: 2 },
            { code: "worker", stage: 2 },
            { code: "xmmagicplant", stage: 1 },
            { code: "crystal", stage: 1 }
        ]
    }]
];

CustomerRecipes.xmas = {
    xmproducer1a_0: [
        {
            ingredients: [{
                code: "xmproduct0a",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "xmproduct1a",
                stage: 0,
                amount: 1
            }]
        }, // 1
        {
            opened: true,
            ingredients: [{
                code: "xmproduct0b",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "xmproduct1a",
                stage: 0,
                amount: 1
            }]
        }, // 2
        {
            opened: true,
            ingredients: [{
                code: "xmproduct0a",
                stage: 0,
                amount: 1
            }, {
                code: "xmproduct0b",
                stage: 0,
                amount: 2
            }],
            order: [{
                code: "xmproduct1a",
                stage: 0,
                amount: 1
            }]
        }, // 3
        {
            opened: true,
            ingredients: [{
                code: "xmproduct0a",
                stage: 0,
                amount: 2
            }, {
                code: "xmproduct0b",
                stage: 0,
                amount: 1
            }],
            order: [{
                code: "xmproduct1a",
                stage: 0,
                amount: 1
            }]
        }, // 4
        {
            opened: true,
            ingredients: [{
                code: "xmproduct0a",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "xmproduct1b",
                stage: 0,
                amount: 1
            }]
        }, // 5
        {
            opened: true,
            ingredients: [{
                code: "xmproduct0b",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "xmproduct1b",
                stage: 0,
                amount: 1
            }]
        }, // 6
        {
            opened: true,
            ingredients: [{
                code: "xmproduct0a",
                stage: 0,
                amount: 1
            }, {
                code: "xmproduct0b",
                stage: 0,
                amount: 2
            }],
            order: [{
                code: "xmproduct1b",
                stage: 0,
                amount: 1
            }]
        }, // 7
        {
            opened: true,
            ingredients: [{
                code: "xmproduct0a",
                stage: 0,
                amount: 2
            }, {
                code: "xmproduct0b",
                stage: 0,
                amount: 1
            }],
            order: [{
                code: "xmproduct1b",
                stage: 0,
                amount: 1
            }]
        }// 8
    ],

    xmproducer2a_0: [
        {
            ingredients: [{
                code: "xmproduct1a",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "xmproduct2a",
                stage: 0,
                amount: 1
            }]
        }, // 1
        {
            ingredients: [{
                code: "xmproduct1b",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "xmproduct2a",
                stage: 0,
                amount: 1
            }]
        }, // 2
        {
            ingredients: [{
                code: "xmproduct1a",
                stage: 0,
                amount: 1
            }, {
                code: "xmproduct1b",
                stage: 0,
                amount: 2
            }],
            order: [{
                code: "xmproduct2a",
                stage: 0,
                amount: 1
            }]
        }, // 3
        {
            ingredients: [{
                code: "xmproduct1a",
                stage: 0,
                amount: 2
            }, {
                code: "xmproduct1b",
                stage: 0,
                amount: 1
            }],
            order: [{
                code: "xmproduct2a",
                stage: 0,
                amount: 1
            }]
        }// 4
    ],

    xmproducer2b_0: [
        {
            ingredients: [{
                code: "xmproduct1a",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "xmproduct2a",
                stage: 0,
                amount: 1
            }]
        }, // 1
        {
            ingredients: [{
                code: "xmproduct1b",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "xmproduct2a",
                stage: 0,
                amount: 1
            }]
        }, // 2
        {
            ingredients: [{
                code: "xmproduct1a",
                stage: 0,
                amount: 1
            }, {
                code: "xmproduct1b",
                stage: 0,
                amount: 2
            }],
            order: [{
                code: "xmproduct2a",
                stage: 0,
                amount: 1
            }]
        }, // 3
        {
            ingredients: [{
                code: "xmproduct1a",
                stage: 0,
                amount: 2
            }, {
                code: "xmproduct1b",
                stage: 0,
                amount: 1
            }],
            order: [{
                code: "xmproduct2a",
                stage: 0,
                amount: 1
            }]
        }// 4
    ],

    xmcustomerbridge1_0: [{
        fog: "fog9",
        ingredients: [{
            code: "xmproduct1a",
            stage: 0,
            amount: 3
        }],
        order: [{
            code: "xmgrowing",
            stage: 1,
            amount: 3
        }]
    }],

    xmcustomerbridge2_0: [{
        fog: "fog27",
        ingredients: [{
            code: "xmproduct1a",
            stage: 0,
            amount: 3
        }],
        order: [{
            code: "xmgrowing",
            stage: 1,
            amount: 3
        }]
    }],

    xmcustomerbridge3_0: [{
        fog: "fog38",
        ingredients: [{
            code: "xmproduct1a",
            stage: 0,
            amount: 3
        }],
        order: [{
            code: "xmgrowing",
            stage: 1,
            amount: 3
        }]
    }],

    xmcustomermain0_0: [{
        ingredients: [{
            code: "xmproduct2a",
            stage: 0,
            amount: 3
        }],
        order: [{
            code: "worker",
            stage: 2,
            amount: 1
        }, {
            code: "crystal",
            stage: 1,
            amount: 1
        }, {
            code: "xmmagicplant",
            stage: 1,
            amount: 1
        }]
    }],

    xmproducertrash_0: [{
        ingredients: [{
            code: "xmproduct2a",
            stage: 0,
            amount: 3
        }],
        order: [{
            code: "worker",
            stage: 1,
            amount: 1
        }, {
            code: "crystal",
            stage: 1,
            amount: 1
        }]
    }]
};

CustomerRecipes.xmas.xmproducer1b_0 = CustomerRecipes.xmas.xmproducer1a_0;
CustomerRecipes.xmas.xmcustomermain1_0 = CustomerRecipes.xmas.xmcustomermain0_0;
CustomerRecipes.xmas.xmcustomermain2_0 = CustomerRecipes.xmas.xmcustomermain0_0;
CustomerRecipes.xmas.xmcustomermain3_0 = CustomerRecipes.xmas.xmcustomermain0_0;
CustomerRecipes.xmas.xmcustomermain4_0 = CustomerRecipes.xmas.xmcustomermain0_0;
CustomerRecipes.xmas.xmcustomermain5_0 = CustomerRecipes.xmas.xmcustomermain0_0;

cleverapps.styles.xmas = {
    PixelView: {
        width: 90,
        height: 105,
        distanceDisappear: 1000,
        animation: {
            x: { align: "center" },
            y: { align: "center", dy: 0 }
        },
        collect: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 },
            delay: 150
        },
        timeScale: {
            base: 1,
            random: 0
        },
        speed: {
            x: 35,
            y: -18
        }
    },

    fogBlockerLock: {
        width: 100,
        height: 100,
        margin: 25,

        content: {
            width: 68,
            height: 68
        },
        position: {
            x: { align: "center" },
            y: { align: "center", dy: 50 }
        },
        text: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -28 }
        },
        icon: {
            x: { align: "center", dx: 5 },
            y: { align: "center", dy: -28 }
        },
        cloud: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -39 }
        },
        lock: {
            x: { align: "center" },
            y: { align: "center" }
        }
    }
};
