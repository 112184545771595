/**
 * Created by spepa on 27.10.2022
 */

var UnitGreeter = function () {
    this.fresh = [];
    this.greeters = [];
    cleverapps.eventBus.on("unitAvailable", this.onUnitAvailable.bind(this), this);
};

UnitGreeter.prototype.addGreeter = function (greeter) {
    if (greeter.isLifted()) {
        return;
    }
    this.greeters.push(greeter);
};

UnitGreeter.prototype.removeGreeter = function (greeter) {
    this.greeters = this.greeters.filter(function (unit) {
        return greeter !== unit;
    });
};

UnitGreeter.prototype.destructor = function () {
    runCleaners(this);
};

UnitGreeter.prototype.onUnitAvailable = function (unit) {
    if (unit instanceof Unit && unit.wantsToGreet()) {
        this.fresh.push(unit);
        Game.currentGame.counter.trigger();
    }

    for (var i = 0; i < this.greeters.length; i++) {
        if (this.greeters[i].wantsToGreet()) {
            var greeter = this.greeters.splice(i, 1)[0];
            this.fresh.push(greeter);
            Game.currentGame.counter.trigger();
            break;
        }
    }
};

UnitGreeter.prototype.processFresh = function () {
    if (!this.fresh.length || cleverapps.meta.isFocused() || Map2d.currentMap.dragging) {
        return;
    }

    var greeter = this.fresh.shift();

    cleverapps.meta.display({
        focus: "StartUnitGreeting",
        action: function (f) {
            greeter.makeGreetings(f);
        }
    });
};