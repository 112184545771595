/**
 * Created by r4zi4l on 09.02.2021
 */

var MergeAdviceMergeLogic = function (options) {
    options = options || {};

    MergeAdviceBaseLogic.call(this, options);

    this.minUnits = options.minUnits || 3;
    this.highlight = options.highlight;

    if (this.targets && this.targets.some(Unit.Equals.bind(Unit, { code: "crystal", stage: 3 }))) {
        this.useCrystal = true;
    }
};

MergeAdviceMergeLogic.prototype = Object.create(MergeAdviceBaseLogic.prototype);
MergeAdviceMergeLogic.prototype.constructor = MergeAdviceMergeLogic;

MergeAdviceMergeLogic.prototype.getShowHintTimeout = function () {
    return 2500;
};

MergeAdviceMergeLogic.prototype.findMove = function () {
    var map = Map2d.currentMap;
    var units = [];
    var crystals = [];

    this.listAvailableUnits().forEach(function (unit) {
        if (Unit.Equals(unit, { code: "crystal", stage: 3 })) {
            crystals.push(unit);
        } else {
            units.push(unit);
        }
    }, this);

    var center = map.getScreenCenterCell();
    var minUnits = this.minUnits;

    if (this.useCrystal) {
        if (!crystals.length) {
            return;
        }

        var crystal = crystals.sort(function (a, b) {
            return cc.getDistanceChebyshev(center, a) - cc.getDistanceChebyshev(center, b);
        })[0];
        minUnits -= 1;
    }

    var used = {};
    var clusters = [];

    units.forEach(function (unit) {
        if (used[Unit.GetPositionKey(unit)]) {
            return;
        }
        used[Unit.GetPositionKey(unit)] = true;

        var cells = map.bfs(unit.x, unit.y, map.compareMergeable.bind(map, unit));
        if (!cells.length) {
            return;
        }

        cells.forEach(function (cell) {
            used[Unit.GetPositionKey(cell)] = true;
        });

        var clusterCenter = cc.getCenterPoint(cells);
        var isScreenCluster = map.isScreenCellPosition(clusterCenter.x, clusterCenter.y);

        var centerScreenDistance = cc.getDistanceChebyshev(center, clusterCenter);

        clusters.push({
            code: unit.code,
            stage: unit.stage,
            full: Boolean(cells.length >= minUnits),
            weight: Boolean(isScreenCluster) * 1000 + cells.length + 1 / (centerScreenDistance + 1),
            cells: cells
        });
    });

    if (!clusters.length) {
        return;
    }

    clusters.sort(function (a, b) {
        return b.full - a.full || b.weight - a.weight;
    });

    var selected = {
        code: clusters[0].code,
        stage: clusters[0].stage,
        cells: clusters[0].cells
    };

    if (selected.cells.length < minUnits) {
        clusters = this.chooseBiggestClustersFamily(clusters);
        selected = {
            code: clusters[0].code,
            stage: clusters[0].stage,
            cells: clusters.slice(0, 10).reduce(function (cells, cluster) {
                return cells.concat(cluster.cells);
            }, [])
        };
    }

    if (selected.cells.length < minUnits) {
        return;
    }

    var cells = this.chooseTargets(selected.cells);
    if (cells && cells.length > 1) {
        selected.cells.sort(function (a) {
            return a === cells[1] ? -1 : 1;
        });

        if (this.highlight) {
            var highlight = clusters.reduce(function (result, cluster) {
                return Unit.Equals(selected, cluster) ? result.concat(cluster.cells) : result;
            }, []);

            return {
                highlight: highlight,
                cells: selected.cells.slice(0, 1)
            };
        }

        if (crystal) {
            cells.unshift(cc.p(crystal));
            selected.cells.unshift(cc.p(crystal));
        }

        return {
            mergeCluster: selected.cells,
            cells: cells
        };
    }
};

MergeAdviceMergeLogic.prototype.chooseBiggestClustersFamily = function (clusters) {
    var groups = {};

    clusters.forEach(function (cluster) {
        var key = Unit.GetKey(cluster);

        if (!groups[key]) {
            groups[key] = {
                code: cluster.code,
                stage: cluster.stage,
                amount: 0,
                clusters: []
            };
        }

        groups[key].amount += cluster.cells.length;
        groups[key].clusters.push(cluster);
    });

    var bestGroup;
    var bestAmount = 0;

    Object.values(groups).forEach(function (group) {
        if (group.amount > bestAmount) {
            bestGroup = group;
            bestAmount = group.amount;
        }
    });

    return bestGroup.clusters;
};

MergeAdviceMergeLogic.prototype.chooseTargets = function (cells) {
    var central = cc.p(0, 0);
    cells.forEach(function (cell) {
        central.x += cell.x;
        central.y += cell.y;
    });
    central.x /= cells.length;
    central.y /= cells.length;

    var centerClosest = cells[0];
    var centerFarthest = cells[0];
    var minDistance = cc.pDistance(central, centerClosest);
    var maxDistance = minDistance;

    for (var i = 1; i < cells.length; i++) {
        var distance = cc.pDistance(central, cells[i]);
        if (distance < minDistance) {
            minDistance = distance;
            centerClosest = cells[i];
        }
        if (distance > maxDistance) {
            maxDistance = distance;
            centerFarthest = cells[i];
        }
    }

    if (centerFarthest === centerClosest) {
        centerFarthest = cleverapps.Random.choose(cells.filter(function (cell) {
            return cell !== centerClosest;
        }));
    }

    return (centerFarthest && centerClosest) ? [centerFarthest, centerClosest] : undefined;
};