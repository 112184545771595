/**
 * Created by razial on 19.10.2021
 */

cleverapps.SkinManager.SKINS.dragonia_expedition = cleverapps.SkinManager.SKINS.dragonia2_expedition = cleverapps.SkinManager.SKINS.dragonia3_expedition = {
    localizations: {
        dragonia: {
            "Fog.clear.notEnoughWands": "Dragonia.Fog.clear.notEnoughWands",
            "ExpeditionPassRulesWindow.secondStage": "Dragonia.ExpeditionPassRulesWindow.secondStage",
            "Battle.notEnoughWands": "Dragonia.Battle.notEnoughWands"
        },

        dragonia2: {
            "Fog.clear.notEnoughWands": "Dragonia2.Fog.clear.notEnoughWands",
            "Battle.notEnoughWands": "Dragonia2.Battle.notEnoughWands"
        }
    },

    bundle: "expedition_dragonia",

    slots: Object.assign({}, cleverapps.SkinManager.SKINS.rose_energy.slots, {
        skinName: function () {
            return "dragonia";
        },

        localization: function (code) {
            var localizations = cleverapps.SkinManager.SKINS.dragonia_expedition.localizations;
            var expedition = cleverapps.travelBook.getCurrentPage().id;
            return localizations && localizations[expedition] && localizations[expedition][code];
        },

        workerSkin: function () {
            var expedition = cleverapps.travelBook.getCurrentPage().id;
            return cleverapps.Spine.hasSkin(expedition, bundles.mineable.jsons.worker_json) ? expedition : undefined;
        },

        missionTableRowIcon: function (options) {
            if (Mission.GetChildType(options.type) === Mission.TYPE_EXPEDITION_FEAST) {
                return bundles.tablerow_icons.frames.feast_leaf;
            }
        },

        gameAudio: function () {
            return bundles.expedition_dragonia.urls.dragonia_music;
        },

        unitIcon: function (unit) {
            return bundles.unit_icons_dragonia.frames["small_icon_family_" + unit.code];
        },

        unitOnCell: function () {
            return cleverapps.styles.UnitOnCellExpedition;
        },

        avatarFramesBundle: function () {
            return cleverapps.bundleLoader.isLoaded("snail_feast") ? "snail_feast" : undefined;
        },

        campaignQuestIcon: function (unit) {
            var frame;

            if (["dr2campaign", "dr2airship"].includes(unit.code)) {
                switch (unit.stage) {
                    case 0:
                        frame = bundles.merge.frames.hammer_icon_png;
                        break;
                    case 1:
                        frame = bundles.merge.frames.runway_icon_png;
                        break;
                    case 2:
                        frame = bundles.merge.frames.bucket_icon_png;
                        break;
                    case 3:
                        frame = bundles.merge.frames.engine_icon_png;
                        break;
                    case 4:
                        frame = bundles.merge.frames.oil_icon_png;
                        break;
                }
            }

            return frame && new cc.Sprite(frame);
        },

        gameSceneBg: function () {
            return {
                horizontalPattern: bundles.expedition_dragonia.urls.dragonia_bg,
                parallax: true
            };
        },

        feastWindowBatteries: function () {
            return [bundles.expedition_feast_dragonia.frames.battery1,
                bundles.expedition_feast_dragonia.frames.battery2,
                bundles.expedition_feast_dragonia.frames.battery3].filter(Boolean);
        },

        merge_wand_png: bundles.menubar.frames.dragonia_merge_wand_png,

        wand_icon_png: bundles.buttons_inlined_icons.frames.dragonia_wand_icon_png,
        feed_icon_png: bundles.buttons_inlined_icons.frames.dragonia_feed_icon_png,

        expedition_buildpass_icon_json: bundles.sidebar.jsons.dragonia_expedition_buildpass_icon_json,
        whirlwind: bundles.fog_animations.jsons.dragonia_whirlwind,

        expedition_feast_icon_json: bundles.sidebar.jsons.dragonia_expedition_feast_icon_json,
        snail_feast_icon_json: bundles.sidebar.jsons.dragonia_snail_feast_icon_json,
        snailhouse_offer_json: bundles.sidebar.jsons.dragonia_snailhouse_offer_json,

        expedition_feast_icon: bundles.reward_icons.frames.dragonia_expedition_feast_icon,
        snail_feast_icon: bundles.reward_icons.frames.dragonia_snail_feast_icon,
        reward_wand_png: bundles.reward_icons.frames.undersea_reward_wand_png,
        reward_wand_small_png: bundles.reward_icons.frames.dragonia_reward_wand_small_png,

        expeditionPassGuideBundle: function () {
            return bundles.dragonia_expeditionguidewindow;
        },

        expeditionFeastBundle: function () {
            return bundles.expedition_feast_dragonia;
        },

        rudolfGuideBundle: function () {
            return bundles.rudolfguidewindow_dragonia;
        },

        landmarkGuideOptions: function () {
            var page = cleverapps.travelBook.getCurrentPage();
            if (page.id === "dragonia3") {
                return {
                    name: "dragonia_product_guidewindow",
                    bundle: bundles.dragonia_product_guidewindow,
                    title: "LandmarkGuideWindow.title"
                };
            }
        }
    })
};
