/**
 * Created by vladislav on 18/05/2023
 */

var GameLevelConfig = {};

(function () {
    var GameLevelConfigData = {
        main: {
            calcThreshold: function () {
                var threshold = [20, 100, 150, 400, 550, 1500, 2500];
                var delta = 1400;
                for (var i = threshold.length; i < 100; i++) {
                    threshold.push(threshold[i - 1] + delta);
                    delta += 200;
                }

                return threshold;
            },
            calcReward: function (level) {
                switch (level) {
                    case 0:
                        return {
                            soft: 150,
                            rubies: 5
                        };

                    case 1:
                        return {
                            soft: 50,
                            rubies: 5,
                            energy: 25
                        };

                    case 2: case 3: case 4:
                        return {
                            soft: 50,
                            rubies: 10,
                            energy: 30
                        };

                    case 5: case 6: case 7:
                        return {
                            soft: 100,
                            rubies: 5,
                            energy: 50
                        };

                    default:
                        return {
                            soft: 150,
                            rubies: 10,
                            energy: 100
                        };
                }
            }
        },

        collections: {
            calcThreshold: function () {
                var threshold = [5, 20, 30, 90, 160];
                var delta = 10;
                for (var i = threshold.length; i < 21; i++) {
                    threshold.push(threshold[i - 1] + delta);
                    delta += 5;
                }
                return threshold;
            },

            calcReward: function (level) {
                if (level < 3) {
                    return {
                        soft: 50,
                        hard: 5,
                        wands: 5
                    };
                }
                if (level < 19) {
                    return {
                        soft: 100,
                        hard: 5,
                        wands: 5
                    };
                }

                return {
                    soft: 150,
                    hard: 5,
                    wands: 5
                };
            }
        }
    };

    for (var id in GameLevelConfigData) {
        GameLevelConfig[id] = {
            threshold: GameLevelConfigData[id].calcThreshold(),
            calcReward: GameLevelConfigData[id].calcReward
        };
    }
}());