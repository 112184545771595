/**
 * Created by ivan on 11.04.18.
 */

var ClansToolbarItem = function () {
    ToolbarItem.call(this, ToolbarItem.CLANS);

    this.enable();
};

ClansToolbarItem.prototype = Object.create(ToolbarItem.prototype);
ClansToolbarItem.prototype.constructor = ClansToolbarItem;

ClansToolbarItem.prototype.resetState = function () {
    this.hasReq = cleverapps.clanMessages.needsAttention();
    if (this.hasReq) {
        this.mark();
    } else {
        this.unmark();
    }
};

ClansToolbarItem.prototype.isVisible = function () {
    return !cleverapps.config.editorMode && Clans.IsAvailable() && cleverapps.environment.isMainScene() && !cleverapps.travelBook.isExpedition();
};

ClansToolbarItem.prototype.onClick = function () {
    var noConnection = false;

    cleverapps.meta.display({
        focus: "ClansIconClicked",
        control: ["MenuBarLivesItem", "MenuBarGoldItem", "MenuBarCoinsItem"],
        actions: [
            function (f) {
                f = cleverapps.wait(2, f);

                cleverapps.userClan.load(f, function () {
                    noConnection = true;
                    f();
                });

                if (cleverapps.userClan.id !== undefined) {
                    f();
                } else {
                    cleverapps.clans.search(undefined, f);
                }
            },

            function (f) {
                if (noConnection) {
                    new NoConnectionWindow();
                } else if (cleverapps.userClan.id !== undefined) {
                    new MyClanWindow({ hasReq: this.hasReq });
                } else {
                    new ClansWindow();
                }

                cleverapps.meta.onceNoWindowsListener = f;
            }
        ]
    });
};