/**
 * Created by spepa on 03.03.2023
 */

cleverapps.SkinManager.SKINS.easter_expedition = {
    localizations: {
        easter: {
            "Quest.build.title": "Easter.Quest.build.title"
        }
    },

    bundle: "expedition_easter",

    slots: Object.assign({}, cleverapps.SkinManager.SKINS.rose_energy.slots, {
        skinName: function () {
            return "easter";
        },

        localization: function (code) {
            var localizations = cleverapps.SkinManager.SKINS.easter_expedition.localizations;
            var expedition = cleverapps.travelBook.getCurrentPage().id;
            return localizations && localizations[expedition] && localizations[expedition][code];
        },

        unitIcon: function (unit) {
            return bundles.unit_icons_easter.frames["small_icon_family_" + unit.code];
        },

        workerCutTime: function () {
            return 500;
        },

        workerMiningAnimationStyles: function () {
            return cleverapps.styles.easter.miningAnimation;
        },

        workerSound: function () {
            return bundles.merge_easter.urls.worker_sound;
        },

        gameAudio: function () {
            return bundles.expedition_easter.urls.easter_music;
        },

        gameSceneBg: function () {
            return {
                movableAnimation: bundles.expedition_easter.jsons.easter_bg,
                parallax: {
                    hideHorizon: true
                }
            };
        },

        outOfBoundaryScale: function () {
            return 0;
        },

        pixelView: function () {
            return cleverapps.styles.easter.PixelView;
        },

        fogBlocker: function () {
            return cleverapps.styles.easter.fogBlockerLock;
        },

        worker_json: function () {
            return bundles.merge_easter.jsons.worker_json;
        },

        whirlwind: bundles.fog_animations.jsons.easter_whirlwind,

        expedition_buildpass_icon_json: bundles.sidebar.jsons.easter_expedition_buildpass_icon_json
    })
};
