/**
 * Created by andrey on 22.01.2021.
 */

var MergeAdviceView = function (advice, scene) {
    this.advice = advice;
    this.mapView = scene.map;

    this.advice.on("showHint", this.showHint.bind(this), scene);
    this.advice.on("hideHint", this.hideHint.bind(this), scene);
    this.advice.on("stopArrowHint", this.removeArrow.bind(this), scene);
};

MergeAdviceView.prototype.findAdviceTarget = function (parent, targets) {
    targets = cleverapps.toArray(targets);

    var children = cleverapps.toArray(parent);
    while (children.length > 0) {
        var node = children.shift();

        if (node.adviceTarget && targets.some(function (target) {
            return target === node.adviceTarget;
        })) {
            return node;
        }

        children = children.concat(node.children);

        if (node.additionalViews) {
            Object.keys(node.additionalViews).forEach(function (type) {
                children.push(node.additionalViews[type]);
            });
        }
    }
};

MergeAdviceView.prototype.showHint = function (move) {
    var node;

    if (move.window) {
        var window = cleverapps.windows.currentWindow();
        if (window) {
            node = this.findAdviceTarget(window, move.window);
            if (!node) {
                return;
            }
        }
    }

    if (move.fogView) {
        var fog = Map2d.currentMap.getFog(move.cells[0].x, move.cells[0].y);
        var fogView = fog && fog.fogBlock.onGetView();
        if (fogView) {
            node = this.findAdviceTarget(fogView, move.fogView);
        }
    }

    if (move.unitView) {
        var units = this.mapView.listTiles(Map2d.LAYER_UNITS, move.cells[0].x, move.cells[0].y);
        if (units) {
            node = this.findAdviceTarget(units, move.unitView);
        }
    }

    if (node) {
        this.removeArrow();

        if (move.resources) {
            this.showResourceHint(node, move.resources);
        } else {
            this.showButtonHint(node);
        }
        return;
    }

    if (move.highlight) {
        this.showHighlight(move);
    } else if (move.mergeCluster) {
        this.showMergeHint(move);
    } else if (move.cells) {
        this.showCellHint(move.cells.slice(0, 1));

        this.createArrow(move.cells);
    }
};

MergeAdviceView.prototype.showResourceHint = function (node, cells) {
    this.hintFinger = FingerView.hintDrag([node].concat(cells), {
        runOnce: true,
        payload: node.createAdviceIcon && node.createAdviceIcon()
    });
};

MergeAdviceView.prototype.showButtonHint = function (node) {
    this.hintFinger = FingerView.hintClick(node);
};

MergeAdviceView.prototype.showHighlight = function (move) {
    if (!this.highlightUnits) {
        this.highlightUnits = move.highlight.map(function (cell) {
            return Map2d.currentMap.getUnit(cell.x, cell.y);
        }).filter(function (unit) {
            if (unit) {
                unit.highlight();
                return true;
            }
            return false;
        });
    }

    this.showCellHint(move.cells.slice(0, 2));
};

MergeAdviceView.prototype.showMergeHint = function (move) {
    if (!this.mergeHintUnit) {
        var initiator = move.mergeCluster[0];
        var unit = this.mergeHintUnit = Map2d.currentMap.getUnit(initiator.x, initiator.y);
        unit.highlightMerge([move.mergeCluster], { noMessage: true });
    }

    if (!this.advice.inactiveHintRunning) {
        this.showCellHint(move.cells.slice(0, 2));

        this.createArrow(move.cells);
    }
};

MergeAdviceView.prototype.showCellHint = function (cells) {
    this.hintFinger = FingerView.hintDrag(cells, {
        runOnce: true
    });
};

MergeAdviceView.prototype.hideHint = function () {
    FingerView.remove(this.hintFinger);
    this.hintFinger = undefined;

    if (this.mergeHintUnit) {
        this.mergeHintUnit.unhighlightMerge();
        delete this.mergeHintUnit;
    }

    if (this.highlightUnits) {
        this.highlightUnits.forEach(function (unit) {
            unit.unhighlight();
        });
        delete this.highlightUnits;
    }

    this.removeArrow();
};

MergeAdviceView.prototype.createArrow = function (cells) {
    if (this.arrow) {
        return;
    }

    var styles = cleverapps.styles.MergeAdviceView.straightArrow;

    var unit = Map2d.currentMap.getUnit(cells[0].x, cells[0].y);
    var unitView = unit && unit.onGetView();
    if (unit && unit.prizes || unitView && unitView.getAdditionalView("ingredients")) {
        styles = cleverapps.styles.MergeAdviceView.skewArrow;
    }

    var arrow = this.arrow = new cc.Sprite(bundles.merge_animations.frames.tutorial_arrow);
    this.mapView.animations.addChild(arrow);

    var pos = this.mapView.getUnitCenterPos(cells[0].x, cells[0].y);
    arrow.setPosition(pos.x + styles.offsetX, pos.y + styles.offsetY);
    arrow.setRotation(styles.rotation);

    arrow.runAction(new cc.RepeatForever(
        new cc.Sequence(
            new cc.MoveBy(0.5, -styles.deltaX, styles.deltaY).easing(cc.easeInOut(1.7)),
            new cc.MoveBy(0.5, styles.deltaX, -styles.deltaY).easing(cc.easeInOut(1.7))
        )
    ));
};

MergeAdviceView.prototype.removeArrow = function (silent) {
    if (this.arrow) {
        if (silent) {
            this.arrow.removeFromParent();
            this.arrow = undefined;
        } else {
            this.arrow.runAction(new cc.Sequence(
                new cc.FadeOut(0.2),
                new cc.CallFunc(function () {
                    this.arrow = undefined;
                }.bind(this)),
                new cc.RemoveSelf()
            ));
        }
    }
};

cleverapps.styles.MergeAdviceView = {
    straightArrow: {
        rotation: 0,
        offsetX: -5,
        offsetY: 120,
        deltaX: 0,
        deltaY: 60
    },

    skewArrow: {
        rotation: -60,
        offsetX: -110,
        offsetY: 70,
        deltaX: 50,
        deltaY: 29
    }
};
