/**
 * Created by razial on 19.10.2021
 */

cleverapps.SkinManager.SKINS.halloween_expedition = {
    localizations: {
        halloween: {
            "FeastMissionRulesWindow.ExpeditionFeast.title": "Halloween.FeastMissionRulesWindow.ExpeditionFeast.title",
            "ExpeditionFeastWindow.title": "Halloween.ExpeditionFeastWindow.title",
            "FeastMissionRulesWindow.ExpeditionFeast.firstStage": "Halloween.FeastMissionRulesWindow.ExpeditionFeast.firstStage",
            "ExpeditionFeastWindow.tooltip.amount": "Halloween.ExpeditionFeastWindow.tooltip.amount",
            "Offer.IconForce": "Halloween.Offer.IconForce",
            "ExpeditionPassRulesWindow.title": "Halloween.ExpeditionPassRulesWindow.title",
            "ExpeditionPassRulesWindow.secondStage": "Halloween.ExpeditionPassRulesWindow.secondStage",
            "OpensFog.infoTitle": "Halloween.OpensFog.infoTitle",
            "OpenChestWindow.question": "Halloween.OpenChestWindow.question",
            "Fog.clear.notEnoughWands": "Halloween.Fog.clear.notEnoughWands",
            "FeastMissionRulesWindow.ExpeditionFeast.description": "Halloween.FeastMissionRulesWindow.ExpeditionFeast.description",
            "ExpeditionWandsGuideWindow.title": "Halloween.ExpeditionWandsGuideWindow.title",
            "ExpeditionWandsGuideWindow.firstStage": "Halloween.ExpeditionWandsGuideWindow.firstStage",
            "ExpeditionWandsGuideWindow.secondStage": "Halloween.ExpeditionWandsGuideWindow.secondStage",
            "ExpeditionWandsGuideWindow.thirdStage": "Halloween.ExpeditionWandsGuideWindow.thirdStage",
            "LocalPushes.PUMPKIN_RIPE": "Halloween.LocalPushes.PUMPKIN_RIPE",
            "Battle.notEnoughWands": "Halloween.Battle.notEnoughWands"
        }
    },

    bundle: "expedition_halloween",

    slots: Object.assign({}, cleverapps.SkinManager.SKINS.rose_energy.slots, {
        skinName: function () {
            return "halloween";
        },

        localization: function (code) {
            var localizations = cleverapps.SkinManager.SKINS.halloween_expedition.localizations;
            var expedition = cleverapps.travelBook.getCurrentPage().id;
            return localizations && localizations[expedition] && localizations[expedition][code];
        },

        water: function () {
            var tilesTexture = Map2d.currentMap && Map2d.currentMap.tilesTexture;
            return bundles[tilesTexture] && bundles[tilesTexture].frames.dark_water;
        },

        workerSound: function () {
            return bundles.merge_halloween.urls.worker_sound;
        },

        pixelView: function () {
            return cleverapps.styles.halloween.PixelView;
        },

        gameAudio: function () {
            return bundles.expedition_halloween.urls.halloween_music;
        },

        bonusWorkerWindowSkin: function () {
            return "halloween";
        },

        worker_json: function () {
            return bundles.merge_halloween.jsons.worker_json;
        },

        workerSkin: function () {
            var json = cleverapps.skins.getSlot("worker_json") || bundles.mineable.jsons.worker_json;
            return cleverapps.Spine.hasSkin("halloween", json) ? "halloween" : undefined;
        },

        missionTableRowIcon: function (options) {
            if (Mission.GetChildType(options.type) === Mission.TYPE_EXPEDITION_FEAST) {
                return bundles.tablerow_icons.frames.feast_skeleton;
            }
        },

        bonusWorkerPersonSkin: function () {
            if (cleverapps.config.name === "mergecraft") {
                return "halloween";
            }
        },

        windowDecors: function (options) {
            return cleverapps.SkinManager.SKINS.halloween.slots.windowDecors(options);
        },
    
        menuBarItem: function () {
            return cleverapps.styles.SceneDecors.halloween_menubar;
        },

        menuBarLevelItem: function () {
            return cleverapps.styles.SceneDecors.halloween_menubar_level;
        },

        merge_wand_png: bundles.menubar.frames.halloween_merge_wand_png,

        merge_worker_png: bundles.menubar.frames.halloween_merge_worker_png,

        worker_icon_png: bundles.buttons_inlined_icons.frames.halloween_worker_icon_png,
        wand_icon_png: bundles.buttons_inlined_icons.frames.halloween_wand_icon_png,

        expedition_buildpass_icon_json: bundles.sidebar.jsons.halloween_expedition_buildpass_icon_json,
        expedition_feast_icon_json: bundles.sidebar.jsons.halloween_expedition_feast_icon_json,

        expedition_feast_icon: bundles.reward_icons.frames.halloween_expedition_feast_icon,
        reward_worker_png: bundles.reward_icons.frames.halloween_reward_worker_png,
        reward_wand_png: bundles.reward_icons.frames.halloween_reward_wand_png,
        reward_wand_small_png: bundles.reward_icons.frames.halloween_reward_wand_small_png,

        feed_icon_png: bundles.buttons_inlined_icons.frames.halloween_feed_icon_png,

        expeditionPassGuideBundle: function () {
            return bundles.halloween_expeditionguidewindow;
        },

        expeditionFeastBundle: function () {
            return bundles.expedition_feast_halloween;
        }
    })
};
