/**
 * Created by olga on 26.10.2022
 */

var FeedableHintContent = function (options) {
    this.fruitCode = options.fruitCode;
    this.unit = options.unit;

    this.doNotBlock = {
        x: this.unit.x,
        y: this.unit.y
    };
};

FeedableHintContent.isApplicable = function (options) {
    return options.fruitCode !== undefined;
};

FeedableHintContent.prototype.hasSameContent = function (options) {
    return this.fruitCode === options.fruitCode && Unit.Equals(this.unit, options.unit);
};

FeedableHintContent.prototype.getMessages = function () {
    return [
        { text: "Feedable.hint1" },
        { icon: UnitView.getUnitImage({ code: this.fruitCode, stage: 4 }, { preferStatic: true }) },
        { text: "Feedable.hint2" }
    ];
};