/**
 * Created by mac on 12/21/20
 */

var FamiliesHelper = {
    ShapeSquare: [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 0, y: 1 },
        { x: 1, y: 1 }
    ],

    ShapeCell: [
        { x: 0, y: 0 }
    ],

    ShapeTopRightCorner: [
        { x: 0, y: 0 },
        { x: -1, y: 0 },
        { x: 0, y: -1 }
    ],

    ShapeXArc: [
        { y: 0, x: 0 },
        { y: 0, x: -1, passable: true },
        { y: 0, x: -2 },
        { y: -1, x: 0 },
        { y: -1, x: -1, passable: true },
        { y: -1, x: -2 }
    ],

    Shape3x3: [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 0, y: 1 },
        { x: 1, y: 1 },
        { x: 2, y: 1 },
        { x: 0, y: 2 },
        { x: 1, y: 2 },
        { x: 2, y: 2 }
    ],

    Shape1x6: [
        { y: 0, x: 0, passable: true },
        { y: 1, x: 0, passable: true },
        { y: 2, x: 0, passable: true },
        { y: 3, x: 0, passable: true },
        { y: 4, x: 0, passable: true },
        { y: 5, x: 0, passable: true }
    ],

    Drop: {
        Default: 0,
        Parallel: 1,
        Open: 2
    },

    ResourceFamily: function (total, code, options) {
        var expPrizes = [0, 1, 2, 3, 5, 10, 15, 25, 150, 300, 500, 1000, 3000, 5000, 8000];
        var softPrizes = [0, 0, 1, 2, 3, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50];
        var deletedStage = undefined;
        var i;
        options = options || {};
        if (options.deletedStage !== undefined) {
            deletedStage = options.deletedStage;
        }
        var collectedStage;
        var createPrizes = [];
        for (i = 0; i < total; i++) {
            if (i === total - 1) {
                createPrizes.push({
                    exp: createPrizes[i - 1].exp * 10,
                    soft: 75
                });
            } else {
                var prize = undefined;
                if (expPrizes[i] || softPrizes[i]) {
                    prize = {};
                    if (expPrizes[i]) {
                        prize.exp = expPrizes[i];
                    }
                    if (softPrizes[i]) {
                        prize.soft = softPrizes[i];
                    }
                }
                createPrizes.push(prize);
            }
        }

        var buildable = ["0 seconds", "0 seconds", "40 seconds", "1 minute", "5 minute", "15 minute", "2 hour", "4 hour", "8 hour", "12 hour", "14 hour", "16 hour", "18 hour", "20 hour", "22 hour"];
        if (code === "wood") {
            buildable.splice(2, 0, "15 seconds");
        }

        if (deletedStage !== undefined) {
            for (i = buildable.length - 1; i > deletedStage; i--) {
                buildable[i] = buildable[i - 1];
            }
        }

        buildable.splice(total - 1);
        while (buildable.length < total - 1) {
            buildable.push(buildable[buildable.length - 1]);
        }

        var units = buildable.map(function (value, stage) {
            var res = {
                buildable: value,
                createPrize: createPrizes[stage],
                price: (options.basicPrice || 13) * Math.pow(3, stage)
            };

            if (stage === buildable.length - 1) {
                res.unmergeable = true;
                res.resourceCollectible = true;
                res.pulsing = true;
                res.draggable = true;
                collectedStage = stage;
            }
            return res;
        });

        if (deletedStage !== undefined) {
            if (deletedStage === units.length - 1) {
                var newLast = units.length - 2;
                units[newLast].unmergeable = true;
                units[newLast].resourceCollectible = true;
                units[newLast].pulsing = true;
                units[newLast].draggable = true;
                collectedStage = newLast;
            }
            units[deletedStage].deleted = true;
        }

        units.push({
            lazy: options.lazy,
            price: (options.basicPrice || 13) * Math.pow(3, 8),
            unmergeable: true,
            temporary: true,
            multicell: FamiliesHelper.ShapeSquare,
            important: true,
            movable: false,
            pointOfInterest: true,
            upgradable: {
                stages: 5,
                level: 0,
                collectedStage: collectedStage,
                moveToNextStage: true
            },
            unsuitableForGift: true
        });

        units.push({
            lazy: options.lazy,
            price: (options.basicPrice || 13) * Math.pow(3, 9),
            unmergeable: true,
            multicell: FamiliesHelper.ShapeSquare,
            upgradable: {
                stages: 5,
                stagesByLevel: 2,
                level: 1,
                collectedStage: collectedStage
            },
            fruit: {
                duration: "24 hour",
                exp: 50,
                prize: [{
                    amount: [5, 8],
                    units: [
                        { code: "sacks", stage: 0 }, { code: "sacks", stage: 1 }, { code: "worker", stage: 0 },
                        { code: "worker", stage: 1 }, { code: code, stage: 0 }, { code: "wands", stage: 0 },
                        { code: "coins", stage: 0 }, { code: "coins", stage: 1 }, { type: "seasonitem", stage: 0 }
                    ]
                }],
                localPush: cleverapps.LocalPushes.TYPES.CASTLE_PRIZE
            },
            important: true,
            buildable: "24 hour",
            createPrize: createPrizes[total - 1],
            unsuitableForGift: true
        });

        return {
            type: "resource",
            expeditions: options && options.expeditions,
            images: options && options.images,
            units: units
        };
    },

    FruitFamily: function (options) {
        var mining = {
            time: "15 seconds",
            exp: 5,
            energy: 5
        };

        return {
            type: "fruit",
            expeditions: options && options.expeditions,
            images: options && options.images,
            units: [
                { price: 1 },
                { price: 4, createPrize: { exp: 1, soft: 1 } },
                { price: 12, createPrize: { exp: 2, soft: 2 } },
                {
                    price: 36,
                    fruit: {
                        duration: options.duration,
                        prize: [{
                            amount: [4, 6],
                            units: [{ code: options.code, stage: 4 }]
                        }],
                        localPush: cleverapps.LocalPushes.TYPES.FRUIT_RIPE
                    },
                    breakable: { nextUnit: { code: options.code, stage: 5 } },
                    unmergeable: true,
                    createPrize: { exp: 3, soft: 3 }
                },
                {
                    price: 3,
                    pulsing: true,
                    collectible: {
                        harvested: true
                    },
                    value: 1,
                    unmergeable: true
                },
                { price: 5, mineable: [mining] }
            ]
        };
    },

    FeedFruitFamily: function (options) {
        var mining = {
            time: options.time || "15 seconds",
            exp: 5,
            energy: 5
        };

        return {
            type: options.type,
            expeditions: options && options.expeditions,
            images: options && options.images,
            skins: options.skins,
            units: [
                {},
                { createPrize: { exp: 1, soft: 1 } },
                { createPrize: { exp: 2, soft: 2 } },
                {
                    fruit: {
                        duration: options.duration,
                        prize: options.prize,
                        localPush: cleverapps.LocalPushes.TYPES.FRUIT_RIPE
                    },
                    breakable: { nextUnit: { code: options.code, stage: 5 } },
                    unmergeable: true,
                    createPrize: { exp: 3, soft: 3 }
                },
                {
                    collectible: true,
                    feedableTarget: true,
                    value: 1,
                    unmergeable: true,
                    pulsing: true,
                    draggable: true
                },
                { mineable: [mining], mined: true }
            ]
        };
    },

    CustomFamily: function (total, type, options) {
        options = options || {};

        var createExp = [0, 5, 10, 15, 25];
        var units = [];
        for (var i = 0; i < total; i++) {
            var res = {};

            if (createExp[i]) {
                res.createPrize = {
                    exp: createExp[i]
                };
            }

            res.price = options.price[i];

            if (options.shine && i > 0) {
                res.shine = true;
            }

            if (options.draggable) {
                res.draggable = true;
            }

            if (options.instantWorker && i === total - 1) {
                res.draggable = true;
                res.instantWorker = true;
            }

            if (options.info) {
                res.info = options.info;
            }

            units.push(res);
        }

        return {
            type: type,
            expeditions: options.expeditions,
            extraBundles: options.extraBundles,
            images: options.images,
            units: units
        };
    },

    ChestFamily: function (resource, options) {
        options = options || {};

        var prizes = ChestListPrize[resource];
        var units = [];
        var price = options.price ? options.price : undefined;

        if (options.resourceChest) {
            price = [3, 35, 52];
        }

        for (var i = 0; i < prizes.length; i++) {
            var unit = {
                price: price ? price[i] : undefined,
                chest: true,
                lockable: true,
                animation: options.animation === undefined ? true : options.animation,
                resourceChest: options.resourceChest,
                skipCheckScreen: options.skipCheckScreen,
                radius: options.radius
            };

            if (ChestCreatePrize[resource]) {
                unit.createPrize = ChestCreatePrize[resource][i];
            } else if (options.resourceChest) {
                unit.createPrize = ChestCreatePrize.resourcechest[i];
            }

            if (ChestEnergyPrize[resource]) {
                unit.energyPrize = ChestEnergyPrize[resource][i];
            } else if (options.resourceChest) {
                unit.energyPrize = ChestEnergyPrize.resourcechest[i];
            }
            units.push(unit);
        }

        return {
            type: "chest",
            resource: resource,
            expeditions: options.expeditions,
            images: options.images,
            extraBundles: options.extraBundles,
            units: units
        };
    },

    ValuableFamily: function (id, options) {
        options = options || {};
        return {
            type: "valuables",
            id: id,
            expeditions: options.expeditions,
            images: options.images,
            skins: options.skins,
            extraBundles: options.extraBundles,
            units: options.amounts.map(function (value, stage) {
                var res = {
                    value: value,
                    price: value
                };

                if (options.exp && options.exp[stage]) {
                    res.createPrize = { exp: options.exp[stage] };
                }

                if (options.collectible && value !== 0) {
                    res.collectible = true;
                }

                if (options.pulsing) {
                    res.pulsing = true;
                }

                if (options.unmergeable) {
                    res.unmergeable = true;
                }

                if (options.shineFrom && stage >= options.shineFrom) {
                    res.shine = true;
                }

                return res;
            })
        };
    },

    GeneratorFamily: function (resource) {
        var level1 = [], level2 = [], level3 = [];
        var j, mineStages;

        mineStages = 7;
        for (j = 0; j < mineStages; j++) {
            level1.push({
                time: j === mineStages - 1 ? "3 hours" : "0 seconds",
                exp: j === mineStages - 1 ? 50 : 15,
                energy: j === mineStages - 1 ? 30 : 5
            });
        }

        mineStages = 6;
        for (j = 0; j < mineStages; j++) {
            level2.push({
                time: j === mineStages - 1 ? "3 hours" : "0 seconds",
                exp: j === mineStages - 1 ? 100 : 30,
                energy: j === mineStages - 1 ? 40 : 10
            });
        }

        mineStages = 5;
        for (j = 0; j < mineStages; j++) {
            level3.push({
                time: j === mineStages - 1 ? "3 hours" : "0 seconds",
                exp: j === mineStages - 1 ? 150 : 50,
                energy: j === mineStages - 1 ? 50 : 15
            });
        }

        return {
            type: "generator",
            resource: resource,
            units: [
                {
                    price: 1000, unmergeable: true, mineable: level1, multicell: FamiliesHelper.ShapeSquare, generator: { levels: 3 }, important: true, pointOfInterest: true
                },
                {
                    price: 3000, unmergeable: true, mineable: level2, multicell: FamiliesHelper.ShapeSquare, generator: { levels: 5 }, important: true
                },
                {
                    price: 5000, unmergeable: true, mineable: level3, multicell: FamiliesHelper.ShapeSquare, generator: {}, important: true
                }
            ]
        };
    },

    MonsterFamily: function (monster, options) {
        var exp = [3, 5, 10, 15, 20, 25];
        var wands = [5, 10, 15, 20, 25, 30];
        var bonusWands = wands.map(function (val) {
            return val * 5;
        });

        var attackStages = [2, 4, 6];
        var levels = [];

        for (var stage = 0; stage < 3; stage++) {
            var attacks = attackStages[stage];
            var level = [];
            for (var i = 0; i < attacks; i++) {
                level.push({
                    exp: exp[i],
                    wands: wands[i],
                    bonusWands: bonusWands[i]
                });
            }
            levels[stage] = level;
        }

        return {
            type: "monster",
            units: [
                {
                    unmergeable: true, mineable: levels[0], movable: false, monster: monster, missionType: options.mission, manualPrizes: true
                },
                {
                    unmergeable: true, mineable: levels[1], movable: false, monster: monster, missionType: options.mission, manualPrizes: true
                },
                {
                    unmergeable: true, mineable: levels[2], movable: false, monster: monster, multicell: FamiliesHelper.ShapeSquare, missionType: options.mission, manualPrizes: true
                }
            ]
        };
    },

    SourceFamily: function (resource, addEnergy) {
        var mining = {
            stump: [
                {
                    time: "0 second",
                    exp: 5,
                    energy: 3 + addEnergy
                }, {
                    time: "30 seconds",
                    exp: 20,
                    energy: 5 + addEnergy
                }
            ],
            sapling: [
                {
                    time: "0 second",
                    exp: 5,
                    energy: 3 + addEnergy
                }, {
                    time: "0 second",
                    exp: 20,
                    energy: 5 + addEnergy
                }, {
                    time: "0 second",
                    exp: 25,
                    energy: 10 + addEnergy
                }, {
                    time: "45 seconds",
                    exp: 30,
                    energy: 15 + addEnergy
                }
            ],
            tree: [
                {
                    time: "0 second",
                    exp: 5,
                    energy: 5 + addEnergy
                }, {
                    time: "0 second",
                    exp: 20,
                    energy: 10 + addEnergy
                }, {
                    time: "0 second",
                    exp: 25,
                    energy: 15 + addEnergy
                }, {
                    time: "0 second",
                    exp: 30,
                    energy: 20 + addEnergy
                }, {
                    time: "0 second",
                    exp: 35,
                    energy: 25 + addEnergy
                }, {
                    time: "1 minute",
                    exp: 40,
                    energy: 30 + addEnergy
                }
            ]
        };

        return {
            type: "source",
            resource: resource,
            units: [
                { source: true, price: 7 },
                {
                    source: true, price: 21, mineable: mining.stump, unmergeable: true, createPrize: { exp: 1, soft: 1 }
                },
                { source: true, price: 63 },
                {
                    source: true, price: 189, mineable: mining.sapling, unmergeable: true, createPrize: { exp: 5, soft: 2 }
                },
                { source: true, price: 567 },
                {
                    source: true, price: 1701, mineable: mining.tree, unmergeable: true, createPrize: { exp: 10, soft: 5 }
                }
            ]
        };
    },

    HeroFamily: function (total, options) {
        var createPrizes = [undefined, { exp: 1, soft: 1 }, { exp: 2, soft: 1 }, { exp: 3, soft: 1 }, { exp: 5, soft: 2 },
            { exp: 10, soft: 3 }, { exp: 25, soft: 5 }, { exp: 40, soft: 5 }, { exp: 50, soft: 5 }, { exp: 70, soft: 7 },
            { exp: 100, soft: 7 }, { exp: 120, soft: 8 }, { exp: 150, soft: 10 }];
        var price = [9, 25];
        var units = cleverapps.arrayFill(total).map(function (value, index) {
            return {
                price: price[index],
                heroitem: true,
                makesorder: index === total - 1,
                unmergeable: index === total - 1,
                unsuitableForGift: index === total - 1
            };
        });

        if (options && options.deletedStage !== undefined) {
            units[options.deletedStage].deleted = true;
        }

        for (var i = 0; i < units.length - 1; i++) {
            units[i].createPrize = createPrizes[i];
        }

        return {
            type: "hero",
            units: units
        };
    },

    MagicPlantFamily: function (options) {
        var expPrizes = [0, 1, 2, 3, 5, 10, 15, 25, 150];
        var softPrizes = [0, 0, 1, 2, 3, 5, 10, 15, 20];

        var units = [];

        for (var i = 0; i < options.total; ++i) {
            var res = {};

            res.price = Math.pow(3, i);
            if (expPrizes[i] || softPrizes[i]) {
                res.createPrize = {
                    exp: expPrizes[i],
                    soft: softPrizes[i]
                };
            }

            if (i === options.total - 1) {
                res.fruit = {
                    duration: options.timeout,
                    exp: 10,
                    prize: options.prize,
                    localPush: cleverapps.LocalPushes.TYPES.MAGIC_PLANT_READY
                };
                res.breakable = {
                    limit: 10,
                    nextUnit: { code: options.code, stage: 9 }
                };

                res.unmergeable = true;
            }

            if (i > 4) {
                res.shine = true;
            }

            units.push(res);
        }
        units.push({
            mineable: [{
                time: "15 seconds",
                exp: 5,
                energy: 5
            }]
        });

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions,
            images: options.images,
            extraBundles: options && options.extraBundles
        };
    },

    ExpeditionMagicPlantFamily: function (options) {
        var expPrizes = [0, 1, 2, 3, 5, 10, 15, 25, 150];
        var softPrizes = [0, 0, 1, 2, 3, 5, 10, 15, 20];

        var units = [];

        for (var i = 0; i < options.total; ++i) {
            var res = {};

            res.price = Math.pow(3, i);
            if (expPrizes[i] || softPrizes[i]) {
                res.createPrize = {
                    exp: expPrizes[i],
                    soft: softPrizes[i]
                };
            }

            if (i === options.total - 1) {
                res.fruit = {
                    duration: options.timeout,
                    exp: 10,
                    prize: options.prize,
                    localPush: cleverapps.LocalPushes.TYPES.MAGIC_PLANT_READY
                };

                res.unmergeable = true;
            }

            if (i > 4) {
                res.shine = true;
            }

            units.push(res);
        }

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions,
            images: options.images,
            extraBundles: options && options.extraBundles
        };
    },

    RubiesPlantFamily: function (total, options) {
        var expPrizes = [0, 50, 200];
        var softPrizes = [0, 10, 30];

        var units = [];

        for (var i = 0; i < total; ++i) {
            var res = {
                fruit: {
                    duration: "6 hours",
                    exp: 10,
                    prize: [{
                        units: [{ code: "rubies", stage: i }]
                    }],
                    localPush: cleverapps.LocalPushes.TYPES.MAGIC_PLANT_READY
                },
                shine: true
            };

            if (expPrizes[i] || softPrizes[i]) {
                res.createPrize = {
                    exp: expPrizes[i],
                    soft: softPrizes[i]
                };
            }

            units.push(res);
        }

        return {
            type: "rubiesplant",
            units: units,
            expeditions: options && options.expeditions,
            images: options && options.images,
            extraBundles: options && options.extraBundles
        };
    },

    CoinsPlantFamily: function (total, options) {
        var expPrizes = [0, 50, 200];
        var softPrizes = [0, 10, 30];

        var units = [];

        for (var i = 0; i < total; ++i) {
            var res = {
                price: 100 * Math.pow(3, i),
                fruit: {
                    duration: "6 hours",
                    exp: 10,
                    prize: [{
                        units: [{ code: "coins", stage: i + 1 }]
                    }],
                    localPush: cleverapps.LocalPushes.TYPES.MAGIC_PLANT_READY
                },

                shine: true
            };

            if (expPrizes[i] || softPrizes[i]) {
                res.createPrize = {
                    exp: expPrizes[i],
                    soft: softPrizes[i]
                };
            }

            units.push(res);
        }

        return {
            type: "coinsplant",
            units: units,
            expeditions: options && options.expeditions,
            images: options && options.images,
            extraBundles: options && options.extraBundles
        };
    },

    EnergyPlantFamily: function (total, options) {
        var expPrizes = [0, 50, 200];
        var softPrizes = [0, 10, 30];
        var energy = [3, 10, 35];

        var units = [];

        for (var i = 0; i < total; ++i) {
            var res = {
                price: 100 * Math.pow(3, i),
                fruit: {
                    duration: "6 hours",
                    exp: 10,
                    prize: [{
                        units: [{ resource: "energy", amount: energy[i] }]
                    }],
                    localPush: cleverapps.LocalPushes.TYPES.MAGIC_PLANT_READY
                },
                shine: true
            };

            if (expPrizes[i] || softPrizes[i]) {
                res.createPrize = {
                    exp: expPrizes[i],
                    soft: softPrizes[i]
                };
            }

            units.push(res);
        }

        return {
            type: "energyplant",
            units: units,
            expeditions: options && options.expeditions,
            images: options && options.images,
            extraBundles: options && options.extraBundles
        };
    },

    ValuableTreeFamily: function (options) {
        var units = [];

        var mining = {
            time: "15 seconds",
            exp: 5,
            energy: 5
        };

        for (var i = 0; i < options.stages.length; ++i) {
            var res = {};

            if (options.stages[i].prize) {
                res.fruit = {
                    duration: options.stages[i].timeout,
                    prize: options.stages[i].prize
                };
            }

            res.price = options.price[i];

            if (options.stages[i].unmergeable) {
                res.unmergeable = true;
            }

            if (options.stages[i].breakable) {
                res.breakable = {
                    limit: options.stages[i].breakable.limit,
                    nextUnit: { code: options.code, stage: 4 }
                };
            }

            units.push(res);
        }
        units.push({ mineable: [mining] });

        return {
            type: options.type,
            units: units,
            expeditions: options && options.expeditions,
            images: options && options.images,
            extraBundles: options && options.extraBundles
        };
    },

    CoinsCupFamily: function (total, options) {
        var amount = [3, 7, 15];

        var probabilities = [
            [0.6, 0.3, 0.1],
            [0.55, 0.35, 0.1],
            [0.55, 0.35, 0.15]
        ];

        var units = [];
        for (var i = 0; i < total; i++) {
            units.push({
                price: (options && options.price) ? options.price[i] : undefined,
                cup: {
                    amount: amount[i],
                    probabilities: probabilities[i],
                    units: [{ code: "coins", stage: 0 }, { code: "coins", stage: 1 }, { code: "coins", stage: 2 }]
                }
            });
        }

        return {
            type: "coinscup",
            units: units
        };
    },

    EnergyCupFamily: function (total, options) {
        var amount = [3, 7, 15];

        var probabilities = [
            [0.6, 0.3, 0.1],
            [0.55, 0.35, 0.1],
            [0.55, 0.35, 0.15]
        ];

        var units = [];
        for (var i = 0; i < total; i++) {
            units.push({
                price: (options && options.price) ? options.price[i] : undefined,
                cup: {
                    amount: amount[i],
                    probabilities: probabilities[i],
                    units: [{ code: "energy", stage: 0 }, { code: "energy", stage: 1 }, { code: "energy", stage: 2 }]
                }
            });
        }

        return {
            type: "energycup",
            units: units
        };
    },

    BoxFamily: function (total, options) {
        var list = [
            {
                code: "sacks",
                stages: [0, 1, 2]
            },
            {
                code: "wands",
                stages: [0, 1, 2]
            },
            {
                code: "crystal",
                stages: [1, 2, 3]
            },
            {
                type: "source",
                stages: [0, 2, 4]
            },
            {
                type: "hero",
                stages: [2, 3, 4]
            },
            {
                code: "coinscup",
                stages: [0, 1, 2]
            },
            {
                type: "seasonitem",
                stages: [2, 3, 4]
            },
            {
                type: "fruit",
                stages: [1, 2, 3]
            },
            {
                code: "energycup",
                stages: [0, 1, 2]
            },
            {
                type: "resource",
                stages: [2, 3, 4]
            },
            {
                code: "worker",
                stages: [2, 3, 4]
            },
            {
                code: "magicplant",
                stages: [2, 3, 4]
            }
        ];
        var listFixed = ["energycup", "coinscup"];

        var probabilities = cleverapps.arrayFill(list.length, 1 / list.length);

        var amount = [8, 10, 12];

        var units = [];
        for (var i = 0; i < total; i++) {
            units.push({
                price: (options && options.price) ? options.price[i] : undefined,
                cup: {
                    amount: amount[i],
                    probabilities: probabilities,
                    units: list.map(function (unit) {
                        return {
                            currentType: unit.type,
                            code: unit.code,
                            stage: unit.stages[i]
                        };
                    }),
                    fixed: listFixed,
                    fixedAmount: 1
                },
                shine: true
            });
        }

        return {
            type: "caravanbox",
            units: units
        };
    },

    TellyFamily: function (options) {
        var prizes = [{
            exp: 0,
            soft: 0
        }, {
            exp: 59,
            soft: 10
        }, {
            exp: 200,
            soft: 30
        }];

        return {
            type: "telly",
            units: prizes.map(function (prize) {
                var res = {
                    telly: true,
                    shine: true
                };

                if (prize.exp || prize.soft) {
                    res.createPrize = {
                        exp: prize.exp,
                        soft: prize.soft
                    };
                }

                return res;
            }),
            expeditions: options.expeditions,
            images: options.images
        };
    },

    BarrelFamily: function () {
        return {
            type: "barrel",
            units: [
                { barrel: true, unmergeable: true, movable: false },
                { barrel: true, unmergeable: true, movable: false },
                { barrel: true, unmergeable: true, movable: false },
                { barrel: true, unmergeable: true, movable: false },
                { barrel: true, unmergeable: true, movable: false }
            ]
        };
    },

    BattlePassPointsFamily: function (options) {
        var unit = {
            price: 35,
            battlepasspoints: true,
            pulsing: true,
            pointsReward: options.reward
        };

        return {
            type: "battlepasspoints",
            units: [unit],
            expeditions: options.expeditions,
            images: options.images
        };
    },

    SpecialUnitFamily: function (unit, options) {
        options = options || {};

        return {
            type: options.type,
            units: Array.isArray(unit) ? unit : cleverapps.arrayFill(options.total || 1, unit),
            expeditions: options.expeditions,
            images: options.images,
            oneViewForStages: options.oneViewForStages,
            locators: options.locators,
            extraBundles: options.extraBundles
        };
    },

    ThirdElementUnitFamily: function (options) {
        return {
            units: [{
                unmergeable: true,
                thirdelement: true
            }, {
                unmergeable: true,
                thirdelement: true
            }],
            expeditions: options.expeditions,
            images: options.images
        };
    },

    LandMarkUnitFamily: function (options) {
        var units = [];
        for (var i = 0; i < options.total; i++) {
            units.push({
                unmergeable: true,
                multicell: FamiliesHelper.ShapeSquare,
                movable: false,
                pointOfInterest: true,
                lazy: options.lazy
            });
        }
        return {
            type: options.type,
            units: units,
            expeditions: options && options.expeditions,
            images: options && options.images
        };
    },

    DecoratorFamily: function (options) {
        var units = [];

        for (var i = 0; i < options.length; ++i) {
            var data = options[i];

            units.push({
                unmergeable: true,
                movable: false,
                pointOfInterest: data.pointOfInterest,
                blocksFog: data.blocksFog === undefined ? true : data.blocksFog
            });
        }

        return {
            units: units,
            expeditions: "all"
        };
    },

    UnitsShopFamily: function (options) {
        return {
            type: "unitsshop",
            units: [{
                unmergeable: true,
                important: true,
                unitsshop: true,
                pointOfInterest: options.pointOfInterest,
                tab: options.tab,
                multicell: options.multicell
            }],
            expeditions: options.expeditions,
            images: options.images
        };
    },

    SeasonItemFamily: function (options) {
        var expPrizes = [0, 1, 2, 3, 5, 10, 15, 25, 150, 300, 500];
        var buildable = ["0 seconds", "0 seconds", "45 seconds", "1 minute", "2 minute", "5 minute", "15 minutes", "40 minutes", "90 minutes", "3 hour", "6 hours"];
        var units = [];
        for (var i = 0; i < options.total; i++) {
            var res = {
                createPrize: {
                    exp: expPrizes[i]
                },
                info: { season: options.month },
                buildable: buildable[i]
            };

            if (i === options.total - 1) {
                res.fruit = {
                    duration: "6 hour",
                    prize: [{
                        units: [{ resource: "energy", amount: 20 }]
                    }]
                };

                res.breakable = {
                    limit: 4,
                    prize: [{
                        units: [{ code: "season" + options.month, stage: 0 }],
                        amount: [2, 3]
                    }, {
                        units: [{ code: "season" + options.month, stage: 1 }],
                        amount: [0, 2]
                    }]
                };
            }

            units.push(res);
        }

        return {
            type: "seasonitem",
            units: units
        };
    },

    MissionWindowOpener: function (options) {
        var units = [];

        for (var i = 0; i < options.total; ++i) {
            units.push({
                movable: false,
                unmergeable: true,
                pointOfInterest: options.pointOfInterest !== undefined ? options.pointOfInterest : true,
                important: true,
                multicell: options.multicell,
                mission: options.mission
            });
        }

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions,
            images: options.images
        };
    },

    CollectionAnimalFamily: function (options) {
        var units = [];
        var expPrizes = [0, 1, 3, 10, 30];
        var mergeBpPoints = [0, 10, 20, 30, 40];
        if (options.type === "clpetrare") {
            expPrizes = [0, 2, 6, 20, 60, 200];
            mergeBpPoints = [0, 30, 40, 50, 60, 70, 80];
        } else if (options.type === "clpetlegend") {
            expPrizes = [0, 3, 10, 30, 100, 300, 1000];
            mergeBpPoints = [0, 50, 60, 70, 80, 90, 100];
        }

        var minFeedableStage = 2;
        var rarity = options.type.replace("clpet", "") || "regular";
        for (var i = 0; i < options.total; i++) {
            var res = {
                unmergeable: i === options.total - 1,
                mergeBpPoints: mergeBpPoints[i],
                createPrize: {
                    exp: expPrizes[i]
                },
                rarity: rarity,
                unitsLibrary: true
            };

            if (i >= minFeedableStage) {
                res.prize = {
                    resource: "pawsPoints",
                    amount: 2
                };
                res.feedable = {
                    fruitCode: "clstrawberry"
                };
            }

            units.push(res);
        }

        return {
            type: options.type,
            units: units,
            expeditions: ["collections"]
        };
    },

    FeedableHeroFamily: function (options) {
        var units = [];
        var expPrizes = [0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377];

        for (var i = 0; i < options.total; ++i) {
            var res = {
                heroitem: true,
                landmarkDonor: options.landmarkDonor,
                pointOfInterest: options.pointOfInterest,
                createPrize: {
                    exp: expPrizes[i]
                }
            };

            if (i === options.total - 1) {
                res.unmergeable = true;
                res.prize = {
                    resource: "wands",
                    amount: 5
                };
                res.feedable = {
                    fruitCode: options.fruitCode
                };
            }

            units.push(res);
        }

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions,
            probability: options.probability,
            dropType: options.dropType,
            images: options.images
        };
    },

    SnailHouseFamily: function (options) {
        var probabilities = [];
        var units = [];
        var n = options.maxStage;

        for (var i = 0; i < n; i++) {
            probabilities.push(2 * (n - i) / (n * (n + 1)));
            units.push({ code: options.prize.code, stage: i });
        }

        return {
            type: "snailhouse",
            units: [{
                cup: {
                    amount: options.amount,
                    probabilities: probabilities,
                    units: units,
                    mission: Mission.TYPE_SNAIL_FEAST
                }
            }],
            expeditions: options.expeditions,
            images: options.images
        };
    },

    ExpeditionHeroFamily: function (options) {
        var units = [];

        var expPrizes = [0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377];
        var dataByTotal = {
            default: {
                growTime: "10 minutes",
                amount: 2,
                maxGrow: 5
            },
            2: {
                growTime: "10 minutes",
                amount: 2,
                maxGrow: 5
            },
            4: {
                growTime: "20 minutes",
                amount: 2,
                maxGrow: 5
            },
            6: {
                growTime: "7 minutes",
                amount: 1,
                maxGrow: 10
            },
            7: {
                growTime: "13 minutes",
                amount: 2,
                maxGrow: 5
            },
            8: {
                growTime: "5 minutes",
                amount: 1,
                maxGrow: 10
            },
            11: {
                growTime: "3 minutes",
                amount: 2,
                maxGrow: 5
            },
            12: {
                growTime: "2 minutes",
                amount: 2,
                maxGrow: 10
            },
            pack: {
                growTime: "8 minutes",
                amount: 2,
                maxGrow: 5
            },
            ruby: {
                growTime: "4 minutes",
                amount: 1,
                maxGrow: 10
            }
        };

        var data = dataByTotal[options.tank] || dataByTotal[options.total] || dataByTotal.default;

        for (var i = 0; i < options.total; ++i) {
            var res = {
                heroitem: true,
                landmarkDonor: options.landmarkDonor,
                price: (options && options.price) ? options.price[i] : undefined,
                createPrize: {
                    exp: expPrizes[i]
                }
            };

            if (i === options.total - 1) {
                res.unmergeable = true;
                res.tank = {
                    prize: {
                        resource: "wands",
                        amount: data.amount
                    },
                    growTime: data.growTime,
                    refillPrice: 99,
                    maxGrow: data.maxGrow
                };
            }

            units.push(res);
        }

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions,
            images: options.images
        };
    },

    ExpeditionResourceFamily: function (options) {
        var expPrizes = [1, 2, 3, 5, 8, 13, 21, 34, 55];
        var buildable = ["0 seconds", "40 seconds", "2 minute", "15 minute", "1 hour", "2 hour", "4 hour", "8 hour", "12 hour"];
        var prizesAmount = [1, 2];
        if (options.total > 6) {
            prizesAmount = [2, 4];
        } else if (options.total > 8) {
            prizesAmount = [3, 5];
        } else if (options.total > 10) {
            prizesAmount = [4, 6];
        }

        var units = [];

        var bpPoints = options.startBpPoints;
        for (var i = 0; i < options.total; ++i) {
            var data = {
                buildable: buildable[i] || buildable[buildable.length - 1],
                bpPoints: Math.floor(bpPoints),
                createPrize: {
                    exp: expPrizes[i]
                }
            };

            bpPoints *= 1 + 3 / (i + 1);

            if (i === options.total - 1) {
                data.unmergeable = true;
                data.fruit = {
                    duration: cleverapps.Random.random(6, 12, options.total) + "hour",
                    exp: 10,
                    prize: [{
                        units: [{ code: options.prize, stage: 0 }],
                        amount: prizesAmount
                    }, {
                        units: [{ code: "worker", stage: 0 }],
                        amount: [0, 1]
                    }]
                };
            }

            units.push(data);
        }

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions,
            images: options.images,
            locators: options.locators
        };
    },

    ExpeditionSourceFamily: function (options) {
        var times = ["0 seconds", "0 seconds", "0 seconds", "45 seconds", "1 minute", "2 minutes", "4 minutes", "6 minutes"];
        if (["seasource", "hlsource"].indexOf(options.type) !== -1) {
            times = ["0 seconds", "0 seconds", "35 seconds", "45 seconds", "1 minute", "5 minutes"];
        }
        times.splice(2, options.skipTime);

        var units = [];

        for (var stage = 0; stage < options.mined.length; ++stage) {
            var mining = cleverapps.arrayFill(options.mined[stage]).map(function (value, index) {
                var energy = 0;
                if (options.energy) {
                    energy = options.energy[index];
                }
                if (options.energyByStage) {
                    energy = options.energyByStage[stage];
                }
                return {
                    time: times[index],
                    exp: 5 * (index + 1),
                    energy: energy
                };
            });

            units.push({
                mineable: mining,
                unmergeable: true,
                source: true,
                price: options.price && options.price[stage]
            });
        }

        return {
            type: options.type,
            resource: options.resource,
            units: units,
            expeditions: options.expeditions,
            images: options.images
        };
    },

    ExpeditionChestFamily: function (options) {
        return {
            type: options.type,
            resource: options.resource,
            units: FamiliesHelper.ChestFamily(options.resource, options).units,
            expeditions: options.expeditions,
            images: options.images
        };
    },

    ExpeditionGiftFamily: function (options) {
        var expPrizes = [0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377];
        var units = [];

        var producer = undefined;

        if (options.producer) {
            producer = {
                code: options.producer,
                stage: 0,
                guide: GuideWindow,
                guideOptions: typeof ImproverGuideOptions !== "undefined" ? ImproverGuideOptions : undefined
            };
        }

        for (var i = 0; i < options.total; ++i) {
            units.push({
                draggable: true,
                price: (options && options.price ? options.price[i] : undefined),
                createPrize: {
                    exp: expPrizes[i]
                },
                info: {
                    guide: GuideWindow,
                    guideOptions: typeof RudolfGuideOptions !== "undefined" ? RudolfGuideOptions : undefined
                },
                producer: producer,
                unmergeable: producer ? i > 1 : undefined
            });
        }

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions,
            images: options.images
        };
    },

    SnailFeastFamily: function (options) {
        var expPrizes = [0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377];
        var units = [];

        for (var i = 0; i < options.total; ++i) {
            units.push({
                draggable: true,
                createPrize: {
                    exp: expPrizes[i]
                },
                info: {
                    guide: GuideWindow,
                    guideOptions: {
                        name: "SnailFeastGuideWindow",
                        bundle: bundles.snailfeastguidewindow
                    }
                }
            });
        }

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions,
            images: options.images
        };
    },

    ThanksGivingFamily: function (options) {
        var buildable = ["0 seconds", "0 seconds", "0 seconds", "0 seconds", "0 seconds", "30 seconds", "1 minute", "5 minute", "15 minute", "0 seconds"];
        var expPrizes = [0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233, 377];
        var units = [];

        for (var i = 0; i < options.total; ++i) {
            units.push({
                buildable: buildable[i] || buildable[buildable.length - 1],
                landmarkDonor: true,
                draggable: true,
                createPrize: {
                    exp: expPrizes[i]
                },
                info: {
                    landmarkDonor: true
                }
            });
        }

        return {
            type: "thanksgiving",
            units: units
        };
    },

    ExpeditionGrowingFamily: function (options) {
        var expPrizes = [0, 1, 2, 3];
        var mining = [
            {
                time: "1 minute",
                exp: 5,
                energy: options.energy ? options.energy[0] : 0
            }, {
                time: "2 minute",
                exp: 10,
                energy: options.energy ? options.energy[1] : 0
            }, {
                time: "3 minute",
                exp: 25,
                energy: options.energy ? options.energy[2] : 0
            }
        ];

        var units = [
            { info: true, createPrize: { exp: expPrizes[0] }, price: 0 },
            {
                mineable: [mining[0]], growing: true, createPrize: { exp: expPrizes[1] }, price: 1
            },
            {
                mineable: [mining[1]], growing: true, createPrize: { exp: expPrizes[2] }, price: 3
            },
            {
                mineable: [mining[0], mining[1], mining[2]], growing: true, createPrize: { exp: expPrizes[3] }, price: 9
            }
        ];

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions,
            images: options.images
        };
    },

    ExpeditionHouseFamily: function (options) {
        var units = [];

        for (var i = 0; i < options.stages.length; ++i) {
            units.push({
                price: options.price && options.price[i],
                multicell: FamiliesHelper.ShapeSquare,
                pointOfInterest: true,
                fruit: {
                    duration: options.stages[i].timeout,
                    exp: 10,
                    prize: options.stages[i].prize
                }
            });
        }

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions,
            images: options.images
        };
    },

    ExpeditionGeneratorFamily: function (options) {
        var prize = [];
        if (options.resource) {
            prize.push({
                units: [{ code: options.resource, stage: 0 }]
            });
        }
        if (options.source) {
            prize.push({
                units: [{ code: options.source, stage: 0 }]
            });
        }
        if (options.targetType) {
            prize.push({
                units: [{ type: options.targetType, stage: 0 }]
            });
        }
        if (options.prize) {
            prize.push(
                {
                    units: [options.prize],
                    amount: options.prizeAmount || [0, 4]
                }
            );
        }

        var units = [{
            multicell: FamiliesHelper.ShapeSquare,
            important: true,
            pointOfInterest: true,
            unmergeable: true,
            fruit: {
                duration: options.duration || "5 minutes",
                exp: 10,
                prize: prize,
                energy: options.energy
            }
        }];

        // return {
        //     type: options.type,
        //     resource: options.resource,
        //     units: [{
        //         multicell: FamiliesHelper.ShapeSquare,
        //         important: true,
        //         pointOfInterest: true,
        //         unmergeable: true,
        //         mineable: [{
        //             time: options.time || "0 seconds",
        //             exp: 10,
        //             energy: 20,
        //             endless: true
        //         }]
        //     }],
        //     expeditions: options.expeditions
        // };

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions,
            images: options.images
        };
    },

    ExpeditionMineableFamily: function (options) {
        return {
            type: options.type,
            resource: options.resource,
            units: [{
                multicell: FamiliesHelper.ShapeSquare,
                important: true,
                pointOfInterest: true,
                unmergeable: true,
                mineable: [{
                    time: options.time || "0 seconds",
                    exp: 10,
                    energy: 20,
                    endless: true
                }]
            }],
            expeditions: options.expeditions
        };
    },

    DragoniaResourceFamily: function (options) {
        var units = [{
            unmergeable: true
        }];

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions,
            locators: options.locators
        };
    },

    ExpeditionFieldFamily: function (options) {
        var units = [{
            field: true
        }];

        return {
            type: "field",
            units: units,
            expeditions: options.expeditions
        };
    },

    ExpeditionBuildingFamily: function (options) {
        var units = [{
            customer: true,
            climbable: true,
            simpleClimb: true,
            unmergeable: true,
            important: true,
            pointOfInterest: true,
            movable: false,
            hasShowUp: options.hasShowUp
        }, {
            unmergeable: true,
            customer: true,
            important: true,
            movable: false
        }];

        if (options.hasShowUpStage) {
            units.unshift({
                customer: true,
                climbable: true,
                simpleClimb: true,
                unmergeable: true,
                important: true,
                pointOfInterest: true,
                movable: false
            });
        }

        units.forEach(function (unit) {
            unit.multicell = options.multicell;
            unit.campaign = options.campaign;
            unit.landmarkDonor = options.landmarkDonor;
            unit.squeezable = options.squeezable;
        });

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions,
            skins: options.skins
        };
    },

    ExpeditionCustomerFamily: function (options) {
        var units = [{
            customer: true,
            unmergeable: true,
            pointOfInterest: true,
            important: true,
            hasShowUp: options.hasShowUp
        }];

        units.forEach(function (unit) {
            unit.multicell = options.multicell;
        });

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions,
            skins: options.skins
        };
    },

    ExpeditionProductFamily: function (options) {
        var unit = {};

        if (options.guide) {
            unit.info = {
                guide: GuideWindow,
                guideOptions: options.guide
            };
        }

        return {
            type: options.type,
            units: [unit],
            expeditions: options.expeditions,
            locators: options.locators,
            skins: options.skins
        };
    },

    RuinsFamily: function (options) {
        var units = [
            {
                price: options.price,
                unmergeable: true,
                pointOfInterest: true,
                movable: false
            }
        ];

        return {
            type: "ruins",
            units: units,
            expeditions: options.expeditions,
            images: options.images,
            skins: options.skins
        };
    },

    ShipFamily: function () {
        return {
            type: "ship",
            units: [
                {
                    unmergeable: true,
                    movable: false,
                    multicell: FamiliesHelper.ShapeSquare
                }
            ]
        };
    },

    BankFamily: function () {
        return {
            units: [
                {
                    unmergeable: true,
                    important: true,
                    pulsing: true
                }
            ]
        };
    },

    CinemaFamily: function (total, options) {
        var units = [];
        for (var i = 0; i < total; i++) {
            var unit = {};

            if (i === total - 1) {
                unit.unitsshop = true;
                unit.multicell = FamiliesHelper.ShapeSquare;
                unit.tab = options.tab;
                unit.vip_tab = options.vip_tab;
                unit.undecided_tab = options.undecided_tab;
            }

            units.push(unit);
        }
        return {
            type: "cinema",
            units: units
        };
    },

    RapunzelResourceFamily: function (options) {
        var expPrizes = [1, 2, 3, 5, 8, 13, 21, 34, 55];
        var mergeBpPoints = options.mergeBpPoints;
        var units = [];

        for (var i = 0; i < options.total; ++i) {
            var data = {
                createPrize: {
                    exp: expPrizes[i]
                },
                mergeBpPoints: mergeBpPoints && mergeBpPoints[i],
                info: options.guideOptions ? {
                    guide: GuideWindow,
                    guideOptions: options.guideOptions
                } : true
            };

            units.push(data);
        }

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions,
            images: options.images
        };
    },

    RapunzelOrderFamily: function (options) {
        var energy = [0, 0, 1, 2];
        var bpPoints = [0, 0, 15, 75];
        var units = [];

        for (var i = 0; i < options.total; ++i) {
            var data = {
                bpPoints: bpPoints[i],
                info: {
                    guide: GuideWindow,
                    guideOptions: options.guideOptions || {
                        name: "rpthrone_guide_window",
                        bundle: bundles.rpthrone_guide_window
                    }
                }
            };

            if (energy[i]) {
                data.creatable = {
                    energy: energy[i]
                };
            }

            units.push(data);
        }

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions,
            images: options.images
        };
    },

    RapunzelHeroFamily: function (options) {
        var energy = [0, 0, 1, 2, 5, 7, 10, 20, 25, 30];
        var bpPoints = [0, 0, 5, 15, 75, 250, 1250, 1300, 1500, 2000];
        var units = [];

        for (var i = 0; i < options.total; ++i) {
            var data = {
                landmarkDonor: options.landmarkDonor,
                heroitem: true,
                bpPoints: bpPoints[i]
            };

            if (energy[i]) {
                data.creatable = {
                    energy: energy[i]
                };
            }
            units.push(data);
        }

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions,
            images: options.images
        };
    },

    AdventureResourceFamily: function (options) {
        var units = [{
            pulsing: true,
            collectible: {
                harvested: true
            },
            value: 1
        }];

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions
        };
    },

    AdventureSourceFamily: function (stages, options) {
        var units = cleverapps.toArray(stages).map(function (stage) {
            return {
                workingAnimation: options.workingAnimation,
                pointOfInterest: options.pointOfInterest,
                unmergeable: true,
                stopsfog: true,
                multicell: stage.multicell || options.multicell,
                autoCollect: true,
                simpleInfo: true,
                mineable: cleverapps.toArray(stage.mineable || [stage]).map(function (level) {
                    return {
                        time: "0 second",
                        energy: level.energy,
                        exp: Math.ceil(level.energy / 10),
                        prizes: Object.keys(level.prizes).map(function (code) {
                            if (code === "energy") {
                                return { resource: code, amount: level.prizes[code] };
                            }
                            return { ingredient: code, amount: level.prizes[code] };
                        })
                    };
                })
            };
        });

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions
        };
    },

    AdventureLeveledSourceFamily: function (stages, options) {
        var levels = cleverapps.toArray(stages).map(function (stage) {
            return {
                energy: stage.energy,
                prizes: stage.prizes
            };
        });

        cleverapps.toArray(stages).forEach(function (stage, index) {
            stage.mineable = levels.slice(0, index + 1).reverse();
        });

        return FamiliesHelper.AdventureSourceFamily(stages, options);
    },

    AdventurePrizesFamily: function (stages, options) {
        var units = cleverapps.toArray(stages).map(function (stage) {
            return {
                multicell: options.multicell,
                unmergeable: true,
                stopsfog: true,
                lockable: {
                    duration: stage.unlock
                },
                workingAnimation: "chest",
                autoCollect: true,
                simpleInfo: true,
                mineable: [{
                    time: "0 second",
                    energy: 0,
                    exp: 0,
                    prizes: cleverapps.toArray(stage.prize)
                }]
            };
        });

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions
        };
    },

    AdventureCustomerFamily: function (options) {
        var units = [];

        for (var i = 0, total = options.total ? options.total - 1 : 1; i < total; ++i) {
            units.push({
                stopsfog: options.stopsfog !== undefined ? options.stopsfog : true,
                lovesGround: options.lovesGround,
                movable: false,
                customer: true,
                climbable: true,
                simpleClimb: true,
                important: true,
                pointOfInterest: true,
                multicell: options.multicell,
                landmarkDonor: true
            });
        }

        units.push({
            lovesGround: options.lovesGround,
            composite: options.composite && options.composite.map(function (part) {
                return {
                    x: part.x,
                    y: part.y,
                    animation: part.animation,
                    layer: Map2d.LAYER_UNITS
                };
            }),
            important: true,
            movable: false,
            clickable: !!options.finishHint,
            multicell: options.multicell,
            landmarkDonor: {
                finishHint: options.finishHint ? "LandmarkDonor.FinishedHint" : undefined
            }
        });

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions
        };
    },

    AdventureProducerFamily: function (options) {
        var units = [{
            stopsfog: options.stopsfog !== undefined ? options.stopsfog : true,
            lovesGround: options.lovesGround,
            movable: false,
            customer: true,
            climbable: true,
            simpleClimb: true,
            important: true,
            pointOfInterest: true,
            multicell: options.multicell
        }, {
            stopsfog: options.stopsfog !== undefined ? options.stopsfog : true,
            lovesGround: options.lovesGround,
            movable: false,
            customer: true,
            important: true,
            pointOfInterest: true,
            multicell: options.multicell
        }];

        return {
            type: options.type,
            units: units,
            expeditions: options.expeditions
        };
    },

    overrideFamilies: function (families) {
        this.overrides = this.overrides || [];

        var override = {};
        for (var key in families) {
            override[key] = Families[key];
            Families[key] = families[key];
        }

        this.overrides.push(override);

        Families.afterInitialize();
    },

    unoverrideFamilies: function () {
        if (!this.overrides || this.overrides.length === 0) {
            return;
        }

        while (this.overrides.length > 0) {
            var override = this.overrides.pop();
            Object.assign(Families, override);
        }

        Families.afterInitialize();
    }
};

if (typeof cc === "undefined") {
    module.exports = FamiliesHelper;
}
