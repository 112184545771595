/**
 * Created by vtbelo on 17/03/21
 */

var PixelView = cc.Node.extend({
    ctor: function (pixel) {
        this._super();
        this.pixel = pixel;

        var styles = this.getStyles();

        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize2(styles.width, styles.height);

        this.target = new cc.Node();
        this.target.setPositionRound(styles.collect);
        this.addChild(this.target);

        var bundle = cleverapps.travelBook.getCurrentPage().getPixelsBundleName();
        switch (this.pixel.type) {
            case PixelsSwarm.ENERGY: bundles[bundle + "_energy"] && (bundle += "_energy"); break;
            case PixelsSwarm.COINS: bundles[bundle + "_coin"] && (bundle += "_coin"); break;
            case PixelsSwarm.ADS: bundles[bundle + "_telly"] && (bundle += "_telly"); break;
        }

        var json = bundles[bundle].jsons.pixels_json;
        this.animation = LazyAsset.IsResLoaded(json) ? new cleverapps.Spine(json) : new LazyAsset(json);
        this.animation.startLoading();
        this.animation.setPositionRound(styles.animation);
        this.animation.setAnimation(0, "animation", true);
        this.animation.setTimeScale(styles.timeScale.base + Math.random() * styles.timeScale.random);
        this.addChild(this.animation);

        cleverapps.UI.onDrag(this, {
            onClick: this.pixel.collect.bind(this.pixel),

            followPointer: true,

            onDragStart: function () {
                this.pixel.setFlying(false);
                this.setLocalZOrder(1);

                return true;
            }.bind(this),
            onDragMove: function (touch) {
                this.setPositionRound(this.parent.convertTouchToNodeSpace(touch));
            }.bind(this),
            onDragEnd: function () {
                this.pixel.setFlying(true);
                this.setLocalZOrder(0);
            }.bind(this)
        });

        this.pixel.onFlyingChanged = this.createListener(this.changeFlying.bind(this));
        this.pixel.onAnimateCollect = this.createListener(this.collect.bind(this));
        this.pixel.onDie = this.createListener(this.explode.bind(this));

        this.minFlyTime = cleverapps.parseInterval(PixelView.FLY_TIME);

        var cell = Map2d.currentMap.getView().alignInIsometricGrid(this.pixel.x, this.pixel.y);
        this.setPosition(cell.x, cell.y);

        this.speed = styles.speed;
        this.distanceDisappear = styles.distanceDisappear;

        Game.currentGame.pushingComponent.add(this, "pixels");

        this.flyStartTime = Date.now();
        this.pixel.setFlying(true);

        this.setScale(0);
        this.runAction(new cc.ScaleTo(0.2, 1));

        this.lastCheck = 0;
    },

    getStyles: function () {
        return cleverapps.skins.getSlot("pixelView") || cleverapps.styles.PixelView;
    },

    update: function (dt) {
        this.setPosition(this.x + this.speed.x * dt, this.y + this.speed.y * dt);
        var screenSize = cleverapps.resolution.getBgSize();

        var ct = Date.now();
        if (this.flyStartTime + this.minFlyTime < ct && this.lastCheck < ct - 3000) {
            this.lastCheck = ct;
            var pos = this.parent.convertToWorldSpace(this.getPosition());

            if (screenSize.width + this.distanceDisappear < pos.x || pos.y < -this.distanceDisappear) {
                this.pixel.die(true);
            }
        }

        var onScreen = cc.rectIntersectsRect(this.animation.getGlobalBoundingBox(), cc.rect(0, 0, screenSize.width, screenSize.height));
        this.setVisible(onScreen);
    },

    changeFlying: function (flying) {
        if (flying) {
            this.scheduleUpdate();
            this.resumePushing();
        } else {
            this.unscheduleUpdate();
            this.pausePushing();
        }
    },

    collect: function (callback) {
        var styles = this.getStyles().collect;
        var bundleId = cleverapps.travelBook.getCurrentPage().getPixelsBundleName();

        var sound = bundles[bundleId].urls.pixel_collect_effect;
        if (this.pixel.type === PixelsSwarm.ENERGY) {
            sound = bundles[bundleId].urls.pixel_energy_drop_effect;
        }

        callback({
            source: this.target,
            delay: styles.delay,
            sound: sound
        });
    },

    explode: function (silent) {
        cc.eventManager.removeListeners(this);

        var callback = cleverapps.once(function () {
            this.removeFromParent();
        }.bind(this));

        if (LazyAsset.IsAssetLoaded(this.animation)) {
            this.animation.setAnimation(0, "animation2", false);
            this.animation.setCompleteListener(callback);
        } else {
            var delay = this.getStyles().collect.delay * 0.001 || 0;
            this.animation.runAction(new cc.Sequence(
                new cc.DelayTime(delay),
                new cc.ScaleTo(0.1, 0.3),
                new cc.Hide(),
                new cc.CallFunc(callback)
            ));
        }

        if (silent) {
            this.removeFromParent();
        }
    }
});

cleverapps.styles.PixelView = {
    width: 80,
    height: 80,
    animation: {
        x: { align: "center" },
        y: { align: "center", dy: -40 }
    },
    collect: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 },
        delay: 150
    },
    timeScale: {
        base: 1,
        random: 0
    },
    speed: {
        x: 35,
        y: -18
    },
    distanceDisappear: 1000
};

PixelView.FLY_TIME = "90 seconds";
