/**
 * Created by andrey on 19.01.2021.
 */

var MergeTutorialView = cc.Node.extend({
    ctor: function (tutorial) {
        this._super();

        this.tutorial = tutorial;

        var sceneSize = cleverapps.resolution.getSceneSize();
        this.setContentSize2(sceneSize);
        this.setAnchorPoint(0.5, 0.5);
        this.setLocalZOrder(12);
        this.setCascadeOpacityEnabled(true);

        this.tutorial.on("showTutorialStep", this.showTutorialStep.bind(this), this);
        this.tutorial.on("finishTutorialStep", this.finishTutorialStep.bind(this), this);
        this.tutorial.on("showUpWindow", this.onWindowShown.bind(this), this);
        this.tutorial.on("closeWindows", this.createDialogue.bind(this, undefined), this);

        cleverapps.eventBus.on("openInfoView", this.removeDialogue.bind(this), this);
        cleverapps.eventBus.on("closeInfoView", this.createDialogue.bind(this, undefined), this);

        tutorial.onAfterResize = this.createListener(this.afterResize.bind(this));
    },

    showTutorialStep: function (data, move) {
        this.stopAllActions();

        this.data = data;
        this.move = move;

        if (this.data.type === Map2d.CLOSE_WINDOWS) {
            this.tutorialWindow = cleverapps.windows.currentWindow();
        }

        var delta;

        if (move.cells && !this.data.noScroll) {
            var scroll = Map2d.currentMap.getView().scroll;

            var options = {
                visibleBox: {
                    left: 0.3,
                    right: 0.4,
                    top: 0.15,
                    bottom: 0.1
                },
                allowScrollWithFocus: true
            };

            var targetCell = move.cells[0];

            var targetCellNode = Map2d.currentMap.getView().getCell(targetCell.x, targetCell.y);
            delta = scroll.calcNodeDelta(targetCellNode, options);
            delta.x *= scroll.zoom;
            delta.y *= scroll.zoom;

            scroll.runAction(new cc.Sequence(
                new cc.DelayTime(0.01),
                new cc.CellScrollAction(targetCell, options).easing(cc.easeInOut(2))
            ));
        }

        this.createDialogue(delta);
    },

    finishTutorialStep: function () {
        this.data = undefined;
        this.move = undefined;

        if (this.tutorialWindow) {
            this.tutorialWindow = undefined;
        }

        this.removeDialogue();
    },

    createDialogueContent: function (text) {
        if (!Array.isArray(text)) {
            return text;
        }

        text = text.map(function (item) {
            if (item.code !== undefined && item.stage !== undefined) {
                var icon = UnitView.getUnitImage(item, { alignAnchorX: true, preferStatic: true });
                icon.setScale(1.5);
                return icon;
            }
            return item;
        });

        var maxIconHeight = cleverapps.UI.generateOnlyText("12345", cleverapps.styles.FONTS.FORCE_MESSAGE_TEXT).height * 2;

        var messages = text.map(function (message) {
            if (typeof message === "string") {
                return cleverapps.UI.generateOnlyText(message, cleverapps.styles.FONTS.FORCE_MESSAGE_TEXT);
            }
            cleverapps.UI.fitToBox(message, {
                maxScale: message.scale,
                height: maxIconHeight
            });
            return message;
        });

        return new cleverapps.Layout(messages, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: cleverapps.styles.MergeTutorialView.margin
        });
    },

    listRects: function (move, delta) {
        delta = delta || cc.p(0, 0);

        var cells = (move.cells || []).concat(move.mergeCluster || []);
        var rects = cells.map(function (coords) {
            var unit = Map2d.currentMap.getUnit(coords.x, coords.y);
            if (unit) {
                var rect;
                Map2d.currentMap.showLayerTile(Map2d.LAYER_UNITS, coords.x, coords.y);
                var unitView = unit.onGetView();

                rect = unitView.getSceneBoundingBox();
                unitView.listAdditionalViews().forEach(function (view) {
                    rect = cc.rectUnion(rect, view.getSceneBoundingBox());
                    if (view.view && view.view.button) {
                        rect = cc.rectUnion(rect, view.view.button.getSceneBoundingBox());
                    }
                });
            }

            var fog = Map2d.currentMap.getFog(move.cells[0].x, move.cells[0].y);
            var fogView = fog && fog.fogBlock.onGetView();
            if (fogView) {
                rect = fogView.getSceneBoundingBox();
                if (fogView.openerWindow) {
                    rect = cc.rectUnion(rect, fogView.openerWindow.getSceneBoundingBox());
                    if (fogView.openerWindow.button) {
                        rect = cc.rectUnion(rect, fogView.openerWindow.button.getSceneBoundingBox());
                    }
                }
            }

            return rect;
        }).filter(Boolean);

        var arrow = cleverapps.scenes.getRunningScene().advice.arrow;
        if (arrow) {
            rects.push(arrow.getSceneBoundingBox());
        }

        rects.forEach(function (rect) {
            rect.x -= delta.x;
            rect.y -= delta.y;
        });

        if (this.data.controls) {
            var scene = cleverapps.scenes.getRunningScene();
            var controls = cleverapps.toArray(this.data.controls);
            if (controls.includes("toolbar")) {
                var toolbarBox = scene.downToolBarControl.getBoundingBox();
                rects.push(toolbarBox);
            }

            if (controls.some(function (control) {
                return control.includes("MenuBar");
            })) {
                var menubarBox = scene.upMenuContainer.getBoundingBox();
                rects.push(menubarBox);
            }
        }

        if (this.tutorialWindow && this.tutorialWindow.window) {
            rects.push(this.tutorialWindow.window.getBoundingBox());
        }

        return rects;
    },

    createDialogue: function (delta) {
        if (this.dialogueComponent || !this.data || !this.data.text) {
            return;
        }

        var window = cleverapps.windows.currentWindow();
        if (window && (!window.initializedSuccess || window !== this.tutorialWindow)) {
            return;
        }

        var params = {
            items: [this.createDialogueContent(this.data.text)],
            person: this.data.person,
            rects: this.listRects(this.move, delta)
        };

        this.dialogueComponent = new MinimalDialogue(params);
        this.addChild(this.dialogueComponent);
        this.dialogueComponent.display();

        if (this.data.dialogueDuration) {
            this.runAction(new cc.Sequence(
                new cc.DelayTime(this.data.dialogueDuration / 1000),
                new cc.CallFunc(this.finishTutorialStep.bind(this))
            ));
        }
    },

    removeDialogue: function () {
        this.stopAllActions();

        if (this.dialogueComponent) {
            this.dialogueComponent.remove();
            this.dialogueComponent = undefined;
        }
    },

    afterResize: function () {
        if (this.data && this.move) {
            this.showTutorialStep(this.data, this.move);
        }
    },

    onWindowShown: function () {
        var window = cleverapps.windows.currentWindow();

        if (window && (!window.initializedSuccess || window !== this.tutorialWindow)) {
            this.removeDialogue();
        } else {
            this.createDialogue();
        }
    }
});

cleverapps.styles.MergeTutorialView = {
    loginBtn: {
        x: { align: "right", dx: -40 },
        y: { align: "top", dy: -30 }
    },

    margin: 10
};
