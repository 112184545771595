/**
 * Created by Anastasiya on 24.05.2024
 */

var UnitTrade = function () {
    this.selectedSource = undefined;
    this.selectedTarget = undefined;
    cleverapps.EventEmitter.call(this);
};

UnitTrade.prototype = Object.create(cleverapps.EventEmitter.prototype);
UnitTrade.prototype.constructor = UnitTrade;

UnitTrade.prototype.getSelected = function (type) {
    return type === UnitTrade.SOURCE ? this.selectedSource : this.selectedTarget;
};

UnitTrade.prototype.setSelected = function (type, selected) {
    var target = type === UnitTrade.SOURCE ? "selectedSource" : "selectedTarget";
    if (this[target] !== selected) {
        this[target] = selected;
        this.trigger("pickChange", type, selected);
    }
};

UnitTrade.prototype.getSourceTypes = function () {
    return ["clpet", "clpetrare"];
};

UnitTrade.prototype.getTargetTypes = function (sourceType) {
    switch (sourceType) {
        case "clpet":
            return ["clpetrare"];
        case "clpetrare":
            return ["clpetlegend"];
    }
};

UnitTrade.prototype.getTypeSubstitute = function (type) {
    switch (type) {
        case "clpetrare": case "clpetlegend":
            return [{ code: "clsimplechest", type: type, stage: type === "clpetlegend" ? 2 : 1 }];
    }
};

UnitTrade.prototype.getTradeUnitStage = function (tradeType, unitType) {
    if (tradeType === UnitTrade.SOURCE) {
        switch (unitType) {
            case "clpet":
                return 4;
            case "clpetrare":
                return 5;
        }
    }
    switch (unitType) {
        case "clpetrare": case "clpetlegend":
            return 2;
    }
};

UnitTrade.prototype.listTradeUnits = function (tradeType, unitType) {
    var tradeUnits = [];
    var targetStage = this.getTradeUnitStage(tradeType, unitType);

    if (tradeType === UnitTrade.SOURCE) {
        var mapUnits = {};
        var unitFilter = { type: unitType, stage: targetStage };
        Map2d.currentMap.listAvailableUnits(unitFilter).forEach(function (pet) {
            if (mapUnits[pet.code]) {
                mapUnits[pet.code].amount++;
            } else {
                mapUnits[pet.code] = {
                    type: pet.getType(),
                    code: pet.code,
                    stage: pet.stage,
                    amount: 1
                };
            }
        });
        tradeUnits = Object.values(mapUnits);
    } else {
        tradeUnits = cleverapps.unitsLibrary.listAvailableByType(unitType).map(function (code) {
            return { type: unitType, code: code, stage: targetStage };
        });
        if (tradeUnits.length === 0) {
            tradeUnits = this.getTypeSubstitute(unitType);
        }
    }
    return tradeUnits;
};

UnitTrade.prototype.exchange = function (callback) {
    var center = Map2d.currentMap.getScreenCenterCell();
    var sourceUnit = Map2d.currentMap.listAvailableUnits(this.selectedSource).sort(function (a, b) {
        return cc.getDistanceChebyshev(a, center) - cc.getDistanceChebyshev(b, center);
    })[0];

    new ActionPlayer([
        function (f) {
            Map2d.currentMap.getView().scroll.runAction(new cc.Sequence(
                new cc.ZoomAction(1, {
                    zoom: 1.5,
                    zoomFocus: Map2d.currentMap.getView().getCell(sourceUnit.x, sourceUnit.y)
                }),
                new cc.CallFunc(f)
            ));
        },

        function (f) {
            cleverapps.audio.playSound(bundles.tradewindow.urls.exchange_effect);

            sourceUnit.setPrizes();
            sourceUnit.onTradeSell(f);
        },

        function (f) {
            sourceUnit.remove();

            var newUnit = new Unit(this.selectedTarget);
            newUnit.setPosition(sourceUnit.x, sourceUnit.y);
            if (newUnit.findComponent(Feedable)) {
                newUnit.findComponent(Feedable).feedTime = Date.now();
            }
            Map2d.currentMap.onUnitAvailable(newUnit);
            Map2d.currentMap.onAddUnit(newUnit.x, newUnit.y, newUnit);

            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.TRADE_EXCHANGE + this.getRarityByType(newUnit.getType()));

            newUnit.onTradeBuy(f);
        }.bind(this)
    ]).play(callback);
};

UnitTrade.prototype.getRarityByType = function (unitType) {
    var rarity = {
        "clpet": "regular",
        "clpetrare": "rare",
        "clpetlegend": "legend"
    };
    return rarity[unitType];
};

UnitTrade.prototype.getIconByType = function (type) {
    return bundles.pet_tab_icons.frames["tab_icon_" + this.getRarityByType(type)];
};

UnitTrade.SOURCE = "source";
UnitTrade.TARGET = "target";