/**
 * Created by mac on 12/28/20
 */

var SourceMine = {
    prize: function (level, unit) {
        var prizes = [];

        if (unit.stage >= 2) {
            prizes = [{
                amount: [0, 2],
                units: [{ code: "cinema", stage: 0 }]
            }];
        }

        if (level !== unit.getData().mineable.length - 1) {
            return prizes.concat(SourceMine["level" + level](unit));
        }

        prizes = prizes.concat([{
            units: [{ code: unit.getResource() + "chest", stage: Math.floor(unit.stage / 2), lock: true }]
        }]);

        if (!unit.isGrounded() && unit.stage < 5) {
            prizes = prizes.concat([{
                units: [{ code: unit.getResource() + "source", stage: unit.stage + 1 }]
            }]);
        }

        return prizes;
    },

    level0: function (unit) {
        return [{
            amount: 2,
            units: [{ code: unit.getResource(), stage: 0 }]
        }, {
            probabilities: [0.25],
            units: [{ currentType: "hero", maxStage: 1 }]
        }];
    },

    level1: function (unit) {
        return [{
            amount: 4,
            probabilities: [0.02, 0.25, 0.5],
            units: [
                { code: "worker", stage: 0 },
                { type: "resource", stage: 0 },
                { code: unit.getResource(), stage: 0 },
                { code: unit.getResource(), stage: 1 }
            ]
        }, {
            probabilities: [0.35],
            units: [{ currentType: "hero", maxStage: 1 }]
        }];
    },

    level2: function (unit) {
        return [{
            amount: 4,
            probabilities: [0.13, 0.13, 0.15, 0.25],
            units: [
                { code: "worker", stage: 0 },
                { code: "coins", stage: 0 },
                { type: "resource", stage: 0 },
                { code: unit.getResource(), stage: 0 },
                { code: unit.getResource(), stage: 1 }
            ]
        }, {
            probabilities: [0.4],
            units: [{ currentType: "hero", maxStage: 1 }]
        }];
    },

    level3: function (unit) {
        return [{
            amount: 5,
            probabilities: [0.1, 0.1, 0.15, 0.25],
            units: [
                { code: "worker", stage: 0 },
                { code: "coins", stage: 0 },
                { type: "resource", stage: 0 },
                { code: unit.getResource(), stage: 0 },
                { code: unit.getResource(), stage: 1 }
            ]
        }, {
            probabilities: [0.1],
            units: [{ type: "fruit", stage: 0 }]
        }, {
            probabilities: [0.5],
            units: [{ currentType: "hero", maxStage: 2 }]
        }];
    },

    level4: function (unit) {
        return [{
            amount: 6,
            probabilities: [0.06, 0.1, 0.25, 0.16],
            units: [
                { code: "worker", stage: 0 },
                { code: "crystal", stage: 0 },
                { code: "coins", stage: 0 },
                { type: "resource", stage: 0 },
                { code: unit.getResource(), stage: 1 }
            ]
        }, {
            probabilities: [0.25],
            units: [{ type: "fruit", stage: 0 }]
        }, {
            probabilities: [0.7],
            units: [{ currentType: "hero", maxStage: 2 }]
        }];
    }
};