/**
 * Created by Aleksandr on 31.10.2022
 */

var LandmarkItem = cc.Node.extend({
    ctor: function (landmark) {
        this._super();
        this.landmark = landmark;
        this.selected = false;
        var styles = cleverapps.styles.LandMarkUnit;

        this.setContentSize2(styles.unit_background);
        this.setBackground(bundles.landmarkwindow.frames.background);

        var text = cleverapps.unitsLibrary.getUnitName(landmark);
        var title = this.title = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.LIGHT_TEXT);
        cleverapps.UI.fitToBox(title, styles.title);
        title.setPositionRound(styles.title);
        this.addChild(title);

        var unitView = this.unitView = UnitView.getUnitImage(landmark, { preferStatic: true, useLibraryAnchors: true });
        cleverapps.UI.fitToBox(this.unitView, styles.unit);
        unitView.setPositionRound(styles.unit);
        this.unitView.setCascadeOpacityEnabledRecursively(true);
        this.addChild(unitView);
        unitView.setOpacity(150);
    },

    setBackground: function (background) {
        if (this.bg) {
            this.bg.removeFromParent();
            delete this.bg;
        }
        var bg = this.bg = cleverapps.UI.createScale9Sprite(background, cleverapps.UI.Scale9Rect.TwoPixelXY);
        bg.setContentSize2(this.getContentSize());
        bg.setPositionRound(this.width / 2, this.height / 2);
        this.addChild(bg, -1);
    },

    addButton: function () {
        var styles = cleverapps.styles.LandMarkUnit;

        this.removeButton();
        var content = new cc.Node();
        var icon = new cc.Sprite(bundles.landmarkwindow.frames.magnifier_icon);
        content.addChild(icon);

        var button = this.button = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.small_button_blue,
            onClicked: this.findButtonClick.bind(this),
            content: content,
            width: styles.button.width,
            height: styles.button.height
        });
        this.addChild(button);
        button.setPositionRound(styles.button);
    },

    removeButton: function () {
        if (this.button) {
            this.button.removeFromParent();
        }
    },

    findButtonClick: function () {
        cleverapps.windows.currentWindow().close();

        if (Game.currentGame.landmarks.isOnMap(this.landmark)) {
            UnitLocator.find(Map2d.OPEN_UNIT, this.landmark, { allowScrollWithFocus: true });
            return;
        }

        if (this.landmark.feature) {
            if (cleverapps.eventManager.isActive(this.landmark.feature)) {
                new LandMarkDonorWindow(this.landmark);
            } else {
                new TravelBookWindow({ targetPage: "unavailable" });
            }
            return;
        }

        new TravelBookWindow({ targetPage: this.landmark.expedition });
    },

    setCheckmark: function () {
        if (this.selected && !this.checkmark) {
            this.checkmark = new cc.Sprite(bundles.landmarkwindow.frames.checkmark);
            this.checkmark.setPositionRound(cleverapps.styles.LandMarkUnit.checkmark);
            this.addChild(this.checkmark);
        } else if (!this.selected && this.checkmark) {
            this.checkmark.removeFromParent();
            delete this.checkmark;
        }
    },

    canDeploy: function () {
        return Game.currentGame.landmarks.canDeploy(this.landmark);
    },

    canHint: function () {
        return !this.canDeploy() && !this.selected;
    },

    updateState: function () {
        if (this.getDeployed()) {
            this.setOpened(true);
            this.setSelected(true);
        } else {
            this.setOpened(Game.currentGame.landmarks.canDeploy(this.landmark));
            this.setSelected(false);
        }

        if (this.canHint()) {
            this.addButton();
        } else {
            this.removeButton();
        }
    },

    getDeployed: function () {
        return Game.currentGame.landmarks.listDeployed().find(function (unit) {
            return Unit.IsApplicable(this.landmark, unit);
        }, this);
    },

    setOpened: function (opened) {
        this.setBackground(opened ? bundles.landmarkwindow.frames.background_selected : bundles.landmarkwindow.frames.background);
        this.unitView.setOpacity(opened ? 255 : 150);
    },

    setSelected: function (state) {
        if (state === this.selected) {
            return;
        }

        this.selected = state;
        this.setCheckmark();
        this.setScale(state ? 1.1 : 1.0);
        this.baseScale = state ? 1.1 : 1.0;
    }
});

cleverapps.styles.LandMarkUnit = {
    title: {
        x: { align: "center" },
        y: { align: "center", dy: 95 },

        width: 200
    },

    unit: {
        x: { align: "center" },
        y: { align: "center", dy: -15 },

        width: 160,
        height: 160
    },

    unit_background: {
        width: 250,
        height: 260
    },

    minimal_dialogue: {
        scale: 0.7
    },

    checkmark: {
        x: { align: "right", dx: -20 },
        y: { align: "bottom" }
    },

    button: {
        x: { align: "right", dx: -11 },
        y: { align: "bottom", dy: 15 },
        width: 70,
        height: 70,
        content: {
            width: 50,
            height: 70
        }
    }
};
