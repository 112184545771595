/**
 * Created by mac on 2019-06-17
 */

var MineSweeper = function (options) {
    BaseMiniGame.call(this);

    this.width = MineSweeper.WIDTH;
    this.height = MineSweeper.HEIGHT;
    this.cellsToOpen = this.width * this.height - MineSweeper.CONFIG.b;

    this.saver = options.saver;
};

MineSweeper.CONFIG = {
    b: 11,
    energy: 14,
    soft: 16,
    hard: 2
};

MineSweeper.prototype = Object.create(BaseMiniGame.prototype);
MineSweeper.prototype.constructor = MineSweeper;

MineSweeper.ELEMENT_OPENED = 0;
MineSweeper.ELEMENT_BOMB = "b";
MineSweeper.ELEMENT_OPENED_BOMB = "bo";
MineSweeper.ELEMENT_EXPLODED_BOMB = "be";
MineSweeper.ELEMENT_EMPTY = "e";
MineSweeper.ELEMENT_RUBY = "hard";
MineSweeper.ELEMENT_ENERGY = "energy";
MineSweeper.ELEMENT_COIN = "soft";

MineSweeper.WIDTH = 8;
MineSweeper.HEIGHT = 7;

MineSweeper.REPLAY_PRICE = 20;

MineSweeper.prototype.generateField = function (col, row) {
    this.reward = {};
    this.board = [];
    this.bombs = [];
    this.prizes = [];
    var i, j;

    var inFreeArea = function (r, c) {
        return Math.abs(r - row) <= 1 && Math.abs(c - col) <= 1;
    };

    var generate = function (config, cellNumb) {
        var items = [];
        for (var type in config) {
            for (var i = 0; i < config[type]; i++) {
                if (items.length < cellNumb) {
                    items.push(type);
                }
            }
        }

        while (items.length < cellNumb) {
            items.push(MineSweeper.ELEMENT_EMPTY);
        }

        cleverapps.Random.randomSeed();
        items = cleverapps.Random.shuffle(items);

        return items;
    };

    var freeAreaSize = 0;
    for (i = 0; i < this.height; i++) {
        for (j = 0; j < this.width; j++) {
            if (inFreeArea(i, j)) {
                freeAreaSize++;
            }
        }
    }

    var notFreeItems = generate(MineSweeper.CONFIG, this.width * this.height - freeAreaSize);
    this.board = [];
    for (i = 0; i < this.height; i++) {
        this.board.push([]);
        for (j = 0; j < this.width; j++) {
            if (inFreeArea(i, j)) {
                this.board[i].push(MineSweeper.ELEMENT_EMPTY);
                continue;
            }
            var elem = notFreeItems.pop();
            this.board[i].push(elem);
            if (elem === MineSweeper.ELEMENT_BOMB) {
                this.bombs.push({ col: j, row: i });
            } else if ([MineSweeper.ELEMENT_RUBY, MineSweeper.ELEMENT_ENERGY, MineSweeper.ELEMENT_COIN].indexOf(elem) !== -1) {
                this.prizes.push({ col: j, row: i });
            }
        }
    }

    this.cellsToOpen = this.width * this.height - this.bombs.length;
    this.trigger("cellsToOpen", this.cellsToOpen);
};

MineSweeper.prototype.initFlags = function () {
    this.flags = [];
    for (var i = 0; i < this.height; i++) {
        this.flags.push([]);
        for (var j = 0; j < this.width; j++) {
            this.flags[i].push(false);
        }
    }
};

MineSweeper.prototype.hasElement = function (col, row) {
    if (row < 0 || col < 0 || row >= this.height || col >= this.width) {
        return false;
    }
    if (!this.board) {
        return true;
    }
    return this.board[row][col] !== MineSweeper.ELEMENT_OPENED && this.board[row][col] !== MineSweeper.ELEMENT_OPENED_BOMB && this.board[row][col] !== MineSweeper.ELEMENT_EXPLODED_BOMB;
};

MineSweeper.prototype.getPrizeValue = function (type) {
    if (type === MineSweeper.ELEMENT_ENERGY) {
        return cleverapps.Random.random(3, 5);
    } if (type === MineSweeper.ELEMENT_COIN) {
        return cleverapps.Random.random(2, 3);
    }
    return cleverapps.Random.random(1, 2);
};

MineSweeper.prototype.openField = function () {
    this.trigger("openField");

    for (var i = 0; i < this.height; i++) {
        for (var j = 0; j < this.width; j++) {
            if (this.flags[i][j]) {
                this.trigger("removeFlag", { row: i, col: j, silent: true });
                this.flags[i][j] = false;
            }
        }
    }

    this.prizes.forEach(function (prize) {
        if (this.board[prize.row][prize.col] !== MineSweeper.ELEMENT_OPENED) {
            this.trigger("showPrize", {
                col: prize.col,
                row: prize.row,
                prize: { type: this.board[prize.row][prize.col] }
            });
        }
    }.bind(this));

    this.bombs.forEach(function (bomb) {
        if (this.board[bomb.row][bomb.col] !== MineSweeper.ELEMENT_OPENED_BOMB) {
            this.trigger("showBomb", {
                col: bomb.col,
                row: bomb.row
            });
        }
    }.bind(this));
};

MineSweeper.prototype.win = function () {
    if (!this.board) {
        this.generateField(0, 0);
    }
    if (!this.flags) {
        this.initFlags();
    }
    this.openField();

    this.saver.add({ reward: this.reward });

    this.trigger("win");
    this.finish();
    setTimeout(function () {
        this.trigger("victory");
    }.bind(this), 1000);
};

MineSweeper.prototype.lose = function (f, options) {
    cleverapps.audio.fadeOut();

    cleverapps.meta.compound(f, [
        function (f) {
            this.trigger("bomb", options);

            cleverapps.timeouts.setTimeout(f, 1000);
        }.bind(this),

        function (f) {
            this.explode(options);

            cleverapps.timeouts.setTimeout(f, 1000);
        }.bind(this),

        function (f) {
            if (cleverapps.platformLogic.reportDefeat) {
                cleverapps.platformLogic.reportDefeat(f);
            } else {
                f();
            }
        },

        function (f) {
            this.trigger("lose", f);
        }.bind(this)
    ]);
};

MineSweeper.prototype.explode = function (options) {
    this.board[options.row][options.col] = MineSweeper.ELEMENT_EXPLODED_BOMB;

    for (var type in this.reward) {
        var newValue = Math.floor(this.reward[type] * (0.3 + 0.2 * Math.random()));
        var delta = newValue - this.reward[type];
        this.reward[type] = newValue;
        this.trigger("explodePrize", {
            type: type,
            amount: delta
        });
    }

    this.saver.add({ reward: this.reward });

    this.openField();
};

MineSweeper.prototype.switchFlag = function (col, row) {
    if (row < 0 || col < 0 || row >= this.height || col >= this.width) {
        return;
    }

    if (this.board) {
        if (this.board[row][col] === MineSweeper.ELEMENT_OPENED || this.board[row][col] === MineSweeper.ELEMENT_OPENED_BOMB || this.board[row][col] === MineSweeper.ELEMENT_EXPLODED_BOMB) {
            return;
        }
    }

    if (!this.flags) {
        this.initFlags();
    }

    if (this.flags[row][col]) {
        this.trigger("removeFlag", { row: row, col: col });
        this.flags[row][col] = false;
    } else {
        this.trigger("addFlag", { row: row, col: col });
        this.flags[row][col] = true;
    }
};

MineSweeper.prototype.open = function (col, row) {
    if (row < 0 || col < 0 || row >= this.height || col >= this.width) {
        return;
    }

    if (!this.board) {
        this.generateField(col, row);
        cleverapps.eventBus.trigger("taskEvent", DailyTasks.PLAY_MINESWEEPER);
    }

    if (this.board[row][col] === MineSweeper.ELEMENT_OPENED || this.board[row][col] === MineSweeper.ELEMENT_OPENED_BOMB || this.board[row][col] === MineSweeper.ELEMENT_EXPLODED_BOMB) {
        return;
    }

    if (!this.flags) {
        this.initFlags();
    }

    if (this.flags[row][col]) {
        return;
    }

    if (this.board[row][col] === MineSweeper.ELEMENT_BOMB) {
        this.board[row][col] = MineSweeper.ELEMENT_OPENED_BOMB;
        this.pause();
        this.trigger("open", { row: row, col: col, bomb: true });
        this.trigger("askContinue", { row: row, col: col });
        return;
    }

    var value = this.board[row][col];
    this.board[row][col] = MineSweeper.ELEMENT_OPENED;
    var bombs = this.countBombsNearby(col, row);
    var options = {
        row: row,
        col: col,
        bombs: bombs
    };
    this.cellsToOpen--;
    this.trigger("cellsToOpen", this.cellsToOpen);

    this.trigger("open", options);
    if (!bombs) {
        this.open(col - 1, row - 1);
        this.open(col, row - 1);
        this.open(col + 1, row - 1);
        this.open(col - 1, row);
        this.open(col + 1, row);
        this.open(col - 1, row + 1);
        this.open(col, row + 1);
        this.open(col + 1, row + 1);
    }

    if ([MineSweeper.ELEMENT_RUBY, MineSweeper.ELEMENT_ENERGY, MineSweeper.ELEMENT_COIN].indexOf(value) !== -1) {
        var rewardAmount = this.getPrizeValue(value);
        this.trigger("prize", {
            col: col,
            row: row,
            prize: { type: value, amount: rewardAmount }
        });

        this.reward[value] = (this.reward[value] || 0) + rewardAmount;
    }

    if (this.isFinished()) {
        this.win();
    }
};

MineSweeper.prototype.listNearby = function (col, row) {
    var near = [];
    for (var i = col - 1; i <= col + 1; i++) {
        if (i >= 0 && i < this.width) {
            for (var j = row - 1; j <= row + 1; j++) {
                if (j >= 0 && j < this.height) {
                    if (this.board[j][i] !== MineSweeper.ELEMENT_OPENED) {
                        near.push({
                            row: j,
                            col: i
                        });
                    }
                }
            }
        }
    }
    return near;
};

MineSweeper.prototype.countBombsNearby = function (col, row) {
    return this.listNearby(col, row).filter(function (pos) {
        return this.board[pos.row][pos.col] === MineSweeper.ELEMENT_BOMB || this.board[pos.row][pos.col] === MineSweeper.ELEMENT_OPENED_BOMB || this.board[pos.row][pos.col] === MineSweeper.ELEMENT_EXPLODED_BOMB;
    }.bind(this)).length;
};

MineSweeper.prototype.isFinished = function () {
    return this.cellsToOpen === 0;
};