/**
 * Created by r4zi4l on 04.06.2021
 */

var SpecialEnergyOfferWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this.specialEnergyOffer = Game.currentGame.specialEnergyOffer;

        this._super({
            name: "SpecialEnergyOfferWindow",
            content: this.createContent(),
            title: "SpecialEnergyOfferWindow.title",
            noBackground: true,
            closeButton: true,
            closeButtonDelay: true,
            notCloseByTouchInShadow: true,
            openSound: bundles.specialenergyofferwindow.urls.show_person_sfx
        });

        cleverapps.paymentsLogic.onChangeStatus(this.updateButton.bind(this), this);

        cleverapps.meta.showControlsWhileFocused(["MenuBarGoldItem", "MenuBarLivesItem"]);

        this.specialEnergyOffer.onBought = this.createListener(this.close.bind(this));
    },

    updateButton: function () {
        if (connector.payments.isConnected() || this.specialEnergyOffer.getProduct() instanceof VirtualProduct) {
            this.button.enable();
        } else {
            this.button.disable();
        }
    },

    getPerson: function () {
        return cleverapps.config.name === "hustlemerge" ? "seller" : cleverapps.persons.choose("jihn", "dwarf");
    },

    createContent: function () {
        var styles = cleverapps.styles.SpecialEnergyOfferWindow;

        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        container.setContentSize2(styles.animation);

        this.animation = new cleverapps.Spine(bundles.specialenergyofferwindow.jsons.energy_offer_json);
        var skin = cleverapps.travelBook.isExpedition() ? "expedition" : "normal";
        if (this.animation.hasSkin(skin)) {
            this.animation.setSkin(skin);
        }
        container.addChild(this.animation);
        this.animation.setPositionRound(styles.animation);
        this.animation.setAnimationAndIdleAfter("open", "idle");

        this.button = new cleverapps.UI.Button({
            text: this.specialEnergyOffer.getPriceTag(),
            width: styles.button.width,
            height: styles.button.height,
            onClicked: function () {
                this.specialEnergyOffer.buy();
            }.bind(this)
        });

        this.updateButton();

        var timer = this.timerBlock = this.createTimer();

        this.createReward(container);

        return new cleverapps.Layout([container, timer, this.button], {
            margin: styles.margin,
            direction: cleverapps.UI.VERTICAL
        });
    },

    createTimer: function () {
        var styles = cleverapps.styles.SpecialEnergyOfferWindow.timer;

        var text = cleverapps.UI.generateOnlyText(
            "SpecialEnergyOfferWindow.timeLeft",
            cleverapps.styles.FONTS.ENERGY_OFFER_TIMER
        );
        text.fitTo(styles.text.width);
        var timeLeft = this.specialEnergyOffer.getIconLeftTime();
        var timer = this.timer = new Timer(timeLeft, this.timeIsUp.bind(this));

        return new cleverapps.Layout([text, timer], {
            margin: styles.margin,
            direction: cleverapps.UI.HORIZONTAL
        });
    },

    timeIsUp: function () {
        this.timerBlock.removeAllChildren();
    },

    createReward: function (container) {
        var sprite = cleverapps.skins.getSlot("energy_icon_0") || bundles.specialenergyofferwindow.frames.energy_icon_0;
        if (!sprite) {
            return;
        }

        var icon = new cc.Sprite(sprite);
        var txt = cleverapps.UI.generateImageText("x" + this.specialEnergyOffer.getEnergyAmount(), cleverapps.styles.FONTS.ENERGY_OFFER);

        var styles = cleverapps.styles.SpecialEnergyOfferWindow.reward;

        var node = this.rewardNode = new cleverapps.Layout([icon, txt], {
            direction: cleverapps.UI.HORIZONTAL
        });
        container.addChild(node);
        node.setPositionRound(styles);
        node.setRotation(styles.rotation || 0);
    },

    onShow: function () {
        this._super();

        this.animation.setOpacity(0);
        this.animation.runAction(new cc.FadeIn(0.4));

        this.button.setScale(0);
        this.button.runAction(new cc.Sequence(
            new cc.DelayTime(0.15),
            new cc.ScaleTo(0.2, 1)
        ));
    },

    listBundles: function () {
        return ["specialenergyofferwindow"];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ENERGY_OFFER: {
        size: 60,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    },
    ENERGY_OFFER_TIMER: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.SpecialEnergyOfferWindow = {
    margin: 30,

    button: {
        width: 300,
        height: 120
    },

    reward: {
        x: { align: "center" },
        y: { align: "center", dy: -284 }
    },

    animation: {
        width: 1200,
        height: 700,

        x: { align: "center" },
        y: { align: "center", dy: 100 }
    },

    timer: {
        margin: 20,

        text: {
            width: 500
        }
    }
};
