/**
 * Created by Andrey Popov on 13.08.2024
 */

CustomerRecipes.universal = {
    rpproducer0_0: [
        {
            ingredients: [{
                stage: 3,
                amount: 1
            }],
            order: [{
                code: "rpproduct0",
                stage: 0,
                amount: 1
            }]
        }],

    rpproducer1_0: [
        {
            requiredUnits: [{ code: "rpcustomermain0b", stage: 1 }],
            ingredients: [{
                code: "rpproduct0",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "rpproduct1",
                stage: 0,
                amount: 1
            }]
        }
    ],

    rpproducer2_0: [
        {
            requiredUnits: [{ code: "rpcustomermain1c", stage: 1 }],
            ingredients: [{
                code: "rpproduct1",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "rpproduct2",
                stage: 0,
                amount: 1
            }]
        }
    ],

    rpproducer3_0: [
        {
            requiredUnits: [{ code: "rpcustomermain2d", stage: 1 }],
            ingredients: [{
                code: "rpproduct2",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "rpproduct3",
                stage: 0,
                amount: 1
            }]
        }
    ],

    rpcustomerbridge0_0: [
        {
            fog: "fog1",
            ingredients: [{
                stage: 3,
                amount: 3
            }],
            order: [{
                code: "coins",
                stage: 1,
                amount: 1
            }]
        }
    ],

    rpcustomerbridge1_0: [
        {
            requiredUnits: [{ code: "rpcustomermain0b", stage: 1 }],
            ingredients: [{
                code: "rpproduct0",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "coins",
                stage: 1,
                amount: 1
            }]
        }
    ],

    rpcustomerbridge2_0: [
        {
            requiredUnits: [{ code: "rpcustomermain1c", stage: 1 }],
            ingredients: [{
                code: "rpproduct1",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "coins",
                stage: 1,
                amount: 1
            }]
        }
    ],

    rpcustomerbridge3_0: [
        {
            requiredUnits: [{ code: "rpcustomermain2d", stage: 1 }],
            ingredients: [{
                code: "rpproduct2",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "coins",
                stage: 1,
                amount: 1
            }]
        }
    ],

    rpcustomermain0a_0: [
        {
            ingredients: [{
                code: "rpproduct0",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "coins",
                stage: 1,
                amount: 1
            }]
        }
    ],

    rpcustomermain0b_0: [
        {
            ingredients: [{
                code: "rpproduct0",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "energy",
                stage: 0,
                amount: 1
            }],
            requiredUnits: [{ code: "rpcustomermain0a", stage: 1 }]
        }
    ],

    rpcustomermain1a_0: [
        {
            requiredUnits: [{ code: "rpcustomermain0a", stage: 1 }],
            ingredients: [{
                code: "rpproduct1",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "coins",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 0,
                amount: 1
            }]
        }
    ],

    rpcustomermain1b_0: [
        {
            requiredUnits: [{ code: "rpcustomermain1a", stage: 1 }],
            ingredients: [{
                code: "rpproduct1",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "energy",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 0,
                amount: 1
            }]
        }
    ],

    rpcustomermain1c_0: [
        {
            requiredUnits: [{ code: "rpcustomermain1b", stage: 1 }],
            ingredients: [{
                code: "rpproduct1",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "energy",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 0,
                amount: 1
            }]
        }
    ],

    rpcustomermain2a_0: [
        {
            ingredients: [{
                code: "rpproduct2",
                stage: 0,
                amount: 1
            }],
            order: [{
                code: "coins",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 0,
                amount: 1
            }]
        }
    ],

    rpcustomermain2b_0: [
        {
            requiredUnits: [{ code: "rpcustomermain2a", stage: 1 }],
            ingredients: [{
                code: "rpproduct2",
                stage: 0,
                amount: 2
            }],
            order: [{
                code: "energy",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 0,
                amount: 1
            }]
        }
    ],

    rpcustomermain2c_0: [
        {
            requiredUnits: [{ code: "rpcustomermain2b", stage: 1 }],
            ingredients: [{
                code: "rpproduct2",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "energy",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 0,
                amount: 1
            }]
        }
    ],

    rpcustomermain2d_0: [
        {
            requiredUnits: [{ code: "rpcustomermain2c", stage: 1 }],
            ingredients: [{
                code: "rpproduct2",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "energy",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 0,
                amount: 1
            }]
        }
    ],

    rpcustomermain3a_0: [
        {
            ingredients: [{
                code: "rpproduct3",
                stage: 0,
                amount: 1
            }],
            order: [{
                code: "coins",
                stage: 1,
                amount: 1
            },
            {
                code: "crystal",
                stage: 1,
                amount: 1
            }]
        }
    ],
    rpcustomermain3b_0: [
        {
            requiredUnits: [{ code: "rpcustomermain3a", stage: 1 }],
            ingredients: [{
                code: "rpproduct3",
                stage: 0,
                amount: 2
            }],
            order: [{
                code: "energy",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 0,
                amount: 1
            }]
        }
    ],

    rpcustomermain3c_0: [
        {
            requiredUnits: [{ code: "rpcustomermain3b", stage: 1 }],
            ingredients: [{
                code: "rpproduct3",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "energy",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 0,
                amount: 1
            }]
        }
    ],
    rpcustomermain3d_0: [
        {
            requiredUnits: [{ code: "rpcustomermain3c", stage: 1 }],
            ingredients: [{
                code: "rpproduct3",
                stage: 0,
                amount: 3
            }],
            order: [{
                code: "energy",
                stage: 0,
                amount: 1
            },
            {
                code: "crystal",
                stage: 0,
                amount: 1
            }]
        }
    ]
};