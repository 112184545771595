/**
 * Created by razial on 19.10.2021
 */

cleverapps.SkinManager.SKINS.undersea_expedition = cleverapps.SkinManager.SKINS.undersea2_expedition = cleverapps.SkinManager.SKINS.undersea3_expedition = {
    localizations: {
        undersea: {
            "Fog.clear.notEnoughWands": "Undersea.Fog.clear.notEnoughWands",
            "Battle.notEnoughWands": "Undersea.Battle.notEnoughWands",
            "RudolfGuideWindow.title": "Undersea.RudolfGuideWindow.title",
            "RudolfGuideWindow.firstStage": "Undersea.RudolfGuideWindow.firstStage",
            "RudolfGuideWindow.secondStage": "Undersea.RudolfGuideWindow.secondStage",
            "RudolfGuideWindow.thirdStage": "Undersea.RudolfGuideWindow.thirdStage",

            "ImproverGuideWindow.firstStage": "Undersea.ImproverGuideWindow.firstStage",
            "ImproverGuideWindow.secondStage": "Undersea.ImproverGuideWindow.secondStage",
            "ImproverGuideWindow.thirdStage": "Undersea.ImproverGuideWindow.thirdStage",

            "ExpeditionWandsGuideWindow.title": "Undersea.ExpeditionWandsGuideWindow.title",
            "ExpeditionWandsGuideWindow.firstStage": "Undersea.ExpeditionWandsGuideWindow.firstStage",
            "ExpeditionWandsGuideWindow.secondStage": "Undersea.ExpeditionWandsGuideWindow.secondStage",
            "ExpeditionWandsGuideWindow.thirdStage": "Undersea.ExpeditionWandsGuideWindow.thirdStage",

            "FeastMissionRulesWindow.ExpeditionFeast.title": "Undersea.FeastMissionRulesWindow.ExpeditionFeast.title",
            "FeastMissionRulesWindow.ExpeditionFeast.description": "Undersea.FeastMissionRulesWindow.ExpeditionFeast.description",
            "FeastMissionRulesWindow.ExpeditionFeast.firstStage": "Undersea.FeastMissionRulesWindow.ExpeditionFeast.firstStage",
            "FeastMissionRulesWindow.ExpeditionFeast.secondStage": "Undersea.FeastMissionRulesWindow.ExpeditionFeast.secondStage",
            "FeastMissionRulesWindow.ExpeditionFeast.thirdStage": "Undersea.FeastMissionRulesWindow.ExpeditionFeast.thirdStage",

            "ExpeditionFeastWindow.title": "Undersea.ExpeditionFeastWindow.title",
            "ExpeditionFeastWindow.tooltip.amount": "Undersea.ExpeditionFeastWindow.tooltip.amount",

            "ExpeditionPassRulesWindow.title": "Undersea.ExpeditionPassRulesWindow.title",
            "ExpeditionPassRulesWindow.firstStage": "Undersea.ExpeditionPassRulesWindow.firstStage",
            "ExpeditionPassRulesWindow.secondStage": "Undersea.ExpeditionPassRulesWindow.secondStage",
            "ExpeditionPassRulesWindow.thirdStage": "Undersea.ExpeditionPassRulesWindow.thirdStage",
            "PassWindow.moreItemsToolTip.star": "Undersea.PassWindow.moreItemsToolTip.star"
        },

        undersea2: {
            "ExpeditionWandsGuideWindow.title": "Undersea2.ExpeditionWandsGuideWindow.title",
            "ExpeditionWandsGuideWindow.firstStage": "Undersea2.ExpeditionWandsGuideWindow.firstStage",
            "ExpeditionWandsGuideWindow.secondStage": "Undersea2.ExpeditionWandsGuideWindow.secondStage",
            "ExpeditionWandsGuideWindow.thirdStage": "Undersea2.ExpeditionWandsGuideWindow.thirdStage",

            "ExpeditionPassRulesWindow.title": "Undersea.ExpeditionPassRulesWindow.title",
            "ExpeditionPassRulesWindow.firstStage": "Undersea.ExpeditionPassRulesWindow.firstStage",
            "ExpeditionPassRulesWindow.secondStage": "Undersea.ExpeditionPassRulesWindow.secondStage",
            "ExpeditionPassRulesWindow.thirdStage": "Undersea.ExpeditionPassRulesWindow.thirdStage",
            "PassWindow.moreItemsToolTip.star": "Undersea.PassWindow.moreItemsToolTip.star"

        },

        undersea3: {
            "ExpeditionWandsGuideWindow.title": "Undersea3.ExpeditionWandsGuideWindow.title",
            "ExpeditionWandsGuideWindow.firstStage": "Undersea3.ExpeditionWandsGuideWindow.firstStage",
            "ExpeditionWandsGuideWindow.secondStage": "Undersea3.ExpeditionWandsGuideWindow.secondStage",
            "ExpeditionWandsGuideWindow.thirdStage": "Undersea3.ExpeditionWandsGuideWindow.thirdStage"
        }
    },

    bundle: "expedition_undersea",

    slots: {
        skinName: function () {
            return "undersea";
        },

        localization: function (code) {
            var localizations = cleverapps.SkinManager.SKINS.undersea_expedition.localizations;
            var expedition = cleverapps.travelBook.getCurrentPage().id;
            return localizations && localizations[expedition] && localizations[expedition][code];
        },

        workerSkin: function () {
            var json = cleverapps.skins.getSlot("worker_json") || bundles.mineable.jsons.worker_json;
            return cleverapps.Spine.hasSkin("undersea", json) ? "undersea" : undefined;
        },

        missionTableRowIcon: function (options) {
            if (Mission.GetChildType(options.type) === Mission.TYPE_EXPEDITION_FEAST) {
                return bundles.tablerow_icons.frames.feast_pearl;
            }
        },

        unitMoveAnimation: function () {
            return {
                json: bundles.merge.jsons.move_animation,
                zOrder: -1,
                sound: bundles.merge.urls.move_animation_effect
            };
        },

        gameAudio: function () {
            return bundles.expedition_undersea.urls.undersea_music;
        },

        bonusWorkerProductAnimation: function () {
            return cleverapps.config.name === "fairy" ? "animation" : "animation2";
        },

        bonusWorkerPersonSkin: function () {
            var animation = cleverapps.persons.getRole("narrator").json;
            return cleverapps.Spine.hasSkin("undersea", animation) ? "undersea" : undefined;
        },

        avatarFramesBundle: function () {
            return cleverapps.bundleLoader.isLoaded("snail_feast") ? "snail_feast" : undefined;
        },

        king: function () {
            return cleverapps.config.name === "fairy" ? "dwarf" : "king";
        },

        pixelView: function () {
            return cleverapps.styles.undersea.PixelView;
        },

        unitIcon: function (unit) {
            return bundles.unit_icons_undersea.frames["small_icon_family_" + unit.code];
        },

        unitOnCell: function () {
            return cleverapps.styles.UnitOnCellExpedition;
        },

        gameSceneBg: function () {
            return {
                scale9: bundles.expedition_undersea.frames.undersea_bg,
                animation: bundles.expedition_undersea.jsons.undersea_bg_animation,
                topAnimation: bundles.expedition_undersea.jsons.undersea_bg_animation_top,
                parallax: true
            };
        },

        hasChessLayer: function () {
            return true;
        },

        merge_wand_png: bundles.menubar.frames.undersea_merge_wand_png,

        wand_icon_png: bundles.buttons_inlined_icons.frames.undersea_wand_icon_png,
        feed_icon_png: bundles.buttons_inlined_icons.frames.undersea_feed_icon_png,

        expedition_buildpass_icon_json: bundles.sidebar.jsons.undersea_expedition_buildpass_icon_json,
        expedition_feast_icon_json: bundles.sidebar.jsons.undersea_expedition_feast_icon_json,
        snail_feast_icon_json: bundles.sidebar.jsons.undersea_snail_feast_icon_json,
        snailhouse_offer_json: bundles.sidebar.jsons.undersea_snailhouse_offer_json,

        merge_animation_json: bundles.merge_animations.jsons.undersea_merge_animation_json,

        expedition_feast_icon: bundles.reward_icons.frames.undersea_expedition_feast_icon,
        snail_feast_icon: bundles.reward_icons.frames.undersea_snail_feast_icon,
        reward_wand_png: bundles.reward_icons.frames.undersea_reward_wand_png,
        reward_wand_small_png: bundles.reward_icons.frames.undersea_reward_wand_small_png,

        expeditionPassGuideBundle: function () {
            return bundles.undersea_expeditionguidewindow;
        },

        expeditionFeastBundle: function () {
            return bundles.expedition_feast_undersea;
        },

        rudolfGuideBundle: function () {
            return bundles.rudolfguidewindow_undersea;
        },

        getPoiAnimation: function (unit) {
            if (unit.code === "rpcustomermain3a") {
                return "idle_fog";
            }
        }
    }
};
