/**
 * Created by r4zi4l on 15.09.2021
 */

var MergeAdviceClickLogic = function (options) {
    MergeAdviceBaseLogic.call(this, options);
};

MergeAdviceClickLogic.prototype = Object.create(MergeAdviceBaseLogic.prototype);
MergeAdviceClickLogic.prototype.constructor = MergeAdviceClickLogic;

MergeAdviceClickLogic.prototype.findMove = function () {
    var unit = this.listAvailableUnits()[0];

    if (unit) {
        return {
            cells: [cc.p(unit.x, unit.y)],
            unitView: [ "click" ]
        }
    }
};
