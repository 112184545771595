/**
 * Created by razial on 24.03.2023
 */

cleverapps.Lives.prototype.getMaxLives = function () {
    return this.slot === CustomSyncers.SLOT_MAIN ? 75 + cleverapps.user.level * 2 : 80;
};

cleverapps.Lives.prototype.canShowForce = function () {
    return VirtualProducts.buyEnergy350
        && Game.currentGame && Game.currentGame.isMainGame() && cleverapps.gameLevel
        && !cleverapps.forces.isShown(Forces.BUY_ENERGY_ICON.id);
};

cleverapps.Lives.prototype.showForce = function () {
    if (!this.canShowForce()) {
        return;
    }

    return cleverapps.menuBar.showForce("LivesItem");
};

cleverapps.Lives.TIMEOUT = cleverapps.parseInterval("3 minutes");
