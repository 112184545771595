/**
 * Created by mac on 12/8/20
 */

var Map2dView = cc.Node.extend({
    ctor: function (map2d) {
        this._super();

        this.map2d = map2d;

        map2d.onDiscoverView = function () {
            return this;
        }.bind(this);

        var visibleBox = map2d.visibleBox;

        var SIZE = map2d.getWidth() + map2d.getHeight();
        // console.log(map2d.getWidth(), map2d.getHeight())
        var totalWidth = SIZE * cleverapps.styles.Map2dView.cell.width / 2;
        var totalHeight = SIZE * cleverapps.styles.Map2dView.cell.height / 2;

        this.cutLeft = totalWidth * visibleBox.cutLeft;
        this.cutRight = totalWidth * visibleBox.cutRight;

        this.cutTop = totalHeight * visibleBox.cutTop;
        this.cutBottom = totalHeight * visibleBox.cutBottom;

        this.setContentSize2(totalWidth - this.cutLeft - this.cutRight, totalHeight - this.cutTop - this.cutBottom);
        this.setAnchorPoint(0.5, 0.5);

        var under = this.under = new cc.Node();
        under.setContentSize(this.getContentSize());
        under.setPositionRound(this.width / 2, this.height / 2);
        under.setAnchorPoint(0.5, 0.5);
        this.addChild(under, -1);

        var cloudsAbove = this.cloudsAbove = new cc.Node();
        cloudsAbove.setContentSize(this.getContentSize());
        cloudsAbove.setPositionRound(this.width / 2, this.height / 2);
        cloudsAbove.setAnchorPoint(0.5, 0.5);
        this.addChild(cloudsAbove, 1);

        var animations = this.animations = this.movingNode = new cc.Node();
        animations.setContentSize(this.getContentSize());
        animations.setPositionRound(this.width / 2, this.height / 2);
        animations.setAnchorPoint(0.5, 0.5);
        animations.debugId = "Animations";
        this.addChild(animations, 1);
        if (cleverapps.config.debugMode) {
            animations.isExcluded = true;
        }

        map2d.onAdd = this.createListener(function (layer, x, y, object) {
            if (cleverapps.config.debugMode && object instanceof Unit) {
                throw "User onAddUnit to create unit view on map";
            }

            var view = this.createView(object);
            if (object && view) {
                this.addTile(layer, x, y, view);
                if (map2d.getValue(layer, x, y) === object) {
                    view.cell.view = true;
                }
            }
        }.bind(this));

        map2d.onAddUnit = this.createListener(function (x, y, unit) {
            if (unit) {
                var unitView = this.createView(unit);
                if (unitView) {
                    this.addTile(unitView.layer, x, y, unitView);
                    if (map2d.getValue(unitView.layer, x, y) === unit) {
                        unitView.cell.view = true;
                    }
                }
            }
        }.bind(this));

        map2d.onAnimationsAdd = this.createListener(function (object) {
            var view = this.createView(object);
            if (view) {
                this.animations.addChild(view);
            }
        }.bind(this));

        this.addControls();

        cleverapps.aims.registerTarget("units", this, {
            noTargetDelta: true,
            flyingUnderShadow: true,
            flyingAnimation: Reward.SPAWN_UNITS_ANIMATION
        });

        var layers = this.getLayersOrder();
        this.layers = layers;
        this.layersFlat = this.layers.flat();

        this.tiles = [];

        for (var i = 0; i < layers.length; i++) {
            this.tiles[layers[i][0]] = [];
            for (var y = Math.round(-map2d.getHeight() * 0.5); y < Math.round(map2d.getHeight() * 1.5); y++) {
                this.tiles[layers[i][0]][y] = (y >= 0 && y < map2d.getHeight()) ? [] : {};
            }
            for (var j = 1; j < layers[i].length; j++) {
                this.tiles[layers[i][j]] = this.tiles[layers[i][0]];
            }
        }

        this.tilesAddedBeforeRunning = [];
        this.visibleTiles = [];

        this.map2d.showTiles = this.createListener(this.showTiles.bind(this));
        this.map2d.hideTiles = this.createListener(this.hideTiles.bind(this));
        this.map2d.removeTiles = this.createListener(this.removeTiles.bind(this));
        this.map2d.showLayerTile = this.showLayerTile.bind(this);

        this.map2d.onScrollToUnit = this.createListener(this.scrollToUnit.bind(this));

        if (engine === "creator") {
            this.pixiObject._static = true;
            this.pixiObject.addComponent(MapRenderer);
        }

        this.map2d.fences.generateFences();

        this.map2d.workers.onAddWorker = this.createListener(function (worker) {
            var view = new WorkerView(worker);
            this.addTile(Map2d.LAYER_UNITS, 0, 0, view);
        }.bind(this));

        this.map2d.workers.regular.forEach(function (worker) {
            this.map2d.workers.onAddWorker(worker);
        }, this);

        this.scheduleUpdateWithPriority(-1);
    },

    onExit: function () {
        this._super();

        this.map2d.onUpdateVisitRect = function () {};
        this.map2d.addViews = function () {};
        this.map2d.removeViews = function () {};
    },

    onEnter: function () {
        this._super();

        if (engine === "creator") {
            this.tilesAddedBeforeRunning.forEach(function (tile) {
                tile._performRecursive(function (node) {
                    node.onEnter();
                    node.onEnterTransitionDidFinish();
                });
            });
            this.tilesAddedBeforeRunning = [];
        }
    },

    onEnterTransitionDidFinish: function () {
        this._super();

        this.visibleRect && this.setVisibleRect(this.visibleRect);
    },

    onResize: function () {
    },

    update: function (dt) {
        this._super(dt);

        this.updateControls && this.updateControls(dt);

        if (this.tilesVisibleRectDirty) {
            var rects = this.getTilesVisitIsoRect(this.visibleRect);
            this.map2d.setVisitRect(rects.visitRect, rects);
            delete this.tilesVisibleRectDirty;
        }

        this.updateInvisibleTiles();

        this.map2d.removeQueue.process(dt);
    },

    getLayersOrder: function () {
        return [
            [Map2d.LAYER_WATER],
            [Map2d.LAYER_BORDERS],
            [Map2d.LAYER_GROUND],
            [Map2d.ABOVE_GROUND],
            [Map2d.LAYER_UNITS, Map2d.LAYER_FOG]
        ];
    },

    updateInvisibleTiles: function () {
        var bgSize = cleverapps.resolution.getBgSize();
        var sceneBox = cc.rect(0, 0, bgSize.width, bgSize.height);
        var map2d = this.map2d;

        var disabled = map2d.lastVisitRectTime + 500 > cleverapps.timeouts.time;

        if (this.updateInvisibleTilesDisabled === disabled) {
            return;
        }
        this.updateInvisibleTilesDisabled = disabled;

        this._renderCmd.setDirtyFlag(cc.Node._dirtyFlags.transformDirty);

        this.iterateVisibleTiles(function (tile, layer, x, y) {
            tile.setVisible(disabled || map2d.isScreenCellPosition(x, y) || cc.rectIntersectsRect(sceneBox, tile.getBoundingBoxToWorld()));
        });
    },

    findTouchUnit: function (touch) {
        var cell = this.getCellByTouch(touch);
        if (cell) {
            var cellOrder = [
                { x: 0, y: 0 },
                { x: -1, y: 1 }, { x: -1, y: 0 }, { x: 0, y: 1 },
                { x: -2, y: 2 }, { x: -2, y: 1 }, { x: -1, y: 2 },
                { x: -2, y: 0 }, { x: 0, y: 2 },
                { x: -3, y: 3 }, { x: -3, y: 2 }, { x: -2, y: 3 },
                { x: -3, y: 1 }, { x: -1, y: 3 },
                { x: -4, y: 4 }, { x: -4, y: 3 }, { x: -3, y: 4 },
                { x: -4, y: 2 }, { x: -2, y: 4 },
                { x: -5, y: 5 }, { x: -5, y: 4 }, { x: -4, y: 5 },
                { x: -5, y: 3 }, { x: -3, y: 5 }
            ].reverse();

            var bestScore = 1;
            var bestUnit = undefined;

            for (var i = 0; i < cellOrder.length; i++) {
                var unit = this.map2d.getUnitWithHead(cell.x + cellOrder[i].x, cell.y + cellOrder[i].y);
                if (!unit) {
                    continue;
                }

                var score = 0;

                if (unit.checkTouchInside(touch, true)) {
                    score = 2;
                } else if (unit.checkTouchInside(touch)) {
                    score = 1;
                }

                if (score >= bestScore) {
                    bestScore = score;
                    bestUnit = unit;
                }

                if (score === 2) {
                    return bestUnit;
                }
            }

            return bestUnit;
        }
    },

    getCellByTouch: function (touch, rawValue) {
        var pos = this.convertTouchToNodeSpace(touch);
        return this.getCellByCoordinates(pos, rawValue);
    },

    findTouchFog: function (touch) {
        var cell = this.getCellByTouch(touch);
        if (cell) {
            var additionalCells = [{ x: 0, y: 0 }, { x: -1, y: 1 }, { x: -1, y: 0 }, { x: 0, y: 1 }];
            for (var i = 0; i < additionalCells.length; i++) {
                var fog = this.map2d.getFog(cell.x + additionalCells[i].x, cell.y + additionalCells[i].y);
                if (fog) {
                    if (fog.checkPointOfInterestTouchInside(touch)) {
                        return fog;
                    }
                }
            }
            return this.map2d.getFog(cell.x, cell.y);
        }
    },

    getUnitCenterPos: function (x, y) {
        var unit = this.map2d.getUnit(x, y);
        var unitView = unit && unit.getData().multicell && unit.onGetView();

        if (!unitView) {
            var tile = this.map2d.fogs.findTile(x, y);
            unitView = tile && tile.onGetView() && tile.onGetView().fogUnit;
        }

        if (unitView) {
            return this.animations.convertToNodeSpace(unitView.parent.convertToWorldSpace(unitView.getCenterPos()));
        }

        if (unit && unit.isMultiCell()) {
            return this.alignPositionInIsometricGrid(
                unit.x,
                unit.y,
                MultiCellView.calcCenterPosition(unit.findComponent(MultiCell).shape)
            );
        }

        return this.alignInIsometricGrid(x, y);
    },

    addControls: function () {
        var dragUnit, templateUnit, touchUnit, touchFog, baseTouch;

        var controlsNode = new cc.Node();
        controlsNode.setContentSize2(this.width, this.height);
        controlsNode.setAnchorPoint(0.5, 0.5);
        controlsNode.setPositionRound({ align: "center" }, { align: "center" });
        this.addChild(controlsNode, -1);

        controlsNode.debugId = "Map2dView";
        controlsNode.isMap = true;

        var releaseUnit = function (cancelled) {
            var unit = dragUnit;

            touchUnit = undefined;
            touchFog = undefined;
            dragUnit = undefined;
            templateUnit = undefined;
            baseTouch = undefined;

            if (unit) {
                unit.onDragEnd(cancelled);
            }
        };

        var createUnit = function (template, touch) {
            var cell = this.getCellByTouch(touch);

            var fog = this.map2d.getFog(cell.x, cell.y);
            if (fog) {
                return;
            }

            var unit = this.map2d.getUnit(cell.x, cell.y);
            if (template.eraser && unit) {
                unit.debugRemove();
                return;
            }

            if (!template.eraser && !unit) {
                if (Unit.CreateDebug(template, cell)) {
                    cleverapps.audio.playSound(bundles.merge.urls.spawn_landing_effect);
                }
            }
        }.bind(this);

        var getOrangerySelection = function () {
            var orangery = Map2d.currentMap.orangery;
            if (orangery) {
                return orangery.selected;
            }
        };

        var pressHandler = cleverapps.UI.onPressed(controlsNode, function (touch) {
            if (touchUnit && InfoView.IsDisplayedFor(touchUnit)) {
                touchUnit.onClickEvent(touch);
                return true;
            }

            InfoView.CloseInfo();
        });

        var pointerHandler = new PointerHandler(controlsNode, {
            filter: function (touch) {
                var unit = this.findTouchUnit(touch);
                if (unit && !unit.isBlocked()) {
                    releaseUnit(true);
                    touchUnit = unit;
                    baseTouch = touch;
                    return true;
                }

                var fog = this.findTouchFog(touch);
                if (fog) {
                    releaseUnit(true);
                    touchFog = fog;
                    baseTouch = touch;
                    return true;
                }

                return true;
            }.bind(this),

            onClick: function (touch) {
                var cell = this.getCellByTouch(touch);

                if (cleverapps.config.debugMode) {
                    console.log(cell);

                    var unit = this.map2d.getUnit(cell.x, cell.y);
                    if (unit) {
                        console.log(unit.getConsoleInfo());
                    }

                    var fog = this.map2d.getFog(cell.x, cell.y);
                    if (fog && fog.fogBlock) {
                        console.log(fog.fogBlock.getConsoleInfo());
                    }
                }

                if (getOrangerySelection() && !cleverapps.meta.isFocused()) {
                    createUnit(getOrangerySelection(), touch);
                    return;
                }

                if (baseTouch === touch) {
                    if (touchUnit) {
                        if (touchUnit === this.map2d.getUnit(touchUnit.x, touchUnit.y)) {
                            if (touchUnit.onClickEvent(touch)) {
                                releaseUnit();
                                return;
                            }
                        }
                    }

                    if (touchFog) {
                        if (touchFog === this.map2d.getFog(touchFog.x, touchFog.y)) {
                            touchFog.onClickEvent(touch);
                            releaseUnit();
                            return;
                        }
                    }

                    releaseUnit();
                }

                if (cleverapps.skins.getSlot("walkingWorkersSkins")) {
                    if (this.map2d.workers.tryMove(cell)) {
                        this.animateClick(touch, false);
                    } else {
                        this.animateClick(touch, true);
                    }
                }
            }.bind(this),

            followPointer: function (touch) {
                if (baseTouch !== touch) {
                    return;
                }

                if (dragUnit) {
                    dragUnit.onFollowPointer(touch);
                }
            },

            onDragStart: function (touch) {
                if (baseTouch !== touch) {
                    return;
                }

                if (cleverapps.meta.isFocused() && (Game.currentGame && Game.currentGame.tutorial && !Game.currentGame.tutorial.isActive())) {
                    return;
                }

                var orangery = Map2d.currentMap.orangery;

                if (orangery && orangery.selected && cleverapps.keyboardController.isPressed(cc.KEY.b)) {
                    templateUnit = orangery.selected;
                    return true;
                }

                if (touchUnit && touchUnit === this.map2d.getUnit(touchUnit.x, touchUnit.y)) {
                    if (touchUnit.onDragStart()) {
                        dragUnit = touchUnit;
                        return true;
                    }
                }
            }.bind(this),

            onDragMove: function (touch) {
                if (baseTouch !== touch) {
                    return;
                }

                if (dragUnit) {
                    dragUnit.onDragMove();
                    return;
                }

                if (templateUnit) {
                    createUnit(templateUnit, touch);
                }
            },

            onDragEnd: function (touch) {
                if (baseTouch !== touch) {
                    return;
                }

                releaseUnit();
            }
        });

        this.map2d.stopDragging = this.createListener(function () {
            releaseUnit(true);
        });

        this.toggleControls = this.createListener(function (enabled) {
            pointerHandler.setEnabled(enabled);
            pressHandler.setEnabled(enabled);
            releaseUnit(true);
        });

        if (Game.currentGame) {
            Game.currentGame.on("stop", this.toggleControls.bind(this, false));
        }

        this.updateControls = function (dt) {
            if (this.scroll && dragUnit && !cleverapps.config.wysiwygMode) {
                if (this.scroll.borderTouchScrollIteration(baseTouch, dt * 1500, 85)) {
                    dragUnit.onDragMove(baseTouch);
                }
            }
        }.bind(this);
        
        cleverapps.meta.registerControl("map2d", this.toggleControls);
    },

    setScroll: function (scroll) {
        this.scroll = scroll;
    },

    addTile: function (layer, x, y, tile) {
        if (tile.parent) {
            var position = this.convertToNodeSpace(tile.parent.convertToWorldSpace(tile.getPosition()));
            position.x = Math.round(position.x);
            position.y = Math.round(position.y);

            tile.removeTemporarily(false);
            tile.setPositionRound(position);
        } else {
            this.alignViewInIsometricGrid(x, y, tile);
        }

        tile.cell = { layer: layer, x: x, y: y };

        tile.setOrderOfArrival(cc.s_globalOrderOfArrival++);
        this._addCellTile(layer, x, y, tile);
        tile.saveStack = true;

        if (engine === "creator") {
            cc.Node.prototype.addChild.call(this, tile, undefined, undefined, true);

            if (!this.isRunning()) {
                this.tilesAddedBeforeRunning.push(tile);
            }
        } else {
            tile.setParent(this);
            if (tile.alignment) {
                tile.doAlignment();
            }
        }

        if (tile.additionalViews) {
            Object.values(tile.additionalViews).forEach(function (view) {
                this.upAdditionalViewAboveClouds(tile, view);
            }.bind(this));
        }

        if (engine === "cocos2d") {
            if (this.isRunning()) {
                tile._performRecursive(cc.Node._stateCallbackType.onEnter);
                if (this._isTransitionFinished) {
                    tile._performRecursive(cc.Node._stateCallbackType.onEnterTransitionDidFinish);
                }
            }

            tile.setVisible(true);
            cc.renderer.childrenOrderDirty = true;
        }

        if (tile.createParts) {
            tile.createParts().forEach(function (partView) {
                this.addTile(partView.part.layer, x + partView.part.x, y + partView.part.y, partView);
                partView.x = tile.x;
                partView.y = tile.y + (tile.height - tile.sprite.height) / 2;
            }.bind(this));
        }
    },

    upAdditionalViewAboveClouds: function (tile, additionalView) {
        if (tile.parent) {
            additionalView.replaceParentSamePlace(this.cloudsAbove);
            additionalView.setLocalZOrder(this.getAdditionalViewLocalZOrder(tile.unit.x, tile.unit.y));
        }
    },

    getAdditionalViewLocalZOrder: function (x, y) {
        return -this.map2d.height + y - x;
    },

    getFogViewLocalZOrder: function (x, y) {
        return this.getAdditionalViewLocalZOrder(x, y) - 100;
    },

    addChild: function (node) {
        var cell = node.cell;
        if (cell) {
            this.addTile(cell.layer, cell.x, cell.y, node);
            return;
        }

        if (cleverapps.config.debugMode && this.running) {
            console.error("Map2d.addChild", node);
            throw "Cant add child to map2dView";
        }

        this._super.apply(this, arguments);
    },

    removeTile: function (layer, x, y, tile, cleanup) {
        if (!this._removeCellTile(layer, x, y, tile)) {
            return;
        }

        var cellPosition = this.alignInIsometricGrid(x, y);
        tile.x -= cellPosition.x;
        tile.y -= cellPosition.y;

        if (tile.isVisible()) {
            cc.renderer.childrenOrderDirty = true;
        }

        if (cleanup === undefined) {
            cleanup = true;
        }
        this._detachChild(tile, cleanup);
    },

    removeChild: function (child, cleanup) {
        var cell = child.cell;
        if (cell) {
            this.removeTile(cell.layer, cell.x, cell.y, child, cleanup);
            return;
        }

        this._super.apply(this, arguments);
    },

    setLocalZOrder: function (child, zOrder) {
        if (engine === "creator" && child.cell) {
            if (child._localZOrder === zOrder) {
                return;
            }
            child._localZOrder = zOrder;
            child.arrivalOrder = cc.s_globalOrderOfArrival++;

            this.sortTiles(child.cell.layer, child.cell.y, child.cell.x);
        } else {
            this._super.apply(this, arguments);
        }
    },

    sortTiles: function (layer, y, x) {
        var tiles = this.tiles[layer][y][x];
        if (Array.isArray(tiles)) {
            tiles.sort(cc.Base.CHILDREN_ORDER_FN);
        }
    },

    reorderChild: function (child, zOrder) {
        var cell = child.cell;
        if (cell) {
            child.arrivalOrder = cc.s_globalOrderOfArrival++;
            child._setLocalZOrder(zOrder);

            this.sortTiles(cell.layer, cell.y, cell.x);

            cc.renderer.childrenOrderDirty = true;

            return;
        }

        this._super.apply(this, arguments);
    },

    _addCellTile: function (layer, x, y, tile) {
        var tiles = this.tiles[layer][y][x];
        if (!tiles) {
            this.tiles[layer][y][x] = tile;
        } else {
            if (!Array.isArray(tiles)) {
                this.tiles[layer][y][x] = [tiles];
                tiles = this.tiles[layer][y][x];
            }

            tiles.push(tile);
            tiles.sort(cc.Base.CHILDREN_ORDER_FN);
        }
    },

    _removeCellTile: function (layer, x, y, tile) {
        var tiles = this.tiles[layer][y][x];

        if (tiles === tile) {
            this.tiles[layer][y][x] = undefined;
            return true;
        }

        if (Array.isArray(tiles)) {
            var oldLength = tiles.length;
            cc.arrayRemoveObject(tiles, tile);
            var length = tiles.length;

            if (tiles.length === 1) {
                this.tiles[layer][y][x] = tiles[0];
            }

            return length < oldLength;
        }

        return false;
    },

    visitCurrent: function () {
        this._super();

        var self = this;

        this.iterateVisibleTiles(function (tile) {
            if (tile.isVisible()) {
                tile.visit(self);
            }
        });
    },

    iterateVisibleTiles: function (iterator) {
        for (var i = 0; i < this.layers.length; i++) {
            var layer = this.layers[i][0];
            var self = this;

            cc.iterateIsoRect(this.map2d.getVisitRect(), function (x, y) {
                self.iterateCellTiles(layer, x, y, iterator);
            });
        }
    },

    iterateTiles: function (iterator) {
        for (var i = 0; i < this.layers.length; i++) {
            var layer = this.layers[i][0];

            for (var y = 0; y < this.map2d.getHeight(); y++) {
                for (var x = 0; x < this.map2d.getWidth(); x++) {
                    this.iterateCellTiles(layer, x, y, iterator);
                }
            }
        }
    },

    iterateCellTiles: function (layer, x, y, iterator) {
        var tiles = this.tiles[layer][y];
        tiles = tiles && tiles[x];

        if (!tiles) {
            return;
        }

        if (!Array.isArray(tiles)) {
            iterator(tiles, layer, x, y);
            return;
        }

        for (var i = 0; i < tiles.length; i++) {
            iterator(tiles[i], layer, x, y);
        }
    },

    listTiles: function (layer, x, y) {
        var tiles = cleverapps.toArray(this.tiles[layer][y][x]) || [];
        return tiles.filter(function (tile) {
            return tile.cell.layer === layer;
        });
    },

    showTiles: function (x, y) {
        for (var i = 0; i < this.layersFlat.length; i++) {
            this.showLayerTile(this.layersFlat[i], x, y);
        }
        this.map2d.decorators.addDecoratorsViews(x, y);
    },

    showLayerTile: function (layer, x, y) {
        var object = this.map2d.getValue(layer, x, y);

        if (object === undefined) {
            return;
        }

        var tile = this.getCellLayerTile(layer, x, y);
        if (tile) {
            tile.setVisible(true);
            return;
        }

        var X = this.map2d.pointer.x;
        var Y = this.map2d.pointer.y;

        this.map2d.setPointer(x, y);
        tile = this.createView(object);
        if (tile) {
            this.addTile(tile.layer || layer, x, y, tile);
            tile.cell.view = true;
        }
        this.map2d.setPointer(X, Y);
    },

    hideTiles: function (x, y) {
        for (var i = 0; i < this.layersFlat.length; i++) {
            this.hideLayerTile(this.layersFlat[i], x, y);
        }

        this.map2d.decorators.hideDecoratorsViews(x, y);
    },

    hideLayerTile: function (layer, x, y) {
        var tile = this.getCellLayerTile(layer, x, y);

        if (tile && tile.parent === this) {
            tile.setVisible(false, true);
        }
    },

    removeTiles: function (x, y) {
        for (var i = 0; i < this.layersFlat.length; i++) {
            this.removeLayerTile(this.layersFlat[i], x, y);
        }

        this.map2d.decorators.removeDecoratorsViews(x, y);
    },

    removeLayerTile: function (layer, x, y) {
        var tile = this.getCellLayerTile(layer, x, y);

        if (tile && tile.parent === this) {
            this.putTileInPool(tile);
        }
    },

    getCellLayerTile: function (layer, x, y) {
        var object = this.map2d.getValue(layer, x, y);
        if (object === undefined) {
            return;
        }

        if (layer === Map2d.LAYER_UNITS) {
            return object instanceof Unit && object.onGetView() ? object.onGetView() : undefined;
        }
        if (layer === Map2d.LAYER_FOG) {
            return object instanceof FogTile && object.onGetView() ? object.onGetView() : undefined;
        }

        var tiles = this.tiles[layer][y][x];
        if (!tiles) {
            return;
        }

        if (!Array.isArray(tiles)) {
            return tiles.cell.layer === layer && tiles.cell.view ? tiles : undefined;
        }

        for (var i = 0; i < tiles.length; i++) {
            if (tiles[i].cell.layer === layer && tiles[i].cell.view) {
                return tiles[i];
            }
        }
    },

    putTileInPool: function (tile) {
        if (tile.clearAnimations) {
            tile.clearAnimations();
        }

        var createErrorLog = function (message, tile) {
            message = message + " Cell: " + JSON.stringify(tile.cell);
            if (tile.unit) {
                message += " Unit: " + Unit.GetKey(tile.unit);
            }
            message += (tile.actionStack || "");
            if (cleverapps.config.debugMode) {
                throw message;
            } else {
                cleverapps.throwAsync(message);
            }
        };
        if (tile.getNumberOfRunningActions() > 0) {
            createErrorLog("Node with action is removed.", tile);
        }
        if (tile.sprite instanceof cleverapps.Spine) {
            if (!tile.sprite.isSaveToRemove()) {
                createErrorLog("Node with spine is removed.", tile);
            }
        }

        if (cleverapps.oneOf(tile, Map2dWaterView, Map2dGroundView, Map2dChessGroundView)) {
            cc.pool.putInPool(tile);
        } else {
            tile.removeFromParent();
        }

        delete tile.cell;
    },

    scrollToUnit: function (duration, unit, options) {
        this.scroll.stopAutoScroll();
        this.scroll.runAction(new cc.Sequence(
            new cc.ZoomAction(duration, {
                zoom: options.zoom,
                zoomFocus: Map2d.currentMap.getView().getCell(unit.x, unit.y),
                zoomFocusPadding: options.padding
            }),
            new cc.CallFunc(options.callback)
        ));
    },

    animateClick: function (touch, warning) {
        var cell = this.getCellByTouch(touch);

        var clickAnimation = new cleverapps.Spine(bundles.merge.jsons.ground_click);
        clickAnimation.setAnimation(0, "animation", false);
        clickAnimation.setCompleteListenerRemove();
        clickAnimation.setSafeToRemove();
        clickAnimation.setLocalZOrder(10);
        this.addTile(Map2d.LAYER_UNITS, cell.x + 2, cell.y - 2, clickAnimation);

        clickAnimation.setPosition(clickAnimation.parent.convertTouchToNodeSpace(touch));

        if (warning) {
            clickAnimation.setColor(cleverapps.styles.COLORS.COLOR_RED);
        }
    }
});

Map2dView.prototype.setVisibleRect = function (visibleRect) {
    this.visibleRect = visibleRect;

    this.map2d.setScreenRect(this.screenRectToIso(visibleRect));
    this.tilesVisibleRectDirty = true;
};

Map2dView.prototype.screenRectToIso = function (rect) {
    var cell = this.getCellByCoordinates(rect);
    var width = Math.round(rect.width / cleverapps.styles.Map2dView.cell.width);
    var height = Math.round(rect.height / cleverapps.styles.Map2dView.cell.height);
    return cc.rect(cell.x, cell.y, width, height);
};

Map2dView.prototype.getTilesVisitIsoRect = function (screenRect) {
    var styles = cleverapps.styles.Map2dView.cell;

    var frame = this.map2d.tilesVisibleRectFrame;

    var showRect = cc.rect(
        screenRect.x + frame.x * styles.width,
        screenRect.y + frame.y * styles.height,
        screenRect.width + frame.width * styles.width,
        screenRect.height + frame.height * styles.height
    );

    var visitRect = cc.rect(showRect.x, showRect.y, showRect.width, showRect.height);
    var showCells = {};

    var decorators = this.map2d.decorators.getLargeDecorators();
    for (var i = 0; i < decorators.length; i++) {
        var decorator = decorators[i];
        var decoratorRect = decorator.getLargeBox();
        var screenPosition = decorator.getScreenPosition();

        if (cc.rectIntersectsRect(screenRect, decoratorRect) && !cc.rectContainsPoint(showRect, screenPosition)) {
            visitRect = cc.rectUnion(visitRect, cc.rect(screenPosition.x, screenPosition.y, 0, 0));

            if (!showCells[decorator.y]) {
                showCells[decorator.y] = {};
            }
            showCells[decorator.y][decorator.x] = true;
        }
    }

    return {
        visitRect: this.screenRectToIso(visitRect),
        showRect: this.screenRectToIso(showRect),
        showCells: showCells
    };
};

Map2dView.prototype.getCellByCoordinates = function (p, rawValue) {
    var zero = this.alignInIsometricGrid(0, 0);
    var coors = Map2dView.ScreenToIso(p.x - zero.x, p.y - zero.y);
    if (rawValue) {
        return coors;
    }
    return cc.p(Math.round(coors.x), Math.round(coors.y));
};

Map2dView.prototype.getCoordinatesByCell = function (cell) {
    var zero = this.alignInIsometricGrid(0, 0);
    var coors = Map2dView.IsoToScreen(cell.x, cell.y);
    return cc.p(coors.x + zero.x, coors.y + zero.y);
};

Map2dView.prototype.getOffsetByTouch = function (touch) {
    var cell = this.getCellByTouch(touch);
    var rawCell = this.getCellByTouch(touch, true);
    return cc.p(rawCell.x - cell.x, rawCell.y - cell.y);
};

Map2dView.prototype.getSourceCenterPosition = function (source) {
    var position = this.alignInIsometricGrid(source.x, source.y);
    if (source instanceof Unit) {
        if (source.findComponent(MultiCell)) {
            var centerDelta = source.findComponent(MultiCell).getCenter();
            position.x += centerDelta.x;
            position.y += centerDelta.y;
        }
    }
    return position;
};

Map2dView.ScreenToIso = function (x, y) {
    var dx = x / cleverapps.styles.Map2dView.cell.width;
    var dy = y / cleverapps.styles.Map2dView.cell.height;

    return {
        x: dx + dy,
        y: dx - dy
    };
};

Map2dView.IsoToScreen = function (x, y) {
    var dx = (x + y) / 2;
    var dy = (x - y) / 2;

    return {
        x: dx * cleverapps.styles.Map2dView.cell.width,
        y: dy * cleverapps.styles.Map2dView.cell.height
    };
};

Map2dView.prototype.getCell = function (x, y) {
    return new Map2dCellView(x, y, this);
};

Map2dView.prototype.hasView = function (layer, x, y) {
    var object = this.map2d.getValue(layer, x, y);
    this.map2d.setPointer(x, y);

    return object instanceof FogTile || object instanceof Highlight
        || object instanceof Unit && !this.map2d.getFog(object.x, object.y) && !object.isMultiCellBody()
        || ViewReader.hasView(object, this.map2d)
        || this.map2d.decorators.hasView(layer, x, y);
};

Map2dView.prototype.createView = function (object) {
    if (object instanceof FogTile) {
        return new FogTileView(object);
    }
    if (object instanceof Highlight) {
        return new HighlightView(object);
    }
    if (object instanceof Unit) {
        if (cleverapps.config.debugMode && !(Map2d.unitsVisible || object.code === "decorator" && Map2d.decoratorUnitsVisible)) {
            return;
        }
        if (!object.isMultiCellBody()) {
            return new UnitView(object);
        }
    } else {
        return ViewReader.parse(object, this.map2d);
    }
};

Map2dView.prototype.alignInIsometricGrid = function (x, y) {
    var X = x + y + 1;
    var Y = (x - y) + this.map2d.getHeight();
    return cc.p(X * cleverapps.styles.Map2dView.cell.width / 2 - this.cutLeft, Y * cleverapps.styles.Map2dView.cell.height / 2 - this.cutBottom);
};

Map2dView.prototype.alignViewInIsometricGrid = function (x, y, view) {
    var cellPosition = this.alignInIsometricGrid(x, y);
    var position = view.alignment || view.getPosition();
    position = view.calculatePositionRound(position.x, position.y, { parentSize: { width: 0, height: 0 } });
    position.x += cellPosition.x;
    position.y += cellPosition.y;

    delete view.alignment;

    view.setPositionRound(position.x, position.y);
};

Map2dView.prototype.alignPositionInIsometricGrid = function (x, y, position) {
    var cellPosition = this.alignInIsometricGrid(x, y);
    return cc.p(position.x + cellPosition.x, position.y + cellPosition.y);
};

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    MERGE_BONUS_TEXT: {
        size: 45,
        color: cleverapps.styles.COLORS.COLOR_RED,
        stroke: {
            color: cleverapps.styles.COLORS.WHITE,
            size: 2
        }
    }
});

cleverapps.styles.Map2dView = {
    cell: {
        width: 174,
        height: 100
    }
};
