/**
 * Created by razial on 29.02.2024
 */

var ReceiveWorkerWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        this._super({
            name: "ReceiveWorkerWindow",
            content: this.createContent(),
            noBackground: true,
            closeButtonDelay: true
        });

        Map2d.currentMap.workers.wantsToShowBonusWorkerWindow = false;

        cleverapps.meta.showControlsWhileFocused("MenuBarWorkersItem");

        this.reward = new Reward("worker", Workers.FREE_BONUS_WORKER_PERIOD);
        this.reward.receiveReward();

        Map2d.currentMap.workers.freeWorkerGained();
    },

    createContent: function () {
        var styles = cleverapps.styles.ReceiveWorkerWindow;

        var title = this.createTitle();
        var animation = this.createAnimation();

        var current = Map2d.currentMap.workers.countRegularTotal();
        var gain = this.workersGain = new WorkersGain(current, current + 1);

        return new cleverapps.Layout([title, animation, gain], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createTitle: function () {
        return cleverapps.UI.generateOnlyText("ReceiveWorkerWindow.title", cleverapps.styles.FONTS.RECEIVE_WORKER_TITLE_TEXT);
    },

    createAnimation: function () {
        var styles = cleverapps.styles.ReceiveWorkerWindow.animation;

        var container = new cc.Node();

        var animation = new Person({
            role: "worker",
            emotion: "happy"
        });
        animation.setScale(styles.scale);

        var shine = new cleverapps.Spine(bundles.workerwindow.jsons.worker_shine);
        shine.setAnimationAndIdleAfter("animation", "idle");

        var caption = this.createCaption();

        container.setContentSize(animation.width * animation.scale, animation.height * animation.scale);
        container.setAnchorPoint(0.5, 0.5);

        animation.setPositionRound(container.width / 2, container.height / 2);
        container.addChild(animation);

        caption.setPositionRound(styles.caption);
        container.addChild(caption);

        cleverapps.UI.wrap(container);

        shine.setPositionRound(container.width / 2, container.height / 2);
        container.addChild(shine, -1);

        return container;
    },

    createCaption: function () {
        var styles = cleverapps.styles.ReceiveWorkerWindow.animation.caption;

        var background = cleverapps.UI.createScale9Sprite(bundles.workerwindow.frames.caption_bg);
        background.setContentSize2(styles.width, background.height);

        var text = cleverapps.UI.generateOnlyText("ReceiveWorkerWindow.caption", cleverapps.styles.FONTS.WINDOW_TITLE_TEXT);
        text.fitTo(styles.text.width);
        text.setPositionRound(styles.text);
        background.addChild(text);

        return background;
    },

    beforeCloseAnimation: function (callback) {
        this.workersGain.receiveAnimation(function () {
            this.reward.collectRewardsAnimation(this.workersGain.getPrizeIcon(), {
                callback: callback
            });
        }.bind(this));
    },

    listBundles: function () {
        return ["workerwindow"];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    RECEIVE_WORKER_TITLE_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.COLOR_LIGHT_YELLOW,
        stroke: cleverapps.styles.DECORATORS.LIGHT_TEXT_STROKE
    }
});

cleverapps.styles.ReceiveWorkerWindow = {
    margin: 20,

    animation: {
        x: { align: "center" },
        y: { align: "center" },

        scale: 0.85,

        shine: {
            x: { align: "center" },
            y: { align: "center" }
        },

        caption: {
            x: { align: "center" },
            y: { align: "bottom", dy: -90 },

            width: 600,

            text: {
                x: { align: "center" },
                y: { align: "center", dy: 8 },

                width: 500
            }
        }
    }
};
